import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  Formik,
  FormikHelpers,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
  // useFormikContext,
} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  Bank,
} from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICANT_STATUS,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  educationQualificationMasters,
  fatherOrSpouseSelectMasters,
  genderMasters,
  maritalStatusMasters,
  modeOfHoldingRadioMaster,
  nationalitiesMasters,
  occupationDetailsMasters,
  occupationDetailsMastersForNDPMS,
  salutationsMasters,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import {
  getApplicantName,
  getApplicantType,
  getFirstHolderName,
  minDateForContributor,
  saveForLater,
  getRelation,
  applyRoleBasedStatus,
  applicationComparison,
  sendApplication_Nri,
  removeSingleQuote,
  getOccupationType,
  saveForLaterInvestorPhotoCapture,
  checkEditiable,
  checkNDPMSForIndusindBank,
  checkValuePresenceInKeyvalueMaster,
  checkNDPMS,
  isApplicantNRI,
  isCustodianICICIOrHDFC,
  isCustodianHDFC,
  isCustodianICICI,
  checkForMaritalStatusOthers,
  checkValidationBasedOnDate,
  shouldValidateUponSaveLater,
  getStep,
  isCustodianAxis,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory, useParams } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { contributorDetailsSchema } from '../../utils/schema';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Relations } from '../../utils/constant';
import { InvestorCustodianTypes, getNationalityList } from '../../redux-store/actions';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { OnEditDialogBox } from './additionalKYCDetails';
import { useCustodianData } from '../../utils/useCustodianData';
import { refLoginCheck } from '../Investments/investor-application';
import { useValidationOrConfigHook } from '../../utils/validationOrConfigHook';
import FieldValidationNote from './FieldValidationNote';

const contributorObject: Partial<Applicant> = {
  name: '',
  namePrefix: '',
  motherName: '',
  fatherOrSpouse: '',
  fatherOrSpouseName: '',
  firstName: '',
  lastName: '',
  dateOfBirth: minDateForContributor().toString(),
  status: '',
  nationality: 'Indian',
  panNo: '',
  isExisitngCustomer: '',
  customerId: '',
  relationShipWithFirstApplicant: '',
  mobile: '',
  gender: '',
  aadhaarNumber: '',
  maritalStatus: '',
  occupationDetails: '',
  occupationDescription: '',
  occupationType: '',
  aadhaarCheck: '',
  educationalQualification: '',
  taxAssesseeCheck: '',
  dateOfBecomeNRI: null,
  investorSubType: '',
  maritalStatusOthers: '',
  kraMaritalStatus: '',
  amlCheck: false,
  kraVerified: '',
};
type updateApplicant = Applicant & {
  jointApplicantRelation: string | null;
  occupationType: string | null;
};
export type Values = {
  modeOfHolding: string;
  applicants: Partial<updateApplicant>[];
  banks: Bank[];
  // poaHolderName: string;
  saveType: string;
  nationalityDropdown: string[];
  custodianData: InvestorCustodianTypes;
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  // poaHolderName: '',
  saveType: 'save and proceed',
  banks: [],
  nationalityDropdown: [],
  custodianData: {},
};

const HolderDetails = ({
  index,
  values,
  dataFetchedFromKRA,
  setValues,
  custodianData,
  maritalStatusChange,
}: {
  index: number;
  values: Values;
  dataFetchedFromKRA: boolean | null;
  setValues: FormikHelpers<Values>['setValues'];
  custodianData: InvestorCustodianTypes;
  maritalStatusChange: string;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const isFieldDisabled = [USER_ROLES.POAAPPROVER].includes(role);
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = useState<{
    index: number;
    status: string;
  } | null>(null);
  const {
    occupationType,
    aadhaarCheck,
    status = '',
    maritalStatus = '',
    kraMaritalStatus = '',
  } = values.applicants[index];
  const { accountType } = application || {};
  const selectedCustodian = application?.custodian || '';
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  const handleOpen = (index: number, status: string) => {
    setOpen({ index, status });
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.name`}
          label="Full Name *"
          placeholder="Enter Full Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={
                  isFieldDisabled ||
                  checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
                }
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          // onChange={({ target: { value } }) => {
          //   setFieldValue(`applicants.${index}.name`, value);
          //   index === 0 &&
          //     setFieldValue(
          //       'banks',
          //       values.banks.length
          //         ? values.banks.map((bank) => ({
          //             ...bank,
          //             pennydropCheck: false,
          //             pennydropVerified: false,
          //           }))
          //         : []
          //     );
          // }}
          disabled={
            isFieldDisabled ||
            dataFetchedFromKRA ||
            false ||
            checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.motherName`}
          label="Mother Name *"
          placeholder="Enter Mother Name"
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.firstName`}
          label="First Name *"
          placeholder="Enter First Name"
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.lastName`}
          label="Last Name *"
          placeholder="Enter Last Name"
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Father or Spouse *"
          name={`applicants.${index}.fatherOrSpouse`}
          items={fatherOrSpouseSelectMasters.map((fatherOrSpouse) => ({
            key: fatherOrSpouse,
            value: fatherOrSpouse,
          }))}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={
            isFieldDisabled ||
            dataFetchedFromKRA ||
            checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor) ||
            false
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          name={`applicants.${index}.dateOfBirth`}
          maxDate={minDateForContributor()}
          disabled={
            isFieldDisabled ||
            dataFetchedFromKRA ||
            checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor) ||
            false
          }
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <MFTextField
          name="date-of-birth"
          label="Date of Birth *"
          placeholder="DD/MM/YYYY"
          startAdornment={
            <InputAdornment position="start">
              <CardMedia
                component="img"
                alt="Calender"
                src="/images/calender.svg"
                sx={{ '&.MuiCardMedia-img': { width: '17px !important' } }}
              />
            </InputAdornment>
          }
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Marital Status *"
          name={`applicants.${index}.maritalStatus`}
          items={Object.keys(maritalStatusMasters).map((status) => ({
            key: maritalStatusMasters[status],
            value: status,
          }))}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid> */}
      {kraMaritalStatus && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.kraMaritalStatus`}
            label={`KRA Marital Status`}
            placeholder="Enter KRA Marital Status"
            disabled={true}
          />
        </Grid>
      )}
      {/* {checkForMaritalStatusOthers(selectedCustodian, maritalStatus) && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.maritalStatusOthers`}
            label={`Please Specify Marital Status ${
              checkValidationBasedOnDate(application?.createdAt || '', maritalStatusChange)
                ? '*'
                : ''
            }`}
            placeholder={`Enter Marital Status`}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
          />
        </Grid>
      )} */}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Occupation Type *"
          name={`applicants.${index}.occupationType`}
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
          onChange={(e) => {
            setValues({
              ...values,
              applicants: values.applicants.map((_applicant, _index) => {
                if (_index === index) {
                  return {
                    ..._applicant,
                    occupationType: e.target.value,
                    occupationDetails: '',
                    occupationDescription: '',
                  };
                }
                return _applicant;
              }) as Partial<updateApplicant>[],
            });
          }}
        />
      </Grid>
      {occupationType === 'OTHERS' && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.occupationDetails`}
            label="Occupation *"
            placeholder={`Enter Occupation`}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
            onChange={(e) => {
              const occupationDetails = e.target.value.replace(/\s+/g, ' ');
              setValues({
                ...values,
                applicants: values.applicants.map((_applicant, _index) => {
                  if (_index === index) {
                    return {
                      ..._applicant,
                      occupationDetails: occupationDetails,
                    };
                  }
                  return _applicant;
                }) as Partial<updateApplicant>[],
              });
            }}
          />
        </Grid>
      )}
      {isCustodianAxis(selectedCustodian) && occupationType === 'OTHERS' && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.occupationDescription`}
            label="Brief Description *"
            placeholder={`Enter Brief Description`}
            onChange={(e) => {
              const occupationDescription = e.target.value.replace(/\s+/g, ' ');
              setValues({
                ...values,
                applicants: values.applicants.map((_applicant, _index) => {
                  if (_index === index) {
                    return {
                      ..._applicant,
                      occupationDescription: occupationDescription,
                    };
                  }
                  return _applicant;
                }) as Partial<updateApplicant>[],
              });
            }}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
          />
        </Grid>
      )}
      {/* <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Investor Type *"
          name={`applicants.${index}.investorType`}
          items={
            custodianData
              ? Object.entries(custodianData).map(([key, item]) => ({
                  key: item.displayText,
                  value: key,
                }))
              : []
          }
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
        />
      </Grid> */}

      {/* <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`applicants.${index}.nationality`}
          label="Nationality of Applicant *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.nationality,
            value: nationality.nationality,
          }))}
          disabled={
            isFieldDisabled ||
            applicantStatusMasters[values?.applicants[index]?.status as string] ===
              applicantStatusMasters.NRI
          }
          searchFieldPlaceholder={'Search Nationality'}
        />
        <UseRadioGroup
          name={`applicants.${index}.nationality`}
          formLabel="Nationality of Applicant *"
          defaultValue="Indian"
          items={nationalitiesMasters
            .filter((_nationality) => {
              return values.applicants[index].status === 'NRI'
                ? _nationality !== 'Indian'
                : _nationality;
            })
            .map((_nationality) => ({
              label: _nationality,
              value: _nationality,
            }))}
          disabled={isFieldDisabled}
        />
      </Grid> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ '& .MuiFormControl-root': { my: 2, '& .MuiFormControlLabel-root': { mb: 0 } } }}>
          <UseRadioGroup
            formLabel="Provide Aadhaar *"
            name={`applicants.${index}.aadhaarCheck`}
            disabled={
              checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor) ||
              isCustodianHDFC(selectedCustodian)
            }
            items={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
          />
        </Grid>
        {aadhaarCheck === 'Yes' && (
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.aadhaarNumber`}
              label="Aadhar Last 4 Digit *"
              placeholder="Enter last 4 digits"
              inputProps={{ maxLength: 4 }}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.status`}
            label="Status *"
            items={
              isCustodianICICIOrHDFC(selectedCustodian) && custodianData
                ? Object.entries(custodianData).map(([key, item]) => ({
                    key: item.displayText,
                    value: key,
                  }))
                : Object.keys(applicantStatusMasters).map((status) => ({
                    key: applicantStatusMasters[status],
                    value: status,
                  }))
            }
            disabled={
              isCustodianAxis(selectedCustodian) ||
              isCustodianICICIOrHDFC(selectedCustodian) ||
              checkNDPMS(application?.accountType) ||
              isFieldDisabled ||
              checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor) ||
              APPLICATION_LISTING_STATUS[application?.status || ''] !==
                APPLICATION_LISTING_STATUS.draft
            }
          />
        </Grid>
        {/* {isCustodianICICIOrHDFC(selectedCustodian) && (
          <Grid item xs={12} sm={6}>
            <MFSelectField
              label="Investor Subtype *"
              name={`applicants.${index}.investorSubType`}
              items={
                values?.applicants[index].status &&
                custodianData &&
                custodianData[values?.applicants[index].status as string]
                  ? Object.entries(
                      custodianData[values?.applicants[index].status as string].subType
                    ).map(([key, item]) => ({
                      key: item.displayText,
                      value: key,
                    }))
                  : []
              }
              disabled={true}
            />
          </Grid>
        )} */}
        {isApplicantNRI(status) && (
          <Grid item xs={12} sm={6}>
            <TextDatePicker
              label={'Date of becoming NRI *'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'Enter Date of becoming NRI'}
              name={`applicants.${index}.dateOfBecomeNRI`}
              disabled={
                isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
              }
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ '& .MuiFormControl-root': { my: 2, '& .MuiFormControlLabel-root': { mb: 0 } } }}>
          <UseRadioGroup
            formLabel="Nationality of Applicant *"
            name={`applicants.${index}.nationality`}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
            items={[
              { label: 'Indian', value: 'Indian' },
              { label: 'Others', value: 'Others' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.panNo`}
            label="PAN"
            placeholder="Enter PAN"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.amlCheck`}
            value={values.applicants[index].amlCheck ? YesNoMaster.yes : YesNoMaster.no}
            label="AML Verified"
            placeholder="Enter AML check"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.kraVerified`}
            label="CVL KRA Verified"
            placeholder="Enter CVL KRA Verified"
            sx={{
              '& .MuiInputBase-input': {
                textTransform: 'capitalize',
              },
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
      {isCustodianAxis(selectedCustodian) && (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ '& .MuiFormControl-root': { my: 2, '& .MuiFormControlLabel-root': { mb: 0 } } }}>
            <UseRadioGroup
              formLabel="Existing Customer *"
              name={`applicants.${index}.isExisitngCustomer`}
              items={Object.keys(YesNoMaster).map((fundType) => ({
                label: YesNoMaster[fundType],
                value: fundType,
              }))}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {YesNoMaster[values.applicants[index].isExisitngCustomer || ''] === YesNoMaster.yes && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.customerId`}
                label="Customer Id *"
                placeholder="Enter Customer Id"
                disabled={checkEditiable(
                  referenceDetail.applicant_type,
                  role,
                  index + 1,
                  amcuserAsInvestor
                )}
              />
            </Grid>
          )}
        </Grid>
      )}

      {index !== 0 && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.jointApplicantRelation`}
            label="Relationship With First Applicant *"
            items={Relations.filter((relation) => {
              const { applicants } = values;
              const selectedRelations = applicants
                .filter(
                  (_applicant, ind) =>
                    ind !== index &&
                    _applicant.jointApplicantRelation &&
                    ['Mother', 'Father', 'Spouse'].includes(_applicant.jointApplicantRelation)
                )
                .map((applicant) => applicant.jointApplicantRelation);
              return !selectedRelations.includes(relation);
            }).map((relation) => ({
              key: relation,
              value: relation,
            }))}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
          />
          {values.applicants.map((applicant, idx: number) => {
            if (applicant.jointApplicantRelation === 'Others' && idx === index) {
              return (
                <MFTextField
                  name={`applicants.${index}.relationShipWithFirstApplicant`}
                  label="Please Specify Relationship *"
                  placeholder={`Enter Relationship`}
                  disabled={checkEditiable(
                    referenceDetail.applicant_type,
                    role,
                    index + 1,
                    amcuserAsInvestor
                  )}
                />
              );
            }
          })}
        </Grid>
      )}
      <ConfirmationDialog
        message={`Any change in the investor status will affect the application's status and workflow. Are you sure you want to proceed?`}
        open={open !== null}
        setOpen={handleClose}
        onSave={() => {
          setFieldValue(`applicants.${open?.index}.status`, open?.status);
          setFieldValue(`applicants.${open?.index}.nationality`, 'Indian');
          handleClose();
        }}
        onCancel={handleClose}
      />
      {/* <InfoDialog onClose={handleClose} open={open} content={'status changed to draft.'} /> */}
    </>
  );
};

const ApplicantOccupationDetails = ({
  index,
  values,
}: {
  index: number;
  values: Values;
}): JSX.Element => {
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const { application } = useSelector((store: RootStateType) => store.application);
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Educational Qualification *"
          name={`applicants.${index}.educationalQualification`}
          items={educationQualificationMasters.map((qualification) => ({
            key: qualification,
            value: qualification,
          }))}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiFormControl-root': { my: 2, '& .MuiFormControlLabel-root': { mb: 0 } } }}>
        <UseRadioGroup
          formLabel="Tax Assessee"
          name={`applicants.${index}.taxAssesseeCheck`}
          items={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      {/* <Grid container>
        <Grid item xs={12} sm={6}>
          <MFTextField name="otherInformation" label="Any other information" placeholder="Enter" />
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const [applicantToDelete, setApplicantToDelete] = useState<{
    index: number;
  } | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { accountType } = application || {};
  const [showDialogForEdit, setShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const custodian = application?.custodian || '';
  const { maritalStatusChange = '' } = useValidationOrConfigHook();

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  // }, [application]);

  const [custodianData] = useCustodianData();
  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const { applicants = [], modeOfHolding = '', banks = [] } = application || {};
        const sortedApplicants = applicants
          .map((applicant) => ({
            ...applicant,
            applicant_type: getApplicantType(applicant.applicant_type),
          }))
          .sort((applicant1, applicant2) => {
            return Number(applicant1.applicant_type) - Number(applicant2.applicant_type);
          });
        const shouldSaveForLater = shouldValidateUponSaveLater(contributorValues.saveType);
        setContributorValues({
          ...contributorValues,
          banks,
          modeOfHolding:
            modeOfHolding === 'joint'
              ? 'joint'
              : sortedApplicants.length > 1 && !checkNdpmsFlow
              ? 'either or survivor'
              : checkNdpmsFlow && sortedApplicants.length > 1
              ? checkValuePresenceInKeyvalueMaster(modeOfHolding, modeOfHoldingRadioMaster)
                ? modeOfHolding
                : 'either or survivor'
              : modeOfHolding,
          applicants: applicants.length
            ? sortedApplicants.map((applicant) => ({
                ...applicant,
                nationality: applicant.nationality
                  ? applicant.nationality
                  : [USER_ROLES.POAAPPROVER].includes(role)
                  ? ''
                  : 'Indian',
                namePrefix:
                  applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
                    ? applicant.namePrefix
                    : 'Mr.',
                jointApplicantRelation: getRelation(
                  applicant.relationShipWithFirstApplicant,
                  shouldSaveForLater
                )
                  ? applicant.relationShipWithFirstApplicant
                  : 'Others',
                relationShipWithFirstApplicant:
                  applicant.relationShipWithFirstApplicant === 'Others' ||
                  getRelation(applicant.relationShipWithFirstApplicant, shouldSaveForLater)
                    ? ''
                    : applicant.relationShipWithFirstApplicant,

                occupationType: getOccupationType(applicant.occupationDetails)
                  ? applicant.occupationDetails
                  : 'OTHERS',
                occupationDetails:
                  applicant.occupationDetails === 'OTHERS'
                    ? ''
                    : getOccupationType(applicant.occupationDetails)
                    ? ''
                    : applicant.occupationDetails,
                occupationDescription: applicant.occupationDescription || '',
                maritalStatus: applicant.maritalStatus || '',
                maritalStatusOthers: applicant.maritalStatusOthers || '',
                aadhaarNumber: applicant.aadhaarNumber || '',
                aadhaarCheck: (isCustodianHDFC(custodian) ? 'Yes' : applicant.aadhaarCheck) || '',
                isExisitngCustomer: isCustodianAxis(custodian) ? applicant.isExisitngCustomer : '',
                customerId: applicant.customerId || '',
                educationalQualification: applicant.educationalQualification || '',
                taxAssesseeCheck: applicant.taxAssesseeCheck || '',
                gender: applicant.gender || '',
                dateOfBecomeNRI: applicant.dateOfBecomeNRI || null,
                status:
                  isCustodianICICIOrHDFC(custodian) && custodianData
                    ? APPLICANT_STATUS.INDIVIDUAL
                    : applicant.status || 'Individual',
                investorSubType:
                  applicant.investorSubType || (isCustodianICICIOrHDFC(custodian) && custodianData)
                    ? 'ordinary'
                    : '',
              }))
            : [contributorObject],
          nationalityDropdown: nationalitiesMdmsMasters.countries.map((list) => list.nationality),
          custodianData: custodianData,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, custodianData]);

  // const validate = (values: Values): FormikErrors<Values> => {
  //   let errors: FormikErrors<Values> = {};
  //   const { applicants, poaHolderName } = values;
  //   if (applicants.length > 1 && !poaHolderName) {
  //     errors = { ...errors, poaHolderName: 'Please select POA' };
  //   }
  //   return errors;
  // };
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const handleApplicant = async (values: Values) => {
    const { applicants = [], modeOfHolding = '', saveType, banks = [] } = values;
    const isSaveLater = saveType !== 'save and proceed';
    const {
      id,
      currentStep,
      hasPOA,
      status,
      applicant1ReferenceId = '',
      applicationNumber,
      custodian = '',
    } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    const shouldSaveForLater = shouldValidateUponSaveLater(saveType);
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
      application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
            ...bank,
            pennydropCheck: false,
            pennydropVerified: false,
          }));
    const updatedApplicants = values.applicants.map((applicant) => {
      const { jointApplicantRelation, occupationType, aadhaarCheck, aadhaarNumber, ...rest } =
        applicant;
      return {
        ...rest,
        // status,
        // addresses: addresses.length
        //   ? addresses.map((address) => {
        //       if (
        //         address.address_type === 'overseas' &&
        //         nationality.toLowerCase() === 'indian' &&
        //         applicantStatusMasters[status as string] === applicantStatusMasters.Individual
        //       ) {
        //         return { ...address, isActive: false };
        //       }
        //       return address;
        //     })
        //   : [],
        name: removeSingleQuote(applicant.name),
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        relationShipWithFirstApplicant:
          applicant.relationShipWithFirstApplicant ||
          (getRelation(jointApplicantRelation, shouldSaveForLater)
            ? jointApplicantRelation
            : removeSingleQuote(applicant.relationShipWithFirstApplicant)),

        occupationDetails: getOccupationType(applicant.occupationDetails)
          ? occupationType
          : removeSingleQuote(applicant.occupationDetails),
        maritalStatusOthers: checkForMaritalStatusOthers(custodian, applicant?.maritalStatus || '')
          ? applicant.maritalStatusOthers
            ? removeSingleQuote(applicant.maritalStatusOthers)
            : applicant.maritalStatusOthers
          : !isCustodianICICI(custodian)
          ? applicant.maritalStatusOthers
          : '',
        aadhaarNumber: aadhaarCheck === 'Yes' ? aadhaarNumber : '',
        aadhaarCheck,
        dateOfBecomeNRI: isApplicantNRI(applicant.status || '') ? applicant.dateOfBecomeNRI : null,
        occupationDescription: removeSingleQuote(applicant.occupationDescription),
        isExisitngCustomer: isCustodianAxis(custodian) ? applicant.isExisitngCustomer : '',
        customerId:
          isCustodianAxis(custodian) &&
          YesNoMaster[applicant.isExisitngCustomer || ''] === YesNoMaster.yes
            ? applicant.customerId
            : '',
      };
    });
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
        currentStep: getStep(
          !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
          isSaveLater
        ),
      }
    );

    //save for later route for investor at liveliness
    const investorEditSaveelaterRoute =
      referenceIdForInvestorPhotoCapture &&
      saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
    const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
      ? referenceIdForInvestorPhotoCapture
      : referenceDetail.esignReferenceId;

    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...application!,
            banks: updatedBanks,
            applicants: updatedApplicants,
            modeOfHolding: updatedModeOfHolding,
            ...(saveType === 'save and proceed' && {
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : (sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_applicant1) ||
                    (!sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_amc_approver)
                  ? 'draft'
                  : status,
            }),
            ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            // !!currentStep && currentStep > 1
            //   ? applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //     ? 2
            //     : currentStep
            //   : applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId: referenceIdForSaveProceedRoute,
          })
        : referenceIdForInvestorPhotoCapture
        ? history.push(
            investorEditSaveelaterRoute.routePath,
            investorEditSaveelaterRoute.routeState
          )
        : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId: referenceIdForSaveProceedRoute,
          })
        : referenceIdForInvestorPhotoCapture
        ? history.push(
            investorEditSaveelaterRoute.routePath,
            investorEditSaveelaterRoute.routeState
          )
        : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
    }
  };

  const onsubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const { bankAccountFormCreated = false, showBankIcon = false } = application || {};
    const { applicants = [], modeOfHolding = '', banks = [] } = values;
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
      application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
            ...bank,
            pennydropCheck: false,
            pennydropVerified: false,
          }));
    const shouldSaveForLater = shouldValidateUponSaveLater(values.saveType);
    const updatedApplicants = values.applicants.map((applicant) => {
      const { jointApplicantRelation, occupationType, aadhaarCheck, aadhaarNumber, ...rest } =
        applicant;
      return {
        ...rest,
        name: removeSingleQuote(applicant.name),
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        firstName: removeSingleQuote(applicant.firstName),
        lastName: removeSingleQuote(applicant.lastName),
        relationShipWithFirstApplicant: getRelation(jointApplicantRelation, shouldSaveForLater)
          ? jointApplicantRelation
          : removeSingleQuote(applicant.relationShipWithFirstApplicant),
        occupationDetails: getOccupationType(applicant.occupationDetails)
          ? occupationType
          : removeSingleQuote(applicant.occupationDetails),
        occupationDescription: removeSingleQuote(applicant.occupationDescription),
        maritalStatusOthers: checkForMaritalStatusOthers(custodian, applicant?.maritalStatus || '')
          ? applicant.maritalStatusOthers
            ? removeSingleQuote(applicant.maritalStatusOthers)
            : applicant.maritalStatusOthers
          : !isCustodianICICI(custodian)
          ? applicant.maritalStatusOthers
          : '',
        aadhaarNumber: aadhaarCheck === 'Yes' ? aadhaarNumber : '',
        aadhaarCheck,
        isExisitngCustomer: isCustodianAxis(custodian) ? applicant.isExisitngCustomer : '',
        customerId:
          isCustodianAxis(custodian) &&
          YesNoMaster[applicant.isExisitngCustomer || ''] === YesNoMaster.yes
            ? applicant.customerId
            : '',
        dateOfBecomeNRI: isApplicantNRI(applicant.status || '') ? applicant.dateOfBecomeNRI : null,
      };
    });
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
      }
    );
    if (!checkApplication && checkNdpmsFlow && showBankIcon && bankAccountFormCreated) {
      setFinalValue(values);
      setShowForEdit(true);
    } else {
      onsubmit(values);
    }
  };

  const { status = '' } = application || {};
  const jointHolderAddRemove =
    [USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) &&
    [APPLICATION_LISTING_STATUS.draft].includes(APPLICATION_LISTING_STATUS[status]);

  return (
    <Formik
      initialValues={contributorValues}
      onSubmit={manageSubmit}
      //validationSchema={contributorDetailsSchema}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            contributorDetailsSchema(
              custodian,
              application?.createdAt || '',
              maritalStatusChange,
              shouldValidateUponSaveLater(values.saveType)
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setErrors,
        setTouched,
        setFieldValue,
        errors,
      }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {/* <Typography
              component="div"
              sx={{
                backgroundColor: 'rgba(229, 67, 92, 0.15)',
                display: 'flex',
                flexDirection: 'row',
                padding: '10px 20px',
                borderRadius: '5px',
                alignItems: 'flex-start',
                mt: 0.5,
                mb: 1,
                width: '100%',
                boxSizing: 'border-box',
              }}>
              <Typography
                sx={{
                  color: '#E5435C',
                  fontSize: '13px',
                  fontWeight: 500,
                  ml: 0,
                }}>
                <Typography
                  sx={{
                    color: '#E5435C',
                    fontSize: '13px',
                    fontWeight: 500,
                    ml: 1,
                  }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#E5435C',
                      fontSize: '13px',
                      fontWeight: 600,
                    }}>
                    Note:{'  '}
                  </Typography>
                  Data is retrieved from CVL KRA
                </Typography>
              </Typography>
            </Typography> */}
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Personal details of {getApplicantName(index + 1)} Applicant
                  {index !== 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: jointHolderAddRemove ? 'rgba(196, 42, 51, 0.8)' : '#ccc',
                        cursor: jointHolderAddRemove ? 'pointer' : 'not-allowed',
                        pointerEvents: jointHolderAddRemove ? 'all' : 'none',
                      }}
                      onClick={() => setApplicantToDelete({ index })}>
                      <Typography
                        sx={{
                          // color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          // cursor: 'pointer',
                        }}>
                        Remove Joint Holder{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <DeleteOutlineIcon
                          color={jointHolderAddRemove ? 'error' : 'disabled'}
                          fontSize="small"
                        />
                        {/* <CardMedia
                            component="img"
                            src="/images/delete-red.svg"
                            alt="Delete Icon"
                            sx={{ width: 'unset' }}
                          /> */}
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>

                <HolderDetails
                  index={index}
                  values={values}
                  dataFetchedFromKRA={applicant.dataFetchedFromKRA || null}
                  setValues={setValues}
                  custodianData={custodianData}
                  maritalStatusChange={maritalStatusChange}
                />
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Applicant occupation and education details
                </SubHeading>
                <ApplicantOccupationDetails index={index} values={values} />
              </React.Fragment>
            ))}
            {!!values.applicants.length && values.applicants.length < 3 && jointHolderAddRemove && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 2,
                  }}
                  onClick={async () => {
                    const formErrors = await validateForm();
                    if (!Object.keys(formErrors).length) {
                      await handleApplicant(values);
                      const historyStateObj = {
                        redirectTo: '/application/contributor-details',
                        application,
                        firstHolderName: getFirstHolderName(values.applicants),
                      };
                      history.push(
                        referenceIdForInvestorPhotoCapture
                          ? `/investor-liveliness/onboarding-investor/${referenceIdForInvestorPhotoCapture}/enter-pan-number`
                          : '/onboarding/enter-pan-number',
                        referenceIdForInvestorPhotoCapture
                          ? {
                              ...historyStateObj,
                              redirectTo: `/investor-liveliness/edit-application/${referenceIdForInvestorPhotoCapture}/contributor-details`,
                              // investorRefId: referenceIdForInvestorPhotoCapture,
                            }
                          : historyStateObj
                      );
                      return;
                    }
                    setTouched(setNestedObjectValues(formErrors, true));
                    setErrors(formErrors);
                  }}>
                  Add Joint Holder
                </Button>
              </Grid>
            )}
            {values.applicants.length > 1 && (
              <>
                <Grid item xs={12}>
                  {checkNdpmsFlow ? (
                    <>
                      <SubHeading>Mode of Operation</SubHeading>
                      <UseRadioGroup
                        name={`modeOfHolding`}
                        items={modeOfHoldingRadioMaster}
                        // disabled={
                        //   ![USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(
                        //     role
                        //   )
                        // }
                      />
                    </>
                  ) : (
                    <>
                      <SubHeading>Redeem </SubHeading>
                      <MFCheckbox
                        checked={values.modeOfHolding === 'either or survivor'}
                        name="holding"
                        label="Either or Survivor"
                        onChange={({ target: { checked } }) => {
                          setFieldValue('modeOfHolding', checked ? 'either or survivor' : 'joint');
                          setOpen(checked ? false : true);
                        }}
                        // disabled={
                        //   ![USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(
                        //     role
                        //   )
                        // }
                      />
                    </>
                  )}
                </Grid>
                {/* {values.modeOfHolding !== 'joint' && (
                  <>
                    <SubHeading>POA</SubHeading>
                    <Grid item xs={12}>
                      <UseRadioGroup
                        formLabel=""
                        name="poaHolderName"
                        items={values.applicants.map((applicant, index) => ({
                          label: applicant.name || `Applicant ${index + 1}`,
                          value: applicant.name || `Applicant ${index + 1}`,
                        }))}
                      />
                    </Grid>
                  </>
                )} */}
              </>
            )}
            <OnEditDialogBox
              open={showDialogForEdit}
              setOpen={setShowForEdit}
              handleSubmit={onsubmit}
              values={finalvalue}
            />
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                p: 4,
                width: { xs: '70%', sm: '70%' },
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
                Disabling “Either or Survivor” will cause issues later.
              </Typography>
              <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
                Are you willing to disable?
              </Typography>
            </Box>
            <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'joint',
                  });
                  handleClose();
                }}
                variant="outlined"
                sx={{
                  fontSize: 14,
                  color: 'primary.main',
                  minWidth: 200,
                }}>
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'either or survivor',
                  });
                  handleClose();
                }}
                sx={{
                  fontSize: 14,
                  minWidth: 200,
                }}>
                No
              </Button>
            </Grid>
          </Dialog>
          <ConfirmationDialog
            message={`Are you sure you want to delete applicant ?`}
            open={applicantToDelete !== null}
            setOpen={() => setApplicantToDelete(null)}
            onSave={async () => {
              const remainedApplicants = values.applicants.map((applicant, i) => {
                const noOfApplicants = values.applicants.length;
                if (i === applicantToDelete?.index) {
                  return {
                    ...applicant,
                    isActive: false,
                  };
                }
                if (noOfApplicants === 3 && i === 2) {
                  return {
                    ...applicant,
                    applicant_type: '2',
                  };
                }
                return applicant;
              });
              const shouldSaveForLater = shouldValidateUponSaveLater(values.saveType);
              const defaultPayload = {
                applicants: remainedApplicants.map((applicant) => {
                  const {
                    jointApplicantRelation,
                    occupationDetails,
                    occupationType,
                    aadhaarNumber,
                    aadhaarCheck,
                    ...rest
                  } = applicant;
                  return {
                    ...rest,
                    occupationDetails:
                      occupationType !== 'OTHERS' && occupationType !== null
                        ? occupationType
                        : occupationDetails
                        ? occupationDetails
                        : '',
                    relationShipWithFirstApplicant: getRelation(
                      jointApplicantRelation,
                      shouldSaveForLater
                    )
                      ? jointApplicantRelation
                      : applicant.relationShipWithFirstApplicant,
                    aadhaarNumber: aadhaarCheck === 'Yes' ? aadhaarNumber : '',
                    aadhaarCheck,
                  };
                }),
                modeOfHolding:
                  remainedApplicants.filter((applicant) => applicant.isActive).length === 1
                    ? 'single'
                    : values.modeOfHolding,
              };
              if (application?.id) {
                setApplicantToDelete(null);
                await dispatch(
                  updateApplication({
                    body: {
                      ...application,
                      ...defaultPayload,
                      commSendTo:
                        defaultPayload?.applicants?.filter((ele) => ele.isActive)?.length === 1
                          ? 'firstHolder'
                          : application?.commSendTo,
                      status:
                        !application?.hasPOA &&
                        AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                        application?.status !== 'draft' &&
                        applyRoleBasedStatus(role)
                          ? 'sent_to_amc_approver'
                          : application?.status,
                    },
                    applicationId: application.id,
                  })
                );
              }
            }}
            onCancel={() => setApplicantToDelete(null)}
          />
        </>
      )}
    </Formik>
  );
}
