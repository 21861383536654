import { Box, Button, CardMedia, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import {
  AdditionalDLCLMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  commSentToMaster,
  DLCLMasters,
  grossAnnualMasters,
  lineOfBusinessMaster,
  MonthlyIncomeMasters,
  occupationDetailsMasters,
  PEPsMasters,
  residenceMasters,
  SAVE_LATER_VALIDATION_CHECK,
  sourceForFundOptions,
  SourceOfFundMasters,
  sourceOfWealthMaster,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkEditiable,
  checkNDPMSForIndusindBank,
  currencyConversion,
  getApplicantName,
  getStep,
  isApplicantPEPOrRPEP,
  isCustodianAxis,
  isCustodianICICI,
  isCustodianICICIOrHDFC,
  isCustodianKotak,
  isCustodianNuvuma,
  isCustodianOrbis,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { Fragment, useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory, useParams } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { DatePicker } from '../../lib/formik/DatePicker';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import CloseIcon from '@mui/icons-material/Close';
import { refLoginCheck } from '../Investments/investor-application';
import FieldValidationNote from './FieldValidationNote';
import MFCheckbox from '../../lib/formik/Checkbox';
import { showError } from '../../redux-store/actions/auth';

type Values = {
  applicants: Partial<Applicant>[];
  commSendTo: string;
  saveType: string;
  dlclId: string;
  cdslAddtionalType: string;
  pledgeDeclaration: boolean;
  dividendDeclaration: boolean;
};

const initialValues: Values = {
  applicants: [
    {
      grossAnnualIncomeDate: null,
      netWorth: '',
      projectedCashTransaction: '',
      grossAnnualIncome: '',
      income: 0,
      sourceOfFund: '',
      residence: '',
      monthlyIncome: '',
      sourceOfFundOthers: '',
      netWorthDate: null,
      politicallyExposedPersonStatus: 'Not Applicable',
      otherPepInfo: '',
      ckycNo: '',
      sourceOfFundAmount: 0,
      sourceOfWealth: '',
      sourceOfWealthOthers: '',
      sourceOfWealthAmount: 0,
      lineOfBusiness: '',
      lineOfBusinessOthers: '',
    },
  ],
  commSendTo: 'firstHolder',
  saveType: 'save and proceed',
  dlclId: '',
  cdslAddtionalType: '',
  pledgeDeclaration: false,
  dividendDeclaration: false,
};

export const OnEditDialogBox = ({
  open,
  setOpen,
  handleSubmit,
  values,
  idx,
  message,
}: {
  open: boolean;
  setOpen: any;
  handleSubmit: any;
  values: any;
  idx?: number;
  message?: string;
}): JSX.Element => {
  const handleClose = () => setOpen(false);
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box sx={{ mb: 2 }}>
          <CardMedia
            component="img"
            src="/images/warning-icon-red.svg"
            sx={{ width: 'unset', mx: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
            {message
              ? message
              : 'Any modification in this section will have an effect on the bank account form, and a fresh bank account opening form will be created for implementation'}
          </Typography>
          <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
            Are you willing to Edit?
          </Typography>
        </Box>
        <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
          <Button
            onClick={() => {
              if (idx === 0) {
                handleSubmit(values, idx);
                handleClose();
              } else {
                handleSubmit(values);
                handleClose();
              }
            }}
            variant="outlined"
            sx={{
              fontSize: 14,
              color: 'primary.main',
              minWidth: 200,
            }}>
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            sx={{
              fontSize: 14,
              minWidth: 200,
            }}>
            No
          </Button>
        </Grid>
      </Dialog>
    </>
  );
};

const KYCDetails = ({
  applicant,
  index,
  value,
  values,
  setValues,
  custodian,
  amcuserAsInvestor,
}: {
  applicant: string;
  index: number;
  value: any;
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  custodian: string;
  amcuserAsInvestor: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { application } = useSelector((store: RootStateType) => store.application);
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const fundOptions =
    checkNdpmsFlow && isCustodianOrbis(custodian)
      ? Object.assign({}, SourceOfFundMasters, sourceForFundOptions)
      : checkNdpmsFlow
      ? SourceOfFundMasters
      : sourceForFundOptions;

  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Fragment>
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.grossAnnualIncome`}
            label="Gross Annual Income (in INR) *"
            items={grossAnnualMasters.map((grossIncome) => ({
              key: grossIncome.label,
              value: grossIncome.label,
            }))}
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
            // disabled={true}
          />
        </Grid>
        {(isCustodianICICIOrHDFC(custodian) || isCustodianKotak(custodian || '')) && (
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.income`}
              label="Income (in INR)"
              placeholder="Enter Income (in INR)"
              type="number"
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {value.income !== 0 && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                {currencyConversion(value.income)}
              </Typography>
            )}
          </Grid>
        )}
      </Fragment>
      {/* <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Gross Annual Income as on Date'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on Date'}
          name={`applicants.${index}.grossAnnualIncomeDate`}
        />
      </Grid>  */}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.netWorth`}
          label="Networth (in INR)"
          placeholder="Enter Net Worth (in INR)"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
        {value.netWorth && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
            }}>
            {currencyConversion(value.netWorth)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Networth as on Date'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on'}
          name={`applicants.${index}.netWorthDate`}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
        />
      </Grid>
      {(isCustodianOrbis(custodian) || checkNdpmsFlow) && (
        <>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.sourceOfFund`}
              label="Source of Fund *"
              items={Object.keys(fundOptions).map((item) => ({
                key: fundOptions[item],
                value: item,
              }))}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _index) => {
                    if (_index === index) {
                      return {
                        ..._applicant,
                        sourceOfFund: e.target.value,
                        sourceOfFundOthers: '',
                      };
                    }
                    return _applicant;
                  }) as any,
                });
              }}
              // disabled={true}
            />
          </Grid>
          {fundOptions[value.sourceOfFund] === fundOptions.Others && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.sourceOfFundOthers`}
                label="Please Specify Source of Fund *"
                placeholder={`Enter Source of Fund`}
              />
            </Grid>
          )}
        </>
      )}
      {isCustodianOrbis(custodian) && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.sourceOfFundAmount`}
              label="Fund Amount *"
              placeholder="Enter Fund Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
            {value.sourceOfFundAmount !== 0 && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                {currencyConversion(value.sourceOfFundAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.sourceOfWealth`}
              label="Source of Wealth *"
              items={Object.keys(sourceOfWealthMaster).map((item) => ({
                key: sourceOfWealthMaster[item],
                value: item,
              }))}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _index) => {
                    if (_index === index) {
                      return {
                        ..._applicant,
                        sourceOfWealth: e.target.value,
                        sourceOfWealthOthers: '',
                      };
                    }
                    return _applicant;
                  }) as any,
                });
              }}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}

              // disabled={true}
            />
          </Grid>
          {sourceOfWealthMaster[value.sourceOfWealth] === sourceOfWealthMaster.Others && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.sourceOfWealthOthers`}
                label="Please Specify Source of Wealth *"
                placeholder={`Enter Source of Wealth`}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.sourceOfWealthAmount`}
              label="Wealth Amount *"
              placeholder="Enter Wealth Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
            {value.sourceOfWealthAmount !== 0 && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                {currencyConversion(value.sourceOfWealthAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.lineOfBusiness`}
              label="Line Of Business / Industry *"
              items={Object.keys(lineOfBusinessMaster).map((item) => ({
                key: lineOfBusinessMaster[item],
                value: item,
              }))}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _index) => {
                    if (_index === index) {
                      return {
                        ..._applicant,
                        lineOfBusiness: e.target.value,
                        lineOfBusinessOthers: '',
                      };
                    }
                    return _applicant;
                  }) as any,
                });
              }}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {lineOfBusinessMaster[value.lineOfBusiness] === lineOfBusinessMaster.Others && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.lineOfBusinessOthers`}
                label="Please Specify Line Of Business / Industry *"
                placeholder={`Enter Line Of Business / Industry`}
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
          disabled={value.kraPepStatus}
        />
      </Grid>
      {isCustodianAxis(custodian) && isApplicantPEPOrRPEP(value?.politicallyExposedPersonStatus) && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.otherPepInfo`}
            label="Any Other Information *"
            placeholder="Enter Any Other Information"
            type="string"
            disabled={checkEditiable(
              referenceDetail.applicant_type,
              role,
              index + 1,
              amcuserAsInvestor
            )}
          />
        </Grid>
      )}
      {checkNdpmsFlow && (
        <>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.residence`}
              label="Residence *"
              items={Object.keys(residenceMasters).map((item) => ({
                key: residenceMasters[item],
                value: item,
              }))}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _index) => {
                    if (_index === index) {
                      return {
                        ..._applicant,
                        residence: e.target.value,
                      };
                    }
                    return _applicant;
                  }) as any,
                });
              }}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.monthlyIncome`}
              label="Monthly Income *"
              items={MonthlyIncomeMasters.map((Income) => ({
                key: Income,
                value: Income,
              }))}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((_applicant, _index) => {
                    if (_index === index) {
                      return {
                        ..._applicant,
                        monthlyIncome: e.target.value,
                      };
                    }
                    return _applicant;
                  }) as any,
                });
              }}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.projectedCashTransaction`}
              label="Projected Cash Transaction (Rs. per month) *"
              placeholder="Enter Projected Cash Transaction"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
            {value.projectedCashTransaction && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                {currencyConversion(value.projectedCashTransaction)}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={checkEditiable(
            referenceDetail.applicant_type,
            role,
            index + 1,
            amcuserAsInvestor
          )}
          // disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
        />
      </Grid>

      {/* // */}
      {(isCustodianICICIOrHDFC(custodian) ||
        isCustodianNuvuma(custodian) ||
        isCustodianAxis(custodian) ||
        isCustodianKotak(custodian)) && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`commSendTo`}
            label="Communications to be sent to *"
            items={Object.keys(commSentToMaster).map((key) => ({
              key: commSentToMaster[key],
              value: key,
            }))}
            disabled={
              values.applicants.length === 1 ||
              applicant !== 'First/Sole' ||
              checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
            }
          />
        </Grid>
      )}
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const selectedCustodian = application?.custodian || '';
  useEffect(() => {
    const {
      applicants: exisitingApplicants = [],
      commSendTo = '',
      dlclId,
      dividendDeclaration,
      cdslAddtionalType,
      pledgeDeclaration,
    } = application || {};
    setkycDetails({
      ...kycDetails,
      commSendTo: commSendTo ? commSendTo : 'firstHolder',
      dlclId: isCustodianAxis(selectedCustodian) ? dlclId || '' : '',
      cdslAddtionalType:
        isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
          ? cdslAddtionalType || ''
          : '',
      pledgeDeclaration:
        isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
          ? !!pledgeDeclaration
          : false,
      dividendDeclaration:
        isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
          ? !!dividendDeclaration
          : false,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        income: applicant.income || 0,
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        kraPepStatus: applicant.kraPepStatus,
        ckycNo: applicant.ckycNo || '',
        otherPepInfo: isApplicantPEPOrRPEP(applicant.politicallyExposedPersonStatus || '')
          ? applicant.otherPepInfo
          : '',
        // sourceOfFund: checkNdpmsFlow ? applicant.sourceOfFund : '',
        sourceOfFund:
          checkNdpmsFlow || isCustodianOrbis(selectedCustodian) ? applicant.sourceOfFund : '',
        residence: checkNdpmsFlow ? applicant.residence : '',
        monthlyIncome: checkNdpmsFlow ? applicant.monthlyIncome : '',
        // sourceOfFundOthers: checkNdpmsFlow ? applicant.sourceOfFundOthers : '',
        sourceOfFundOthers:
          checkNdpmsFlow || isCustodianOrbis(selectedCustodian) ? applicant.sourceOfFundOthers : '',
        sourceOfFundAmount: isCustodianOrbis(selectedCustodian)
          ? applicant.sourceOfFundAmount
          : null,
        projectedCashTransaction: checkNdpmsFlow ? applicant.projectedCashTransaction : '',
        netWorth: applicant.netWorth || '',
        grossAnnualIncomeDate: applicant.grossAnnualIncomeDate || null,
        netWorthDate: applicant.netWorthDate || null,
        sourceOfWealth: isCustodianOrbis(selectedCustodian) ? applicant.sourceOfWealth : '',
        sourceOfWealthOthers: isCustodianOrbis(selectedCustodian)
          ? applicant.sourceOfWealthOthers
          : '',
        sourceOfWealthAmount: isCustodianOrbis(selectedCustodian)
          ? applicant.sourceOfWealthAmount
          : null,
        lineOfBusiness: isCustodianOrbis(selectedCustodian) ? applicant.lineOfBusiness : '',
        lineOfBusinessOthers: isCustodianOrbis(selectedCustodian)
          ? applicant.lineOfBusinessOthers
          : '',
      })),
    });
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  const onSubmit = async (values: Values) => {
    try {
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const {
        applicants,
        saveType,
        commSendTo,
        dlclId,
        cdslAddtionalType,
        pledgeDeclaration,
        dividendDeclaration,
      } = values;
      const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
        ...applicant,
        ...{
          ...applicants[index],
          otherPepInfo: isApplicantPEPOrRPEP(
            applicants[index]?.politicallyExposedPersonStatus || ''
          )
            ? applicants[index]?.otherPepInfo
            : '',
          income: applicants[index]?.income
            ? applicants[index]?.income
            : applicants[index]?.income === 0
            ? 0
            : null,
        },
      }));
      const isSaveLater = saveType !== 'save and proceed';
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        commSendTo: commSendTo,
        dlclId,
        cdslAddtionalType,
        pledgeDeclaration,
        dividendDeclaration,
        currentStep: getStep(
          !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
          isSaveLater
        ),
      });

      if (
        shouldValidateUponSaveLater(values?.saveType) &&
        isCustodianAxis(selectedCustodian) &&
        DLCLMasters[dlclId || ''] === DLCLMasters.cdsl &&
        !pledgeDeclaration &&
        !dividendDeclaration
      ) {
        throw 'CDSL Declaration(s) are required';
      }
      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              commSendTo: commSendTo,
              dlclId: isCustodianAxis(selectedCustodian) ? dlclId : '',
              cdslAddtionalType:
                isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
                  ? cdslAddtionalType
                  : '',
              pledgeDeclaration:
                isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
                  ? pledgeDeclaration
                  : false,
              dividendDeclaration:
                isCustodianAxis(selectedCustodian) && DLCLMasters[dlclId || ''] === DLCLMasters.cdsl
                  ? dividendDeclaration
                  : false,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              currentStep: getStep(4, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId: referenceIdForSaveProceedRoute })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId: referenceIdForSaveProceedRoute })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const {
      applicants: exisitingApplicants = [],
      bankAccountFormCreated = false,
      showBankIcon = false,
    } = application || {};
    const { applicants } = values;
    const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
      ...applicant,
      ...applicants[index],
    }));
    const checkApplication = applicationComparison(application, {
      ...application,
      applicants: updatedApplicants,
      currentStep: application?.currentStep,
    });
    if (!checkApplication && checkNdpmsFlow && bankAccountFormCreated && showBankIcon) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      onSubmit(values);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={manageSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            KYCDetailsSchema(
              checkNdpmsFlow,
              selectedCustodian,
              shouldValidateUponSaveLater(values.saveType)
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_applicant, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              value={_applicant}
              key={index}
              index={index}
              values={values}
              setValues={setValues}
              custodian={selectedCustodian}
              amcuserAsInvestor={amcuserAsInvestor}
            />
          ))}
          {isCustodianAxis((application || {})?.custodian || '') && (
            <>
              <SubHeading>Demat Account Details</SubHeading>
              <Grid item xs={12} sm={6}>
                <MFSelectField
                  name="dlclId"
                  label="Demat Account Details (for allotment of units) *"
                  items={Object.keys(DLCLMasters).map((Id) => ({
                    key: DLCLMasters[Id],
                    value: Id,
                  }))}
                />
              </Grid>
              {DLCLMasters[values?.dlclId || ''] === DLCLMasters.cdsl && (
                <>
                  <Grid item xs={12} sm={6}>
                    <MFSelectField
                      name="cdslAddtionalType"
                      label="Additional Type For CDSL *"
                      items={Object.keys(AdditionalDLCLMasters).map((Id) => ({
                        key: AdditionalDLCLMasters[Id],
                        value: Id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mb: { xs: 2.8, md: 1 } }}>
                    <MFCheckbox
                      name="pledgeDeclaration"
                      label="I/We would like to instruct the DP to accept all the pledge instructions in my/our account without any other further instruction from my/our end."
                      sx={{ letterSpacing: '1px', mr: 4 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mb: { xs: 2.8, md: 1 } }}>
                    <MFCheckbox
                      name="dividendDeclaration"
                      label='I/We wish to receive dividend / interest directly into my bank account as given below through ECS (If not marked, the default option would be "Yes")'
                      sx={{ letterSpacing: '1px' }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
          />
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
