/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import { AdminStrategyDetailsType } from '../types/api-types';
import {
  GetStrategiesResponseBody,
  GET_STRATEGIES,
  GET_STRATEGIES_SUCCESS,
  GET_STRATEGY,
  GET_STRATEGY_SUCCESS,
  UPDATE_STRATEGY,
  UPDATE_STRATEGY_SUCCESS,
  GetStrategiesListResponseBody,
  ADD_STRATEGY_SUCCESS,
  ADD_STRATEGY,
} from '../types/strategies';

export type strategiesParams = {
  isActive?: boolean;
  isApplicableForDirect?: boolean;
  isApplicableForDistributor?: boolean;
  applicableForType?: string;
  distributorId?: string | number | null;
  isDPMSFlow?: boolean;
  applicableFlowType?: string;
  isNDPMSFlow?: boolean;
};

export const getStrategies =
  (params?: strategiesParams) =>
  async (dispatch: any): Promise<GetStrategiesResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'GET',
        types: [GET_STRATEGIES_SUCCESS, GET_STRATEGIES],
        params,
      },
    });
  };

export const getStrategyById =
  (Id: string) =>
  async (dispatch: any): Promise<AdminStrategyDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}`,
        method: 'GET',
        types: [GET_STRATEGY_SUCCESS, GET_STRATEGY],
      },
    });
  };

export const addStrategy =
  (body: AdminStrategyDetailsType) =>
  async (dispatch: any): Promise<GetStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'POST',
        types: [ADD_STRATEGY_SUCCESS, ADD_STRATEGY],
        body,
      },
    });
  };

export const updateStrategy =
  (Id: string, body: any) =>
  async (dispatch: any): Promise<GetStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}/update`,
        method: 'POST',
        types: [UPDATE_STRATEGY_SUCCESS, UPDATE_STRATEGY],
        body,
      },
    });
  };
