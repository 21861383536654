import { AnyAction } from 'redux';
import { GET_VALIDATIONORCONFIGMASTER_SUCCESS, ValidationOrConfigMaster } from '../types/mdms';
import { LOGOUT_SUCCESS } from '../types/auth';
import { CREATE_APPLICATION_SUCCESS, GET_ALL_APPLICATION_SUCCESS } from '../types/application';

export type ValidationOrConfigChecksProps = {
  validationOrConfigChecks: ValidationOrConfigMaster;
};

const initialState: ValidationOrConfigChecksProps = {
  validationOrConfigChecks: {},
};

export const validationOrConfigReducer = (
  state = initialState,
  action: AnyAction
): ValidationOrConfigChecksProps => {
  switch (action.type) {
    case GET_VALIDATIONORCONFIGMASTER_SUCCESS:
      return {
        ...state,
        validationOrConfigChecks: action.body,
      };
    case LOGOUT_SUCCESS:
    case GET_ALL_APPLICATION_SUCCESS:
    case CREATE_APPLICATION_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
