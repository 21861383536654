import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { CommonLayout } from '../commonComponents';
import AddPortFolioManager from './AddPortFolioManager';
import PortFolioManagerDetails from './PortfolioManager';
import { IdDependencyRoutes } from '../userManagement/router';

function Routes({ historyLocationState }: { historyLocationState: any }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${path}/details`} />} />
        <Route path={`${path}/details`} component={PortFolioManagerDetails} />
        <Route path={`${path}/add-porfolioManager`} component={AddPortFolioManager} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-porfolioManager`}
          component={AddPortFolioManager}
          replaceUrl={`/mitc`}
        />
      </Switch>
    </Router>
  );
}

export const PortFolioManagerSetup = (): JSX.Element => {
  const history = useHistory();
  return (
    <CommonLayout>
      <Routes historyLocationState={history.location.state} />
    </CommonLayout>
  );
};
