import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Applicant } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import {
  checkAddressField,
  isCustodianAxis,
  isCustodianHDFC,
  isCustodianICICI,
  isCustodianICICIOrHDFC,
  isCustodianKotak,
  isCustodianNuvuma,
  isCustodianOrbis,
} from '../../utils/utilityFunctions';
import {
  ContactRelations,
  ContactRelationsForHDFC,
  ICICIContactRelations,
} from '../../utils/constant';

export default function ContactDetails({
  applicants = [],
  custodian = '',
}: {
  applicants: Partial<Applicant>[];
  custodian: string;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Contact Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                    {title}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={2}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'E-Mail ID'} description={applicant.email} />
                      {(isCustodianHDFC(custodian) || isCustodianAxis(custodian)) && (
                        <KeyValue
                          title={'This Email Id Belongs To?'}
                          description={ContactRelationsForHDFC[applicant.emailDeclaration || '']}
                        />
                      )}
                      <KeyValue
                        title={'Mobile Number'}
                        description={
                          applicant.countryCode
                            ? applicant.countryCode + '-' + applicant.mobile
                            : applicant.mobile
                        }
                      />

                      {(isCustodianHDFC(custodian) || isCustodianAxis(custodian)) && (
                        <KeyValue
                          title={'This Mobile Number Belongs To?'}
                          description={ContactRelationsForHDFC[applicant.mobileDeclaration || '']}
                        />
                      )}
                      {(isCustodianNuvuma(custodian || '') ||
                        isCustodianICICI(custodian || '') ||
                        isCustodianKotak(custodian || '')) && (
                        <KeyValue
                          title={
                            isCustodianICICI(custodian || '') || isCustodianKotak(custodian || '')
                              ? 'KRA Email Id & Mobile Number Belongs To?'
                              : 'This Email Id & Mobile Number Belongs To?'
                          }
                          description={
                            isCustodianICICI(custodian || '') || isCustodianKotak(custodian || '')
                              ? ICICIContactRelations[applicant.mobileEmailDeclaration || '']
                              : ContactRelations[applicant.mobileEmailDeclaration || '']
                          }
                        />
                      )}
                      {applicant.kraMobileNumber && (
                        <KeyValue
                          title={'KRA Mobile Number'}
                          description={applicant.kraMobileNumber}
                        />
                      )}
                      {applicant.kraEmail && (
                        <KeyValue title={'KRA E-Mail ID'} description={applicant.kraEmail} />
                      )}
                      <KeyValue
                        title={'Phone Number (Office)'}
                        description={applicant.phoneNumberoffice}
                      />
                      <KeyValue
                        title={'Phone Number (Residential)'}
                        description={applicant.phoneNumberResidence}
                      />
                      {!!applicant?.addresses?.length &&
                        applicant?.addresses.map((address) => (
                          <React.Fragment key={address.id}>
                            <KeyValue
                              title={`${address.address_type} Address`}
                              description={`${checkAddressField(
                                address.address1
                              )}${checkAddressField(address.address2)}${checkAddressField(
                                address.address3
                              )}
                              ${checkAddressField(address.city)}${
                                isCustodianICICIOrHDFC(custodian) ||
                                isCustodianOrbis(custodian) ||
                                isCustodianKotak(custodian) ||
                                isCustodianAxis(custodian)
                                  ? checkAddressField(address.district)
                                  : ''
                              }${checkAddressField(address.state)}${checkAddressField(
                                address.country
                              )}${address.pincode}`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
