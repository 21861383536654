import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  AddTrusteeRequestBody,
  Trustee,
  mdmsCountriesList,
  nationaliyType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addTrustee,
  UpdateTrustee,
  getNationalityList,
  getTrustesById,
  gettrusteeAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { TrusteeAndFundManagerAMCAuthorisedSchema } from '../../utils/schema';
import { Location } from 'history';
import { CountryCodesDropDown } from '../commonComponents';
import { minDateForContributor } from '../../utils/utilityFunctions';
import { DatePicker } from '../../lib/formik/DatePicker';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: AddTrusteeRequestBody = {
  name: '',
  email: '',
  phone: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  leegalityName: '',
  isActive: true,
};

export default function AddTrustee({
  location,
}: {
  location: Location<{ trusteeId: number }>;
}): JSX.Element {
  const { trusteeId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [formikValues, setFormikValues] = useState<AddTrusteeRequestBody | null>();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //       if (!formikValues) return;
  //       if (!trustee) {
  //         await dispatch(addTrustee(formikValues));
  //       } else {
  //         await dispatch(UpdateTrustee(id, formikValues));
  //       }
  //       if (!isComponentActive) return;
  //       history.push('trustee');
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);
  const [trusteeDetails, setTrusteeDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        if (trusteeId) {
          const getTrusteeDetails = (await dispatch(
            getTrustesById(trusteeId)
          )) as unknown as Trustee;
          const {
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            isActive,
          } = getTrusteeDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getTrusteeDetails.user;
          setTrusteeDetails({
            ...trusteeDetails,
            name,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(trusteeAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddTrusteeRequestBody) => {
    try {
      if (!trusteeId) {
        await dispatch(
          addTrustee({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push('trustee');
      } else {
        await dispatch(
          UpdateTrustee(trusteeId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('trustee-details', { trusteeId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={trusteeDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={TrusteeAndFundManagerAMCAuthorisedSchema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    trusteeId
                      ? history.push('trustee-details', { trusteeId: values.id })
                      : history.push('trustee')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    trusteeId
                      ? history.push('trustee-details', { trusteeId: values.id })
                      : history.push('trustee')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {trusteeId ? 'Trustee' : 'Onboarding Trustee'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name (Individual) *"
                      placeholder="Enter Trustee Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="leegalityName"
                      label="Leegality Name *"
                      placeholder="Enter Leegality Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="Pan"
                      placeholder="Enter Pan "
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      disabled={!accessForEditAdd}
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      label={'Date of birth'}
                      inputLabelStyles={{
                        transform: 'unset',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      placeholder={'Enter Date of birth'}
                      name={`dateOfBirth`}
                      disabled={!values.isActive}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="pincode"
                      label="Pincode"
                      placeholder="Enter Pincode"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="state"
                      label="State"
                      placeholder="Enter State"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="city"
                      label="City"
                      placeholder="Enter City"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
