import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import { Notes, NotesWithText, ProceedSaveLater, SubHeading } from './components';
import {
  Formik,
  setNestedObjectValues,
  useField,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import React, { useEffect, useState } from 'react';
import {
  AddressProofsMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APMIFlow,
  APPLICANT_STATUS,
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  BankAccountProofsMasters,
  docInvalidCheck,
  ENABLE_KRA_DOCUMENT_FETCH,
  IdentityProofsMasters,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import {
  Applicant,
  ApplicationProps,
  Bank,
  Document,
  DocumentType,
  FileType,
  individuals_Poa_nonIndividuals_Documents,
  InvestorEditRouteLinkObj,
  KraDocumentType,
  NomineeType,
  RiskProfileMaster,
} from '../../redux-store/types/api-types';
import {
  createBankAccountFormNDPSM,
  getDocuments,
  isFormValidForSubmission,
  pennyDropVerification,
  updateApplication,
} from '../../redux-store/actions/application';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { documentDetailsSchema, documentDetailsSchemaForInvestorLogin } from '../../utils/schema';
import { logout, showError } from '../../redux-store/actions/auth';
import {
  checkEditiable,
  fieldValidateForInvestor,
  documentNameValidationCheckForMultipleDocumentsMandatory,
  getApplicantName,
  getInvestorRouteLinks,
  isMinor,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  sendApplication_Nri,
  checkNDPMSForIndusindBank,
  checkBankAccoutFieldEnableOrDisalble,
  turnEditOnInDoc,
  isApplicantNRI,
  isCustodianICICIOrHDFC,
  isCustodianICICI,
  isCustodianHDFC,
  isBankAccountProofUploadedFromBankSection,
  shouldValidateUponSaveLater,
  getStep,
  isCustodianAxis,
  getAllStrategiesByDistributorType,
  isCustodianKotak,
} from '../../utils/utilityFunctions';
import { getFieldTitle } from '../ApplicationDetails/DocumentDetails';
import { setLabels } from 'react-chartjs-2/dist/utils';
import { applicationComparison, applyRoleBasedStatus } from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import {
  getMaskedAadhar,
  getNationalityList,
  MaskedAadharBody,
  MaskedAadharResponse,
  riskProfileMasterData,
} from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import { KraDocument } from './kraDocument';
import { LoadingButton } from '@mui/lab';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { ConfirmationDialog, FileUploadDialog } from '../commonComponents';
import { MFTextField } from '../../lib/formik';
import { DatePicker } from '../../lib/formik/DatePicker';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import { SxProps } from '@mui/system';
import { OnEditDialogBox } from './additionalKYCDetails';
import { useCustodianData } from '../../utils/useCustodianData';
import { refLoginCheck } from '../Investments/investor-application';
import { useValidationOrConfigHook } from '../../utils/validationOrConfigHook';
import FieldValidationNote from './FieldValidationNote';
import en from '../../lang/en-us';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const FilePondHeader = ({
  multipleFiles,
  documentsLength,
  index,
  docName,
  required,
  sx,
}: {
  multipleFiles: string;
  documentsLength: number;
  index: number;
  docName: string;
  required: string;
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <>
      {multipleFiles === 'true' && documentsLength > 1 && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            ...sx,
          }}>
          Upload
          {index === 0
            ? ` front ${required === 'true' ? '*' : ''}`
            : ` back ${
                required === 'true'
                  ? '*'
                  : documentNameValidationCheckForMultipleDocumentsMandatory(docName)
                  ? ''
                  : '(if applicable)'
              }`}
        </Typography>
      )}
    </>
  );
};

const FileUpload = ({
  onFileChange,
  name,
  documentData,
  disabled,
  onFileUpload,
  onFileUploaded,
  displayReplaceButton = true,
  multipleFiles,
  index,
  documentsLength,
}: {
  onFileChange: (
    value: string,
    documentId: string,
    errorOnUpload?: boolean,
    removeFile?: boolean
  ) => void;
  name: string;
  documentData: documentDetails;
  disabled: boolean;
  onFileUpload: () => void;
  onFileUploaded: () => void;
  displayReplaceButton?: boolean;
  multipleFiles: string;
  index: number;
  documentsLength: number;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const { token: investorAuthToken } = useSelector((store: RootStateType) => store.investor);
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';
  const [open, setOpen] = useState({ show: false, message: '', code: '' });

  // eslint-disable-next-line
  const handleOpen = (result: any) => {
    setOpen({ show: true, message: result.message, code: result.code });
  };
  const handleClose = () => {
    setOpen({ show: false, message: '', code: '' });
  };
  useEffect(() => {
    if (files?.length === 0) {
      onFileChange('', documentData?.documentId || '', false);
    }
  }, [files]);
  const handleFileChange = (name: string, documentId: string) => {
    try {
      onFileChange(name, documentId);
    } catch (e) {
      onFileUploaded();
      console.error((e as Error).message);
    }
  };
  return (
    <>
      {!documentData.file ? (
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
              pointerEvents: 'none',
            },
            '.filepond--drop-label': {
              cursor: disabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            '.filepond--root': {
              mb: '0em',
            },
            pt: 4,
          }}>
          <FilePondHeader
            multipleFiles={multipleFiles}
            documentsLength={documentsLength}
            index={index}
            docName={documentData.documentName}
            required={documentData?.required || ''}
          />
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken || investorAuthToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.name, result.id);
                  onFileUploaded();
                  return response;
                },
                ondata: (formData: FormData): FormData => {
                  if (
                    isCustodianICICI(selectedCustodian) &&
                    documentData.documentType === 'investorSignature'
                  ) {
                    formData.append('fileSizeRestriction', '100'); //fileSizeRestriction value should be in KB
                  }
                  return formData as unknown as FormData;
                },
                onerror: (response) => {
                  if (response) {
                    const result = JSON.parse(response);
                    if (result && result.message) {
                      onFileChange('', '', true, true);
                      handleOpen(result);
                    }
                  } else {
                    onFileChange('', '', true, true);
                    handleOpen({ code: '', message: en.networkText.unableToProcess });
                  }
                  return response;
                },
              },
            }}
            onaddfilestart={() => {
              onFileUpload();
            }}
            onaddfile={(error, file) => {
              if (error) {
                onFileChange('', '', true, true);
                onFileUploaded();
              }
            }}
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                } else {
                  onFileUploaded();
                }
              }
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            onprocessfilerevert={() => {
              onFileChange('', '', false, true);
            }}
            name="file"
            maxFileSize={
              isCustodianICICI(selectedCustodian) &&
              documentData.documentType === 'investorSignature'
                ? '1MB'
                : '2MB'
            }
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={
              ['investorSignature'].includes(documentData.documentType)
                ? INVESTOR_IMAGE_UPLOAD_FORMATS
                : IMAGE_UPLOAD_FORMATS
            }
            labelIdle={`<span class="filepond--label-action">Upload</span>`}
            labelFileTypeNotAllowed={'Invalid file type'}
            labelMaxFileSize={`Max file size is ${
              isCustodianICICI(selectedCustodian) &&
              documentData.documentType === 'investorSignature'
                ? '1MB'
                : '2MB'
            }`}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            disabled={disabled}
            allowPaste={false}
          />
        </Box>
      ) : (
        <>
          <FilePondHeader
            multipleFiles={multipleFiles}
            documentsLength={documentsLength}
            index={index}
            docName={documentData.documentName}
            required={documentData?.required || ''}
            sx={{ mt: '30px' }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px dashed #AAB2BA',
              borderRadius: '6px',
              mt: multipleFiles === 'true' && documentsLength > 1 ? '1px' : '30px',
              minHeight: '65px',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                src={documentData.file.thumbnailUrl || '/images/photo-placeholder.jpg'}
                alt="aadhar"
                width="50"
                height="50"
                sx={{ display: 'flex', width: 'unset', mx: 1 }}
              />
            </Box>
            <Typography
              component="span"
              sx={{
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: 1,
              }}>
              {documentData.file.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
                flexWrap: 'wrap',
              }}>
              <Button
                sx={{
                  color: 'primary.main',
                  border: '1px dashed #2057A6',
                  mb: 1,
                }}
                onClick={() => window.open(documentData.file?.viewUrl)}>
                View
              </Button>
              {!disabled && displayReplaceButton && (
                <Button
                  variant="contained"
                  onClick={() => {
                    onFileChange('', '', false, true);
                  }}
                  sx={{ mb: 1 }}>
                  Replace
                </Button>
              )}
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  cursor: 'pointer',
                  pointerEvents: 'all',
                  mb: 1,
                }}
                onClick={() => window.open(documentData.file?.url, '_blank')}>
                <CardMedia
                  component="img"
                  src={'/images/download-icon.png'}
                  alt="Download"
                  sx={{ width: '25px' }}
                />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
      <FileUploadDialog
        handleClose={handleClose}
        show={open.show}
        message={open.message}
        code={open.code}
      />
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

export type documentDetails = {
  documentType: string;
  documentName: string;
  documentId: string;
  loading?: boolean;
  isActive: boolean;
  file?: FileType;
  fileView?: FileType;
  required?: string;
  uniqueKey?: string;
  options?: string[];
  id?: number;
  errorOnUpload?: boolean;
  [key: string]: string | boolean | FileType | undefined | string[] | number;
};

type documentProps = {
  documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
  kraDocument?: KraDocumentType[];
  status?: string;
};

type nomineeDocumentProps = {
  nomineedocuments: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
};
export interface Values {
  applicants: documentProps[];
  nominees: nomineeDocumentProps[];
  saveType: string;
  banks: Bank[];
  hasPOA: boolean;
  [key: string]:
    | documentProps[]
    | string
    | undefined
    | string[]
    | Bank[]
    | boolean
    | nomineeDocumentProps[];
}
export const document_object = {
  documentId: '',
  isActive: true,
  errorOnUpload: false,
};
export default function DocumentDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUpload, setDocumentUpload] = useState(false);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);
  const [investorRouteObj, setInvestorRouteObj] = useState<InvestorEditRouteLinkObj | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { path } = useRouteMatch();
  const routeForNDPSM = path === '/application/document-details-ndpms';
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const kycUpdateRouteCheck =
    path === '/investment-details/:referenceId/kyc-update/xmlDocument-details';

  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();
  const { enqueueSnackbar } = useSnackbar();
  const [custodianData] = useCustodianData();
  const validationOrConfigChecks = useValidationOrConfigHook();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentsData(response as Document);
        const routeLinkObj =
          investorEditObj && investorEditObj?.length > 0
            ? getInvestorRouteLinks(
                investorEditObj,
                history?.location?.pathname?.split('/')?.pop() || '',
                referenceDetail
              )
            : null;
        setInvestorRouteObj(routeLinkObj);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const {
    applicants = [],
    banks = [],
    hasPOA = true,
    nominees = [],
    bankAccountFormCreated = false,
    showBankIcon = false,
    ndpmsSectionEdited = false,
    custodian = '',
  } = application || {};

  const initialValues: Values = {
    applicants: applicants.map((applicant, applicant_index) => {
      let docData = '';
      let custodianDoc = '';
      if (isCustodianHDFC(custodian)) {
        custodianDoc = APPLICATION_TYPE_FOR_DOCUMENTS.HDFC;
      }
      if (isCustodianAxis(custodian)) {
        custodianDoc = APPLICATION_TYPE_FOR_DOCUMENTS.AXIS;
      }
      if (isCustodianKotak(custodian)) {
        custodianDoc = APPLICATION_TYPE_FOR_DOCUMENTS.Kotak;
      }
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
      }
      if (!hasPOA && isApplicantNRI(applicant.status as string)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
      }
      if (hasPOA && isApplicantNRI(applicant.status as string)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
      }
      if (checkNdpmsFlow) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NDPSM_DOC;
      }
      return {
        documents:
          typeof documentsData !== 'undefined'
            ? [
                ...((documentsData as Document)[docData] || []),
                ...((documentsData as Document)[custodianDoc] || []),
              ]
                .filter((doc) => {
                  if (
                    hasPOA &&
                    applicant.applicant_type !== '1' &&
                    doc.documentType === 'poaNotarized'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .filter((document) => {
                  if (applicant.amlCheck && document.documentType === 'compliance_document') {
                    return;
                  }
                  return document;
                })
                .filter((ele) => ele)
                .filter((doc) => {
                  if (
                    !applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return;
                  }
                  if (applicant_index > 0 && doc.documentType === 'newBankAccountProof') {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((doc) => {
                  if (
                    applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  if (isCustodianAxis(custodian) && doc.documentType === 'addressProof') {
                    return { ...doc, required: 'true' };
                  }
                  if (
                    checkNdpmsFlow &&
                    bankAccountFormCreated &&
                    showBankIcon &&
                    routeForNDPSM &&
                    doc.documentType === 'newBankAccountProof'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  if (
                    checkNdpmsFlow &&
                    bankAccountFormCreated &&
                    !showBankIcon &&
                    !routeForNDPSM &&
                    doc.documentType === 'newBankAccountProof'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                .map((updateRequired) => {
                  if (
                    updateRequired.documentType === 'investorSignature' &&
                    !fieldValidateForInvestor(
                      referenceDetail.applicant_type,
                      applicant_index + 1,
                      false
                    )
                  ) {
                    return {
                      ...updateRequired,
                      required: 'false',
                    };
                  }
                  return updateRequired;
                })
                .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const { documentType, documentName, multipleFiles, required, options } = doc;
                  const { documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  const defaultDocObj = {
                    documentType,
                    documentName,
                    required,
                    options,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      '0') as string,
                    ...document_object,
                  };
                  const existingDocObj = docsOfCurrentDocType?.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                      loading = false,
                    } = doc;
                    return {
                      id: doc.id,
                      applicantId: doc.applicantId,
                      documentType,
                      documentName,
                      documentId,
                      loading,
                      isActive,
                      file,
                      options,
                      required:
                        ind === 1 &&
                        !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                          ? 'false'
                          : required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  });
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? multipleFiles === 'true' && existingDocObj?.length === 1
                        ? [
                            ...existingDocObj,
                            {
                              ...defaultDocObj,
                              documentName: existingDocObj[existingDocObj.length - 1].documentName,
                              required: documentNameValidationCheckForMultipleDocumentsMandatory(
                                existingDocObj[existingDocObj.length - 1].documentName
                              )
                                ? required
                                : 'false',
                              uniqueKey: (applicant_index.toString() +
                                '-' +
                                index.toString() +
                                '-' +
                                '1') as string,
                            },
                          ]
                        : existingDocObj
                      : multipleFiles === 'true'
                      ? [
                          defaultDocObj,
                          {
                            ...defaultDocObj,
                            required: documentNameValidationCheckForMultipleDocumentsMandatory(
                              defaultDocObj.documentName
                            )
                              ? required
                              : 'false',
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : [defaultDocObj],
                    required,
                    multipleFiles,
                    options,
                  };
                })
            : [],
        kraDocument: applicant.kraDocument,
        status: applicant.status,
      };
    }),
    nominees: nominees.map((nominee, nominee_index) => {
      return {
        nomineedocuments:
          typeof documentsData !== 'undefined'
            ? (isMinor(nominee.dateOfBirth || '')
                ? [
                    ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                      []),
                    ...((documentsData as Document)[
                      APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                    ] || []),
                  ].map((doc) => {
                    if (doc.documentType === 'nomineeIdProof') {
                      return { ...doc, required: 'false' };
                    }
                    if (doc.documentType === 'guardianIdProof') {
                      return { ...doc, required: 'true' };
                    }
                    return doc;
                  })
                : (
                    (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
                  ).map((_nomineeDoc) => {
                    if (
                      isCustodianAxis(custodian) &&
                      _nomineeDoc.documentType === 'nomineeIdProof'
                    ) {
                      return { ..._nomineeDoc, required: 'true' };
                    }
                    return _nomineeDoc;
                  })
              ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                const defaultDocObj = {
                  documentType,
                  documentName,
                  required,
                  options,
                  uniqueKey: (nominee_index.toString() +
                    '-' +
                    index.toString() +
                    '-' +
                    '0') as string,
                  ...document_object,
                };
                const existingDocObj = docsOfCurrentDocType.map((doc, ind) => {
                  const {
                    documentName = '',
                    documentType = '',
                    documentId = '',
                    isActive = true,
                    file = undefined,
                  } = doc;
                  return {
                    id: doc.id,
                    nomineeId: doc.nomineeId,
                    documentType,
                    documentName,
                    documentId,
                    isActive,
                    file,
                    options,
                    required:
                      ind === 1 &&
                      !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                        ? 'false'
                        : required,
                    uniqueKey: (nominee_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      ind.toString()) as string,
                  };
                });
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? multipleFiles === 'true' && existingDocObj?.length === 1
                      ? [
                          ...existingDocObj,
                          {
                            ...defaultDocObj,
                            documentName: existingDocObj[existingDocObj.length - 1].documentName,
                            required: documentNameValidationCheckForMultipleDocumentsMandatory(
                              existingDocObj[existingDocObj.length - 1].documentName
                            )
                              ? required
                              : 'false',
                            uniqueKey: (nominee_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : existingDocObj
                    : multipleFiles === 'true'
                    ? [
                        defaultDocObj,
                        {
                          ...defaultDocObj,
                          required: documentNameValidationCheckForMultipleDocumentsMandatory(
                            defaultDocObj.documentName
                          )
                            ? required
                            : 'false',
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '1') as string,
                        },
                      ]
                    : [defaultDocObj],
                  required,
                  multipleFiles,
                  options,
                };
              })
            : [],
      };
    }),
    saveType: 'save and proceed',
    banks: banks,
    hasPOA: hasPOA,
  };
  const updatedApplicants = (
    exisitingApplicants: Partial<Applicant>[],
    applicants: documentProps[]
  ): Partial<Applicant>[] => {
    return exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const finalDocs = documents.map((doc) => {
        doc.documentsList.map((_documentCheck) => {
          if (_documentCheck?.errorOnUpload) {
            throw docInvalidCheck;
          }
        });
        return doc.documentsList.filter((d) => d.documentId);
      });
      const getTypedDeclaration = applicant?.documents?.find(
        (_doc) => _doc.documentType === 'typedDeclaration' && _doc.documentId
      );
      return {
        ...applicant,
        documents: (getTypedDeclaration
          ? [...finalDocs.flat(), { ...getTypedDeclaration, uniqueKey: '', required: '' }]
          : finalDocs.flat()
        ).map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, errorOnUpload, ...rest } = doc;
          return rest;
        }),
      };
    }) as Partial<Applicant>[];
  };

  const updatedNominees = (
    existingNominees: NomineeType[],
    nominees: nomineeDocumentProps[]
  ): NomineeType[] => {
    return existingNominees.map((nominee, index) => {
      const { nomineedocuments = [] } = nominees[index] || {};
      const finalDocs = nomineedocuments.map((doc) => {
        doc.documentsList.map((_nomineeDocumentCheck) => {
          if (_nomineeDocumentCheck?.errorOnUpload) {
            throw docInvalidCheck;
          }
        });
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...nominee,
        nomineedocuments: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, errorOnUpload, ...rest } = doc;
          return rest;
        }),
      };
    }) as NomineeType[];
  };
  const { esignReferenceId } = referenceDetail;
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const responceMaskAadhar = async (doc: any, getDocument: any, params: MaskedAadharBody) => {
    if (
      doc.documentName.toLowerCase() === 'aadhaar card(masked by system)' &&
      doc.documentId &&
      (getDocument?.documentName !== doc.documentName || !doc.file)
    ) {
      const maskedAadharResponse = (await dispatch(
        getMaskedAadhar(params)
      )) as unknown as MaskedAadharResponse;
      return {
        ...doc,
        documentId: maskedAadharResponse.id,
      };
    }
    return doc;
  };

  const maskAadhaar = async (applicant: Partial<Applicant>, index: any) => {
    return (
      applicant.documents &&
      (await Promise.all(
        applicant.documents.map(async (doc: any) => {
          const existingApplicant = application?.applicants[index];
          const getDocument = existingApplicant?.documents?.find(
            (document) => document.documentType === doc.documentType
          );
          return responceMaskAadhar(doc, getDocument, {
            fileId: doc.documentId,
            applicationId: application?.id || '',
            applicantId: applicant.id || '',
          });
        })
      ))
    );
  };

  const maskAadhaarForNominee = async (nominee: NomineeType, index: any) => {
    return (
      nominee.nomineedocuments &&
      (await Promise.all(
        nominee.nomineedocuments.map(async (doc: any) => {
          const existingNominee = application?.nominees[index];
          const getDocument = existingNominee?.nomineedocuments?.find(
            (document) => document.documentType === doc.documentType
          );
          return responceMaskAadhar(doc, getDocument, {
            fileId: doc.documentId,
            applicationId: application?.id || '',
            nomineeId: nominee.id || '',
          });
        })
      ))
    );
  };

  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  const handleSubmit = async (values: Values) => {
    try {
      if (
        shouldValidateUponSaveLater(values.saveType) &&
        role !== USER_ROLES.INVESTOR &&
        !amcuserAsInvestor
      ) {
        setLoading(true);
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        const strategiesResponse = (await getAllStrategiesByDistributorType(
          dispatch,
          application as ApplicationProps
        )) as unknown as GetStrategiesResponseBody[];
        const responseRiskProfile = (await dispatch(
          riskProfileMasterData()
        )) as unknown as RiskProfileMaster;
        setLoading(false);

        await isFormValidForSubmission(
          application,
          false,
          true,
          nationalitiesMdmsMasters,
          custodianData,
          validationOrConfigChecks,
          documentsData,
          role,
          responseRiskProfile.riskProfile,
          strategiesResponse,
          referenceDetail,
          !checkBankAccoutFieldEnableOrDisalble(
            checkNdpmsFlow,
            routeForNDPSM,
            bankAccountFormCreated,
            showBankIcon
          ),
          routeForNDPSM
        );
      }
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId,
        currentStep,
        status,
        applicationNumber,
        nominees: existingNominees = [],
      } = application || {};
      const { applicants, saveType, hasPOA, nominees } = values;
      // const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      //   const applicantsWithNoDocsAttached = Object.keys(applicants[index].documents).filter(
      //     (doc) => applicants[index].documents[doc].documentId
      //   );
      //   return {
      //     ...applicant,
      //     countryCode: '+91', //For now hard coding country code. Need to remove after UI is done
      //     documents: applicantsWithNoDocsAttached.map((documentType) => ({
      //       ...getExistingDocuments(exisitingApplicants[index].documents || [], documentType),
      //       ...applicants[index].documents[documentType],
      //     })),
      //   };
      // });
      const isSaveLater = saveType !== 'save and proceed';
      const updatedApplicantsWithDoc = updatedApplicants(exisitingApplicants, applicants);
      updatedApplicantsWithDoc.map((applicant) => {
        if (applicant.documents?.length) {
          applicant.documents.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${getFieldTitle[document.documentType]}`;
            }
          });
        }
      });

      const updatedNomineesWithDoc = updatedNominees(existingNominees, nominees);
      updatedNomineesWithDoc.map((_nominee) => {
        if (_nominee.nomineedocuments?.length) {
          _nominee.nomineedocuments.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${getFieldTitle[document.documentType]}`;
            }
          });
        }
      });
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  ?.map((document: any) => {
                    const {
                      documentData,
                      // id,
                      // applicantId,
                      filename,
                      updatedAt,
                      createdAt,
                      updatedBy,
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: application?.nominees
            ?.map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  ?.map((document: any) => {
                    const {
                      documentData,
                      // id,
                      // nomineeId,
                      filename,
                      updatedAt,
                      createdAt,
                      updatedBy,
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
        },
        {
          ...application,
          applicants: updatedApplicantsWithDoc
            .map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  ?.map((document: any) => {
                    const {
                      documentData,
                      options,
                      loading,
                      filename,
                      updatedAt,
                      createdAt,
                      updatedBy,
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: updatedNomineesWithDoc
            .map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  ?.map((document: any) => {
                    const { documentData, options, ...rest } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
          currentStep: getStep(
            !!currentStep && currentStep > 12 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        }
      );

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);

      if (id && !checkApplication) {
        setLoading(true);
        const applicantsUpdate = await Promise.all(
          updatedApplicantsWithDoc.map(async (applicant, index) => {
            return {
              ...applicant,
              documents: await maskAadhaar(applicant, index),
            };
          })
        );
        const nomineeUpdate = await Promise.all(
          updatedNomineesWithDoc.map(async (nominee, index) => {
            return {
              ...nominee,
              nomineedocuments: await maskAadhaarForNominee(nominee, index),
            };
          })
        );
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ndpmsSectionEdited: turnEditOnInDoc(
                routeForNDPSM,
                role,
                checkNdpmsFlow,
                showBankIcon,
                bankAccountFormCreated
              )
                ? true
                : application?.ndpmsSectionEdited,
              applicants: applicantsUpdate,
              nominees: nomineeUpdate,
              // : (status === 'draft' || status === 'sent_to_amc_approver') &&
              status:
                !application?.hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                applyRoleBasedStatus(role) &&
                !amcuserAsInvestor &&
                status !== 'draft'
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: getStep(13, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        setLoading(false);
        kycUpdateRouteCheck
          ? history.push(`/investment-details/${esignReferenceId}/application-details`)
          : investorRouteObj
          ? !isSaveLater
            ? setOpenPopup(true)
            : investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: esignReferenceId,
              })
            : setOpenPopup(true)
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(
              role === USER_ROLES.INVESTOR || amcuserAsInvestor
                ? `/investment-details/${esignReferenceId}/application-details`
                : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
                ? `/application-details/${id}`
                : isSaveLater
                ? `/applications`
                : `/application-preview/${id}`
            );
      } else if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        investorRouteObj
          ? !isSaveLater
            ? setOpenPopup(true)
            : investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: esignReferenceId,
              })
            : setOpenPopup(true)
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(
              role === USER_ROLES.INVESTOR || amcuserAsInvestor
                ? `/investment-details/${esignReferenceId}/application-details`
                : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
                ? `/application-details/${id}`
                : `/applications`
            );
      } else {
        kycUpdateRouteCheck
          ? history.push(`/investment-details/${esignReferenceId}/application-details`)
          : investorRouteObj
          ? !isSaveLater
            ? setOpenPopup(true)
            : investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: esignReferenceId,
              })
            : setOpenPopup(true)
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(
              role === USER_ROLES.INVESTOR || amcuserAsInvestor
                ? `/investment-details/${esignReferenceId}/application-details`
                : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
                ? `/application-details/${id}`
                : `/application-preview/${id}`
            );
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.POAAPPROVER].includes(role);
  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values,
    ind: number
  ): string => {
    return `${documentName}  ${
      documentType === 'bankAccountProof'
        ? (values.banks.filter(
            (bank) => !bank.pennydropVerified && bank.defaultBankAccount && !checkNdpmsFlow
          ).length !== 0 &&
            ind === 0) ||
          isApplicantNRI(values.applicants[ind]?.status || '')
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      //validationSchema={documentDetailsSchema}
      validate={(values: Values) => {
        const shouldSaveForLater = shouldValidateUponSaveLater(values.saveType);
        try {
          validateYupSchema(
            values,
            referenceDetail.applicant_type
              ? documentDetailsSchemaForInvestorLogin(shouldSaveForLater)
              : documentDetailsSchema(!checkNdpmsFlow, shouldSaveForLater),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setTouched,
        setErrors,
        setFieldValue,
      }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid sx={{ width: '100%' }}>
            {referenceDetail.applicant_type ? (
              <>
                <Notes displayContent={"Please don't upload any password protected files"} />
                <NotesWithText displayContent={'Min Size: 2KB, Max Size: 2MB.'} />
              </>
            ) : (
              <>
                <Notes
                  displayContent={
                    "Please upload latest documents not more than 2 months old. Please don't upload any password protected files"
                  }
                />
                <NotesWithText
                  displayContent={'Min Size: 2KB, Max Size: 2MB. Supports: png, jpg, jpeg, pdf'}
                />
              </>
            )}
          </Grid>
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            return !checkEditiable(
              referenceDetail.applicant_type,
              role,
              ind + 1,
              amcuserAsInvestor
            ) ? (
              <>
                {' '}
                <SubHeading>Applicant {ind + 1} Document Details</SubHeading>
                {documents.map((document, doc_index) => {
                  if (
                    !!referenceDetail.referenceId &&
                    document.documentType !== 'investorSignature'
                  )
                    return;
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList
                            .filter(
                              (document, index) =>
                                documentsList.findIndex(
                                  (obj) => document.documentType === obj.documentType
                                ) === index
                            )
                            .map((doc: any, index: number) => (
                              <React.Fragment key={index}>
                                <Grid xs={12} sm={12}>
                                  <MFSelectField
                                    name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                    label={getLabel(
                                      documentName?.includes('(')
                                        ? documentName?.split('(')[0]
                                        : documentName,
                                      documentType,
                                      required,
                                      values,
                                      ind
                                    )}
                                    items={options.map((item: string) => ({
                                      key: item,
                                      value: item,
                                    }))}
                                    disabled={
                                      isFieldDisabled ||
                                      checkEditiable(
                                        referenceDetail.applicant_type,
                                        role,
                                        ind + 1,
                                        amcuserAsInvestor
                                      ) ||
                                      isBankAccountProofUploadedFromBankSection(
                                        ind,
                                        documentType,
                                        banks
                                      )
                                    }
                                    applyLabelStyles={true}
                                    onChange={({
                                      target: { value },
                                    }: SelectChangeEvent<unknown>) => {
                                      const updatedDocumentList = values.applicants[ind].documents[
                                        doc_index
                                      ].documentsList.map((docList) => {
                                        if (docList.documentType === doc.documentType) {
                                          return {
                                            ...docList,
                                            required:
                                              Number(docList.uniqueKey?.split('-')[2]) === 1 &&
                                              !documentNameValidationCheckForMultipleDocumentsMandatory(
                                                value as string
                                              )
                                                ? 'false'
                                                : required,
                                            documentName: value,
                                          };
                                        }
                                        return docList;
                                      });

                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList`,
                                        updatedDocumentList
                                      );
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                            {documentType === 'investorSignature' && (
                              <Typography
                                sx={{
                                  color: 'primary.main',
                                  fontWeight: 400,
                                  fontSize: 14,
                                }}>
                                (Supports: png, jpg, jpeg.
                                {isCustodianICICI(custodian) ? ` Max Size: 1MB` : ''})
                              </Typography>
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {documentsList.map((doc, index) => {
                          const hideReplace =
                            isBankAccountProofUploadedFromBankSection(
                              ind,
                              doc.documentType,
                              banks
                            ) ||
                            (kycUpdateRouteCheck && doc.documentType === 'investorSignature');
                          return (
                            <React.Fragment key={doc.uniqueKey}>
                              <FileUpload
                                onFileChange={(value, documentId, errorOnUpload, removeFile) => {
                                  const { file, ...rest } =
                                    values.applicants[ind].documents[doc_index].documentsList[
                                      index
                                    ];
                                  setFieldValue(
                                    `applicants.${ind}.documents.${doc_index}.documentsList.${index}`,
                                    {
                                      ...rest,
                                      documentId,
                                      ...(!removeFile && { file }),
                                      errorOnUpload: errorOnUpload || document_object.errorOnUpload,
                                    }
                                  );
                                }}
                                name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentId`}
                                documentData={doc}
                                disabled={
                                  isFieldDisabled ||
                                  checkEditiable(
                                    referenceDetail.applicant_type,
                                    role,
                                    ind + 1,
                                    amcuserAsInvestor
                                  )
                                }
                                onFileUpload={handleUpload}
                                onFileUploaded={handleUploaded}
                                displayReplaceButton={!hideReplace}
                                multipleFiles={multipleFiles}
                                index={index}
                                documentsLength={documentsList.length}
                              />
                              {/* {doc.documentType === 'identityProof' && (
                                <LoadingButton
                                  loadingPosition="start"
                                  type="submit"
                                  loading={
                                    values.applicants[ind].documents[doc_index].documentsList[index]
                                      .loading
                                  }
                                  variant="contained"
                                  sx={{
                                    mt: 1,
                                    float: 'right',
                                    fontSize: 15,
                                    pl: values.applicants[ind].documents[doc_index].documentsList[
                                      index
                                    ].loading
                                      ? 5
                                      : 1,
                                    py: 0.5,
                                    cursor:
                                      !doc.documentName.includes('Aadhaar Card') ||
                                      doc.documentId === ''
                                        ? 'none'
                                        : 'pointer',
                                  }}
                                  onClick={async () => {
                                    try {
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList.${index}.loading`,
                                        true
                                      );
                                      const maskedAadharResponse = (await dispatch(
                                        getMaskedAadhar({
                                          fileId: doc.documentId,
                                          applicationId: application?.id || '',
                                          applicantId: application?.applicants[ind].id || '',
                                        })
                                      )) as unknown as MaskedAadharResponse;
                                      const { file, loading, ...rest } =
                                        values.applicants[ind].documents[doc_index].documentsList[
                                          index
                                        ];
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList.${index}`,
                                        {
                                          ...rest,
                                          loading: false,
                                          documentId: maskedAadharResponse.id,
                                          ...(rest.id && { file }),
                                        }
                                      );
                                      if (maskedAadharResponse.id) {
                                        enqueueSnackbar('Aadhar masked Successfully', {
                                          variant: 'success',
                                          autoHideDuration: 3000,
                                        });
                                      }
                                    } catch (e) {
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList.${index}.loading`,
                                        false
                                      );
                                      console.error((e as Error).message);
                                    }
                                  }}
                                  disabled={
                                    !doc.documentName.includes('Aadhaar Card') ||
                                    doc.documentId === ''
                                  }>
                                  Mask Aadhar
                                </LoadingButton>
                              )} */}
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                    </React.Fragment>
                  );
                })}
                {ENABLE_KRA_DOCUMENT_FETCH && !referenceDetail.referenceId && (
                  <KraDocument KraDocument={applicant.kraDocument} />
                )}
                {kycUpdateRouteCheck &&
                  referenceDetail &&
                  referenceDetail?.aadharXMLFromDigiLockerFileId && (
                    <KraDocument XMLFile={referenceDetail?.aadharXMLFromDigiLockerFile} />
                  )}
              </>
            ) : (
              <></>
            );
          })}
          {values.nominees.map((nominee, ind) => {
            if (referenceDetail.referenceId) return;
            const { nomineedocuments } = nominee;
            return (
              <>
                {' '}
                {nomineedocuments.length !== 0 && (
                  <SubHeading>
                    {' '}
                    {getApplicantName(ind + 1, true)} Nominee Document Details
                  </SubHeading>
                )}
                {nomineedocuments.map((document, doc_index) => {
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList
                            .filter(
                              (_document, index) =>
                                documentsList.findIndex(
                                  (_obj) => _document.documentType === _obj.documentType
                                ) === index
                            )
                            .map((doc, index) => (
                              <React.Fragment key={index}>
                                <Grid xs={12} sm={12}>
                                  <MFSelectField
                                    name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentName`}
                                    label={getLabel(
                                      documentName,
                                      documentType,
                                      required,
                                      values,
                                      ind
                                    )}
                                    items={options.map((item: string) => ({
                                      key: item,
                                      value: item,
                                    }))}
                                    applyLabelStyles={true}
                                    disabled={checkEditiable(
                                      referenceDetail.applicant_type,
                                      role,
                                      ind + 1,
                                      amcuserAsInvestor
                                    )}
                                    onChange={({
                                      target: { value },
                                    }: SelectChangeEvent<unknown>) => {
                                      const updatedDocumentList = values.nominees[
                                        ind
                                      ].nomineedocuments[doc_index].documentsList.map((docList) => {
                                        if (docList.documentType === doc.documentType) {
                                          return {
                                            ...docList,
                                            required:
                                              Number(docList.uniqueKey?.split('-')[2]) === 1 &&
                                              !documentNameValidationCheckForMultipleDocumentsMandatory(
                                                value as string
                                              )
                                                ? 'false'
                                                : required,
                                            documentName: value,
                                          };
                                        }
                                        return docList;
                                      });

                                      setFieldValue(
                                        `nominees.${ind}.nomineedocuments.${doc_index}.documentsList`,
                                        updatedDocumentList
                                      );
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {documentsList.map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, errorOnUpload, removeFile) => {
                                const { file, ...rest } =
                                  values.nominees[ind].nomineedocuments[doc_index].documentsList[
                                    index
                                  ];
                                setFieldValue(
                                  `nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                    errorOnUpload: errorOnUpload || document_object.errorOnUpload,
                                  }
                                );
                              }}
                              name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentId`}
                              documentData={doc}
                              disabled={
                                isFieldDisabled ||
                                checkEditiable(
                                  referenceDetail.applicant_type,
                                  role,
                                  ind + 1,
                                  amcuserAsInvestor
                                )
                              }
                              onFileUpload={handleUpload}
                              onFileUploaded={handleUploaded}
                              multipleFiles={multipleFiles}
                              index={index}
                              documentsLength={documentsList.length}
                            />
                          </React.Fragment>
                        ))}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            );
          })}
          {/* <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                Application filled successfully you can approve application in next step
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  '.MuiButton-root': {
                    minWidth: '100px',
                  },
                }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                    history.push(`/application-details/${application?.id}`);
                  }}
                  sx={{ color: 'common.white' }}>
                  Ok
                </Button>
              </Box>
            </Box>
          </Dialog> */}
          {/* <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
          /> */}
          {(APMIFlow
            ? !referenceDetail?.applicant_type
            : !(
                (investorRouteObj && investorRouteObj.saveAndProceedSectionLink) ||
                kycUpdateRouteCheck
              )) && (
            <FieldValidationNote
              saveType={
                investorRouteObj
                  ? investorRouteObj.saveAndProceedSectionLink
                    ? 'Save and Proceed'
                    : 'Save Application'
                  : isFieldDisabled || USER_ROLES.AMCAPPROVER === role
                  ? 'Save Application'
                  : 'Preview'
              }
            />
          )}

          <ProceedSaveLater
            // saveButtonText={
            //   isFieldDisabled || USER_ROLES.AMCAPPROVER === role
            //     ? 'Save Application'
            //     : values.hasPOA
            //     ? 'Send to POA Approver'
            //     : // : (application?.status === 'draft' ||
            //     //     application?.status === 'sent_to_amc_approver') &&
            //     AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
            //     ? 'Send to Checker'
            //     : 'Send to Investor'
            // }
            showEndIcon={false}
            saveLater={() => {
              setFieldValue('saveType', 'save for later');
            }}
            // saveAndProceed={async () => {
            //   try {
            //     if (![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)) {
            //       await isFormValidForSubmission(application, false);
            //       const error = await validateForm();
            //       if (!Object.keys(error).length) {
            //         history.push(`/application-details/${application?.id}`);
            //         //setDialog(true);
            //       }
            //       setTouched(setNestedObjectValues(error, true));
            //       setErrors(error);
            //     }
            //   } catch (e) {
            //     dispatch(showError(e as string));
            //   }
            // }}
            //saveAndProceedType={isFieldDisabled ? 'submit' : 'button'}
            loader={loading}
            clickedButton={values.saveType}
            disabled={documentUpload}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            saveForLaterButtonText={
              investorRouteObj
                ? investorRouteObj.backSectionLink
                  ? `Save and Back To ${investorRouteObj.backSectionName}`
                  : 'Save For Later'
                : 'Save For Later'
            }
            saveButtonText={
              kycUpdateRouteCheck
                ? 'Proceed'
                : investorRouteObj
                ? investorRouteObj.saveAndProceedSectionLink
                  ? 'Save and Proceed'
                  : 'Save Application'
                : isFieldDisabled || USER_ROLES.AMCAPPROVER === role
                ? 'Save Application'
                : 'Preview'
            }
            showSaveForLater={
              kycUpdateRouteCheck
                ? !kycUpdateRouteCheck
                : investorRouteObj
                ? investorRouteObj.backSectionLink
                  ? true
                  : false
                : USER_ROLES.AMCAPPROVER === role
                ? false
                : true
            }
          />
          <ConfirmationDialog
            message={`Application Saved successfully and no further edits provided, Are you sure you want to go back to preview?`}
            open={openPopup}
            setOpen={() => {
              setOpenPopup(false);
            }}
            onSave={() => {
              setOpenPopup(false);
              history.push(`/investment-details/${esignReferenceId}/application-details`);
            }}
            onCancel={() => {
              setOpenPopup(false);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
