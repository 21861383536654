import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, StrategyDetailsType } from '../../redux-store/types/api-types';
import { currencyConversion } from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import SourceOfInvestment from './sourceOfInvestment';
import {
  FeeDetailTypeMasters,
  STRATEGY_TYPE_OPTIONS,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  USER_ROLES,
} from '../../utils/constant';
import { SubHeading } from '../investors/components';
import { KeyValue } from './contributor';
import { Gridstyles } from '.';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { checkVariableFee } from '../StrategySetup/addStrategy';

export default function StrategyDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicationstrategydetails = [] } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const headers = [
    {
      header: 'Strategy',
      label: 'strategyName',
      valueGetter: (row: StrategyDetailsType) => row.strategyName || 'N/A',
    },

    {
      header: 'Management Fee',
      label: 'fee',
      valueGetter: (row: StrategyDetailsType) => row.fee || 'N/A',
    },

    {
      header: 'Performance Fee',
      label: 'performanceFee',
      valueGetter: (row: StrategyDetailsType) => row.performanceFee || 'N/A',
    },
    {
      header: 'Investment Amount',
      label: 'investmentAmount',
      renderCell: (row: StrategyDetailsType) => {
        return (
          <>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 14,
              }}>
              {row.investmentAmount || 'N/A'}
            </Typography>
            {row.investmentAmount && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                {currencyConversion(row.investmentAmount)}
              </Typography>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Strategy Details
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                {applicationstrategydetails?.length > 0 ? (
                  <React.Fragment>
                    {applicationstrategydetails?.map((strategy: any, index: number) => (
                      <>
                        <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>
                          {strategy.strategyName}
                        </SubHeading>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pt={2}
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={13}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <KeyValue
                              title={'Fee Type'}
                              description={FeeDetailTypeMasters[strategy?.feeType || '']}
                            />
                            <KeyValue title="Management Fee" description={strategy.fee || ''} />
                            {checkVariableFee(strategy?.feeType || '') && (
                              <>
                                <KeyValue
                                  title={'Hurdle Rate'}
                                  description={strategy?.hurdleRate || ''}
                                />
                                <KeyValue
                                  title={'Performance Fees'}
                                  description={strategy?.performanceFee || ''}
                                />
                              </>
                            )}
                            <KeyValue title={'Exit Load'} description={strategy?.exitLoad || ''} />
                            <KeyValue
                              title="Strategy Type"
                              description={STRATEGY_TYPE_OPTIONS[strategy?.strategyType] || ''}
                            />
                            <KeyValue title="Benchmark" description={strategy?.benchmark} />
                            <KeyValue
                              title={'Other Charges'}
                              description={strategy?.charges || ''}
                            />
                            <KeyValue
                              title={' Investment Amount'}
                              description={strategy.investmentAmount}
                              amount={
                                strategy.investmentAmount
                                  ? currencyConversion(strategy.investmentAmount)
                                  : strategy.investmentAmount
                              }
                            />
                          </Grid>
                        </Gridstyles>
                      </>
                    ))}
                  </React.Fragment>
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Strategy Details
                  </Grid>
                )}
              </Box>
            </React.Fragment>
          </Grid>

          {/* <Gridstyles> */}
          {/* <Box sx={{ ml: -2, mt: -2 }}> */}
          <SourceOfInvestment application={application} />
          {/* </Box> */}
          {/* </Gridstyles> */}
        </Box>
      </Box>
    </>
  );
}
