import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  Formik,
  FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MFCheckbox } from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';
import MFSelectField from '../../lib/formik/SelectField';
import MFTextField from '../../lib/formik/TextField';
import { updateApplication } from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { getStrategies } from '../../redux-store/actions/strategies';
import { RootStateType } from '../../redux-store/reducers';
import {
  ApplicationProps,
  StrategyDetailsType,
  strategyFormType,
} from '../../redux-store/types/api-types';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  ACCOUNT_TYPE_OPTIONS,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  CHECKER_EDIT_ALL_SECTIONS,
  distributorMaster,
  FeeDetailTypeMasters,
  FundInvestStockMarket,
  ModeOfTransaction,
  SAVE_LATER_VALIDATION_CHECK,
  STRATEGY_TYPE_OPTIONS,
  USER_ROLES,
} from '../../utils/constant';
import { strategyDeclarationScheme } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkDPMSFlow,
  checkNDPMFlow,
  checkStrategyBoxSelected,
  currencyConversion,
  getAllStrategiesByDistributorType,
  getDisableStatusForjointHolder,
  getKey,
  getStep,
  getTotalStrategyAmount,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import { refLoginCheck } from '../Investments/investor-application';
import { checkVariableFee } from '../StrategySetup/addStrategy';
import { particulars } from '../../utils/feeDescription';
import { ConfirmationDialog } from '../commonComponents';
import { Item, ItemDetails } from '../ApplicationDetails';
import FieldValidationNote from './FieldValidationNote';

export type updatedStrategyDetails = StrategyDetailsType & {
  maxFee?: number | null;
  minFee?: number | null;
  performanceMaxFee?: number | null;
  performanceMinFee?: number | null;
  exitMaxFee?: number | null;
  exitMinFee?: number | null;
  hurdleRate?: string;
  exitLoad?: string;
  strategyFeeDetailId: string | null;
  strategyFeeDetails: strategyFormType[];
  feeType: string;
  charges: string;
  benchmark: string;
  adminBenchmark?: string;
  adminStrategyType?: string;
  applicableForType?: string;
};
type Values = {
  applicationstrategydetails: updatedStrategyDetails[];
  totalStrategyInvestmentAmount: string | number | null;
  ownBorrowedFunds: string | null;
  cheque: boolean;
  rtgs: boolean;
  saveType: string;
  strategiesFeeType: string;
};

export const newStrategy: updatedStrategyDetails = {
  fee: '',
  strategyType: '',
  benchmark: '',
  performanceFee: '',
  investmentAmount: null,
  isActive: false,
  charges: '',
  hurdleRate: '',
  exitLoad: '',
  strategyFeeDetailId: null,
  strategyFeeDetails: [],
  feeType: '',
  applicableForType: '',
};

const initialValues: Values = {
  applicationstrategydetails: [],
  totalStrategyInvestmentAmount: null,
  ownBorrowedFunds: '',
  cheque: false,
  rtgs: false,
  strategiesFeeType: '',
  saveType: 'save and proceed',
};

export const feeTypeStratagiesFilter = (
  _stratagy_Details: updatedStrategyDetails[],
  application_FeeType: string,
  reset = false
): updatedStrategyDetails[] => {
  return _stratagy_Details
    ?.map((_strategy) => {
      const { strategyFeeDetails, ...rest } = _strategy;
      const feeDetailCheck = strategyFeeDetails?.some(
        (_detail) => _detail.feeType === application_FeeType
      );
      if (feeDetailCheck) {
        return {
          ..._strategy,
          ...(reset && { ...newStrategy, investmentAmount: '' }), //due to null value setting, rendering value is not clearing, to fix this issue assigning ''
          strategyFeeDetails: strategyFeeDetails || [],
          benchmark: rest?.benchmark,
          strategyType: rest?.strategyType,
        };
      }
      return;
    })
    ?.filter((_item) => _item) as updatedStrategyDetails[];
};

function StrategyAccordion({
  values,
  setValues,
  strategyMasterData,
  strategy,
  applicationStrategyIndex,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  strategyMasterData: updatedStrategyDetails[];
  strategy: updatedStrategyDetails;
  applicationStrategyIndex: number;
}): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const {
    role = '',
    id: authId = '',
    strategyUpdate,
  } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const isFieldDisabled =
    [USER_ROLES.INVESTOR].includes(role) ||
    refLoginCheck(authId, application as unknown as ApplicationProps, referenceDetail);
  const dispatch = useDispatch();
  const selectedTypedStrategies = feeTypeStratagiesFilter(
    strategyMasterData,
    values?.strategiesFeeType || '',
    true
  );
  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        mb: 3,
        cursor: 'default',
        '&::before': { display: 'none' },
        '& .MuiOutlinedInput-root': {
          mt: 0,
        },
        '& .label': { fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 },
      }}
      expanded={strategy?.isActive}>
      <AccordionSummary
        sx={{
          bgcolor: 'rgba(221, 234, 243, 0.2)',
          borderRadius: '5px',
          cursor: 'default',
          '&.Mui-expanded': { minHeight: 'unset', cursor: 'default' },
          '& .MuiAccordionSummary-content': { m: 0, py: 0.7, '&.Mui-expanded': { m: 0 } },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack>
          <Typography
            sx={{
              color: '#131836',
              fontSize: 16,
              fontWeight: 500,
              width: '100%',
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
            }}>
            <MFCheckbox
              name={`applicationstrategydetails.${applicationStrategyIndex}.isActive`}
              label=""
              sx={{ letterSpacing: '1px', mr: 0 }}
              onChange={({ target: { checked } }) => {
                try {
                  const updatedStrategy = values.applicationstrategydetails?.map(
                    (_strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        const getFeeDetail = _strategyDetails.strategyFeeDetails?.filter(
                          (_feeTypeFilter) => _feeTypeFilter.feeType === values.strategiesFeeType
                        );

                        const feeDetail = (getFeeDetail?.find((_item) => !_item?.strategyId) ||
                          {}) as strategyFormType;

                        return {
                          ..._strategyDetails,
                          applicableForType: checked ? (feeDetail.applicableForType as string) : '',
                          strategyFeeDetailId: feeDetail.id,
                          isActive: checked,
                          feeType: checked ? values?.strategiesFeeType : '',
                          fee: checked ? feeDetail.fee : '',
                          hurdleRate: checked ? feeDetail.hurdleRate : '',
                          exitLoad: checked ? feeDetail.exitLoad : '',
                          charges: checked ? feeDetail.charges : '',
                          performanceFee: checked ? feeDetail.performanceFee : '',
                          benchmark: checked
                            ? selectedTypedStrategies?.[applicationStrategyIndex]?.benchmark
                              ? selectedTypedStrategies?.[applicationStrategyIndex]?.benchmark
                              : _strategyDetails?.adminBenchmark
                            : '',
                          strategyType: checked
                            ? Object.keys(STRATEGY_TYPE_OPTIONS).includes(
                                selectedTypedStrategies?.[applicationStrategyIndex]?.strategyType ||
                                  ''
                              )
                              ? selectedTypedStrategies?.[applicationStrategyIndex]?.strategyType
                              : _strategyDetails?.adminStrategyType
                            : '',
                        };
                      }
                      return {
                        ..._strategyDetails,
                        feeType: values?.strategiesFeeType || '',
                        strategyType: _strategyDetails?.adminStrategyType || '',
                        strategyFeeDetailId:
                          _strategyDetails?.strategyFeeDetails?.find(
                            (_feeDetails) => _feeDetails?.isActive
                          )?.id || null,
                      };
                    }
                  ) as updatedStrategyDetails[];
                  setValues({
                    ...values,
                    applicationstrategydetails: updatedStrategy,
                    totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                  });
                } catch (e) {
                  typeof e === 'string' && dispatch(showError(e));
                }
              }}
              disabled={
                isFieldDisabled ||
                [USER_ROLES.AMCAPPROVER].includes(role) ||
                !values.strategiesFeeType
              }
            />
            {strategy?.strategyName}{' '}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '20px' } },
          }}>
          <Grid item xs={12} sm={12}>
            <UseRadioGroup
              formLabel="Fee Type *"
              name={`applicationstrategydetails.${applicationStrategyIndex}.feeType`}
              items={Object.keys(FeeDetailTypeMasters)
                ?.filter((_feeTypeFilter) => _feeTypeFilter === values?.strategiesFeeType)
                ?.map((_fee_Type) => ({
                  label: FeeDetailTypeMasters[_fee_Type],
                  value: _fee_Type,
                }))}
              disabled={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography className="label">Management Fee *</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFSelectField
                name={`applicationstrategydetails.${applicationStrategyIndex}.strategyFeeDetailId`}
                items={(() => {
                  const { main, others } = strategy.strategyFeeDetails
                    ?.filter((val) => val.isActive && val?.feeType === values?.strategiesFeeType)
                    .reduce(
                      (acc, item) => {
                        const newItem = {
                          key: (item?.fee || '') + ' - ' + item.applicableForType,
                          value: item?.id || '',
                        };

                        acc.main.push(newItem);

                        return acc;
                      },
                      {
                        main: [] as { key: string; value: string }[],
                        others: [] as { key: string; value: string }[],
                      }
                    );

                  return [...main, ...others];
                })()}
                onChange={(e) => {
                  setValues({
                    ...values,
                    applicationstrategydetails: values?.applicationstrategydetails?.map(
                      (item, index) => {
                        if (index === applicationStrategyIndex) {
                          const value = item?.strategyFeeDetails?.find(
                            (detail) => detail?.id === e.target.value
                          );

                          if (value) {
                            const _item = {
                              ...item,
                              fee: value?.fee || '',
                              charges: value?.charges || '',
                              exitLoad: value?.exitLoad || '',
                              hurdleRate: value?.hurdleRate || '',
                              strategyFeeDetailId: value?.id || '',
                              performanceFee: value?.performanceFee || '',
                              applicableForType: value?.applicableForType as string,
                              investmentAmount: item?.investmentAmount || '',
                            };
                            return { ..._item, investmentAmount: item?.investmentAmount || '' };
                          }
                        }
                        return item;
                      }
                    ),
                  });
                }}
                disabled={isFieldDisabled || [USER_ROLES.AMCAPPROVER].includes(role)}
                applyRenderValue={true}
                renderValuePosition={0}
              />
            </Stack>
          </Grid>
          {checkVariableFee(strategy.feeType) && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`applicationstrategydetails.${applicationStrategyIndex}.hurdleRate`}
                  label="Hurdle Rate *"
                  placeholder="Enter Hurdle Rate"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`applicationstrategydetails.${applicationStrategyIndex}.performanceFee`}
                  label="Performance Fees *"
                  placeholder="Enter Performance Fees"
                  disabled={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicationstrategydetails.${applicationStrategyIndex}.exitLoad`}
              label="Exit Load *"
              placeholder="Enter Exit Load"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicationstrategydetails.${applicationStrategyIndex}.strategyType`}
              label="Strategy type *"
              items={Object.keys(STRATEGY_TYPE_OPTIONS)?.map((item) => ({
                key: STRATEGY_TYPE_OPTIONS[item],
                value: item,
              }))}
              applyLabelStyles={true}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box width={'100%'}>
              <Item>Benchmark</Item>
              <ItemDetails sx={{ marginTop: '0.5rem' }}>{strategy?.benchmark || ''}</ItemDetails>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicationstrategydetails.${applicationStrategyIndex}.charges`}
              label="Other Charges *"
              placeholder="Enter Other Charges"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicationstrategydetails.${applicationStrategyIndex}.investmentAmount`}
              label="Investment Amount *"
              placeholder="Enter Investment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const updatedAmount = values.applicationstrategydetails?.map(
                  (strategyDetails, _index) => {
                    if (applicationStrategyIndex === _index) {
                      return {
                        ...strategyDetails,
                        investmentAmount: value ? Number(value) : null,
                      };
                    }
                    return strategyDetails;
                  }
                );

                setValues({
                  ...values,
                  applicationstrategydetails: updatedAmount,
                  totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled}
            />
            {Number(strategy?.investmentAmount) > 0 && strategy?.isActive && (
              <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                {currencyConversion(strategy?.investmentAmount)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// function StrategyTable({
//   values,
//   setValues,
//   strategyMasterData,
// }: {
//   values: Values;
//   setValues: FormikHelpers<Values>['setValues'];
//   strategyMasterData: GetStrategiesResponseBody[];
// }): JSX.Element {
//   const { application } = useSelector((store: RootStateType) => store.application);
//   const { staggeredStrategyFrom = '', staggeredStrategyTo = '' } = application || {};
//   const headers = [
//     {
//       header: 'Strategy',
//       label: 'strategy',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <>
//             <MFCheckbox
//               name={`applicationstrategydetails.${index}.isActive`}
//               label={params.strategyName}
//               sx={{ letterSpacing: '1px', width: 140, maxWidth: '100%', mt: 1 }}
//               onChange={({ target: { checked } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         isActive: checked,
//                         fee: checked ? strategyMasterData[index].fee : '',
//                         investmentAmount: checked ? strategyMasterData[index].investmentAmount : '',
//                         managementFeeNature: checked ? strategyDetails.managementFeeNature : '',
//                         performanceFeeNature: checked ? strategyDetails.performanceFeeNature : '',
//                         performanceFee: checked ? strategyMasterData[index].performanceFee : '',
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//             />
//             <Typography
//               sx={{
//                 ml: 4,
//                 mt: -2,
//               }}>
//               ({params?.strategyType})
//             </Typography>
//             {(staggeredStrategyFrom || staggeredStrategyTo) &&
//               params?.id &&
//               (Number(params.id) === Number(staggeredStrategyFrom) ||
//                 Number(params.id) === Number(staggeredStrategyTo)) && (
//                 <Typography sx={{ fontSize: 13, textAlign: 'left', color: 'primary.main' }}>
//                   This Strategy was selected in staggered investment plan
//                   {/* This Strategy is selected as{' '}
//                   {Number(params.id) === Number(staggeredStrategyFrom)
//                     ? 'strategy from'
//                     : 'strategy to'}{' '}
//                   in staggered investment plan details */}
//                 </Typography>
//               )}
//           </>
//         );
//       },
//     },
//     {
//       header: 'Management Fee Nature',
//       label: 'managementFeeNature',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 150,
//               maxWidth: '100%',
//               py: 1,
//               '& .MuiFormControl-root': { width: 180, m: 0 },
//             }}>
//             <MFSelectField
//               name={`applicationstrategydetails.${index}.managementFeeNature`}
//               items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
//                 key: strategyFeeTypeMaster[fee],
//                 value: fee,
//               }))}
//               applyLabelStyles={true}
//               onChange={({ target: { value } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         managementFeeNature: value,
//                         fee:
//                           value !== strategyFeeTypeMaster.none ? strategyMasterData[index].fee : 0,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//               disabled={!params?.isActive}
//             />
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Management Fee',
//       label: 'fee',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 110,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { width: 120, m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.fee`}
//               placeholder="Enter Fee"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               trimOnBlur={false}
//               disabled={
//                 !params?.isActive || params.managementFeeNature === strategyFeeTypeMaster.none
//               }
//             />
//             {strategyFeeTypeMaster[params.managementFeeNature] ===
//               strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
//           </Stack>
//         );
//       },
//     },

//     {
//       header: 'Performance Fee Nature',
//       label: 'performanceFeeNature',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 150,
//               maxWidth: '100%',
//               py: 1,
//               '& .MuiFormControl-root': { width: 180, m: 0 },
//             }}>
//             <MFSelectField
//               name={`applicationstrategydetails.${index}.performanceFeeNature`}
//               items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
//                 key: strategyFeeTypeMaster[fee],
//                 value: fee,
//               }))}
//               applyLabelStyles={true}
//               onChange={({ target: { value } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         performanceFeeNature: value,
//                         performanceFee:
//                           value !== strategyFeeTypeMaster.none
//                             ? strategyMasterData[index].performanceFee
//                             : 0,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//               disabled={!params?.isActive}
//             />
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Performance Fee',
//       label: 'performanceFee',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 110,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { width: 120, m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.performanceFee`}
//               placeholder="Enter Fee"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               trimOnBlur={false}
//               disabled={
//                 !params?.isActive || params.performanceFeeNature === strategyFeeTypeMaster.none
//               }
//             />
//             {strategyFeeTypeMaster[params.performanceFeeNature] ===
//               strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Investment Amount',
//       label: 'investmentAmount',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Box
//             sx={{
//               width: 200,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.investmentAmount`}
//               placeholder="Enter Investment Amount"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
//                 const updatedAmount = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         investmentAmount: value ? Number(value) : null,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 );

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedAmount,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
//                 });
//               }}
//               trimOnBlur={false}
//               disabled={!params?.isActive}
//             />
//             {Number(params?.investmentAmount) > 0 && params?.isActive && (
//               <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
//                 {currencyConversion(params?.investmentAmount)}
//               </Typography>
//             )}
//           </Box>
//         );
//       },
//     },
//   ];

//   return (
//     <DataTable
//       tableData={values.applicationstrategydetails}
//       tableHeader={headers}
//       renderAdditionalRow={false}
//       tableHeaderCustomStyles={{
//         '.MuiTableCell-head': {
//           fontFamily: 'Poppins, sans-serif',
//           fontSize: 14,
//           fontWeight: 500,
//           color: 'primary.main',
//           textAlign: 'left',
//           pl: 2,
//           '&:nth-of-type(1)': {
//             pl: 6,
//           },
//         },
//       }}
//       rowCustomStyles={{
//         '.MuiTableCell-root': {
//           py: 0,
//           overflowWrap: 'anywhere',
//           textAlign: 'left',
//           verticalAlign: 'top',
//         },
//       }}
//       boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
//     />
//   );
// }

export default function StrategyDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [strategyData, setStrategyData] = useState<GetStrategiesResponseBody[]>([]);
  const [selectedStrategyData, setSelectedStrategyData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response: GetStrategiesResponseBody[] = await getAllStrategiesByDistributorType(
          dispatch,
          application as ApplicationProps
        );

        if (!isComponentAlive) {
          return;
        }
        const { applicationstrategydetails = [] } = application || {};

        const transformedDataArray: updatedStrategyDetails[] | GetStrategiesResponseBody[] =
          response?.filter((responseData) => {
            return !applicationstrategydetails?.some(
              (appData) => appData.strategyId === responseData.id
            );
          });

        const result = [
          ...applicationstrategydetails?.map((applicationData) => {
            const { id, strategyFeeDetailId, strategyType, benchmark, ...rest } = applicationData;

            const savedStrategyDetails = response
              ?.filter((responseItem) => responseItem.id === applicationData.strategyId)
              ?.map((responseItem) => responseItem.strategyFeeDetails)
              ?.flat()
              ?.filter((item) => item);
            const savedAdminStrategy = response?.find(
              (responseItem) => responseItem.id === applicationData.strategyId
            );

            return {
              ...applicationData,
              strategyFeeDetails: savedStrategyDetails
                ?.filter(
                  (savedStrategyDetail) =>
                    Number(savedStrategyDetail?.id) !== Number(applicationData?.strategyFeeDetailId)
                )
                .concat([
                  {
                    id: strategyFeeDetailId || null,
                    applicableForType: (rest.applicableForType as string) || '',
                    isActive: rest.isActive,
                    feeType: rest.feeType || '',
                    fee: rest.fee || '',
                    hurdleRate: rest.hurdleRate || '',
                    exitLoad: rest.exitLoad || '',
                    charges: rest.charges || '',
                    performanceFee: rest.performanceFee || '',
                  },
                ]),
              maxFee: applicationData?.maxFee || null,
              minFee: applicationData?.minFee || null,
              strategyType: (strategyType || '') as string,
              benchmark: (benchmark || '') as string,
              adminBenchmark: benchmark || savedAdminStrategy?.benchmark || '',
              adminStrategyType:
                strategyType && Object.keys(STRATEGY_TYPE_OPTIONS).includes(strategyType || '')
                  ? strategyType
                  : savedAdminStrategy?.strategyType || '',
            };
          }),
          ...transformedDataArray?.map((transformData) => {
            const { id, strategyFeeDetails, benchmark, strategyType, ...rest } = transformData;
            return {
              ...rest,
              ...newStrategy,
              strategyId: id as string,
              strategyType: (strategyType || '') as string,
              benchmark: (benchmark || '') as string,
              strategyFeeDetails: (strategyFeeDetails || [])?.map((feeDetails) => {
                return {
                  ...feeDetails,
                };
              }),
            };
          }),
        ] as GetStrategiesResponseBody[] | updatedStrategyDetails[];
        setStrategyData(result as GetStrategiesResponseBody[]);
        setSelectedStrategyData({
          ...selectedStrategyData,
          totalStrategyInvestmentAmount: application?.totalStrategyInvestmentAmount || null,
          ownBorrowedFunds: application?.ownBorrowedFunds || '',
          cheque: application?.modeOfPayment ? application.modeOfPayment.includes('cheque') : false,
          rtgs: application?.modeOfPayment ? application.modeOfPayment.includes('rtgs') : false,
          applicationstrategydetails: application?.strategiesFeeType
            ? feeTypeStratagiesFilter(
                result as updatedStrategyDetails[],
                application.strategiesFeeType
              )
            : (result as updatedStrategyDetails[]),
          strategiesFeeType: application?.strategiesFeeType || '',
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicationstrategydetails, cheque, rtgs, saveType, ...rest } = values;

      if (
        shouldValidateUponSaveLater(values.saveType) &&
        (!applicationstrategydetails?.length ||
          (applicationstrategydetails?.length &&
            !checkStrategyBoxSelected(applicationstrategydetails)))
      ) {
        throw 'Please Select atleast One Strategy';
      }
      const isBenchmarkAdded = applicationstrategydetails
        .filter((item) => item?.isActive)
        .every((startegyItem) => startegyItem?.benchmark);

      if (!isBenchmarkAdded) {
        throw 'Benchmark is required';
      }
      let paymentMode = '';
      if (cheque && rtgs) {
        paymentMode = Object.keys(ModeOfTransaction)?.toString();
      } else if (cheque && !rtgs) {
        paymentMode = 'cheque';
      } else if (!cheque && rtgs) {
        paymentMode = 'rtgs';
      }
      const updateStratagy =
        (
          application?.applicationstrategydetails.filter((initialDetail) => {
            return !applicationstrategydetails.some((detail) => detail.id === initialDetail.id);
          }) as updatedStrategyDetails[]
        )?.map((_updateStratagyValue) => ({ ..._updateStratagyValue, isActive: false })) || [];
      const updatedPayload = {
        ...application,
        ...rest,
        totalStrategyInvestmentAmount: rest.totalStrategyInvestmentAmount || null,
        modeOfPayment: paymentMode,
        applicationstrategydetails: [...applicationstrategydetails, ...updateStratagy]
          ?.filter((strategyDetails) => strategyDetails.id || strategyDetails.isActive)
          ?.map((strategy) => {
            const {
              minFee,
              performanceMaxFee = '',
              performanceMinFee = '',
              exitMaxFee = '',
              exitMinFee = '',
              adminBenchmark,
              adminStrategyType,
              strategyFeeDetails = [],
              ...rest
            } = strategy;
            return {
              ...rest,
              investmentAmount: rest.investmentAmount || null,
            };
          }),
      };
      const isSaveLater = saveType !== 'save and proceed';
      const checkApplication = applicationComparison(
        {
          ...application,
          applicationstrategydetails: application?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
        },
        {
          ...updatedPayload,
          applicationstrategydetails: updatedPayload?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
          currentStep: getStep(
            !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        }
      );

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep:
                USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
                  ? getStep(currentStep as number, isSaveLater)
                  : getStep(9, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={selectedStrategyData}
        validate={(values: Values) => {
          try {
            validateYupSchema(
              values,
              strategyDeclarationScheme(shouldValidateUponSaveLater(values.saveType)),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, setValues, values, errors }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              {/* <Notes
                displayContent={'Any strategy change could affect the staggered investment plan.'}
              /> */}
              <UseRadioGroup
                formLabel="Fee Type *"
                name="strategiesFeeType"
                items={Object.keys(FeeDetailTypeMasters)?.map((fee_Type) => ({
                  label: FeeDetailTypeMasters[fee_Type],
                  value: fee_Type,
                }))}
                onChange={({ target: { value } }) => {
                  setValues({
                    ...values,
                    strategiesFeeType: value,
                    applicationstrategydetails: feeTypeStratagiesFilter(strategyData, value, true),
                  });
                }}
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
              {values.applicationstrategydetails?.length !== 0 ? (
                <Box sx={{ mt: 3 }}>
                  {values.applicationstrategydetails?.map((strategy, index) => (
                    <StrategyAccordion
                      key={index}
                      values={values}
                      setValues={setValues}
                      strategyMasterData={strategyData}
                      strategy={{
                        ...strategy,
                      }}
                      applicationStrategyIndex={index}
                    />
                  ))}
                </Box>
              ) : (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 'large',
                    fontWeight: '500',
                  }}>
                  No strategies mapped for this user
                </Typography>
              )}
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontSize: 25, fontWeight: 500, color: '#131836', mb: 4 }}>
                  Source of Investment
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  Complete the funding source details by which the Holder(s) wish to fund this PMS
                  investment.
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                    Note :{' '}
                  </Typography>
                  This section is required to be filled based on SEBI’s regulation pertaining to the
                  prevention of money laundering act, 2002.
                </Typography>
                <SubHeading sx={{ color: '#131836', fontSize: 18, fontWeight: 500, mt: 0, mb: 3 }}>
                  Fund details
                </SubHeading>
                <Box sx={{ mb: 5, maxWidth: 310, '& .MuiFormControl-root': { mb: 0 } }}>
                  <MFTextField
                    name="totalStrategyInvestmentAmount"
                    label="Total Investment Amount (in INR)"
                    placeholder="Enter Total Investment Amount"
                    type="number"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.totalStrategyInvestmentAmount && (
                    <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                      {currencyConversion(values.totalStrategyInvestmentAmount)}
                    </Typography>
                  )}
                </Box>
                <UseRadioGroup
                  formLabel="Do you intend to invest in the stock market with own Funds/Borrowed Funds? *"
                  name="ownBorrowedFunds"
                  items={Object.keys(FundInvestStockMarket)?.map((fundType) => ({
                    label: FundInvestStockMarket[fundType],
                    value: fundType,
                  }))}
                  disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                />
                <Box sx={{ mt: 5 }}>
                  <Typography
                    sx={{ fontSize: 14, color: 'rgba(38, 48, 60, 0.7)', fontWeight: 500 }}>
                    What is the mode of transaction for this investment? *
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      position: 'relative',
                      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(38, 48, 60, 0.6)',
                      },
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        left: 0,
                        bottom: -15,
                      },
                    }}>
                    <MFCheckbox
                      name="cheque"
                      label="Cheque"
                      sx={{ letterSpacing: '1px', mr: 4 }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                    />
                    <Grid item xs={12} sm={12} sx={{ mb: { xs: 2.8, md: 1 } }}>
                      <MFCheckbox
                        name="rtgs"
                        label="Online Transfer (RTGS)"
                        sx={{ letterSpacing: '1px' }}
                        disabled={getDisableStatusForjointHolder(
                          role,
                          referenceDetail.applicant_type
                        )}
                      />
                    </Grid>
                  </Stack>
                </Box>
              </Box>
              <FieldValidationNote />
              <ProceedSaveLater
                saveLater={() => {
                  setValues({
                    ...values,
                    saveType: 'save for later',
                  });
                }}
                saveAndProceed={() => {
                  setValues({
                    ...values,
                    saveType: 'save and proceed',
                  });
                }}
                loader={loading}
                clickedButton={values.saveType}
                saveButtonText={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
                    ? 'Save Application'
                    : 'Save and Proceed'
                }
                showSaveForLater={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
                showEndIcon={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
              />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
