import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';

import { mitcAccessType, PortfolioManagerDetailsType } from '../../redux-store/types/api-types';
import { getPorfolioManager, mitcAccess } from '../../redux-store/actions/porfolioManager';

export default function PortFolioManagerDetails(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const [porfolioManagerDetails, setPorfolioManagerDetails] =
    useState<PortfolioManagerDetailsType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { mitcAccess: mitSetupcAccess } = (await dispatch(
          mitcAccess()
        )) as unknown as mitcAccessType;
        const [portfolioManagerDetails] = (await dispatch(
          getPorfolioManager()
        )) as unknown as PortfolioManagerDetailsType[];

        setPorfolioManagerDetails(portfolioManagerDetails);
        setAccess(mitSetupcAccess || false);
        if (!portfolioManagerDetails?.id && mitSetupcAccess) {
          history.push('add-porfolioManager');
          return;
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { md: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
            }}>
            <Hidden smUp={true}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  MITC
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'MITC'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() =>
                        history.push(`edit-porfolioManager`, {
                          portFolioManagerId: porfolioManagerDetails?.id,
                        })
                      }
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() =>
                      history.push(`edit-porfolioManager`, {
                        portFolioManagerId: porfolioManagerDetails?.id,
                      })
                    }
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>
          {isLoading ? (
            <LinearProgress sx={{ ml: 5, mr: 5 }} />
          ) : (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'MITC Details'}
                </SubHeading>
              </Box>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <Box sx={{ width: '100%' }}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title="Portfolio Manager Name"
                        description={porfolioManagerDetails?.portfolioManagerName}
                      />
                      <KeyValue
                        title={'Portfolio Manager Contact Details'}
                        description={porfolioManagerDetails?.portfolioManagerMobileNumber}
                      />
                      <KeyValue
                        title={'SEBI Registration Number'}
                        description={porfolioManagerDetails?.sebiRegistrationNumber}
                      />
                      <KeyValue
                        title={'Investor Relations Officer Name'}
                        description={porfolioManagerDetails?.investorRelationOfficerName}
                      />
                      <KeyValue
                        title={'Investor Relations Officer Contact Details'}
                        description={porfolioManagerDetails?.investorRelationOfficerMobileNumber}
                      />
                      <KeyValue
                        title={'Tenure of PMS'}
                        description={porfolioManagerDetails?.tenureOfPMS}
                      />
                      <KeyValue
                        title={'Fees & Charges including exit load'}
                        description={porfolioManagerDetails?.pmFee}
                      />
                      <KeyValue
                        title={'Other important terms & conditions'}
                        description={porfolioManagerDetails?.otherTnC}
                      />
                    </Grid>
                  </Gridstyles>
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
