import { ExitToApp } from '@mui/icons-material';
import { AppBar, CardMedia, Divider, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';
import { logout } from '../../redux-store/actions/auth';
import LoginOtp from '../auth/loginOtp';
import { Footer } from '../commonComponents';
import { Routes } from '../investors/routes';
import InvestorApplication, { refLoginCheck } from './investor-application';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import LoginRequireRoute from '../../routes/LoginRequireRoute';
import MobileLogin from '../auth/MobileLogin';
import InvestmentDetails from './investor-details';
import InvestorApplicationBeforeFaceCapture from '../geoLocationAndFaceCapture.tsx/investorApplicationEdit';
import WebcamCapture, {
  CapturedDetailsSucsessfully,
} from '../geoLocationAndFaceCapture.tsx/faceCapture';
import LocationCapture from '../geoLocationAndFaceCapture.tsx/locationCapture';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { getInvestorTokenFromDigilocker } from '../../redux-store/actions/onBoarding';

export const EditApplication = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { application } = useSelector((store: RootStateType) => store.application);
  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <CardMedia
            component="img"
            src="/images/kfin-logo.svg"
            alt="Logo"
            sx={{ width: 'unset', m: 3, mb: 2 }}
          />
          {role === USER_ROLES.INVESTOR ||
          refLoginCheck(authId, application as unknown as ApplicationProps, referenceDetail) ? (
            <></>
          ) : (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  dispatch(logout());
                  history.push('/login');
                }}>
                <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <Routes
          id={id || application?.id || ''}
          applicant1ReferenceId={applicant1ReferenceId || referenceDetail.esignReferenceId}
        />
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

const decodeState = (
  stateValue = ''
): null | { digiLockerAmcCode: string; esignReferenceId: string; token: string } => {
  if (!stateValue) return null;
  return JSON.parse(atob(stateValue));
};

const InvestorRedirectedRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const referenceId = result?.params.slug;

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/verify-pan`} component={LoginOtp} />
      <ProtectedRoute
        path={`${path}/:referenceId/application-details`}
        component={InvestorApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investment-details/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/investor-edit-application`}
        component={EditApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investment-details/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/kyc-update`}
        component={EditApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investment-details/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/edit-application`}
        component={EditApplication}
        isProtectedRouteForRefLogin={false}
        redirectedRoute={`/login`}
        acceptedRoles={[USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER]}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/photo-capture`}
        component={WebcamCapture}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investment-details/${referenceId}/details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/location-capture`}
        component={LocationCapture}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investment-details/${referenceId}/details`}
      />
      <LoginRequireRoute
        path="*"
        routePath={
          referenceId !== 'edit-application'
            ? `/investment-details/${referenceId}/details`
            : '/login'
        }
      />
    </Switch>
  );
};

const InvestorRoutes = (): JSX.Element => {
  const query = new URLSearchParams(window.location.search);
  const getState = query.get('state');
  const dispatch = useDispatch();
  const [tokenSet, setTokenSet] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (getState) {
      const { token = '' } = decodeState(getState || '') || { token: '' };
      dispatch(getInvestorTokenFromDigilocker({ token }));
      setTokenSet(true);
      query.delete('state');
      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    }
  }, []);
  return !tokenSet && getState ? (
    <Typography>Please wait...</Typography>
  ) : (
    <InvestorRedirectedRoutes />
  );
};

export default InvestorRoutes;
