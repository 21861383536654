/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
  strategyFormType,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
  defaultRegexWithSingleLine,
  decimalNumbersRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  futureAge,
  fieldValidateForInvestor,
  checkNDPMS,
  isApplicantNRI,
  isCustodianICICIOrHDFC,
  isCustodianHDFC,
  isCustodianNuvuma,
  isCustodianICICI,
  isCustodianOrbis,
  checkForMaritalStatusOthers,
  checkValidationBasedOnDate,
  isCustodianAxis,
  isApplicantPEPOrRPEP,
  isCustodianKotak,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import {
  getSubQuestionsIndex,
  Values as NonIndividualFatcaValues,
} from '../components/NonIndividualInvestor/fatca';
import {
  ACCOUNT_TYPE_OPTIONS,
  AdditionalDLCLMasters,
  addressForTaxsMasters,
  addressTypesMasters,
  adminSupportMaster,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  CalendarMaxYearLimit,
  commSentToMaster,
  ContactRelations,
  ContactRelationsForHDFC,
  CpUboCodesMaster,
  CustodianMaster,
  distributorMaster,
  DLCLMasters,
  Edit_KYC,
  FeeDetailTypeMasters,
  genderMasters,
  grossAnnualMasters,
  ICICIContactRelations,
  Invalid_Date,
  investorTypeMasters,
  lineOfBusinessMaster,
  maritalStatusMasters,
  nationalitiesMasters,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  Relations,
  SourceOfFundMasters,
  sourceOfWealthMaster,
  STRATEGY_TYPE_OPTIONS,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  YesNoMaster,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import {
  checkStratagyFeeDetails,
  checkVariableFee,
  filterStrategyFeeDetails,
} from '../components/StrategySetup/addStrategy';

const RequiredfieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, any>>,
  mes: string,
  saveForLater: boolean
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && saveForLater) {
    return createError({
      message: `${mes}`,
      path,
    });
  }
  return true;
};

export const contributorDetailsSchema = (
  custodian: string,
  createdAt: string,
  maritalStatusChange: string,
  saveForLater = true
) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .nullable()
          .test('name', (value, context) => {
            return RequiredfieldValidation(value, context, 'Full Name is required', saveForLater);
          }),

        gender: yup
          .string()
          .nullable()
          .oneOf(genderMasters, 'Invalid value for Gender')
          .test('gender', (value, context) => {
            return RequiredfieldValidation(value, context, 'Please select gender', saveForLater);
          }),

        motherName: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .test('motherName', (value, context) => {
            return RequiredfieldValidation(value, context, 'Mother Name is required', saveForLater);
          }),

        fatherOrSpouseName: yup
          .string()
          .nullable()
          .test('fatherOrSpouseName', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Father/Spouse Name is required',
              saveForLater
            );
          }),
        firstName: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .test('firstName', (value, context) => {
            return RequiredfieldValidation(value, context, 'First Name is required', saveForLater);
          }),

        lastName: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .test('lastName', (value, context) => {
            return RequiredfieldValidation(value, context, 'Last Name is required', saveForLater);
          }),

        dateOfBirth: yup
          .string()
          .nullable()
          .test('dateOfBirth', (value, context) => {
            const { createError, path } = context;
            if (value && value === Invalid_Date) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (isMinor(value || '')) {
              return createError({
                message: 'Age should be between greater than 18',
                path,
              });
            } else {
              return RequiredfieldValidation(
                value,
                context,
                'Date of Birth is required',
                saveForLater
              );
            }
          }),

        status: yup
          .string()
          .nullable()
          .test('status', (value, context) => {
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<ContributorValues>;
            const { custodianData = {} } = optionsContext || {};

            if (saveForLater && !value) {
              return createError({
                message: 'Status is required',
                path,
              });
            }

            if (
              value &&
              !Object.keys(
                isCustodianICICIOrHDFC(custodian) ? custodianData : applicantStatusMasters
              ).includes(value)
            ) {
              return createError({
                message: 'Invalid value for Status',
                path,
              });
            }
            return true;
          }),
        investorSubType: yup
          .string()
          .nullable()
          .test('investorSubType', (value, context) => {
            if (!isCustodianICICIOrHDFC(custodian)) {
              return true;
            }
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<ContributorValues>;
            const { custodianData = {} } = optionsContext || {};
            const investorType = parent.status;

            if (saveForLater && !value) {
              return createError({
                message: 'Investor Subtype is required',
                path,
              });
            }
            if (
              value &&
              investorType &&
              !Object.keys(custodianData[investorType].subType).includes(value)
            ) {
              return createError({
                message: 'Invalid value for Investor Subtype',
                path,
              });
            }
            return true;
          }),
        dateOfBecomeNRI: yup
          .string()
          .nullable()
          .when('status', {
            is: (status: string) => isApplicantNRI(status),
            then: yup
              .string()
              .nullable()
              .test('dateOfBecomeNRI', (value, context) => {
                const fieldValue = value === undefined ? '' : value;
                const { path, createError, parent } = context;
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'Date of becoming NRI is required',
                    path,
                  });
                }
                if (futureAge(fieldValue || '') || fieldValue === Invalid_Date) {
                  return createError({
                    message: 'Invalid Date',
                    path,
                  });
                }
                return true;
              }),
          }),
        nationality: yup
          .string()
          .nullable()
          .test('nationality', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<ContributorValues>;
            const { nationalityDropdown = [] } = optionsContext || {};
            if (saveForLater && !fieldValue) {
              return createError({
                message: 'Nationality is required',
                path,
              });
            }
            // if (!nationalityDropdown.includes(fieldValue)) {
            //   return createError({
            //     message: 'Invalid value for Nationality',
            //     path,
            //   });
            // }
            // if (
            //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
            //   value?.toUpperCase() !== 'INDIAN'
            // ) {
            //   return createError({
            //     message: 'Invalid value for Nationality',
            //     path,
            //   });
            // }
            return true;
          }),
        jointApplicantRelation: yup
          .string()
          .nullable()
          .test('jointApplicationRelation', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path } = context;
            const { index } = options as InternalOptions<ContributorValues> & {
              index: number;
            };

            if (index !== 0) {
              if (saveForLater && !fieldValue) {
                return createError({
                  message: 'Relationship with first applicant is required',
                  path,
                });
              }
              if (fieldValue && !Relations.includes(fieldValue)) {
                return createError({
                  message: 'Invalid value for Relationship with first applicant',
                  path,
                });
              }
            }
            return true;
          }),
        relationShipWithFirstApplicant: yup
          .string()
          .nullable()
          .test('relationShipWithFirstApplicant', (value, context) => {
            const { parent } = context;
            return parent.jointApplicantRelation === 'Others'
              ? RequiredfieldValidation(value, context, 'Please Specify Relationship', saveForLater)
              : true;
          }),

        fatherOrSpouse: yup
          .string()
          .nullable()
          .test('fatherOrSpouse', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please Select Father/Spouse',
              saveForLater
            );
          }),

        // maritalStatus: yup
        //   .string()
        //   .nullable()
        //   .oneOf(Object.keys(maritalStatusMasters), 'Invalid value')
        //   .test('maritalStatus', (value, context) => {
        //     return RequiredfieldValidation(
        //       value,
        //       context,
        //       'Please Select Marital Status',
        //       saveForLater
        //     );
        //   }),

        occupationType: yup
          .string()
          .nullable()
          .test('occupationType', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please Select Occupation Details',
              saveForLater
            );
          }),

        aadhaarCheck: yup
          .string()
          .nullable()
          .test('aadhaarCheck', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please Select Provide Aadhaar',
              saveForLater
            );
          }),

        aadhaarNumber: yup
          .string()
          .nullable()
          .when('aadhaarCheck', {
            is: (aadhaarCheck: string | null, aadhaarNumber: string | null) =>
              aadhaarCheck === 'Yes',
            then: yup
              .string()
              .nullable()
              .test('aadhaarNumber', (value, context) => {
                const { createError, path, parent } = context;

                if (saveForLater && !value) {
                  return createError({
                    message: 'Aadhaar Last 4 digits are required',
                    path,
                  });
                }
                if (value && value?.length !== 4) {
                  return createError({
                    message: 'Please Specify Aadhaar Last 4 digits',
                    path,
                  });
                }
                return true;
              })
              // .required('Please Specify Aadhaar Last 4 digits')
              .matches(numberRegex, 'Aadhaar Number allows only digits'),
          }),

        isExisitngCustomer: yup
          .string()
          .nullable()
          .test('isExisitngCustomer', (value, context) => {
            if (isCustodianAxis(custodian)) {
              return RequiredfieldValidation(
                value,
                context,
                'Please select existing customer',
                saveForLater
              );
            } else {
              return true;
            }
          }),

        customerId: yup
          .string()
          .nullable()
          .when('isExisitngCustomer', {
            is: (isExisitngCustomer: string | null) =>
              isCustodianAxis(custodian) &&
              YesNoMaster[isExisitngCustomer || ''] === YesNoMaster.yes,
            then: yup
              .string()
              .nullable()
              .test('customerId', (value, context) => {
                const { createError, path, parent } = context;

                if (saveForLater && !value) {
                  return createError({
                    message: 'Customer Id is required',
                    path,
                  });
                }
                return true;
              })
              .matches(alphaNumeric, 'Special Characters are Not allowed'),
          }),

        educationalQualification: yup
          .string()
          .nullable()
          .test('educationalQualification', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please Select Education Qualification',
              saveForLater
            );
          }),

        // taxAssesseeCheck: yup.string().nullable().required('Please Select Tax Assessee'),
        occupationDetails: yup
          .string()
          .nullable()
          .test('occupationDetails', (value, context) => {
            const { createError, path, parent } = context;
            const { occupationType } = parent;

            if (occupationType === 'OTHERS') {
              if (saveForLater && !value) {
                return createError({
                  message: 'Please Specify Occupation Type',
                  path,
                });
              }
              if (value && !stringRegex.test(value)) {
                return createError({
                  message: 'Special characters and numbers not allowed',
                  path,
                });
              }
            }

            return true;
          }),
        occupationDescription: yup
          .string()
          .nullable()
          .max(60, 'Maximum 60 characters allowed')
          .test('occupationDescription', (value, context) => {
            const { createError, path, parent } = context;
            const { occupationType } = parent;

            if (occupationType === 'OTHERS') {
              if (isCustodianAxis(custodian) && saveForLater && !value) {
                return createError({
                  message: 'Please specify brief description',
                  path,
                });
              }
            }

            return true;
          }),
        // maritalStatusOthers: yup
        //   .string()
        //   .nullable()
        //   .when('maritalStatus', {
        //     is: (maritalStatus: string | null) =>
        //       saveForLater &&
        //       checkForMaritalStatusOthers(custodian, maritalStatus) &&
        //       checkValidationBasedOnDate(createdAt || '', maritalStatusChange),
        //     then: yup.string().nullable().required('Please Specify Marital Status'),
        //   }),
      })
    ),
  });

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string,
  custodian: string,
  saveForLater: boolean
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (saveForLater && !fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  const dataFeatchedFromKRADigilocker = parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker;
  if (
    fieldValue &&
    parent.country?.toLowerCase() === 'india' &&
    !indianPin.test(fieldValue) &&
    !(Edit_KYC
      ? dataFeatchedFromKRADigilocker
      : addressType === 'correspondence'
      ? dataFeatchedFromKRADigilocker && isCustodianICICI(custodian)
      : dataFeatchedFromKRADigilocker)
  ) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !alphaNumeric.test(fieldValue) &&
    !dataFeatchedFromKRADigilocker
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  if (
    fieldValue &&
    !defaultRegexWithSingleLine.test(fieldValue) &&
    (Edit_KYC
      ? dataFeatchedFromKRADigilocker
      : addressType === 'correspondence'
      ? dataFeatchedFromKRADigilocker && isCustodianICICI(custodian)
      : dataFeatchedFromKRADigilocker)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const corresspondenceAndPermanentAddressValidation = (
  custodian: string,
  saveForLater = true
) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        address: yup.object().shape({
          correspondence: yup.object().shape({
            fetchedFromKRA: yup.bool().nullable(),
            fetchedFromDigiLocker: yup.bool().nullable(),
            address1: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 1 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              .test('address1', 'Address line 1 is required', (value, context) =>
                saveForLater ? validateCorrespondenceAddressFields(value, context) : true
              ),
            address2: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 2 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              .test('address2', 'Address line 2 is required', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null, fetchedFromDigiLocker = null } =
                  currentApplicant.address.correspondence || {};
                if (
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian)
                ) {
                  return true;
                }
                return saveForLater ? validateCorrespondenceAddressFields(value, context) : true;
              }),

            address3: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 3 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
                  ),
              }),
            pincode: yup
              .string()
              .nullable()
              .test('pincode', (value, context) =>
                validatePincodeField(value, context, 'correspondence', custodian, saveForLater)
              ),
            city: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian),
                then: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid Value'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(stringRegex, 'Special characters and numbers not allowed'),
              })
              .test('city', 'City is required', (value, context) =>
                saveForLater ? validateCorrespondenceAddressFields(value, context) : true
              ),
            district: yup
              .string()
              .nullable()
              .test('district', (value, context) => {
                const fieldValue = !value ? '' : value;
                const { createError, path, options, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                if (
                  !isCustodianKotak(custodian) &&
                  !isCustodianICICIOrHDFC(custodian) &&
                  !isCustodianOrbis(custodian) &&
                  !isCustodianAxis(custodian)
                ) {
                  return true;
                }
                if (
                  saveForLater &&
                  !fieldValue &&
                  checkForCorrespondenceAddress(nationality, status)
                ) {
                  return createError({
                    message: 'District is required',
                    path,
                  });
                }

                if (fieldValue && !stringRegex.test(fieldValue) && !parent?.fetchedFromDigiLocker) {
                  return createError({
                    message: 'Special characters and numbers not allowed',
                    path,
                  });
                }
                if (
                  fieldValue &&
                  !defaultRegexWithSingleLine.test(fieldValue) &&
                  parent?.fetchedFromDigiLocker
                ) {
                  return createError({
                    message: 'Invalid District',
                    path,
                  });
                }
                return true;
              }),
            state: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC
                    ? fetchedFromKRA || fetchedFromDigiLocker
                    : (fetchedFromKRA || fetchedFromDigiLocker) && isCustodianICICI(custodian),
                then: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid value'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(stringRegex, 'Special characters and numbers not allowed'),
              })
              .test('state', 'State is required', (value, context) =>
                saveForLater ? validateCorrespondenceAddressFields(value, context) : true
              )
              .test('state', (value, context) => {
                if (!isCustodianICICIOrHDFC(custodian)) {
                  return true;
                }
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { statesDropdown = [] } = optionsContext || {};
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                if (!checkForCorrespondenceAddress(nationality, status)) {
                  return true;
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'State is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.country?.toUpperCase() === 'INDIA' &&
                  !(Edit_KYC
                    ? parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker
                    : (parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker) &&
                      isCustodianICICI(custodian)) &&
                  !statesDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for State',
                    path,
                  });
                }
                return true;
              }),
            // country: yup
            // .string()
            // .nullable()
            // .test('country', 'Country is required', (value, context) =>
            // validateCorrespondenceAddressFields(value, context)
            // ),
            country: yup
              .string()
              .nullable()
              .test('country', (value, context) => {
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { countryDropdown = [], applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;

                if (saveForLater && !value && checkForCorrespondenceAddress(nationality, status)) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (
                  value &&
                  checkForCorrespondenceAddress(nationality, status) &&
                  !(Edit_KYC
                    ? parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker
                    : (parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker) &&
                      isCustodianICICI(custodian)) &&
                  !countryDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
                return true;
              }),
          }),
          permanent: yup.object().shape({
            fetchedFromKRA: yup.bool().nullable(),
            fetchedFromDigiLocker: yup.bool().nullable(),
            address1: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 1 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              .test('address1', 'Address line 1 is required', (value, context) =>
                saveForLater
                  ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
                  : true
              ),
            address2: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 2 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              // .test('address2', 'Address2 is required', (value, context) =>
              //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
              // ),
              .test('address2', 'Address line 2 is required', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null, fetchedFromDigiLocker = null } =
                  currentApplicant.address.permanent || {};
                if (saveForLater && !(fetchedFromKRA || fetchedFromDigiLocker)) {
                  return validatePermanentAddressFields(
                    value,
                    context,
                    APPLICATION_TYPE.INDIVIDUAL
                  );
                }
                return true;
              }),
            address3: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                  Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 3 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
                  ),
              }),
            pincode: yup
              .string()
              .trim()
              .nullable()
              .test('pincode', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                const { permanentAddressSameAsCorresponding = false } =
                  checkForCorrespondenceAddress(nationality, status)
                    ? currentApplicant.address.correspondence || {}
                    : currentApplicant.address.overseas || {};
                if (!permanentAddressSameAsCorresponding) {
                  return validatePincodeField(value, context, 'permanent', custodian, saveForLater);
                }
                return true;
              }),
            city: yup
              .string()
              .nullable()
              .test('city', 'City is required', (value, context) =>
                saveForLater
                  ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
                  : true
              ),
            district: yup
              .string()
              .nullable()
              .test('district', (value, context) => {
                const fieldValue = !value ? '' : value;
                const { createError, path, options, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null, address_type } =
                  currentApplicant.address.permanent || {};
                const { nationality, status = '' } = currentApplicant.address;
                const { permanentAddressSameAsCorresponding = false } =
                  checkForCorrespondenceAddress(nationality, status)
                    ? currentApplicant.address.correspondence || {}
                    : currentApplicant.address.overseas || {};
                if (
                  (!isCustodianKotak(custodian) &&
                    !isCustodianICICIOrHDFC(custodian) &&
                    !isCustodianOrbis(custodian) &&
                    !isCustodianAxis(custodian)) ||
                  permanentAddressSameAsCorresponding
                ) {
                  return true;
                }
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'District is required',
                    path,
                  });
                }
                if (fieldValue && !stringRegex.test(fieldValue) && !parent?.fetchedFromDigiLocker) {
                  return createError({
                    message: 'Special characters and numbers not allowed',
                    path,
                  });
                }
                if (!defaultRegexWithSingleLine.test(fieldValue) && parent?.fetchedFromDigiLocker) {
                  return createError({
                    message: 'Invalid District',
                    path,
                  });
                }
                return true;
              }),
            state: yup
              .string()
              .nullable()
              .test('state', 'State is required', (value, context) =>
                validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
              )
              .test('state', (value, context) => {
                if (!isCustodianICICIOrHDFC(custodian)) {
                  return true;
                }
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { statesDropdown = [] } = optionsContext || {};
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                const { permanentAddressSameAsCorresponding = false } =
                  checkForCorrespondenceAddress(nationality, status)
                    ? currentApplicant.address.correspondence || {}
                    : currentApplicant.address.overseas || {};
                if (permanentAddressSameAsCorresponding) {
                  return true;
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'State is required',
                    path,
                  });
                }

                if (
                  value &&
                  parent.country?.toUpperCase() === 'INDIA' &&
                  !(parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker) &&
                  !statesDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for State',
                    path,
                  });
                }
                return true;
              }),
            country: yup
              .string()
              .nullable()
              .test('country', (value, context) => {
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { countryDropdown = [] } = optionsContext || {};
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status } = currentApplicant.address;
                const { permanentAddressSameAsCorresponding = false } =
                  checkForCorrespondenceAddress(nationality, status)
                    ? currentApplicant.address.correspondence || {}
                    : currentApplicant.address.overseas || {};
                if (!permanentAddressSameAsCorresponding) {
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Country is required',
                      path,
                    });
                  }
                  // if (
                  //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                  //   value.toUpperCase() === 'INDIA'
                  // ) {
                  //   return createError({
                  //     message:
                  //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                  //     path,
                  //   });
                  // }
                  if (
                    value &&
                    !(parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker) &&
                    !countryDropdown.includes(value)
                  ) {
                    return createError({
                      message: 'Invalid value for Country',
                      path,
                    });
                  }
                }
                return true;
              }),
            // country: yup
            //   .string()
            //   .nullable()
            //   .test('country', 'Country is required', (value, context) =>
            //     validatePermanentAddressFields(value, context)
            //   ),
          }),
        }),
      })
    ),
  });

export const contactDetailsSchema = (custodian: string, saveForLater = true) =>
  yup
    .object()
    .shape({
      applicants: yup.array().of(
        yup.object().shape({
          phoneNumberoffice: yup.string().nullable(),
          phoneNumberResidence: yup.string().nullable(),
          email: yup
            .string()
            .nullable()
            .matches(emailRegex, 'invalid Email ID')
            .test('email', (value, context) => {
              return RequiredfieldValidation(value, context, 'Email is required', saveForLater);
            }),
          mobile: yup
            .string()
            .nullable()
            .test('mobile', (value, context) => {
              const { createError, path, parent } = context;
              const codesList = getCountryCodes()
                .map((codes) => {
                  if (codes.label === parent.countryNameAndCode) {
                    return codes.countryCode;
                  }
                })
                .filter((ele) => ele)
                .toString();
              if (saveForLater && !value) {
                return createError({
                  message: 'Mobile is required',
                  path,
                });
              }
              if (value) {
                if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
                if (!isValidPhoneNumber(value, codesList as CountryCode)) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
              }
              return true;
            }),
          mobileEmailDeclaration: yup
            .string()
            .nullable()
            .test(
              'mobileEmailDeclaration',
              (
                value: string | undefined | null,
                context: yup.TestContext<Record<string, Values>>
              ) => {
                const fieldValue = !value ? '' : value;
                const { path, createError, parent, options } = context;
                const { context: optionsContext } = options as InternalOptions<Values> & {
                  index: number;
                };
                const relationShipDropDown =
                  isCustodianICICI(custodian) || isCustodianKotak(custodian || '')
                    ? ICICIContactRelations
                    : ContactRelations;
                const { applicants = [] } = optionsContext || {};
                if (
                  !(
                    isCustodianNuvuma(custodian) ||
                    isCustodianICICI(custodian) ||
                    isCustodianKotak(custodian || '')
                  )
                )
                  return true;
                if (saveForLater && !fieldValue) {
                  return createError({
                    message:
                      isCustodianICICI(custodian || '') || isCustodianKotak(custodian || '')
                        ? 'KRA Email Id & Mobile Number Belongs To is required'
                        : 'This Email Id & Mobile Number Belongs To is required',
                    path,
                  });
                }
                if (fieldValue && !Object.keys(relationShipDropDown).includes(fieldValue)) {
                  return createError({
                    message: 'Invalid value for Email Id & Mobile Number Belongs To',
                    path,
                  });
                }
                if (
                  (parent.mobile || parent.email) &&
                  fieldValue &&
                  relationShipDropDown[fieldValue] === relationShipDropDown.self &&
                  applicants.filter(
                    (applicant) =>
                      (applicant.mobile === parent.mobile ||
                        applicant?.email?.toLowerCase() === parent?.email?.toLowerCase()) &&
                      relationShipDropDown[applicant?.mobileEmailDeclaration || ''] ===
                        relationShipDropDown.self
                  ).length !== 1
                ) {
                  return createError({
                    message: `RelationShip can't be choosen as ${relationShipDropDown[fieldValue]} for joint holders who has same email id or mobile number`,
                    path,
                  });
                }
                return true;
              }
            ),
          emailDeclaration: yup
            .string()
            .nullable()
            .test(
              'emailDeclaration',
              (
                value: string | undefined | null,
                context: yup.TestContext<Record<string, Values>>
              ) => {
                const fieldValue = !value ? '' : value;
                const { path, createError, parent, options } = context;
                const { context: optionsContext } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                if (!(isCustodianHDFC(custodian) || isCustodianAxis(custodian))) return true;
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'Email Id Belongs To is required',
                    path,
                  });
                }
                if (fieldValue && !Object.keys(ContactRelationsForHDFC).includes(fieldValue)) {
                  return createError({
                    message: 'Invalid value for Email Id Belongs To',
                    path,
                  });
                }
                if (
                  parent.email &&
                  fieldValue &&
                  ContactRelationsForHDFC[fieldValue] === ContactRelationsForHDFC.self &&
                  applicants.filter(
                    (applicant) =>
                      applicant?.email?.toLowerCase() === parent?.email?.toLowerCase() &&
                      ContactRelationsForHDFC[applicant?.emailDeclaration || ''] ===
                        ContactRelationsForHDFC.self
                  ).length !== 1
                ) {
                  return createError({
                    message: `RelationShip can't be choosen as ${ContactRelationsForHDFC[fieldValue]} for joint holders who has same email id`,
                    path,
                  });
                }
                return true;
              }
            ),
          mobileDeclaration: yup
            .string()
            .nullable()
            .test(
              'mobileDeclaration',
              (
                value: string | undefined | null,
                context: yup.TestContext<Record<string, Values>>
              ) => {
                const fieldValue = !value ? '' : value;
                const { path, createError, parent, options } = context;
                const { context: optionsContext } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                if (!(isCustodianHDFC(custodian) || isCustodianAxis(custodian))) return true;
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'Mobile Number Belongs To is required',
                    path,
                  });
                }
                if (fieldValue && !Object.keys(ContactRelationsForHDFC).includes(fieldValue)) {
                  return createError({
                    message: 'Invalid value for Mobile Number Belongs To',
                    path,
                  });
                }
                if (
                  parent.mobile &&
                  fieldValue &&
                  ContactRelationsForHDFC[fieldValue] === ContactRelationsForHDFC.self &&
                  applicants.filter(
                    (applicant) =>
                      applicant.mobile === parent.mobile &&
                      ContactRelationsForHDFC[applicant?.mobileDeclaration || ''] ===
                        ContactRelationsForHDFC.self
                  ).length !== 1
                ) {
                  return createError({
                    message: `RelationShip can't be choosen as ${ContactRelationsForHDFC[fieldValue]} for joint holders who has same mobile number`,
                    path,
                  });
                }
                return true;
              }
            ),
          countryNameAndCode: yup
            .string()
            .nullable()
            .test('countryNameAndCode', (value, context) => {
              const { createError, path, parent } = context;
              const codesList = getCountryCodes()
                .map((codes) => {
                  if (codes.label === value) {
                    return codes.countryCode;
                  }
                })
                .filter((ele) => ele)
                .toString();
              if (saveForLater && !value) {
                return createError({
                  message: 'Country Code is required',
                  path,
                });
              }
              if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
                return createError({
                  message: 'Invalid Mobile number',
                  path,
                });
              }

              return true;
            }),
          address: yup.object().shape({
            overseas: yup.object().shape({
              fetchedFromKRA: yup.bool().nullable(),
              fetchedFromDigiLocker: yup.bool().nullable(),
              address1: yup
                .string()
                .nullable()
                .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                  is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                    Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                  then: yup
                    .string()
                    .nullable()
                    .matches(defaultRegexWithSingleLine, 'invalid address line 1 format'),
                  otherwise: yup
                    .string()
                    .nullable()
                    .matches(
                      addressRegex,
                      "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
                    ),
                })
                .test('address1', 'Address line 1 is required', (value, context) =>
                  saveForLater ? validateOverseasAddressFields(value, context) : true
                ),
              address2: yup
                .string()
                .nullable()
                .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                  is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                    Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                  then: yup
                    .string()
                    .nullable()
                    .matches(defaultRegexWithSingleLine, 'invalid address line 2 format'),
                  otherwise: yup
                    .string()
                    .nullable()
                    .matches(
                      addressRegex,
                      "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
                    ),
                })
                .test('address2', 'Address line 2 is required', (value, context) =>
                  saveForLater ? validateOverseasAddressFields(value, context) : true
                ),
              address3: yup
                .string()
                .nullable()
                .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                  is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                    Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                  then: yup
                    .string()
                    .nullable()
                    .matches(defaultRegexWithSingleLine, 'invalid address line 3 format'),
                  otherwise: yup
                    .string()
                    .nullable()
                    .matches(
                      addressRegex,
                      "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
                    ),
                }),
              pincode: yup
                .string()
                .nullable()
                .test('pincode', (value, context) =>
                  validatePincodeField(value, context, 'overseas', custodian, saveForLater)
                ),
              city: yup
                .string()
                .nullable()
                .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                  is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                    Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                  then: yup
                    .string()
                    .nullable()
                    .matches(defaultRegexWithSingleLine, 'Invalid Value'),
                  otherwise: yup
                    .string()
                    .nullable()
                    .matches(stringRegex, 'Special characters and numbers not allowed'),
                })
                .test('city', 'City is required', (value, context) =>
                  saveForLater ? validateOverseasAddressFields(value, context) : true
                ),
              state: yup
                .string()
                .nullable()
                .when(['fetchedFromKRA', 'fetchedFromDigiLocker'], {
                  is: (fetchedFromKRA: boolean, fetchedFromDigiLocker: boolean) =>
                    Edit_KYC ? fetchedFromKRA || fetchedFromDigiLocker : false,
                  then: yup
                    .string()
                    .nullable()
                    .matches(defaultRegexWithSingleLine, 'Invalid Value'),
                  otherwise: yup
                    .string()
                    .nullable()
                    .matches(stringRegex, 'Special characters and numbers not allowed'),
                }),
              // .test('state', 'State is required', (value, context) =>
              //   validateOverseasAddressFields(value, context)
              // ),
              country: yup
                .string()
                .nullable()
                .test('country', (value, context) => {
                  const { options, createError, path, parent } = context;
                  const { context: optionsContext, index } = options as InternalOptions<Values> & {
                    index: number;
                  };
                  const { applicants = [], countryDropdown = [] } = optionsContext || {};
                  const currentApplicant = applicants[index] || {};
                  const { nationality, status = '' } = currentApplicant.address;
                  if (!value && checkForCorrespondenceAddress(nationality, status)) {
                    return true;
                  }
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Country is required',
                      path,
                    });
                  }
                  if (value) {
                    if (value.toUpperCase() === 'INDIA') {
                      return createError({
                        message: "Country can't be choosen as INDIA",
                        path,
                      });
                    }
                    if (
                      !(parent?.fetchedFromKRA || parent?.fetchedFromDigiLocker) &&
                      !countryDropdown.includes(value)
                    ) {
                      return createError({
                        message: 'Invalid value for Country',
                        path,
                      });
                    }
                  }
                  return true;
                }),
              // country: yup
              //   .string()
              //   .nullable()
              //   .test('country', 'Country is required', (value, context) =>
              //     validateOverseasAddressFields(value, context)
              //   ),
            }),
          }),
        })
      ),
    })
    .concat(corresspondenceAndPermanentAddressValidation(custodian, saveForLater));

export const KYCDetailsSchema = (NDPSMCheck: boolean, custodian: string, saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        politicallyExposedPersonStatus: yup
          .string()
          .nullable()
          .test('politicallyExposedPersonStatus', (value, context) => {
            const { createError, path } = context;
            if (value && !PEPsMasters.includes(value)) {
              return createError({
                message: 'Invalid value for PEP Status',
                path,
              });
            } else {
              return RequiredfieldValidation(
                value,
                context,
                'PEP status is required',
                saveForLater
              );
            }
          }),
        otherPepInfo: yup
          .string()
          .nullable()
          .test('otherPepInfo', (value, context) => {
            const { createError, path, parent } = context;

            if (
              isApplicantPEPOrRPEP(parent?.politicallyExposedPersonStatus || '') &&
              saveForLater &&
              isCustodianAxis(custodian) &&
              !value
            ) {
              return createError({
                message: 'Any Other Information is required',
                path,
              });
            }
            return true;
          }),
        sourceOfFund: yup
          .string()
          .nullable()
          .test('sourceOfFund', (value, context) => {
            return NDPSMCheck || isCustodianOrbis(custodian)
              ? RequiredfieldValidation(value, context, 'Source of Fund is required', saveForLater)
              : true;
          }),

        sourceOfFundOthers: yup
          .string()
          .nullable()
          .test('sourceOfFundOthers', (value, context) => {
            const { parent } = context;
            return SourceOfFundMasters[parent.sourceOfFund || ''] === SourceOfFundMasters.Others &&
              (NDPSMCheck || isCustodianOrbis(custodian))
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Please Specify Source Of Fund',
                  saveForLater
                )
              : true;
          }),

        sourceOfFundAmount: yup
          .string()
          .nullable()
          .test('sourceOfFundAmount', (value, context) => {
            return isCustodianOrbis(custodian)
              ? RequiredfieldValidation(value, context, 'Fund Amount is Required', saveForLater)
              : true;
          }),

        sourceOfWealth: yup
          .string()
          .nullable()
          .test('sourceOfWealth', (value, context) => {
            return isCustodianOrbis(custodian)
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Source Of Wealth is Required',
                  saveForLater
                )
              : true;
          }),

        sourceOfWealthOthers: yup
          .string()
          .nullable()
          .test('sourceOfWealthOthers', (value, context) => {
            const { parent } = context;
            return sourceOfWealthMaster[parent.sourceOfWealth || ''] === sourceOfWealthMaster.Others
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Please Specify Source Of Wealth',
                  saveForLater
                )
              : true;
          }),

        sourceOfWealthAmount: yup
          .string()
          .nullable()
          .test('sourceOfWealthAmount', (value, context) => {
            return isCustodianOrbis(custodian)
              ? RequiredfieldValidation(value, context, 'Wealth Amount is Required', saveForLater)
              : true;
          }),

        lineOfBusiness: yup
          .string()
          .nullable()
          .test('lineOfBusiness', (value, context) => {
            return isCustodianOrbis(custodian)
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Line Of Business is Required',
                  saveForLater
                )
              : true;
          }),

        lineOfBusinessOthers: yup
          .string()
          .nullable()

          .test('lineOfBusinessOthers', (value, context) => {
            const { parent } = context;
            return lineOfBusinessMaster[parent.lineOfBusiness || ''] === lineOfBusinessMaster.Others
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Please Specify Source Of Wealth',
                  saveForLater
                )
              : true;
          }),

        residence: yup
          .string()
          .nullable()
          .test('residence', (value, context) => {
            return NDPSMCheck
              ? RequiredfieldValidation(value, context, 'Residence is Required', saveForLater)
              : true;
          }),

        monthlyIncome: yup
          .string()
          .nullable()
          .test('monthlyIncome', (value, context) => {
            return NDPSMCheck
              ? RequiredfieldValidation(value, context, 'Monthly Income is Required', saveForLater)
              : true;
          }),

        projectedCashTransaction: yup
          .string()
          .nullable()
          .test('projectedCashTransaction', (value, context) => {
            return NDPSMCheck
              ? RequiredfieldValidation(
                  value,
                  context,
                  'Projected Cash Transaction (Rs. per month) is Required',
                  saveForLater
                )
              : true;
          }),

        ckycNo: yup.number().test('ckycNo', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
          } = context;
          const { applicants } = optionsContext || {};
          if (!value) {
            return true;
          }
          if (value?.toString().length !== 14) {
            return createError({
              message: 'Invalid CKYC No',
              path,
            });
          }
          if (
            applicants.filter(
              (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
            ).length !== 1
          ) {
            return createError({
              message:
                'There is already same CKYC Number for an applicant associated with this application',
              path,
            });
          }
          return true;
        }),

        grossAnnualIncome: yup
          .string()
          .nullable()
          .test('grossAnnualIncome', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext, index } = options as InternalOptions<Values> & {
              index: number;
            };
            if (saveForLater && !value) {
              return createError({
                message: 'Gross Annual Income is Required',
                path,
              });
            }
            return true;
          }),
        // grossAnnualIncome: yup.string().required('Gross Annual Income is Required'),
        // netWorth: yup.string().required('Net Worth is Required'),
        netWorthDate: yup
          .string()
          .nullable()
          .test('netWorthDate', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            const year = new Date(fieldValue || '').getFullYear();
            if (
              futureAge(fieldValue || '') ||
              fieldValue === Invalid_Date ||
              year < CalendarMaxYearLimit
            ) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            return true;
          }),
        income: yup
          .number()
          .nullable()
          .test('income', (value, context) => {
            if (!(isCustodianICICIOrHDFC(custodian) || isCustodianKotak(custodian))) {
              return true;
            }
            const fieldValue = value === undefined || value === null ? 0 : value;
            const { path, createError, parent } = context;
            const grossAnnualIncome = parent.grossAnnualIncome;
            const incomeRange = grossAnnualMasters.find((item) => item.label === grossAnnualIncome);

            if (
              saveForLater &&
              incomeRange &&
              (fieldValue < incomeRange.min || (incomeRange.max && fieldValue >= incomeRange.max))
            ) {
              return createError({
                message: 'Income must be within the range specified by Gross Annual Income',
                path,
              });
            }
            return true;
          }),
      })
    ),
    dlclId: yup
      .string()
      .nullable()
      .oneOf(
        Object.keys(DLCLMasters),
        'Invalid value for Demat Account Details (for allotment of units)'
      )
      .test(
        'dlclId',
        'Demat Account Details (for allotment of units) is required',
        (value, context) => {
          const { options } = context;
          return isCustodianAxis(custodian) && saveForLater ? !!value : true;
        }
      ),
    cdslAddtionalType: yup
      .string()
      .nullable()
      .oneOf(Object.keys(AdditionalDLCLMasters), 'Invalid value for Additional Type For CDSL')
      .test('cdslAddtionalType', 'Additional Type For CDSL is required', (value, context) => {
        const { options, parent } = context;
        return isCustodianAxis(custodian) &&
          saveForLater &&
          DLCLMasters[parent.dlclId] === DLCLMasters.cdsl
          ? !!value
          : true;
      }),
    commSendTo: yup
      .string()
      .nullable()
      .test('commSendTo', (value, context) => {
        if (
          !(
            isCustodianICICIOrHDFC(custodian) ||
            isCustodianNuvuma(custodian) ||
            isCustodianAxis(custodian) ||
            isCustodianKotak(custodian)
          )
        ) {
          return true;
        }
        const fieldValue = value === undefined || value === null ? '' : value;
        const { path, createError } = context;

        if (!Object.keys(commSentToMaster).includes(fieldValue)) {
          return createError({
            message: 'Invalid Value for Communications to be sent to',
            path,
          });
        }
        return true;
      }),
  });

const createSchemaForNonIndianResident = (validationMessage: string, saveForLater = true) => {
  return {
    is: (value: string) => saveForLater && value.toString() === 'true',
    then: yup.string().required(validationMessage),
  };
};

export const FATCAValidationSchema = (custodian: string, saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        typeOfAddressProvidedAtKRA: yup
          .string()
          .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
          .test('typeOfAddressProvidedAtKRA', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please select type of address provided',
              saveForLater
            );
          }),

        placeOfBirth: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .test('placeOfBirth', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Please enter place of birth',
              saveForLater
            );
          }),
        fatcaAddress: yup
          .object()
          .nullable()
          .shape({
            address_tax_purpose: yup
              .string()
              .nullable()
              .test('address_tax_purpose', (value, context: any) => {
                const { path, createError } = context;
                const [parent1, parent2, ...rest] = context.from;
                const taxResidentOfAnyCountryOtherThanIndia =
                  parent2?.value?.taxResidentOfAnyCountryOtherThanIndia || '';
                if (
                  !(isCustodianKotak(custodian) || isCustodianAxis(custodian)) ||
                  taxResidentOfAnyCountryOtherThanIndia?.toString() !== 'true'
                ) {
                  return true;
                }
                if (value && !Object.keys(addressForTaxsMasters).includes(value || '')) {
                  return createError({
                    message: 'Invalid Address For Tax Purpose',
                    path,
                  });
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'Address For Tax Purpose is required',
                    path,
                  });
                }
                return true;
              }),
            address1: yup
              .string()
              .nullable()
              .test('address1', (value, context: any) => {
                const { path, createError, parent } = context;
                const [parent1, parent2, ...rest] = context.from;
                const taxResidentOfAnyCountryOtherThanIndia =
                  parent2?.value?.taxResidentOfAnyCountryOtherThanIndia || '';
                if (
                  !(isCustodianKotak(custodian) || isCustodianAxis(custodian)) ||
                  taxResidentOfAnyCountryOtherThanIndia?.toString() !== 'true' ||
                  addressForTaxsMasters[parent?.address_type] !== addressForTaxsMasters.others
                ) {
                  return true;
                }

                if (value && !defaultRegexWithSingleLine.test(value || '')) {
                  return createError({
                    message: ' invalid address line 1 format',
                    path,
                  });
                }
                return RequiredfieldValidation(
                  value,
                  context,
                  'Address line 1 is required',
                  saveForLater
                );
              }),
            pincode: yup
              .string()
              .nullable()
              .test('pincode', (value, context: any) => {
                const fieldValue = value === undefined ? '' : value;
                const { path, createError, parent } = context;
                const [parent1, parent2, ...rest] = context.from;
                const taxResidentOfAnyCountryOtherThanIndia =
                  parent2?.value?.taxResidentOfAnyCountryOtherThanIndia || '';
                if (
                  taxResidentOfAnyCountryOtherThanIndia?.toString() !== 'true' ||
                  addressForTaxsMasters[parent.address_type] !== addressForTaxsMasters.others ||
                  !(isCustodianKotak(custodian) || isCustodianAxis(custodian))
                ) {
                  return true;
                }
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'Pincode is required',
                    path,
                  });
                } else if (
                  fieldValue &&
                  parent.country?.toLowerCase() === 'india' &&
                  !indianPin.test(fieldValue as string)
                ) {
                  return createError({
                    message: 'Pincode length should be 6 digits',
                    path,
                  });
                } else if (
                  fieldValue &&
                  parent.country?.toLowerCase() !== 'india' &&
                  !alphaNumeric.test(fieldValue as string)
                ) {
                  return createError({
                    message: 'Invalid Pincode',
                    path,
                  });
                }
                return true;
              }),

            state: yup
              .string()
              .nullable()
              .test('state', (value, context: any) => {
                const { path, createError, parent } = context;
                const [parent1, parent2, ...rest] = context.from;
                const taxResidentOfAnyCountryOtherThanIndia =
                  parent2?.value?.taxResidentOfAnyCountryOtherThanIndia || '';
                if (
                  !(isCustodianKotak(custodian) || isCustodianAxis(custodian)) ||
                  taxResidentOfAnyCountryOtherThanIndia?.toString() !== 'true' ||
                  addressForTaxsMasters[parent?.address_type] !== addressForTaxsMasters.others
                ) {
                  return true;
                }

                if (value && !stringRegex.test(value || '')) {
                  return createError({
                    message: ' Special characters and numbers not allowed',
                    path,
                  });
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'State is required',
                    path,
                  });
                }
                return true;
              }),

            country: yup
              .string()
              .nullable()
              .test('country', (value, context: any) => {
                const { options, createError, path, parent } = context;
                const { context: optionsContext } =
                  options as InternalOptions<individualFatcaValues>;
                const { countryDropdown = [] } = optionsContext || {};
                const [parent1, parent2, ...rest] = context.from;
                const taxResidentOfAnyCountryOtherThanIndia =
                  parent2?.value?.taxResidentOfAnyCountryOtherThanIndia || '';
                if (
                  taxResidentOfAnyCountryOtherThanIndia?.toString() !== 'true' ||
                  addressForTaxsMasters[parent.address_type] !== addressForTaxsMasters.others ||
                  !(isCustodianKotak(custodian) || isCustodianAxis(custodian))
                ) {
                  return true;
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (value && !isCustodianAxis(custodian) && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
                return true;
              }),
          }),
        // countryOfBirth: yup
        //   .string()
        //   .required('Please enter country of birth')
        //   .matches(stringRegex, 'Please enter country of birth'),
        countryOfBirth: yup
          .string()
          .nullable()
          .test('countryOfBirth', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
            const { countryDropdown = [] } = optionsContext || {};
            if (saveForLater && !fieldValue) {
              return createError({
                message: 'country of birth is required',
                path,
              });
            }
            if (fieldValue && !countryDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for country of birth',
                path,
              });
            }
            return true;
          }),
        // countryOfNationality: yup
        //   .string()
        //   .required('Please enter country of nationality')
        //   .matches(stringRegex, 'Please enter country of nationality'),
        countryOfNationality: yup
          .string()
          .nullable()
          .test('countryOfNationality', (value, context) => {
            const fieldValue = value === null ? '' : value;
            const { options, createError, path, parent } = context;
            const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
            const { countryDropdown = [] } = optionsContext || {};
            if (saveForLater && !fieldValue) {
              return createError({
                message: 'country of nationality is required',
                path,
              });
            }
            if (fieldValue && !countryDropdown.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for country of nationality',
                path,
              });
            }
            return true;
          }),
        taxCountryName: yup
          .string()
          .when(
            'taxResidentOfAnyCountryOtherThanIndia',
            createSchemaForNonIndianResident('Please enter tax country name', saveForLater)
          ),
        taxID: yup
          .string()
          .max(20, 'Invalid Tax ID')
          .when(
            'taxResidentOfAnyCountryOtherThanIndia',
            createSchemaForNonIndianResident('Please enter Tax ID', saveForLater)
          ),
        idType: yup
          .string()
          .when(
            'taxResidentOfAnyCountryOtherThanIndia',
            createSchemaForNonIndianResident('Please enter ID Type', saveForLater)
          ),
        // nameOfEntity: yup
        //   .string()
        //   .when(
        //     'taxResidentOfAnyCountryOtherThanIndia',
        //     buildValidationSchema('Please enter name of entity')
        //   ),
        // dateOfIncorporation: yup
        //   .string()
        //   .when(
        //     'taxResidentOfAnyCountryOtherThanIndia',
        //     buildValidationSchema('Please enter date of incorporation')
        //   ),
        // cityOfIncorporation: yup
        //   .string()
        //   .when(
        //     'taxResidentOfAnyCountryOtherThanIndia',
        //     buildValidationSchema('Please enter city of incorporation')
        //   ),
        // countryOfIncorporation: yup
        //   .string()
        //   .when(
        //     'taxResidentOfAnyCountryOtherThanIndia',
        //     buildValidationSchema('Please enter country of incorporation')
        //   ),
        // entityExcemptionCode: yup
        //   .string()
        //   .when(
        //     'taxResidentOfAnyCountryOtherThanIndia',
        //     buildValidationSchema('Please enter entity exemption code')
        //   ),
      })
    ),
  });

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  application_number: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        [
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().required('Application Number is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
      then: yup.string().required('Status is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        ![
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().test('phone', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        return true;
      }),
    }),
  // .required('Mobile Number is required')
  // .min(8, 'Invalid mobile number')
  // .max(16, 'Invalid mobile number')
  // .matches(phoneRegExp, 'Invalid mobile number'),
  // countryCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }
  //     return true;
  //   }),
});

const validateNomineePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  country: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;

  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent[country]?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (parent[country]?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

export const nomineeDetailsSchema = (
  custodian: string,
  applicants?: Partial<Applicant>[],
  saveForLater = true
) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        nomineeName: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('nomineeName', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Nominee Name is required',
                  saveForLater
                );
              }),
          }),
        nomineeRelationship: yup.string().when(['Relationship', 'isActive'], {
          is: (Relationship: string | null, isActive: boolean) =>
            isActive && Relationship === 'OTHERS',
          then: yup
            .string()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('nomineeRelationship', (value, context) => {
              return RequiredfieldValidation(
                value,
                context,
                'Please Specify Relationship',
                saveForLater
              );
            }),
        }),
        Relationship: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
              .test('Relationship', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Please select relationship',
                  saveForLater
                );
              }),
          }),
        otherGuardianRelationship: yup
          .string()
          .when(['guardianRelationship', 'isActive', 'dateOfBirth'], {
            is: (guardianRelationship: string | null, isActive: boolean, dateOfBirth: string) =>
              isActive && guardianRelationship === 'OTHERS' && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('otherGuardianRelationship', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Please Specify Guardian Relationship',
                  saveForLater
                );
              }),
          }),
        guardianRelationship: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianRelationship', (value, context) => {
                const { path, createError } = context;
                if (value && !NomineeRelations.includes(value || '')) {
                  return createError({
                    message: 'Invalid value for Guardian Relationship',
                    path,
                  });
                }
                return RequiredfieldValidation(
                  value,
                  context,
                  'Please select Guardian Relationship',
                  saveForLater
                );
              }),
          }),
        dateOfBirth: yup
          .string()
          .nullable()
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (saveForLater && !fieldValue && parent.isActive) {
              return createError({
                message: 'Date of Birth is required',
                path,
              });
            }
            if ((futureAge(fieldValue || '') || fieldValue === Invalid_Date) && parent.isActive) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (maxAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Age should be less than 125',
                path,
              });
            }
            return true;
          }),
        nomineePercentage: yup.number().when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .number()
            .typeError('Nominee % should be a number')
            .test(
              'nomineePercentage',
              'Total Nominee % should be equal to 100%',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                } = context;
                const { nominees } = optionsContext || {};
                if (saveForLater && !value) {
                  return createError({
                    message: 'Nominee % is required',
                    path,
                  });
                }

                if (value === 0) {
                  return createError({
                    message: 'Nominee % should be greater than 0',
                    path,
                  });
                }

                const total = nominees
                  .filter((_nominee: NomineeType) => _nominee.isActive)
                  .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
                  .reduce((a: number, b: number) => a + b, 0);

                const allNomineesHaveValues = nominees
                  .filter((nominee: NomineeType) => nominee.isActive)
                  .every((nominee: NomineeType) => nominee.nomineePercentage);

                if (nominees.length && allNomineesHaveValues && total !== 100) {
                  return createError({
                    message: 'Total Nominee % should be equal to 100%',
                    path,
                  });
                }
                return true;
              }
            ),
        }),
        guardianName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              saveForLater && isActive && isMinor(dateOfBirth || ''),
            then: yup.string().nullable().required('Guardian Name is required'),
          }),
        nomineeAddress1: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
              )
              .when({
                is: () => saveForLater,
                then: yup.string().nullable().required('Nominee Address line 1 is required'),
              }),
          }),
        nomineeAddress2: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('nomineeAddress2', (value, context) => {
                const { options, createError, path } = context;
                const { context: optionsContext } = options;
                if (saveForLater && !value) {
                  return createError({
                    message: 'Nominee Address line 2 is required',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeAddress3: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('nomineeAddress3', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Nominee Address line 3 is required',
                  saveForLater
                );
              }),
          }),
        nomineePincode: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .test('nomineePincode', 'Invalid Pincode', (value, context) => {
                return saveForLater || value
                  ? validateNomineePincodeField(value, context, 'nomineeCountry')
                  : true;
              }),
          }),

        nomineeCity: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('nomineeCity', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Nominee City is required',
                  saveForLater
                );
              }),
          }),
        nomineeState: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('nomineeState', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Nominee State is required',
                  saveForLater
                );
              }),
          }),
        nomineeCountry: yup
          .string()
          .nullable()
          .when(['isActive', 'addressSameAsApplicant'], {
            is: (isActive: boolean, addressSameAsApplicant: boolean) =>
              isActive && !addressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .test('nomineeCountry', (value, context) => {
                const { options, createError, path } = context;
                const { context: optionsContext } = options;
                const { countryDropdown = [] } = optionsContext || {};
                if (saveForLater && !value) {
                  return createError({
                    message: 'Nominee Country is required',
                    path,
                  });
                }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
                return true;
              }),
          }),
        guardianAddress1: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 1 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('guardianAddress1', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Guardian Address line 1 is required',
                  saveForLater
                );
              }),
          }),
        guardianAddress2: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 2 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('guardianAddress2', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Guardian Address line 2 is required',
                  saveForLater
                );
              }),
          }),
        guardianAddress3: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 3 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('guardianAddress3', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Guardian Address line 3 is required',
                  saveForLater
                );
              }),
          }),
        guardianPincode: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .test('guardianPincode', 'Invalid Pincode', (value, context) => {
                return saveForLater || value
                  ? validateNomineePincodeField(value, context, 'guardianCountry')
                  : true;
              }),
          }),
        guardianCity: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('guardianCity', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Guardian City is required',
                  saveForLater
                );
              }),
          }),
        guardianState: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .test('guardianState', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Guardian State is required',
                  saveForLater
                );
              }),
          }),
        guardianCountry: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'guardianAddressSameAsApplicant'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              guardianAddressSameAsApplicant: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !guardianAddressSameAsApplicant,
            then: yup
              .string()
              .nullable()
              .test('guardianCountry', (value, context) => {
                const { options, createError, path } = context;
                const { context: optionsContext } = options;
                const { countryDropdown = [] } = optionsContext || {};
                if (saveForLater && !value) {
                  return createError({
                    message: 'Guardian Country is required',
                    path,
                  });
                }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Guardian Country',
                    path,
                  });
                }
                return true;
              }),
          }),
        guardianEmail: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianEmail', (value, context) => {
                if (
                  !(
                    isCustodianICICIOrHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian)
                  )
                ) {
                  return true;
                }
                const { createError, path } = context;
                if (
                  saveForLater &&
                  !value &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian))
                ) {
                  return createError({
                    message: 'Guardian Email is required',
                    path,
                  });
                }
                if (value && !emailRegex.test(value)) {
                  return createError({
                    message: 'Invalid Email Address',
                    path,
                  });
                }
                return true;
              }),
          }),
        guardianCountryNameAndCode: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianCountryNameAndCode', (value, context) => {
                const { createError, path, parent } = context;
                const codesList = getCountryCodes()
                  .map((codes) => {
                    if (codes.label === value) {
                      return codes.countryCode;
                    }
                  })
                  .filter((ele) => ele)
                  .toString();

                if (
                  saveForLater &&
                  !value &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian))
                ) {
                  return createError({
                    message: 'Country Code is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.guardianPhone &&
                  !isValidPhoneNumber(parent.guardianPhone, codesList as CountryCode)
                ) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }

                return true;
              }),
          }),
        guardianPhone: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianPhone', (value, context) => {
                if (
                  !(
                    isCustodianICICIOrHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian)
                  )
                ) {
                  return true;
                }
                const { createError, path, parent } = context;
                const codesList = getCountryCodes()
                  .map((codes) => {
                    if (codes.label === parent.guardianCountryNameAndCode) {
                      return codes.countryCode;
                    }
                  })
                  .filter((ele) => ele)
                  .toString();

                if (
                  saveForLater &&
                  !value &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian))
                ) {
                  return createError({
                    message: 'Guardian Phone Number is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.guardianCountryNameAndCode === 'India: +91' &&
                  !phoneRegExp.test(value)
                ) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
                if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeEmail: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineeEmail', (value, context) => {
                const { createError, path, parent } = context;
                if (
                  !(
                    isCustodianICICIOrHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    (!isMinor(parent.dateOfBirth ? parent.dateOfBirth : '') &&
                      isCustodianKotak(custodian))
                  )
                ) {
                  return true;
                }

                if (
                  saveForLater &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian)) &&
                  !value
                ) {
                  return createError({
                    message: 'Nominee Email is required',
                    path,
                  });
                }
                if (value && !emailRegex.test(value)) {
                  return createError({
                    message: 'Invalid Email Address',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeCountryNameAndCode: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineeCountryNameAndCode', (value, context) => {
                const { createError, path, parent } = context;
                const codesList = getCountryCodes()
                  .map((codes) => {
                    if (codes.label === value) {
                      return codes.countryCode;
                    }
                  })
                  .filter((ele) => ele)
                  .toString();
                if (
                  saveForLater &&
                  !value &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian))
                ) {
                  return createError({
                    message: 'Country Code is required',
                    path,
                  });
                }
                if (
                  parent.nomineePhone &&
                  !isValidPhoneNumber(parent.nomineePhone, codesList as CountryCode)
                ) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }

                return true;
              }),
          }),
        nomineePhone: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineePhone', (value, context) => {
                const { createError, path, parent } = context;
                if (
                  !(
                    isCustodianICICIOrHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    (!isMinor(parent.dateOfBirth ? parent.dateOfBirth : '') &&
                      isCustodianKotak(custodian))
                  )
                ) {
                  return true;
                }

                const codesList = getCountryCodes()
                  .map((codes) => {
                    if (codes.label === parent.nomineeCountryNameAndCode) {
                      return codes.countryCode;
                    }
                  })
                  .filter((ele) => ele)
                  .toString();
                if (
                  saveForLater &&
                  !value &&
                  (isCustodianHDFC(custodian) ||
                    isCustodianAxis(custodian) ||
                    isCustodianKotak(custodian))
                ) {
                  return createError({
                    message: 'Nominee Phone is required',
                    path,
                  });
                }
                if (value) {
                  if (
                    parent.nomineeCountryNameAndCode === 'India: +91' &&
                    !phoneRegExp.test(value)
                  ) {
                    return createError({
                      message: 'Invalid Mobile number',
                      path,
                    });
                  }

                  if (!isValidPhoneNumber(value, codesList as CountryCode)) {
                    return createError({
                      message: 'Invalid Mobile number',
                      path,
                    });
                  }
                }

                return true;
              }),
          }),
        nomineePan: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineePan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const { nominees = [] } = optionsContext || {};
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                if (
                  saveForLater &&
                  (isCustodianICICIOrHDFC(custodian) ||
                    ((isCustodianAxis(custodian) || isCustodianKotak(custodian)) &&
                      !isMinor(parent.dateOfBirth || ''))) &&
                  !value
                ) {
                  return createError({
                    message: 'Nominee PAN is required',
                    path,
                  });
                }
                // if (!value && !isMinor(parent.dateOfBirth || '')) {
                //   return createError({
                //     message: 'Nominee PAN is required',
                //     path,
                //   });
                // }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as nominee PAN',
                    path,
                  });
                }
                if (
                  value &&
                  nominees.filter((nominee: NomineeType) => nominee.nomineePan === value).length !==
                    1
                ) {
                  return createError({
                    message:
                      'There is already same pan for a nominee associated with this application',
                    path,
                  });
                }
                if (
                  value &&
                  parent.nomineeGuardianPan &&
                  value === parent.nomineeGuardianPan &&
                  isMinor(parent.dateOfBirth || '')
                ) {
                  return createError({
                    message: 'Nominee Pan should not be same as guardian PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeGuardianPan: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineeGuardianPan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                if (saveForLater && !value && isMinor(parent.dateOfBirth || '')) {
                  return createError({
                    message: 'Guardian Pan is required',
                    path,
                  });
                }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as Guardian PAN',
                    path,
                  });
                }
                if (value && parent.nomineePan && value === parent.nomineePan) {
                  return createError({
                    message: 'Guardian Pan should not be same as Nominee PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
      })
    ),
  });

export const bankDetailsSchema = (
  applicants: Partial<Applicant>[] | undefined,
  checkNDPSM: boolean
) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        ifscCode: yup
          .string()
          .nullable()
          .when({
            is: () => checkNDPSM,
            then: yup
              .string()
              .nullable()
              .required('IFSC Code is required')
              .matches(ifscRegex, 'Invalid IFSC Code'),
          }),
        bankAccountNumber: yup
          .string()
          .nullable()
          .when({
            is: () => checkNDPSM,
            then: yup
              .string()
              .nullable()
              .required('Bank Account Number is required')
              .matches(accountNumberRegex, 'Please enter valid Account Number')
              .test('bankAccountNumber', 'Account number already used', (value, context) => {
                const {
                  options: { context: optionsContext },
                } = context;
                const { banks } = optionsContext || {};
                return banks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1;
              }),
          }),
        bankAccountType: yup
          .string()
          .nullable()
          .when({
            is: () => checkNDPSM,
            then: yup
              .string()
              .nullable()
              // .required('Bank Account Type is required')
              .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const fieldValue = value === null ? '' : value;
                const { applicationType, status, banks } = optionsContext || {};
                if (
                  applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                  !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
                  !fieldValue
                ) {
                  return true;
                }
                if (
                  applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                  fieldValue &&
                  ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(
                    fieldValue
                  )
                ) {
                  return false;
                } else if (
                  fieldValue &&
                  !isApplicantNRI(status as string) &&
                  !Object.values(BankAccountTypeMaster).includes(fieldValue)
                ) {
                  return false;
                }
                if (
                  fieldValue &&
                  isApplicantNRI(status as string) &&
                  !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
                ) {
                  return false;
                }
                if (
                  fieldValue &&
                  isApplicantNRI(status as string) &&
                  banks.filter(
                    (bank: Bank) =>
                      Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
                      Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
                      bank.bankAccountType != fieldValue
                  ).length === 1
                ) {
                  return createError({
                    message: 'Both Bank Account Type should be same',
                    path,
                  });
                }

                return !!fieldValue;
              }),
          }),

        bankName: yup
          .string()
          .nullable()
          .when({
            is: () => checkNDPSM,
            then: yup.string().nullable().required('Bank Name is required'),
          }),
        bankBranch: yup
          .string()
          .nullable()
          .when({
            is: () => checkNDPSM,
            then: yup.string().nullable().required('Bank Branch is required'),
          }),
        defaultBankAccount: yup
          .bool()
          .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
            return isDefaultBankSelected;
          }),
      })
    ),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  saveForLater = true
) =>
  yup.object().shape({
    schemeId: yup.string().test('schemeId', (value, context) => {
      return RequiredfieldValidation(value, context, 'Fund Scheme is required', saveForLater);
    }),

    planId: yup.string().test('planId', (value, context) => {
      return RequiredfieldValidation(value, context, 'Class Plan is required', saveForLater);
    }),
    commitmentAmount: yup
      .number()
      .nullable()
      .min(
        minCommitmentAmount,
        `Commitment Amount should be greater than or equal to ${currencyConversion(
          minCommitmentAmount
        )}`
      )
      .max(
        maxCommitmentAmount,
        `Commitment Amount should be between ${
          currencyConversion(minCommitmentAmount) || '1 Cr'
        } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
      )
      .test('commitmentAmount', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Commitment Amount is required',
          saveForLater
        );
      }),

    setupFeePercentage: yup
      .number()
      .nullable()
      .lessThan(101, 'Setup Fee Percentage should be less then 100%')
      .test('setupFeePercentage', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Setup Fee Percentage is required',
          saveForLater
        );
      }),

    contributionChequeAmount: yup
      .number()
      .nullable()
      .test('contributionChequeAmount', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Contribution Cheque Amount is required',
          saveForLater
        );
      })
      .max(
        yup.ref('commitmentAmount'),
        'Contribution Amount should not be greater than commitment amount'
      )
      .test(
        'contributionChequeAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Contribution Amount should be equal to commitment amount`
            : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
        }`,
        (value, context) => {
          const { parent } = context;
          const { commitmentAmount } = parent;
          return (
            (100 * Number(value)) / Number(commitmentAmount) >= Number(minContributionPercentage)
          );
        }
      ),
    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
  });

export const typeDeclarationScheme = (text: string) =>
  yup.object().shape({
    typedDeclaration: yup
      .string()
      .required('Typed Declaration is Required')
      .oneOf([text, null], "Typed Declaration doesn't match with sample!"),
  });

export const authorizedSignatoriesDocumentScheme = yup.object().shape({
  groupsignatorydocuments: yup.array().of(
    yup.object().shape({
      documentId: yup
        .string()
        .nullable()
        .test('documentId', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
            parent,
          } = context;
          const { required, documentName, documentType } = parent || {};
          if (required === 'false') {
            return true;
          }
          if (!value && required === 'true') {
            return createError({
              message: `Please upload ${documentName}`,
              path,
            });
          }

          return true;
        }),
    })
  ),
});

export const strategyDeclarationScheme = (saveForLater = true) =>
  yup.object().shape(
    {
      strategiesFeeType: yup
        .string()
        .nullable()
        .test('strategiesFeeType', (value, context) => {
          const { createError, path } = context;
          if (saveForLater && !value) {
            return createError({
              message: 'Fee Type is required',
              path,
            });
          }
          return true;
        }),

      applicationstrategydetails: yup.array().of(
        yup.object().shape({
          feeType: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .test('feeType', (value, context) => {
                  const { createError, path } = context;
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Fee Type is required',
                      path,
                    });
                  }
                  if (value && !Object.keys(FeeDetailTypeMasters).includes(value)) {
                    return createError({
                      message: 'Invalid value',
                      path,
                    });
                  }
                  return true;
                }),
            }),
          strategyType: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .test('strategyType', (value, context) => {
                  const { createError, path } = context;
                  if (value && !Object.keys(STRATEGY_TYPE_OPTIONS).includes(value)) {
                    return createError({
                      message: 'Invalid value for strategy type',
                      path,
                    });
                  }
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Strategy type is required',
                      path,
                    });
                  }
                  return true;
                }),
            }),
          strategyFeeDetailId: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .test('strategyFeeDetailId', (value, context) => {
                  const {
                    options: { context: optionsContext },
                    createError,
                    path,
                    parent,
                  } = context;

                  if (!value) {
                    return createError({
                      message: 'Management Fee is Required',
                      path,
                    });
                  }

                  return true;
                }),
            }),
          hurdleRate: yup
            .string()
            .nullable()
            .when(['isActive', 'feeType'], {
              is: (isActive: boolean, feeType: string) => isActive && checkVariableFee(feeType),
              then: yup
                .string()
                .nullable()
                .matches(defaultRegexWithSingleLine, 'Please enter valid Hurdle Rate')
                .test('hurdleRate', (value, context) => {
                  const { createError, path } = context;
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Hurdle Rate is required',
                      path,
                    });
                  }
                  return true;
                }),
            }),
          performanceFee: yup
            .string()
            .nullable()
            .when(['isActive', 'feeType'], {
              is: (isActive: boolean, feeType: string) => isActive && checkVariableFee(feeType),
              then: yup
                .string()
                .nullable()
                .matches(defaultRegexWithSingleLine, 'Please enter valid Performance Fees')
                .test('performanceFee', (value, context) => {
                  const { createError, path } = context;
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Performance Fees is required',
                      path,
                    });
                  }
                  return true;
                }),
            }),
          exitLoad: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .matches(defaultRegexWithSingleLine, 'Please enter valid Exit Load')
                .test('exitLoad', (value, context) => {
                  const { createError, path } = context;
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Exit Load is required',
                      path,
                    });
                  }
                  return true;
                }),
            }),
          charges: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .matches(defaultRegexWithSingleLine, 'Please enter valid other charges')
                .required('Other charges is required'),
            }),

          investmentAmount: yup
            .number()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .number()
                .nullable()
                .test('investmentAmount', (value, context) => {
                  const { createError, path } = context;
                  if (saveForLater && !value) {
                    return createError({
                      message: 'investment amount is Required',
                      path,
                    });
                  }
                  return true;
                })
                .moreThan(0, 'Investment Amount cannot be zero'),
            }),
        })
      ),
      totalStrategyInvestmentAmount: yup
        .number()
        .nullable()
        // .required('Investment Amount is required')
        .min(5000000, 'Total Investment Amount (in INR) must be greater than or equal to 50 lakhs'),
      ownBorrowedFunds: yup
        .string()
        .nullable()
        .test('ownBorrowedFunds', (value, context) => {
          const { createError, path } = context;

          if (saveForLater && !value) {
            return createError({
              message: 'Fund Type is Required',
              path,
            });
          }
          return true;
        }),

      cheque: yup.boolean().when('rtgs', {
        is: (rtgs: boolean) => saveForLater && !rtgs,
        then: yup.boolean().oneOf([true], 'At least one mode of transaction needs to be selected'),
      }),
      rtgs: yup.boolean().when('cheque', {
        is: (cheque: boolean) => saveForLater && !cheque,
        then: yup.boolean().oneOf([true], ''),
      }),
    },
    [['cheque', 'rtgs']]
  );

export const riskProfileScheme = yup.object().shape({
  riskprofiles: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().required('Field is required'),
      otherValue: yup
        .string()
        .nullable()
        .when('answer', {
          is: (answer: string) => answer === 'others',
          then: yup.string().nullable().required('Field is required'),
        }),
    })
  ),
});

export const relatedPartyConsentSchema = (saveForLater = true) =>
  yup.object().shape({
    relatedpartyconsents: yup.array().of(
      yup.object().shape({
        singleAssociateLimit: yup
          .number()
          .nullable()
          .max(15, 'Setup fee percentage Should be less then or equal to 15%')
          .test('singleAssociateLimit', (value, context) => {
            return RequiredfieldValidation(value, context, 'Setup fee required', saveForLater);
          }),

        multipleAssociateLimit: yup
          .number()
          .nullable()
          .when('securityType', {
            is: (securityType: string) => securityType !== 'Equity + Debt + Hybrid Securities',
            then: yup
              .number()
              .nullable()
              .max(25, 'percentage Should be less then or equal to 25%')
              .test('multipleAssociateLimit', (value, context) => {
                return RequiredfieldValidation(value, context, 'Percentage required', saveForLater);
              }),
          }),
      })
    ),
  });

export const sourceOfInvestmentSchema = (saveForLater = true) =>
  yup.object().shape(
    {
      totalStrategyInvestmentAmount: yup
        .number()
        .nullable()
        .test('totalStrategyInvestmentAmount', (value, context) => {
          return RequiredfieldValidation(
            value,
            context,
            'Investment Amount is required',
            saveForLater
          );
        }),

      ownBorrowedFunds: yup
        .string()
        .nullable()
        .test('ownBorrowedFunds', (value, context) => {
          return RequiredfieldValidation(value, context, 'Fund Type is Required', saveForLater);
        }),

      cheque: yup.boolean().when('rtgs', {
        is: (rtgs: boolean) => saveForLater && !rtgs,
        then: yup.boolean().oneOf([true], 'At least one mode of transaction needs to be selected'),
      }),
      rtgs: yup.boolean().when('cheque', {
        is: (cheque: boolean) => saveForLater && !cheque,
        then: yup.boolean().oneOf([true], ''),
      }),
    },
    [['cheque', 'rtgs']]
  );

export const disclosureOfInterestSchema = (
  referenceApplicantType: string,
  saveForLater = true,
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) =>
  yup.object().shape({
    interestedCompaniesCheck: yup
      .string()
      .nullable()
      .test('interestedCompaniesCheck', (value, context) => {
        const { parent } = context;
        return !parent.isDisclosureOfInterestToBeFilledByInvestor ||
          (parent.isDisclosureOfInterestToBeFilledByInvestor &&
            (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
              validateFormIncludingAllJointHoldersInInvestorLogin))
          ? RequiredfieldValidation(value, context, 'Field is required', saveForLater)
          : true;
      }),

    interestedcompanies: yup.array().of(
      yup.object().shape({
        companyName: yup
          .string()
          .nullable()
          .test('companyName', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: 'Company name is required',
                path,
              });
            }

            return true;
          }),

        natureOfInterest: yup
          .string()
          .nullable()
          .test('natureOfInterest', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: 'Nature Of Interest required',
                path,
              });
            }

            return true;
          }),

        numberOfShares: yup
          .string()
          .nullable()
          .test('numberOfShares', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: 'Number Of Share required',
                path,
              });
            }
            if (
              value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive &&
              !numberRegex.test(value)
            ) {
              return createError({
                message: 'Field value should be a number',
                path,
              });
            }
            return true;
          }),

        percentageofPaidUpCapital: yup
          .string()
          .nullable()
          .test('percentageofPaidUpCapital', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: '% of Paid Up Capital required',
                path,
              });
            }
            if (
              value &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive &&
              !numberRegex.test(value)
            ) {
              return createError({
                message: 'Field value should be a number',
                path,
              });
            }
            return true;
          }),
      })
    ),
  });

export const disclosureOfExcludedSchema = (
  referenceApplicantType: string,
  saveForLater = true,
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) =>
  yup.object().shape({
    excludedCompaniesCheck: yup
      .string()
      .nullable()
      .test('excludedCompaniesCheck', (value, context) => {
        const { parent } = context;
        return !parent.isDisclosureOfExclusionsToBeFilledByInvestor ||
          (parent.isDisclosureOfExclusionsToBeFilledByInvestor &&
            (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
              validateFormIncludingAllJointHoldersInInvestorLogin))
          ? RequiredfieldValidation(value, context, 'Field is required', saveForLater)
          : true;
      }),

    excludedcompanies: yup.array().of(
      yup.object().shape({
        companySectorType: yup
          .string()
          .nullable()
          .test('companySectorType', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.excludedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: 'Company type required',
                path,
              });
            }

            return true;
          }),

        companySectorName: yup
          .string()
          .nullable()
          .test('companySectorName', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              saveForLater &&
              !value &&
              YesNoMaster[optionsContext?.excludedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              return createError({
                message: 'Name of Company/ Industry/ Sector required',
                path,
              });
            }

            return true;
          }),
      })
    ),
  });

export const documentDetailsSchema = (checkNdpmsFlow: boolean, saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);

                      if (saveForLater && optionsDropdown) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          ((banks.filter(
                            (bank) =>
                              !bank.pennydropVerified && bank.defaultBankAccount && checkNdpmsFlow
                          ).length !== 0 &&
                            Number(indexes[0]) === 0) ||
                            isApplicantNRI(applicants[indexes[0]]?.status || ''))
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                        if (documentType === 'bankAccountProof' && Number(indexes[0]) !== 0) {
                          return true;
                        }
                        if (
                          !fieldValue &&
                          required === 'true' &&
                          documentType !== 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (saveForLater && !value) {
                        if (
                          !value &&
                          banks &&
                          ((banks.filter(
                            (bank) =>
                              !bank.pennydropVerified && bank.defaultBankAccount && checkNdpmsFlow
                          ).length !== 0 &&
                            Number(indexes[0]) === 0) ||
                            isApplicantNRI(applicants[indexes[0]]?.status || '')) &&
                          documentType === 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (!value && required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
    nominees: yup.array().of(
      yup.object().shape({
        nomineedocuments: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (saveForLater && optionsDropdown) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (saveForLater && !value && required === 'true') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
    // applicants: yup.array().of(
    //   yup.object().shape({
    //     documents: yup.object().shape({
    //       // identityProof: yup.object().shape({
    //       //   documentName: yup.string().required('Please select identity proof'),
    //       //   documentId: yup.string().required('Please upload Identity Proof'),
    //       // }),
    //       // addressProof: yup.object().shape({
    //       //   documentName: yup.string().required('Please select Address Proof'),
    //       //   documentId: yup.string().required('Please upload Address Proof'),
    //       // }),
    //       bankAccountProof: yup.object().shape({
    //         documentName: yup
    //           .string()
    //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
    //             DocumentRequiredForPennyDrop(value, context)
    //           ),

    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
    //             DocumentRequiredForPennyDrop(value, context)
    //           ),
    //       }),
    //       poaNotarized: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
    //             POADocumentValidation(value, context, 'poaNotarized')
    //           ),
    //       }),
    //       investorsConsent: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
    //             POADocumentValidation(value, context)
    //           ),
    //       }),
    //       fatca: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
    //             POADocumentValidation(value, context)
    //           ),
    //       }),
    //     }),
    //   })
    // ),
  });

export const documentDetailsSchemaForInvestorLogin = (saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }

                      if (
                        saveForLater &&
                        !value &&
                        required === 'true' &&
                        documentType === 'investorSignature'
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  });

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[],
  checkNDPMS: boolean,
  saveForLater = true
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    distributorId: yup
      .string()
      .nullable()
      .test('distributorId', (value, context) => {
        const { parent } = context;
        return parent.loginType === 'distributor' && !!distributors.length
          ? RequiredfieldValidation(
              value,
              context,
              'Please select a distributor code',
              saveForLater
            )
          : true;
      }),

    subdistributorId: yup
      .string()
      .nullable()
      .test('subdistributorId', (value, context) => {
        return selectedDistributor.length > 0
          ? RequiredfieldValidation(
              value,
              context,
              "Please select a distributor's rm name - code",
              saveForLater
            )
          : true;
      }),
    bankDetails: yup
      .string()
      .nullable()
      .test('bankDetails', (value, context) => {
        return checkNDPMS
          ? RequiredfieldValidation(value, context, 'Banks Details is required', saveForLater)
          : true;
      }),

    custodian: yup
      .string()
      .nullable()
      .test('custodian', (value, context) => {
        const { createError, path } = context;

        if (saveForLater && value && !Object.keys(CustodianMaster)?.includes(value || '')) {
          return createError({
            message: 'Invalid value for Custodian Name',
            path,
          });
        } else {
          return checkNDPMS || !value
            ? RequiredfieldValidation(value, context, 'Custodian Name is required', saveForLater)
            : true;
        }
      }),
  });

export const userManagementRmSchema = yup.object().shape({
  name: yup.string().nullable().required('RM Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
});

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('Mobile Number is required'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value && parent.type=== 'individual') {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value&&parent.type=== 'individual') {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  arnCode: yup
    .string()
    .nullable()
    .matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
    .required('ARN Code is required'),
  rmId: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('RM Name is required'),
    }),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerAMCAuthorisedSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { createError, path } = context;
      if (value && value === Invalid_Date) {
        return createError({
          message: 'Invalid Date',
          path,
        });
      }
      if (value && isMinor(value || '')) {
        return createError({
          message: 'Age should be between greater than 18',
          path,
        });
      }

      return true;
    }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = yup.object().shape({
  name: yup.string().nullable().required("Distributor's RM name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
  rmId: yup.string().nullable().required("AMC's RM name is required"),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const NonIndividualContributorValidationSchema = (saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .nullable()
          .test('name', (value, context) => {
            return RequiredfieldValidation(value, context, 'Name is required', saveForLater);
          }),

        cityOfIncorporation: yup
          .string()
          .nullable()
          .test('cityOfIncorporation', (value, context) => {
            return RequiredfieldValidation(
              value,
              context,
              'Place of Registration/Incorporation is required',
              saveForLater
            );
          }),
        dateOfBirth: yup
          .string()
          .nullable()
          .test('dateOfBirth', (value, context) => {
            const { path, createError } = context;
            if (value && value === Invalid_Date) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            } else {
              return RequiredfieldValidation(
                value,
                context,
                'Date of Registration/Incorporation is required',
                saveForLater
              );
            }
          }),

        ckycNo: yup
          .string()
          .nullable()
          .test('ckycNo', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const {
              options: { context: optionsContext },
              createError,
              path,
            } = context;
            const { applicants } = optionsContext || {};
            if (!fieldValue) {
              return true;
            } else if (fieldValue.length !== 14) {
              return createError({
                message: 'Invalid CKYC No./CKYC Acknowledge No./KIN No',
                path,
              });
            } else if (!numberRegex.test(fieldValue)) {
              return createError({
                message: 'CKYC No./CKYC Acknowledge No./KIN No allows only digits',
                path,
              });
            } else if (
              applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length >
              1
            ) {
              return createError({
                message:
                  'There is already same CKYC Number for an applicant associated with this application',
                path,
              });
            }
            return true;
          }),
        panNo: yup
          .string()
          .nullable()
          .uppercase()
          .length(10, 'PAN must be exactly 10 characters')
          .matches(nonIndividualPanRegex, 'invalid PAN')
          .test('panNo', (value, context) => {
            return RequiredfieldValidation(value, context, 'PAN is required', saveForLater);
          }),

        investorType: yup
          .string()
          .nullable()
          .test('investorType', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (fieldValue) {
              if (fieldValue && !Object.keys(investorTypeMasters).includes(fieldValue)) {
                return createError({
                  message: 'Invalid value for Investor Type',
                  path,
                });
              }
            }

            if (saveForLater && !fieldValue) {
              return createError({
                message: 'Please Select Investor Type',
                path,
              });
            }
            return true;
          }),

        netWorth: yup
          .string()
          .nullable()
          .test('netWorth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (saveForLater && !fieldValue) {
              return createError({
                message: 'Net Worth is required',
                path,
              });
            }
            if (fieldValue && !amountRegex.test(fieldValue)) {
              return createError({
                message: 'Invalid amount',
                path,
              });
            }
            return true;
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .test('grossAnnualIncome', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;

            if (fieldValue && !grossAnnualMasters.map((item) => item.label).includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Gross Annual Income',
                path,
              });
            }

            if (saveForLater && !fieldValue) {
              return createError({
                message: 'Gross Annual Income is required',
                path,
              });
            }
            return true;
          }),
      })
    ),
  });

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string,
  custodian: string,
  saveForLater = true
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};

  if (saveForLater && !fieldValue && addressType !== 'contactperson') {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() === 'india' &&
    !indianPin.test(fieldValue) &&
    !(Edit_KYC
      ? parent?.fetchedFromKRA
      : addressType === 'correspondence'
      ? parent?.fetchedFromKRA && isCustodianICICI(custodian)
      : parent?.fetchedFromKRA)
  ) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (
    fieldValue &&
    parent.country?.toLowerCase() !== 'india' &&
    !alphaNumeric.test(fieldValue) &&
    !parent?.fetchedFromKRA
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  if (
    fieldValue &&
    !defaultRegexWithSingleLine.test(fieldValue) &&
    (Edit_KYC
      ? parent?.fetchedFromKRA
      : addressType === 'correspondence'
      ? parent?.fetchedFromKRA && isCustodianICICI(custodian)
      : parent?.fetchedFromKRA)
  ) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = (custodian: string, saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        contactperson: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            ),
          // .required('Address line 1 is required')
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            ),
          // .required('Address line 2 is required')
          // address3: yup
          //   .string()
          //   .nullable()
          //   .matches(
          //     addressRegex,
          //     "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
          //   ),
          landLineNumber: yup
            .string()
            .nullable()
            .test('landLineNumber', (value, context) => {
              const fieldValue = value === undefined ? '' : value;
              const { path, createError, parent } = context;
              if (saveForLater && !fieldValue && parent.stdCode) {
                return createError({
                  message: 'Please enter Landline number',
                  path,
                });
              } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
                return createError({
                  message: 'Please enter valid Landline Number',
                  path,
                });
              }
              return true;
            }),
          stdCode: yup
            .string()
            .nullable()
            .test('stdCode', (value, context) => {
              const fieldValue = value === undefined ? '' : value;
              const { path, createError, parent } = context;
              if (saveForLater && !fieldValue && parent.landLineNumber) {
                return createError({
                  message: 'Please enter STD Code',
                  path,
                });
              } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
                return createError({
                  message: 'Please enter valid STD Code',
                  path,
                });
              }
              return true;
            }),

          city: yup.string().nullable(),
          // .required('City is required')
          state: yup.string().nullable(),
          // .required('State is required')
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              // if (!value) {
              //   return createError({
              //     message: 'Country is required',
              //     path,
              //   });
              // }
              if (value && !countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validateNonIndividualPincodeField(
                value,
                context,
                'contactperson',
                custodian,
                saveForLater
              )
            ),
          name: yup
            .string()
            .nullable()
            .test('name', (value, context) => {
              return RequiredfieldValidation(value, context, 'Name is required', saveForLater);
            }),

          email: yup
            .string()
            .nullable()
            .matches(emailRegex, 'invalid Email ID')
            .test('email', (value, context) => {
              return RequiredfieldValidation(value, context, 'Email is required', saveForLater);
            }),

          mobile: yup
            .string()
            .nullable()
            .test('mobile', (value, context) => {
              const { createError, path, parent } = context;
              const codesList = getCountryCodes()
                .map((codes) => {
                  if (codes.label === parent.countryNameAndCode) {
                    return codes.countryCode;
                  }
                })
                .filter((ele) => ele)
                .toString();
              if (saveForLater && !value) {
                return createError({
                  message: 'Mobile is required',
                  path,
                });
              }
              if (value) {
                if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
                if (!isValidPhoneNumber(value, codesList as CountryCode)) {
                  return createError({
                    message: 'Invalid Mobile number',
                    path,
                  });
                }
              }

              return true;
            }),
          countryNameAndCode: yup
            .string()
            .nullable()
            .test('countryNameAndCode', (value, context) => {
              const { createError, path, parent } = context;
              const codesList = getCountryCodes()
                .map((codes) => {
                  if (codes.label === value) {
                    return codes.countryCode;
                  }
                })
                .filter((ele) => ele)
                .toString();
              if (saveForLater && !value) {
                return createError({
                  message: 'Country Code is required',
                  path,
                });
              }
              if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
                return createError({
                  message: 'Invalid Mobile number',
                  path,
                });
              }

              return true;
            }),
        }),
        address: yup.object().shape({
          correspondence: yup.object().shape({
            fetchedFromKRA: yup.bool().nullable(),
            address1: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA'], {
                is: (fetchedFromKRA: boolean) =>
                  Edit_KYC ? fetchedFromKRA : fetchedFromKRA && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 1 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              .test('address1', (value, context) => {
                return RequiredfieldValidation(
                  value,
                  context,
                  'Address line 1 is required',
                  saveForLater
                );
              }),

            address2: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA'], {
                is: (fetchedFromKRA: boolean) =>
                  Edit_KYC ? fetchedFromKRA : fetchedFromKRA && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 2 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
                  ),
              })
              .test('address2', 'Address line 2 is required', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
                if (fetchedFromKRA && isCustodianICICI(custodian)) {
                  return true;
                }
                if (saveForLater && !fetchedFromKRA && !value) {
                  return false;
                }
                return true;
              }),
            address3: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA'], {
                is: (fetchedFromKRA: boolean) =>
                  Edit_KYC ? fetchedFromKRA : fetchedFromKRA && isCustodianICICI(custodian),
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'invalid address line 3 format'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(
                    addressRegex,
                    "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
                  ),
              }),
            pincode: yup
              .string()
              .nullable()
              .test('pincode', (value, context) =>
                validateNonIndividualPincodeField(
                  value,
                  context,
                  'correspondence',
                  custodian,
                  saveForLater
                )
              ),
            city: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA'], {
                is: (fetchedFromKRA: boolean) =>
                  Edit_KYC ? fetchedFromKRA : fetchedFromKRA && isCustodianICICI(custodian),
                then: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid value'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(stringRegex, 'Special characters and numbers not allowed'),
              })
              .test('city', (value, context) => {
                return RequiredfieldValidation(value, context, 'City is required', saveForLater);
              }),

            district: yup
              .string()
              .nullable()
              .test('district', (value, context) => {
                const fieldValue = !value ? '' : value;
                const { createError, path, options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                if (
                  !isCustodianICICIOrHDFC(custodian) &&
                  !isCustodianOrbis(custodian) &&
                  !isCustodianAxis(custodian)
                ) {
                  return true;
                }
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'District is required',
                    path,
                  });
                }
                if (fieldValue && !stringRegex.test(fieldValue)) {
                  return createError({
                    message: 'Special characters and numbers not allowed',
                    path,
                  });
                }
                return true;
              }),
            state: yup
              .string()
              .nullable()
              .when(['fetchedFromKRA'], {
                is: (fetchedFromKRA: boolean) =>
                  Edit_KYC ? fetchedFromKRA : fetchedFromKRA && isCustodianICICI(custodian),
                then: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid value'),
                otherwise: yup
                  .string()
                  .nullable()
                  .matches(stringRegex, 'Special characters and numbers not allowed'),
              })
              .test('state', (value, context) => {
                if (!isCustodianICICIOrHDFC(custodian)) {
                  return true;
                }
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { statesDropdown = [] } = optionsContext || {};
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;

                if (saveForLater && !value) {
                  return createError({
                    message: 'State is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.country?.toUpperCase() === 'INDIA' &&
                  !(Edit_KYC
                    ? parent?.fetchedFromKRA
                    : parent?.fetchedFromKRA && isCustodianICICI(custodian)) &&
                  !statesDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for State',
                    path,
                  });
                }
                return true;
              }),
            country: yup
              .string()
              .nullable()
              .test('country', (value, context) => {
                const { options, createError, path, parent } = context;
                const { context: optionsContext } =
                  options as InternalOptions<NonIndividualContactValues>;
                const { countryDropdown = [] } = optionsContext || {};
                if (saveForLater && !value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (
                  value &&
                  !(Edit_KYC
                    ? parent?.fetchedFromKRA
                    : parent?.fetchedFromKRA && isCustodianICICI(custodian)) &&
                  !countryDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
                return true;
              }),
          }),
          permanent: yup.object().shape({
            fetchedFromKRA: yup.bool().nullable(),
            address1: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
              )
              .test('address1', 'Address line 1 is required', (value, context) =>
                saveForLater
                  ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                  : true
              ),
            address2: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
              )
              // .test('address2', 'Address2 is required', (value, context) =>
              //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
              // ),
              .test('address2', 'Address line 2 is required', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
                if (saveForLater && !fetchedFromKRA) {
                  return validatePermanentAddressFields(
                    value,
                    context,
                    APPLICATION_TYPE.NON_INDIVIDUAL
                  );
                }
                return true;
              }),
            address3: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
              ),
            pincode: yup
              .string()
              .nullable()
              .test('pincode', (value, context) => {
                const { options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { permanentAddressSameAsCorresponding = false } =
                  currentApplicant.address.correspondence || {};
                if (!permanentAddressSameAsCorresponding) {
                  return validateNonIndividualPincodeField(
                    value,
                    context,
                    'permanent',
                    custodian,
                    saveForLater
                  );
                }
                return true;
              }),
            city: yup
              .string()
              .nullable()
              .test('city', 'City is required', (value, context) =>
                saveForLater
                  ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                  : true
              ),
            district: yup
              .string()
              .nullable()
              .test('district', (value, context) => {
                const fieldValue = !value ? '' : value;
                const { createError, path, options } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { fetchedFromKRA = null, address_type } =
                  currentApplicant.address.permanent || {};
                const { permanentAddressSameAsCorresponding = false } =
                  currentApplicant.address.correspondence || {};
                if (
                  (!isCustodianICICIOrHDFC(custodian) &&
                    !isCustodianOrbis(custodian) &&
                    !isCustodianAxis(custodian)) ||
                  permanentAddressSameAsCorresponding
                ) {
                  return true;
                }
                if (saveForLater && !fieldValue) {
                  return createError({
                    message: 'District is required',
                    path,
                  });
                }
                if (fieldValue && !stringRegex.test(fieldValue)) {
                  return createError({
                    message: 'Special characters and numbers not allowed',
                    path,
                  });
                }
                return true;
              }),
            state: yup
              .string()
              .nullable()
              .test('state', 'State is required', (value, context) =>
                saveForLater
                  ? validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
                  : true
              )
              .test('state', (value, context) => {
                if (!isCustodianICICIOrHDFC(custodian)) {
                  return true;
                }
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { statesDropdown = [] } = optionsContext || {};
                const { applicants = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { nationality, status = '' } = currentApplicant.address;
                const { permanentAddressSameAsCorresponding = false } =
                  currentApplicant.address.correspondence || {};
                if (permanentAddressSameAsCorresponding) {
                  return true;
                }
                if (saveForLater && !value) {
                  return createError({
                    message: 'State is required',
                    path,
                  });
                }

                if (
                  value &&
                  parent.country?.toUpperCase() === 'INDIA' &&
                  !parent?.fetchedFromKRA &&
                  !statesDropdown.includes(value)
                ) {
                  return createError({
                    message: 'Invalid value for State',
                    path,
                  });
                }
                return true;
              }),
            country: yup
              .string()
              .nullable()
              .test('country', (value, context) => {
                const { options, createError, path, parent } = context;
                const { context: optionsContext, index } =
                  options as InternalOptions<NonIndividualContactValues> & {
                    index: number;
                  };
                const { applicants = [], countryDropdown = [] } = optionsContext || {};
                const currentApplicant = applicants[index] || {};
                const { permanentAddressSameAsCorresponding = false } =
                  currentApplicant.address.correspondence || {};
                if (!permanentAddressSameAsCorresponding) {
                  if (saveForLater && !value) {
                    return createError({
                      message: 'Country is required',
                      path,
                    });
                  }
                  if (value && !parent?.fetchedFromKRA && !countryDropdown.includes(value)) {
                    return createError({
                      message: 'Invalid value for Country',
                      path,
                    });
                  }
                }
                return true;
              }),
            // .test('country', 'Country is required', (value, context) =>
            //   validatePermanentAddressFields(value, context)
            // ),
          }),
        }),
      })
    ),
  });

export const nonIndividualDocumentDetailsSchema = (saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (saveForLater && optionsDropdown) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                            .length !== 0
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                        if (
                          !fieldValue &&
                          required === 'true' &&
                          documentType !== 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (saveForLater && !value) {
                        if (
                          !value &&
                          documentType === 'bankAccountProof' &&
                          banks &&
                          banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                            .length !== 0
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  });

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  groupsignatorydocuments: yup.array().of(
    yup.object().shape({
      documentId: yup
        .string()
        .nullable()
        .test('documentId', (value, { path, parent, createError }) => {
          if (parent.required === 'true' && !value) {
            return createError({
              path,
              message: `Please upload ${parent.documentName || ''}`,
            });
          }
          return true;
        }),
    })
  ),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
        return createError({
          message:
            'There is already same Email ID for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = (saveForLater = true) =>
  yup.object().shape({
    panNumber: yup
      .string()
      .nullable()
      .uppercase()
      .test('panNumber', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;

        const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
        if (!value) {
          return createError({
            message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
            path,
          });
        }
        if (uboPanNumbers && uboPanNumbers.includes(value)) {
          return createError({
            message:
              'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
            path,
          });
        }

        return true;
      }),

    dob: yup
      .string()
      .nullable()
      .test('dob', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (value && value === Invalid_Date) {
          return createError({
            message: 'Invalid Date',
            path,
          });
        }

        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          isMinor(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          maxAge(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        return true;
      }),
    name: yup
      .string()
      .nullable()
      .test('name', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Name') : true;
      }),
    identificationType: yup
      .string()
      .nullable()
      .test('identificationType', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Identification Type') : true;
      }),
    percentageOfBeneficialInterest: yup
      .number()
      .nullable()
      .test('percentageOfBeneficialInterest', (value, context) => {
        const fieldValue = value;
        const { path, createError, parent } = context;
        if (
          saveForLater &&
          (fieldValue === null || (fieldValue === undefined && !parent.isFetchData))
        ) {
          return createError({
            message: `Percentage Of Beneficial Interest is required`,
            path,
          });
        }
        if (saveForLater && fieldValue === 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater 0`,
            path,
          });
        }
        if (fieldValue) {
          if (fieldValue < 0 && !parent.isFetchData) {
            return createError({
              message: `Percentage Of Beneficial Interest should be greater 0`,
              path,
            });
          }
          if (fieldValue > 100 && !parent.isFetchData) {
            return createError({
              message: `Percentage Of Beneficial Interest must not exceed 100%`,
              path,
            });
          }
        }
        return true;
      }),
    countryOfTaxResidency: yup
      .string()
      .nullable()
      .test('countryOfTaxResidency', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Country Of Tax Residency') : true;
      }),
    cpUboCode: yup
      .string()
      .nullable()
      .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
      .test('cpUboCode', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Cp/UboCode') : true;
      }),
    placeAndCountryOfBirth: yup
      .string()
      .nullable()
      .test('placeAndCountryOfBirth', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Place And Country Of Birth') : true;
      }),
    occupation: yup
      .string()
      .nullable()
      .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
      .test('occupation', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Occupation') : true;
      }),
    gender: yup
      .string()
      .nullable()
      .test('gender', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Gender') : true;
      }),
    nationality: yup
      .string()
      .nullable()
      // .oneOf(nationalitiesMasters, 'Invalid value for Nationality')
      .test('nationality', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Nationality') : true;
      }),
    fatherName: yup
      .string()
      .nullable()
      .test('fatherName', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'FatherName') : true;
      }),
    ckycNumber: yup
      .string()
      .nullable()
      .length(14, 'Invalid CKYC Number')
      .test('ckycNumber', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, options, parent } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;
        if (!fieldValue && !parent.isFetchData) {
          return true;
        } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
          return createError({
            message: 'CKYC Number allows only digits',
            path,
          });
        }
        const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
        if (
          uboCkycNumbers &&
          uboCkycNumbers.includes(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message:
              'There is already same CKYC Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
            path,
          });
        }
        return true;
      }),
    address2: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address2', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Address Line 1') : true;
      }),
    address3: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address3', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Address Line 2') : true;
      }),
    city: yup
      .string()
      .nullable()
      .test('city', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'City') : true;
      }),
    state: yup
      .string()
      .nullable()
      .test('state', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'State') : true;
      }),
    country: yup
      .string()
      .nullable()
      .test('country', (value, context) => {
        return saveForLater ? fieldValidation(value, context, 'Country') : true;
      }),
    pincode: yup
      .string()
      .nullable()
      .test('pincode', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (saveForLater && !fieldValue && !parent.isFetchData) {
          return createError({
            message: 'Pincode is required',
            path,
          });
        } else if (
          fieldValue &&
          parent.country?.toLowerCase() === 'india' &&
          !indianPin.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Pincode length should be 6 digits',
            path,
          });
        } else if (
          fieldValue &&
          parent.country?.toLowerCase() !== 'india' &&
          !alphaNumeric.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Invalid Pincode',
            path,
          });
        }
        return true;
      }),
  });

export const nonIndividualControllingPersons = (saveForLater = true) =>
  yup.object().shape({
    name: yup
      .string()
      .nullable()
      .test('name', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Name of controlling person is required',
          saveForLater
        );
      }),
    correspondenceAddress: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "invalid address format [special characters are not allowed except -/',&()#:.]"
      )
      .test('correspondenceAddress', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Correspondence address is required',
          saveForLater
        );
      }),
    countryOfResidense: yup
      .string()
      .nullable()
      .test('countryOfResidense', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Country of residence for tax purposes is required',
          saveForLater
        );
      }),
    issuingCountry: yup
      .string()
      .nullable()
      .test('issuingCountry', (value, context) => {
        return RequiredfieldValidation(value, context, 'Issuing country is required', saveForLater);
      }),
    controllingPersonType: yup
      .string()
      .nullable()
      .test('controllingPersonType', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Controlling Person Type is required',
          saveForLater
        );
      }),

    indentificationType: yup
      .string()
      .nullable()
      .test('indentificationType', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Identification Type is required',
          saveForLater
        );
      }),

    indentificationNumber: yup
      .string()
      .nullable()
      .test('indentificationNumber', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
        const indentificationNumberArr = optionsContext?.map(
          (person) => person.indentificationNumber
        );
        if (saveForLater && !value) {
          return createError({
            message: 'Identification Number is required',
            path,
          });
        }
        if (value) {
          if (!alphaNumeric.test(value)) {
            return createError({
              message: 'Special Characters are Not allowed',
              path,
            });
          }
          if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
            return createError({
              message:
                'There is already same Identification Number for a Controlling Persons associated with this application',
              path,
            });
          }
        }

        return true;
      }),
    occupationType: yup
      .string()
      .nullable()
      .test('occupationType', (value, context) => {
        return RequiredfieldValidation(value, context, 'Occupation Type is required', saveForLater);
      }),

    occupation: yup
      .string()
      .nullable()
      .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
      .test('occupation', (value, context) => {
        return RequiredfieldValidation(value, context, 'Occupation is required', saveForLater);
      }),
    dateOfBirth: yup
      .string()
      .nullable()
      .test('dateOfBirth', (value, context) => {
        const { path, createError } = context;
        if (value && value === Invalid_Date) {
          return createError({
            message: 'Invalid Date',
            path,
          });
        }
        if (saveForLater && !value) {
          return createError({
            message: 'Date of Birth is required',
            path,
          });
        }
        if (value && isMinor(value)) {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        }
        if (value && maxAge(value)) {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        return true;
      }),
    countryOfBirth: yup
      .string()
      .nullable()
      .test('countryOfBirth', (value, context) => {
        return RequiredfieldValidation(
          value,
          context,
          'Country Of Birth is required',
          saveForLater
        );
      }),

    nationality: yup
      .string()
      .nullable()
      .test('nationality', (value, context) => {
        return RequiredfieldValidation(value, context, 'Nationality is required', saveForLater);
      }),

    tin: yup
      .string()
      .nullable()
      .test('tin', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
        const controllingPersons = optionsContext?.map((person) => person.tin);
        if (saveForLater && !value) {
          return createError({
            message: 'TIN is required',
            path,
          });
        }
        if (value && !alphaNumeric.test(value)) {
          return createError({
            message: 'Special Characters are Not allowed',
            path,
          });
        }
        if (value && controllingPersons && controllingPersons.includes(value)) {
          return createError({
            message:
              'There is already same Tin for a Controlling Persons associated with this application',
            path,
          });
        }

        return true;
      }),
  });

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string,
  saveForLater = true
): errorObj | errorObj[] | undefined => {
  if (
    saveForLater &&
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (
    saveForLater &&
    fatca.question_type === 'group' &&
    fatca.isVisible &&
    fatca.sub_questions?.length
  ) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = (saveForLater = true) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        fatcadetail: yup.array().of(
          yup.object().shape({
            answer: yup
              .string()
              .nullable()
              .test('answer', (value, context) => {
                const { path, createError, parent } = context;
                if (
                  saveForLater &&
                  !value &&
                  parent.isVisible &&
                  parent.isMandatory === 'true' &&
                  parent.backend_key &&
                  parent.question_type !== 'single_choice_radio'
                ) {
                  return createError({
                    message:
                      parent.question_type === 'open_text_field'
                        ? (parent.placeholder || 'Field') + ' is required'
                        : parent.question_text
                        ? parent.question_text + ' is required'
                        : (parent.placeholder || 'Field') + ' is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.isVisible &&
                  parent.backend_key &&
                  parent.validation === 'alphaNumeric' &&
                  !alphaNumeric.test(value)
                ) {
                  return createError({
                    message: 'Special Characters are Not allowed',
                    path,
                  });
                }
                if (parent.question_type === 'group' && parent.isVisible && parent.sub_questions) {
                  //subQuestionsValidation(value, context)
                  const res: errorObj[] = [];
                  const val = parent.sub_questions
                    ?.map((qun: nonIndividualQuestionsFatca) => {
                      const r = subQuestionsValidation(
                        qun,
                        path?.split('.')[0],
                        saveForLater
                      ) as errorObj;
                      return r;
                      // return (r as errorObj)
                      //   ? new yup.ValidationError(r.fieldName, '', `${r.fieldPath}.answer`)
                      //   : (r as unknown as errorObj[])?.map(
                      //       (ele: errorObj) =>
                      //         new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`)
                      //     );
                    })
                    .filter((ele: errorObj) => ele)
                    .flat();
                  // .toString();
                  if (val.length) {
                    const error = val.map((ele: errorObj) => {
                      return new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`);
                    });
                    if (error.length) {
                      return new yup.ValidationError(error);
                    }

                    // return createError({
                    //   message: 'Field is Required1',
                    //   path: `${val}.answer`,
                    // });
                  }
                  return true;
                }
                return true;
              }),
          })
        ),
      })
    ),
  });

export const addPlanValidation = yup.object().shape({
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const addFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
});
export const addStrategyValidation = (distributorsIds: number[]) =>
  yup.object().shape({
    distributorIds: yup
      .array()
      .nullable()
      .when('isApplicableForDistributor', {
        is: (isApplicableForDistributor: boolean) => isApplicableForDistributor === true,
        then: yup
          .array()
          .nullable()
          .min(1, 'Distributors required')
          .required('Distributors required')
          .test('distributorIds', (value, context) => {
            const fieldValue = value === null ? [] : value;
            const { createError, path } = context;
            if (
              fieldValue &&
              fieldValue?.length &&
              fieldValue
                ?.map((dis: number) => dis && distributorsIds.includes(Number(dis)))
                .includes(false)
            ) {
              return createError({
                message: 'Invalid Distributor',
                path,
              });
            }
            return true;
          }),
      }),
    strategyName: yup.string().nullable().required('Strategy Name is required'),
    strategyType: yup
      .string()
      .nullable()
      .oneOf(Object.keys(STRATEGY_TYPE_OPTIONS), 'Invalid value for strategy type')
      .required('Strategy type is required'),
    strategyFeeDetails: yup
      .array()
      .when(['isApplicableForDistributor', 'isApplicableForDirect', 'isDPMSFlow', 'isNDPMSFlow'], {
        is: (
          isApplicableForDistributor: boolean,
          isApplicableForDirect: boolean,
          isDPMSFlow: boolean,
          isNDPMSFlow: boolean
        ) =>
          checkStratagyFeeDetails(
            isApplicableForDirect,
            isApplicableForDistributor,
            isDPMSFlow,
            isNDPMSFlow
          ),
        then: yup.array().of(
          yup.object().shape({
            applicableFlowType: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .oneOf(Object.keys(ACCOUNT_TYPE_OPTIONS), 'Invalid Account Type')
                  .required('Account Type is required'),
              }),
            feeType: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .oneOf(Object.keys(FeeDetailTypeMasters), 'Invalid Fee Type')
                  .test('feeType', (value, context) => {
                    const {
                      options: { context: optionsContext },
                      createError,
                      path,
                      parent,
                    } = context;

                    if (!value) {
                      return createError({
                        message: 'Fee Type is required',
                        path,
                      });
                    }

                    return true;
                  }),
              }),
            applicableForType: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .oneOf(Object.keys(distributorMaster), 'Invalid Provide Type')
                  .required('Provide Type is required'),
              }),
            fee: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .required('Management Fee is required')
                  .test('fee', (value, context: any) => {
                    const { createError, path } = context;
                    const parent = context?.parent || {};
                    const [parent1, parent2, ...rest] = context.from;
                    const strategyFeeDetails = parent2?.value?.strategyFeeDetails || [];

                    const directValues = new Set<string>();
                    const distributorValues = new Set<string>();

                    strategyFeeDetails.forEach((item: strategyFormType) => {
                      const applicableForType = distributorMaster[item.applicableForType || ''];
                      const fee = item.fee as string;

                      if (
                        applicableForType === distributorMaster.Distributor &&
                        parent.id !== item.id
                      ) {
                        if (!distributorValues.has(fee)) {
                          distributorValues.add(fee);
                        }
                      } else if (
                        applicableForType === distributorMaster.Direct &&
                        parent.id !== item.id
                      ) {
                        if (!directValues.has(fee)) {
                          directValues.add(fee);
                        }
                      }
                    });
                    const duplicateError = (type: string) =>
                      createError({
                        message: `Duplicate value for ${type}`,
                        path,
                      });

                    if (
                      distributorMaster[parent.applicableForType || ''] ===
                        distributorMaster.Distributor &&
                      distributorValues.has(value?.toString() as string)
                    ) {
                      return duplicateError('Distributor');
                    } else if (
                      distributorMaster[parent.applicableForType || ''] ===
                        distributorMaster.Direct &&
                      directValues.has(value?.toString() as string)
                    ) {
                      return duplicateError('Direct');
                    }
                    if (value && !defaultRegexWithSingleLine.test(value)) {
                      return createError({
                        message: 'Special characters are not allowed',
                        path,
                      });
                    }
                    return true;
                  }),
              }),

            hurdleRate: yup
              .string()
              .nullable()
              .when(['feeType', 'isActive'], {
                is: (feeType: string, isActive: boolean) => checkVariableFee(feeType) && isActive,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'Please enter valid Hurdle Rate')
                  .required('Hurdle Rate is required'),
              }),
            performanceFee: yup
              .string()
              .nullable()
              .when(['feeType', 'isActive'], {
                is: (feeType: string, isActive: boolean) => checkVariableFee(feeType) && isActive,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'Please enter valid Performance fees')
                  .required('Performance fees is required'),
              }),
            exitLoad: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'Please enter valid Exit Load')
                  .required('Exit Load is required'),
              }),
            charges: yup
              .string()
              .nullable()
              .when('isActive', {
                is: (isActive: boolean) => isActive,
                then: yup
                  .string()
                  .nullable()
                  .matches(defaultRegexWithSingleLine, 'Please enter valid other charges')
                  .required('Other charges is required'),
              }),
          })
        ),
      }),
  });

export const portfolioManagerSchema = yup.object().shape({
  portfolioManagerName: yup.string().nullable().required('Portfolio manager name is required'),
  sebiRegistrationNumber: yup
    .string()
    .nullable()
    .matches(alphaNumeric, 'Special characters are not allowed')
    .required('SEBI registration number is required'),
  investorRelationOfficerName: yup
    .string()
    .nullable()
    .required('Investor relations officer name is required'),
  investorRelationOfficerMobileNumber: yup
    .string()
    .nullable()
    .required('Investor relations officer contact details is required'),
  portfolioManagerMobileNumber: yup
    .string()
    .nullable()
    .required('Portfolio manager contact details is required'),
  pmFee: yup.string().nullable().required('Fees & charges including exit load is required'),
  tenureOfPMS: yup.string().nullable().required('Tenure of PMS is required'),
});
