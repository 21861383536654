import {
  Box,
  Typography,
  Button,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
  Tooltip,
  LinearProgress,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Applicant,
  ApplicationProps,
  Document,
  esignApplicationResponse,
  FatcaMdms,
  getAuthorizedSignatoriesDetailsByRefrenceType,
  getDetailsByRefrenceType,
  Groups,
  GroupSignatories,
  individuals_Poa_nonIndividuals_Documents,
  nonIndividualMdmsQuestionsFatca,
  RiskProfileMaster,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import {
  authorizedSignatoriesEsignApplication,
  esignApplication,
  getApplicationDetailsWithRefId,
  sendEsignAuthorised,
  sendEsignEmail,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { FileUploadDialog, Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  APPLICATION_LISTING_STATUS,
  ESIGN_STATUS,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  USER_ROLES,
} from '../../utils/constant';
import {
  FatcaMdmsData,
  FormValidationForAuthorizedSignatoryLogin,
  FormValidationForDisplayCompleteApplicationButtonForInvestor,
  authorizedSignatoriesTypedDeclaration,
  getASLoginEditSections,
  getDocuments,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
  sendTypedDeclaration,
  updateApplication,
  validateDisclosureOfInterest,
  validateDisclusionOfExclusion,
  validateDocuments,
} from '../../redux-store/actions/application';
import { logout, showError } from '../../redux-store/actions/auth';
import {
  getNationalityList,
  getStatesList,
  riskProfileMasterData,
} from '../../redux-store/actions';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
} from '../../redux-store/types/mdms';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MFTextField } from '../../lib/formik';
import { authorizedSignatoriesDocumentScheme, typeDeclarationScheme } from '../../utils/schema';
import React from 'react';
import { Notes, SubHeading } from '../investors/components';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { getApplicant } from '../ApplicationDetails/typeDeclaration';
import { DeclarationErrors, RiskProfileErrors } from '../../redux-store/middleware';
import { InvestorEditErrorObj } from '../../redux-store/reducers/investorEditSections';
import MFCheckbox from '../../lib/formik/Checkbox';
import { riskProfileDeclaration } from '../../utils/declaration';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  NonIndividualApplicationLayout,
  filterSignatoryData,
} from '../NonIndividualApplicationDetails';
import { documentDetails } from '../investors/documentDetails';
import { FilePond } from 'react-filepond';
import { getAllStrategiesByDistributorType } from '../../utils/utilityFunctions';
import en from '../../lang/en-us';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export type Values = {
  groupsignatorydocuments: documentDetails[];
};

const initialValues: Values = {
  groupsignatorydocuments: [
    {
      documentType: 'typedDeclaration',
      documentName: 'Typed Declaration',
      required: 'true',
      options: [],
      documentId: '',
      isActive: true,
    },
  ],
};

export const getLoggedInAuthorizedSignatory = (
  authorizedSignatories: Groups[],
  loggedInsignatoryDetails: getAuthorizedSignatoriesDetailsByRefrenceType,
  getAllSignatoryDocuments = false
): GroupSignatories => {
  const groupSignatoryObj = authorizedSignatories
    ?.find((group) => Number(group.id) === Number(loggedInsignatoryDetails.groupId))
    ?.groupsignatories.find(
      (signatory) => Number(signatory.id) === Number(loggedInsignatoryDetails.id)
    ) as GroupSignatories;
  return {
    ...groupSignatoryObj,
    groupsignatorydocuments: getAllSignatoryDocuments
      ? groupSignatoryObj?.groupsignatorydocuments
      : filterSignatoryData(groupSignatoryObj?.groupsignatorydocuments),
  };
};

export const getAuthorizedSignatories = (authorizedSignatories: Groups[]): GroupSignatories[] => {
  return authorizedSignatories
    ?.map((_group) => _group.groupsignatories)
    ?.flat() as GroupSignatories[];
};

const DeclarationUpload = ({
  doc_Index,
  setValues,
  values,
  disabled,
  onFileUpload,
  onFileUploaded,
}: {
  doc_Index: number;
  setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean) => void;
  values: Values;
  disabled: boolean;
  onFileUpload: () => void;
  onFileUploaded: () => void;
}): JSX.Element => {
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const { token: investorAuthToken } = useSelector((store: RootStateType) => store.investor);
  const { application } = useSelector((store: RootStateType) => store.application);
  const dispatch = useDispatch();
  const [open, setOpen] = useState({ show: false, message: '', code: '' });

  // eslint-disable-next-line
  const handleOpen = (result: any) => {
    setOpen({ show: true, message: result.message, code: result.code });
  };
  const handleClose = () => {
    setOpen({ show: false, message: '', code: '' });
  };

  const handleFileChange = async (documentId: string) => {
    setValues({
      ...values,
      groupsignatorydocuments: values.groupsignatorydocuments.map((_doc, index) => {
        if (index === doc_Index) {
          return {
            ..._doc,
            documentId,
          };
        }
        return _doc;
      }),
    });
    onFileUploaded();
  };
  return (
    <Grid container sx={{ alignItems: 'center', mb: 1 }}>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Typed Declaration Document <span style={{ color: 'red' }}>*</span>
        </Typography>
        <Typography sx={{ fontSize: 12 }}>Min Size: 2KB, Max Size: 2MB</Typography>
        <Typography sx={{ fontSize: 12 }}>Supports: png, jpg, jpeg</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
              cursor: disabled ? 'not-allowed' : 'pointer',
            },
            '.filepond--drop-label': {
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            // pt: 4,
          }}>
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken || investorAuthToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.id);
                  onFileUploaded();
                  return response;
                },
                onerror: (response) => {
                  if (response) {
                    const result = JSON.parse(response);
                    if (result && result.message) {
                      handleOpen(result);
                    }
                  } else {
                    handleOpen({ code: '', message: en.networkText.unableToProcess });
                  }
                  return response;
                },
              },
            }}
            onaddfilestart={() => {
              onFileUpload();
            }}
            onaddfile={(error, file) => {
              if (error) {
                onFileUploaded();
              }
            }}
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                } else {
                  onFileUploaded();
                }
              }
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            onprocessfilerevert={() => {
              setValues({
                ...values,
                groupsignatorydocuments: values.groupsignatorydocuments.map((_doc, index) => {
                  if (index === doc_Index) {
                    const { file, ...rest } = _doc;
                    return {
                      ...rest,
                      documentId: '',
                    };
                  }
                  return _doc;
                }),
              });
            }}
            name="file"
            maxFileSize={'2MB'}
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={INVESTOR_IMAGE_UPLOAD_FORMATS}
            labelIdle={`<span class="filepond--label-action">Upload</span>`}
            labelFileTypeNotAllowed={'Invalid file type'}
            labelMaxFileSize={'Max file size is 2 MB'}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            disabled={disabled}
            allowPaste={false}
          />
          <FileUploadDialog
            handleClose={handleClose}
            show={open.show}
            message={open.message}
            code={open.code}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default function AuthorizedSignatoriesInvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [application, setApplication] = useState<ApplicationProps>();
  const [tooltip, setTooltip] = useState('Click Text');
  const [loading, setLoading] = useState(false);
  // const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consentSubmitting, setconsentSubmitting] = useState(false);
  const [authorizedSignatory, setAuthorizedSignatoryDocuments] = useState(initialValues);
  const [documentsResponse, setDocumentResponse] = useState<Document>();
  const [riskProfileMasterResponse, setRiskProfileMasterResponse] = useState<RiskProfileMaster>();
  const [readmoreDisclaimer, setReadmoreDisclaimer] = useState(false);
  const [consentInfo, setConsentInfo] = useState('');
  const [nationalityMaster, setNationalityMaster] = useState<nationaliyType>();
  const [stateMaster, setStateMaster] = useState<statesType>();
  const [strategiesMaster, setStrategiesMaster] = useState<GetStrategiesResponseBody[]>([]);
  const [documentUpload, setDocumentUpload] = useState(false);
  const [fatcaMaster, setFatcaMaster] = useState<FatcaMdms>();
  const { authorizedSignatoryEditObj } = useSelector(
    (store: RootStateType) => store.authorizedSignatoryEditObj
  );
  const [isClicked, setIsClicked] = useState(false);
  const text = 'I here by understand and agree to the above fee structure & terms and conditions.';

  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
        const signatoryDocuments = getLoggedInAuthorizedSignatory(
          _application.groups,
          authorizedSignatoriesReferenceDetail
        );
        setAuthorizedSignatoryDocuments({
          groupsignatorydocuments: (
            signatoryDocuments?.groupsignatorydocuments as documentDetails[]
          )?.length
            ? (signatoryDocuments?.groupsignatorydocuments as documentDetails[])
            : initialValues.groupsignatorydocuments,
        });
        const responseRisk = (await dispatch(
          riskProfileMasterData()
        )) as unknown as RiskProfileMaster;
        setRiskProfileMasterResponse(responseRisk);
        const docResponse = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentResponse(docResponse);
        const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
        setStateMaster(statesMdmsMasters);
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setNationalityMaster(nationalitiesMdmsMasters);
        const responseFatca = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
        setFatcaMaster(responseFatca);
        const strategiesResponse = (await getAllStrategiesByDistributorType(
          dispatch,
          application as ApplicationProps
        )) as unknown as GetStrategiesResponseBody[];
        setStrategiesMaster(strategiesResponse);
      } catch (e) {
        typeof e === 'string' && setConsentInfo(e);
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (application) {
          const { applicants = [] } = application || {};
          const getInvestorType = applicants[0]?.investorType;
          const signatoryDocuments = getLoggedInAuthorizedSignatory(
            application.groups,
            authorizedSignatoriesReferenceDetail
          );
          if (signatoryDocuments.groupSignatoryConsent) {
            getAuthorizedSignatories(application.groups).map((signatory, ind) => {
              if (!signatory.groupSignatoryConsent) {
                throw `Consent pending with Authorized Signatories`;
              }
            });
            if (
              !application?.signDetails?.url &&
              documentsResponse &&
              nationalityMaster &&
              riskProfileMasterResponse &&
              fatcaMaster
            ) {
              await nonIndividualFormValidForSubmission(
                documentsResponse,
                authorizedSignatoriesReferenceDetail,
                application as ApplicationProps,
                documentsResponse[
                  getInvestorType || ''
                ] as individuals_Poa_nonIndividuals_Documents[],
                nationalityMaster.countries,
                stateMaster?.states || [],
                true,
                auth.role,
                riskProfileMasterResponse.riskProfile,
                strategiesMaster,
                true,
                fatcaMaster.fatca_questions,
                true
              );
            }
            throw '';
          }
        }
      } catch (e) {
        typeof e === 'string' && setConsentInfo(e);
      }
    })();
  }, [strategiesMaster]);

  useEffect(() => {
    (async function () {
      if (application && documentsResponse) {
        try {
          await FormValidationForAuthorizedSignatoryLogin(
            application,
            documentsResponse,
            authorizedSignatoriesReferenceDetail,
            true
          );
        } catch (error) {
          await dispatch(getASLoginEditSections(error as unknown as InvestorEditErrorObj[]));
        }
      }
    })();
  }, [documentsResponse]);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/authorized-signatories-investment-details/${referenceId}/strategy-details`);
    }
  }, []);

  const handleEsign = async (values: Values) => {
    try {
      const { signDetails, id, applicants = [] } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }
      if (id) {
        setIsSubmitting(true);
        const response = (await dispatch(
          authorizedSignatoriesEsignApplication(referenceId)
        )) as unknown as esignApplicationResponse;
        setApplication({
          ...application,
          signDetails: response,
        } as unknown as ApplicationProps);
        window.open(response?.url, '_blank');
        return;
      }
    } catch (e) {
      if (typeof e === 'string') {
        dispatch(showError(e as string));
      }
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleConsentSubmit = async (values: Values) => {
    try {
      const { signDetails, id, groups = [] } = application || {};
      values.groupsignatorydocuments.map((signatoryDocument) => {
        if (signatoryDocument.required === 'true' && !signatoryDocument.documentId) {
          throw new DeclarationErrors(`Please upload ${signatoryDocument.documentName}`);
        }
      });

      if (id) {
        setIsSubmitting(true);
        const getLoggedInAS =
          getLoggedInAuthorizedSignatory(
            application?.groups as Groups[],
            authorizedSignatoriesReferenceDetail,
            true
          ) || [];
        const finalDoc = filterSignatoryData(getLoggedInAS.groupsignatorydocuments);
        const applicationResponse = (!getLoggedInAS?.groupSignatoryConsent
          ? ((await dispatch(
              authorizedSignatoriesTypedDeclaration(
                {
                  groupSignatoryConsent: true,
                  groupsignatorydocuments:
                    finalDoc && finalDoc?.length
                      ? getLoggedInAS.groupsignatorydocuments?.map((_signatoryDoc) => {
                          const getDeclarationDoc = values.groupsignatorydocuments?.find(
                            (_feeDoc) => _feeDoc.documentType === _signatoryDoc.documentType
                          );
                          return getDeclarationDoc
                            ? { ..._signatoryDoc, documentId: getDeclarationDoc.documentId }
                            : _signatoryDoc;
                        })
                      : [
                          ...getLoggedInAS.groupsignatorydocuments,
                          ...values.groupsignatorydocuments,
                        ],
                },
                authorizedSignatoriesReferenceDetail.esignReferenceId
              )
            )) as unknown as getAuthorizedSignatoriesDetailsByRefrenceType)
          : application) as unknown as ApplicationProps;
        setApplication(applicationResponse);
        setConsentInfo('Consent pending with Authorized Signatories');
        getAuthorizedSignatories(applicationResponse.groups as Groups[]).map((signatory, ind) => {
          if (!signatory.groupSignatoryConsent) {
            throw `Consent pending with Authorized Signatories`;
          }
        });

        if (
          ESIGN_STATUS.PENDING_SIGNATURE !== applicationResponse?.signDetails?.status &&
          documentsResponse
        ) {
          const { applicants = [] } = applicationResponse || {};
          const getInvestorType = applicants[0]?.investorType;
          await nonIndividualFormValidForSubmission(
            documentsResponse,
            authorizedSignatoriesReferenceDetail,
            applicationResponse as ApplicationProps,
            documentsResponse[getInvestorType || ''] as individuals_Poa_nonIndividuals_Documents[],
            nationalityMaster?.countries as mdmsCountriesList[],
            stateMaster?.states as mdmsStatesList[],
            true,
            auth.role,
            riskProfileMasterResponse?.riskProfile as RiskProfileMasterType[],
            strategiesMaster,
            true,
            fatcaMaster?.fatca_questions as nonIndividualMdmsQuestionsFatca,
            true
          );
        }
        const responseForEsign = getAuthorizedSignatories(
          applicationResponse.groups as Groups[]
        ).every((item) => item.groupSignatoryConsent === true)
          ? ((await dispatch(sendEsignAuthorised(id))) as unknown as ApplicationProps)
          : applicationResponse;
        setApplication(responseForEsign);
        throw '';
      }
    } catch (e) {
      if (e instanceof DeclarationErrors) {
        dispatch(showError((e as DeclarationErrors).message));
      }
      if (typeof e === 'string') {
        setConsentInfo(e);
        dispatch(
          showError(
            (!e
              ? 'Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated'
              : 'Consent is submitted. ' + e) as string
          )
        );
      }
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={authorizedSignatory}
      // validate={(values: Values) => {
      //   try {
      //     validateYupSchema(values, authorizedSignatoriesDocumentScheme, true, values);
      //   } catch (e) {
      //     return yupToFormErrors(e);
      //   }
      // }}
      onSubmit={
        getLoggedInAuthorizedSignatory(
          application?.groups as Groups[],
          authorizedSignatoriesReferenceDetail
        )?.groupSignatoryConsent
          ? handleEsign
          : handleConsentSubmit
      }
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box>
              <CardMedia
                component="img"
                src="/images/kfin-logo.svg"
                alt="Logo"
                sx={{ width: 'unset', m: 3 }}
              />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 10,
                flexDirection: { xs: 'column', sm: 'row' },
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: 'primary.main',
                    mb: 4,
                    mr: { xs: 1.35, sm: 3, md: 3 },
                  }}>
                  Applicant Name:{'  '}
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: '18px',
                      letterSpacing: '0.4px',
                      ml: 0.5,
                      textTransform: 'capitalize',
                    }}>
                    {authorizedSignatoriesReferenceDetail.name}
                  </Typography>
                </Typography>

                {authorizedSignatoryEditObj && authorizedSignatoryEditObj?.length > 0 && (
                  <IconButton sx={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() =>
                        history.push(
                          `/authorized-signatories-investment-details/${referenceId}/nonIndividual-edit-application/${authorizedSignatoryEditObj[0].endPoint}`,
                          {
                            id: application?.id,
                            applicant1ReferenceId:
                              authorizedSignatoriesReferenceDetail.esignReferenceId,
                          }
                        )
                      }
                    />
                  </IconButton>
                )}
              </Typography>
            </Box>
            {loading && <LinearProgress />}
            {application && (
              <>
                <NonIndividualApplicationLayout
                  loading={loading}
                  application={application as ApplicationProps}
                />
                {!getLoggedInAuthorizedSignatory(
                  application.groups,
                  authorizedSignatoriesReferenceDetail
                )?.groupsignatorydocuments?.find(
                  (doc) => doc.documentType === 'typedDeclaration'
                ) && (
                  <Box
                    sx={{
                      bgcolor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10px',
                      mt: 5,
                    }}
                    mb={3}>
                    <Box sx={{ p: 2 }}>
                      <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                        <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                          Authorized Signatory Declaration
                        </Typography>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            sx={{
                              color: 'rgba(41, 49, 57, 0.7)',
                              fontSize: '14px',
                              fontWeight: 550,
                              mb: 1,
                              mt: 3,
                            }}>{`Please look on the text and upload the document`}</Typography>
                          <Typography
                            component="div"
                            sx={{
                              backgroundColor: 'rgba(51, 127, 201, 0.04)',
                              display: 'flex',
                              flexDirection: 'row',
                              padding: '10px 20px',
                              borderRadius: '5px',
                              alignItems: 'flex-start',
                              mt: 0.5,
                              mb: 1,
                              // pb: 5,
                              width: '100%',
                              boxSizing: 'border-box',
                              userSelect: 'none',
                            }}>
                            <Typography
                              sx={{
                                color: 'rgba(41, 49, 57, 0.7)',
                                fontSize: '14px',
                                fontWeight: 500,
                                ml: 1,
                              }}>
                              {`${text}`}
                            </Typography>
                            {/* <Typography sx={{ mt: 10, mb: 1 }}>
                                    <Tooltip title={tooltip}>
                                      <IconButton
                                        onMouseOver={() => {
                                          setTooltip('Copy Link');
                                        }}
                                        onClick={() => {
                                          setTooltip('Copied');
                                          navigator.clipboard.writeText(text);
                                        }}>
                                        <ContentCopyIcon
                                          fontSize="small"
                                          sx={{
                                            ':hover': {
                                              bgcolor: '#F4FCFC',
                                            },
                                            cursor: 'pointer',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography> */}
                          </Typography>
                          {values.groupsignatorydocuments.map((doc, index) => {
                            return (
                              <Grid
                                key={index}
                                container
                                rowSpacing={2}
                                columnSpacing={5}
                                display="flex"
                                alignItems="center">
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  display="flex"
                                  flexDirection="column"
                                  mt={2}>
                                  <DeclarationUpload
                                    doc_Index={index}
                                    setValues={setValues}
                                    values={values}
                                    disabled={false}
                                    onFileUpload={handleUpload}
                                    onFileUploaded={handleUploaded}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                {getAuthorizedSignatories(application.groups as Groups[]).every(
                  (item) => item.groupSignatoryConsent
                ) &&
                  !application.leegalityDocumentId &&
                  !isSubmitting &&
                  !consentInfo && (
                    <Box sx={{ mt: 4 }}>
                      <Notes
                        displayContent={
                          'Consent submitted by all Authorized Signatories. Your document generation is in progress and e-signing link will be shared with you once it is generated.'
                        }
                      />
                    </Box>
                  )}
                {!isSubmitting && consentInfo && (
                  <Box sx={{ mt: 4 }}>
                    <Notes displayContent={consentInfo} />
                  </Box>
                )}
                {authorizedSignatoryEditObj &&
                authorizedSignatoryEditObj?.length > 0 &&
                !getLoggedInAuthorizedSignatory(
                  application.groups,
                  authorizedSignatoriesReferenceDetail
                )?.groupSignatoryConsent ? (
                  <Box sx={{ textAlign: 'center' }}>
                    <LoadingButton
                      loadingPosition="start"
                      variant="contained"
                      //type="submit"
                      loading={isClicked}
                      sx={{
                        color: 'common.white',
                        minWidth: '200px',
                        mt: consentInfo ? 1 : 4,
                        fontWeight: 600,
                        lineHeight: 1.5,
                      }}
                      onClick={async () => {
                        try {
                          const { id } = application || {};
                          values.groupsignatorydocuments.map((signatoryDocument) => {
                            if (
                              signatoryDocument.required === 'true' &&
                              !signatoryDocument.documentId
                            ) {
                              throw `Please upload ${signatoryDocument.documentName}`;
                            }
                          });
                          if (id) {
                            setIsClicked(true);
                            const getLoggedInAS =
                              getLoggedInAuthorizedSignatory(
                                application?.groups as Groups[],
                                authorizedSignatoriesReferenceDetail,
                                true
                              ) || [];
                            const finalDoc = filterSignatoryData(
                              getLoggedInAS.groupsignatorydocuments
                            );
                            const applicationResponse = (!getLoggedInAS?.groupSignatoryConsent &&
                            !finalDoc.find((data) => data.documentType === 'typedDeclaration')
                              ?.documentId
                              ? ((await dispatch(
                                  authorizedSignatoriesTypedDeclaration(
                                    {
                                      groupsignatorydocuments:
                                        finalDoc && finalDoc?.length
                                          ? getLoggedInAS.groupsignatorydocuments?.map(
                                              (_signatoryDoc) => {
                                                const getDeclarationDoc =
                                                  values.groupsignatorydocuments?.find(
                                                    (_feeDoc) =>
                                                      _feeDoc.documentType ===
                                                      _signatoryDoc.documentType
                                                  );
                                                return getDeclarationDoc
                                                  ? {
                                                      ..._signatoryDoc,
                                                      documentId: getDeclarationDoc.documentId,
                                                    }
                                                  : _signatoryDoc;
                                              }
                                            )
                                          : [
                                              ...getLoggedInAS.groupsignatorydocuments,
                                              ...values.groupsignatorydocuments,
                                            ],
                                    },
                                    authorizedSignatoriesReferenceDetail.esignReferenceId
                                  )
                                )) as unknown as getAuthorizedSignatoriesDetailsByRefrenceType)
                              : application) as unknown as ApplicationProps;
                            setApplication(applicationResponse);
                            history.push(
                              `/authorized-signatories-investment-details/${referenceId}/nonIndividual-edit-application/${authorizedSignatoryEditObj[0].endPoint}`,
                              {
                                id: application?.id,
                                applicant1ReferenceId:
                                  authorizedSignatoriesReferenceDetail.esignReferenceId,
                              }
                            );
                          }
                        } catch (e) {
                          typeof e === 'string' && dispatch(showError(e as string));
                          console.error((e as Error).message);
                        } finally {
                          setIsClicked(false);
                        }
                      }}
                      disabled={isSubmitting || documentUpload}>
                      Next
                    </LoadingButton>
                  </Box>
                ) : (
                  ![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                    application?.signDetails?.status
                  ) &&
                  !loading && (
                    <Box sx={{ textAlign: 'center' }}>
                      <LoadingButton
                        variant="contained"
                        loadingPosition="start"
                        type="submit"
                        sx={{
                          color: 'common.white',
                          minWidth: '200px',
                          mt: consentInfo ? 1 : 4,
                          fontWeight: 600,
                          lineHeight: 1.5,
                          px: 4,
                        }}
                        disabled={
                          isSubmitting ||
                          ((getLoggedInAuthorizedSignatory(
                            application.groups,
                            authorizedSignatoriesReferenceDetail
                          )?.groupSignatoryConsent && consentInfo) as boolean) ||
                          (getAuthorizedSignatories(application.groups as Groups[]).every(
                            (item) => item.groupSignatoryConsent
                          ) &&
                            !application.leegalityDocumentId) ||
                          documentUpload
                        }>
                        {!getLoggedInAuthorizedSignatory(
                          application.groups,
                          authorizedSignatoriesReferenceDetail
                        )?.groupSignatoryConsent
                          ? 'Submit Consent'
                          : getLoggedInAuthorizedSignatory(
                              application.groups,
                              authorizedSignatoriesReferenceDetail
                            )?.groupSignatoryConsent && consentInfo
                          ? 'Consent Submitted'
                          : 'e-Sign'}
                      </LoadingButton>
                    </Box>
                  )
                )}
              </>
            )}
          </Box>
          <Footer />
          <Dialog
            open={isSubmitting}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
              {'Please Wait'}
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center', px: 8 }}>
              <DialogContentText sx={{ mb: 4 }} id="alert-dialog-description">
                Don&#39;t refresh or click on back button
              </DialogContentText>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Formik>
  );
}
