import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAllPhotoCaptureApplicantionsResponseBody,
  getAllPhotoCaptureApplications,
  PhotoCaptureDetails,
  PhotoCaptureHistory,
  photoCaptureHistoryResponse,
  sendPhotoCaptureLink,
} from '../../redux-store/actions/photoLocationCapture';
import { RootStateType } from '../../redux-store/reducers';
import { APPLICATION_LISTING_STATUS, PHOTOCAPTURE_STATUS, USER_ROLES } from '../../utils/constant';
import { useDebounce } from '../../utils/useDebounce';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CommonLayout, LoadingDialog } from '../commonComponents';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { DataTable } from '../DataTable';
import CloseIcon from '@mui/icons-material/Close';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useSnackbar } from 'notistack';
import HistoryIcon from '@mui/icons-material/History';
import { Item, ItemDetails } from '../ApplicationDetails';
import { formatShortDate, formatShortDateAndTime } from '../../utils/date';

const headers = [
  {
    header: 'Application Number',
    valueGetter: (row: PhotoCaptureDetails) => row.applicationNumber || 'N/A',
  },
  {
    header: 'Investor Name',
    valueGetter: (row: PhotoCaptureDetails) => row.name || 'N/A',
  },
  {
    header: 'Email Id',
    valueGetter: (row: PhotoCaptureDetails) => row.email || 'N/A',
  },
  {
    header: 'Application Status',
    renderCell: (row: PhotoCaptureDetails) => {
      const applicationStatus = APPLICATION_LISTING_STATUS[row.status];
      return (
        <Typography
          className={applicationStatus}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
            color: '#B78813 !important',
            '&.Draft': {
              color: 'rgba(51, 127, 201, 0.7) !important',
            },
            '&.Completed': {
              fontWeight: 600,
              color: 'rgba(35, 133, 63, 0.7) !important',
            },
            '&.Rejected': {
              color: '#BD2932 !important',
              fontWeight: 500,
            },
          }}>
          {applicationStatus}
        </Typography>
      );
    },
  },
  {
    header: 'Status',
    renderCell: (row: PhotoCaptureDetails) => {
      const photoCaptureApplicationStatus = PHOTOCAPTURE_STATUS[row.photocaptureStatus];
      return (
        <Typography
          className={photoCaptureApplicationStatus}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
            color: '#B78813 !important',
            '&.Pending': {
              color: 'rgba(51, 127, 201, 0.7) !important',
            },
            '&.Completed': {
              fontWeight: 600,
              color: 'rgba(35, 133, 63, 0.7) !important',
            },
          }}>
          {photoCaptureApplicationStatus}
        </Typography>
      );
    },
  },
  // {
  //   header: 'Updated At',
  //   valueGetter: (row: PhotoCaptureDetails) => formatShortDateAndTime(row.updatedAt) || 'N/A',
  // },
];

export const PhotoCapturedApplicationsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [photoCaptureApplicationsList, setPhotoCaptureApplicationsList] = useState<
    PhotoCaptureDetails[]
  >([]);
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const debounceSearchString = useDebounce(search, 500);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<{ refId: string; applicantId: string } | null>(null);
  const [reSendRefLoading, setReSendRefLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [tooltip, setTooltip] = useState('Click to Copy');
  const [historyData, setHistoryData] = useState<photoCaptureHistoryResponse[] | null>(null);
  const [historyPopup, setHistoryPopup] = useState(false);

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setPhotoCaptureApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllPhotoCaptureApplications({ search: search, page: currentPage })
        )) as unknown as GetAllPhotoCaptureApplicantionsResponseBody;
        const { photocaptures, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setPhotoCaptureApplicationsList(photocaptures);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: PhotoCaptureDetails): JSX.Element => {
      const displayResendPopup =
        APPLICATION_LISTING_STATUS[row.status] !== APPLICATION_LISTING_STATUS.draft;
      return (
        <Box display="flex">
          <IconButton
            sx={{
              p: 0,
              pr: 0.5,
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              cursor: 'pointer',
              pointerEvents: 'all',
            }}
            onClick={() => {
              setId({ refId: row.referenceId, applicantId: row.applicantId }), setOpen(true);
            }}
            disabled={displayResendPopup}>
            <InfoOutlinedIcon color={displayResendPopup ? 'disabled' : 'info'} />
          </IconButton>

          <IconButton
            sx={{
              p: 0,
              pl: 1.5,
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              cursor: 'pointer',
              pointerEvents: 'all',
            }}
            onClick={async () => {
              try {
                setReSendRefLoading(true);
                const response = (await dispatch(
                  PhotoCaptureHistory(row.referenceId)
                )) as unknown as photoCaptureHistoryResponse[];
                setHistoryData(response);
                // setHistoryPopup(true);
                setReSendRefLoading(false);
              } catch (e) {
                setReSendRefLoading(false);
                console.error((e as Error).message);
              }
            }}>
            <HistoryIcon color="info" />
          </IconButton>
        </Box>
      );
    },
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false), setId(null);
  };
  const handleLoadingPopupClose = () => {
    setReSendRefLoading(false);
  };

  const handleHistoryPopupClose = () => {
    setHistoryData(null);
    // setHistoryPopup(false);
  };

  return (
    <CommonLayout>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
          }}>
          Investors Liveliness Status
        </Typography>
        <Grid
          item
          container
          sx={{
            width: 'unset',
            alignItems: 'flex-end',
            gap: 2,
            p: '20px 30px',
            bgcolor: 'common.white',
            mb: 1.5,
          }}>
          <Grid item>
            <FormControl variant="standard" sx={{ display: 'block' }}>
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                Search
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={search}
                onChange={({ target: { value } }) => {
                  setPage(1);
                  setSearch(value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchSharpIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <DataTable
            isLoading={isLoading}
            tableHeader={USER_ROLES.AMC_ADMIN === role ? headers : [...headers, additionalCell]}
            tableData={photoCaptureApplicationsList}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
              '.MuiTableCell-body ': { color: '#686e72' },
              background: 'transparent',
            }}
            boxStyles={{ minWidth: 650 }}
          />
        </Box>
        {!!photoCaptureApplicationsList.length && (
          <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
            <Pagination
              count={noOfPages}
              page={currentPage}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        )}
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              width: '100%',
              mx: { xs: 2, sm: 4 },
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end', mt: 2, mr: 2, mb: 2 }}>
            <CloseIcon fontSize="medium" />
          </IconButton>

          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            sx={{
              px: { xs: 1, sm: 4 },
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              ':last-of-type': {
                mb: 5,
              },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Re-Send link for live capture
            </Typography>
            <IconButton
              sx={{ mr: '9px' }}
              onClick={async () => {
                try {
                  handleClose();
                  setReSendRefLoading(true);
                  await dispatch(sendPhotoCaptureLink(id?.applicantId || ''));
                  const response = (await dispatch(
                    getAllPhotoCaptureApplications({ search: search, page: currentPage })
                  )) as unknown as GetAllPhotoCaptureApplicantionsResponseBody;
                  setPhotoCaptureApplicationsList(response?.photocaptures);
                  setNoPages(response?.pages);
                  handleLoadingPopupClose();
                  enqueueSnackbar('Email sent successfully', {
                    variant: 'success',
                    autoHideDuration: 3000,
                  });
                } catch (e) {
                  handleLoadingPopupClose();
                  console.error((e as Error).message);
                }
              }}>
              <ForwardToInboxIcon color="info" />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            sx={{
              px: { xs: 1, sm: 4 },
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              ':last-of-type': {
                mb: 5,
              },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Copy link for live capture
            </Typography>
            <Tooltip title={tooltip}>
              <IconButton
                onMouseOver={() => {
                  setTooltip('Copy Link');
                }}
                onClick={() => {
                  setTooltip('Copied'),
                    navigator.clipboard.writeText(
                      `${window.location.origin}/investor-liveliness/${id?.refId}/login`
                    );
                }}>
                <CardMedia
                  component="img"
                  src="/images/link-icon.svg"
                  alt="Link"
                  sx={{ width: '40px' }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Dialog>
        <LoadingDialog
          loadingPopup={reSendRefLoading}
          onLoadingPopupClose={handleLoadingPopupClose}
        />
        <Dialog
          onClose={handleHistoryPopupClose}
          open={historyData !== null}
          sx={{
            '.MuiPaper-root ': {
              maxWidth: 750,
            },
          }}>
          {historyData && (
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                zIndex: 10,
                bgcolor: 'white',
                py: 2,
              }}>
              <IconButton onClick={handleHistoryPopupClose} sx={{ float: 'right' }}>
                <CloseIcon fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '25px',
                  fontWeight: 600,
                  textAlign: 'center',
                }}>
                History Log
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              width: { xs: '100%', sm: '90%' },
              mx: 'auto',
              px: { xs: 3, sm: 0 },
              pb: 4,
              mt: 4,
            }}>
            {historyData &&
              historyData?.map((history) => (
                <Grid
                  key={history.id}
                  container
                  rowSpacing={1}
                  //columnSpacing={5}
                  sx={{
                    width: '100%',
                    ml: 0,
                    '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                    bgcolor: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10px',
                    my: 2,
                    py: 2,
                    px: 1,
                    display: 'flex',
                  }}>
                  <Grid item xs={12} sm={6}>
                    <Item>Status</Item>
                    <ItemDetails
                      className={PHOTOCAPTURE_STATUS[history.photocaptureStatus || '']}
                      sx={{
                        textDecoration: 'none',
                        fontWeight: 500,
                        fontSize: 15,
                        color: '#B78813',
                        '&.Pending': {
                          color: 'rgba(51, 127, 201, 0.7)',
                        },
                        '&.Completed': {
                          fontWeight: 600,
                          color: 'rgba(35, 133, 63, 0.7)',
                        },
                      }}>
                      {' '}
                      {history.photocaptureStatus
                        ? PHOTOCAPTURE_STATUS[history.photocaptureStatus]
                        : '-'}
                    </ItemDetails>
                  </Grid>
                  {PHOTOCAPTURE_STATUS[history.photocaptureStatus] ===
                    PHOTOCAPTURE_STATUS.received && (
                    <Grid item xs={12} sm={6}>
                      <CardMedia
                        component="img"
                        src={history?.photocaptureFile?.viewUrl}
                        alt="investor image"
                        width="120"
                        height="120"
                        sx={{
                          display: 'flex',
                          // justifyContent: 'space-between',
                          // alignItems: 'center',
                          width: 'unset',
                          // pl: { xs: 0, sm: '40px' },
                          // ml: { xs: 11, sm: 3 },
                          // mt: 8,
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Item>Link sent at</Item>
                    <ItemDetails>
                      {' '}
                      {formatShortDateAndTime(history.photocaptureLinkSentAt) || '-'}
                    </ItemDetails>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Item>Link sent by</Item>
                    <ItemDetails sx={{ textTransform: 'capitalize' }}>
                      {history.photocaptureLinkSentBy && history.photocaptureLinkSentByRole
                        ? history.photocaptureLinkSentBy +
                          ' (' +
                          history.photocaptureLinkSentByRole +
                          ')'
                        : history.photocaptureLinkSentBy
                        ? history.photocaptureLinkSentBy
                        : history.photocaptureLinkSentByRole
                        ? history.photocaptureLinkSentByRole
                        : '-'}
                    </ItemDetails>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Item>Location</Item>
                    <ItemDetails> {history.geolocation || '-'}</ItemDetails>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Item>Real Score</Item>
                    <ItemDetails>
                      {' '}
                      {history.realScore ? Number(Number(history.realScore) * 100) + '%' : '-'}
                    </ItemDetails>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Item>Spoof Score</Item>
                    <ItemDetails>
                      {' '}
                      {history.spoofScore ? Number(Number(history.spoofScore) * 100) + '%' : '-'}
                    </ItemDetails>
                  </Grid>
                  {history.photocaptureError && (
                    <Grid item xs={12} sm={6}>
                      <Item>Photo Capture Error</Item>
                      <ItemDetails> {history.photocaptureError || '-'}</ItemDetails>
                    </Grid>
                  )}
                </Grid>
              ))}
          </Box>
        </Dialog>
      </Box>
    </CommonLayout>
  );
};
