/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  CardMedia,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Groups,
  GroupSignatories,
  Document,
  individuals_Poa_nonIndividuals_Documents,
  InvestorEditRouteLinkObj,
} from '../../redux-store/types/api-types';
import {
  DrawDownMasters,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  docInvalidCheck,
  getGroupNameMaster,
} from '../../utils/constant';
import { DataTable } from '../DataTable';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ProceedSaveLater } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getDocuments, updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  applicationComparison,
  getInvestorRouteLinks,
  getStep,
  preventSpecialCharacters,
  removeSingleQuote,
  saveForLater,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import { nonIndividualAuthorisedSignatories } from '../../utils/schema';
import { ConfirmationDialog, CountryCodesDropDown } from '../commonComponents';
import { showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { nonDigitRegex } from '../../utils/regex';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { documentDetails } from '../investors/documentDetails';
import { FileUpload } from './DocumentDetails';
import { filterSignatoryData } from '../NonIndividualApplicationDetails';
import { getLoggedInAuthorizedSignatory } from '../SignatoriesInvestments/signatories-application';
import FieldValidationNote from '../investors/FieldValidationNote';

// type GroupsProps = {
//   name: string;
//   threshold: number;
//   groupsignatories: GroupSignatories[];
// };

// export type Values = {
//   groups: GroupsProps[];
//   saveType: string;
// };

export const GroupSignatoriesObject: GroupSignatories = {
  groupId: '',
  name: '',
  pan: '',
  mobile: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  email: '',
  designation: '',
  canEsign: true,
  isActive: true,
  groupsignatorydocuments: [],
};

// const initialValues: Values = {
//   groups: [
//     {
//       name: '',
//       threshold: 1,
//       groupsignatories: [GroupSignatoriesObject],
//     },
//   ],
//   saveType: 'save and proceed',
// };

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: GroupSignatories) => row.name || 'N/A',
  },
  {
    header: 'PAN',
    valueGetter: (row: GroupSignatories) => row.pan.toUpperCase() || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: GroupSignatories) => row.email || 'N/A',
  },
  {
    header: 'Designation',
    valueGetter: (row: GroupSignatories) => row.designation || 'N/A',
  },
];

export const viewDownloadCell = (docData: documentDetails[]): any => {
  return docData.map((_doc_data) => {
    return {
      header: _doc_data.documentName,
      renderEditDeleteCell: (row: GroupSignatories): JSX.Element => {
        const docDataFilter =
          row.groupsignatorydocuments?.length > 0 &&
          row.groupsignatorydocuments.filter(
            (_data) => _data.documentType === _doc_data.documentType
          )?.length > 0;
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', md: 'center' },
              }}>
              {docDataFilter ? (
                row.groupsignatorydocuments
                  .filter((_data) => _data.documentType === _doc_data.documentType)
                  .map((rowData) => {
                    const fileData = rowData?.file || rowData?.fileView;
                    {
                      return fileData?.id ? (
                        <>
                          {fileData?.viewUrl && (
                            <Tooltip title="View">
                              <IconButton onClick={() => window.open(fileData?.viewUrl, '_blank')}>
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {fileData?.url && (
                            <Tooltip title="Download">
                              <IconButton
                                sx={{
                                  p: 0,
                                  pr: 0.5,
                                  ':hover': {
                                    bgcolor: '#F4FCFC',
                                  },
                                  cursor: 'pointer',
                                  pointerEvents: 'all',
                                  mb: 1,
                                }}
                                onClick={() => window.open(fileData?.url, '_blank')}>
                                <CardMedia
                                  component="img"
                                  src={'/images/download-icon.png'}
                                  alt="Download"
                                  sx={{ width: '25px' }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Typography>N/A</Typography>
                      );
                    }
                  })
              ) : (
                <Typography>N/A</Typography>
              )}
            </Box>
          </>
        );
      },
    };
  });
};

export const getASDocuments = (
  response: Document | null,
  signatory: GroupSignatories,
  authorizedSignatoryRefLogin: boolean
): documentDetails[] => {
  return (
    (
      (response?.non_individual_autherized_signatory as unknown as individuals_Poa_nonIndividuals_Documents[]) ||
      []
    ).map((responseData) => {
      const checkGroupSignatoryDoc =
        signatory.groupsignatorydocuments &&
        signatory.groupsignatorydocuments?.length > 0 &&
        signatory.groupsignatorydocuments?.filter(
          (doc) => doc.documentType === responseData.documentType
        )?.length > 0;

      if (checkGroupSignatoryDoc) {
        return signatory.groupsignatorydocuments
          .reduce((acc: documentDetails[], groupData) => {
            if (groupData.documentType === responseData.documentType) {
              const { required, multipleFiles, ...rest } = responseData;
              acc.push({
                ...groupData,
                required: authorizedSignatoryRefLogin ? 'true' : required,
                multipleFiles,
                errorOnUpload: false,
              });
            }
            return acc;
          }, [])
          .flat();
      } else {
        return {
          ...responseData,
          required: authorizedSignatoryRefLogin ? 'true' : responseData.required,
          documentId: '',
          isActive: true,
          errorOnUpload: false,
        };
      }
    }) as documentDetails[]
  ).flat();
};

export default function AuthorisedSignatories(): JSX.Element {
  const [authorisedSignatoriesGroup, setAuthorisedSignatoriesGroup] = useState<Groups[]>([]);
  const [authorisedSignatories, setAuthorisedSignatories] = useState(GroupSignatoriesObject);
  const [loading, setLoading] = useState(false);
  const [saveType, setSaveType] = useState('');
  const [open, setOpen] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const [groupSignatoriesDocData, setGroupSignatoriesDocData] = useState<documentDetails[]>([]);
  // const [thresholdValue, setThresholdValue] = useState(1);
  const [groupIndex, setGroupIndex] = useState(Number);
  const [rowIndex, setRowIndex] = useState(Number);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [deletePopup, setDeletePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );
  const [openPopup, setOpenPopup] = useState(false);

  const { authorizedSignatoryEditObj } = useSelector(
    (store: RootStateType) => store.authorizedSignatoryEditObj
  );
  const [authorizedSignatoryRouteObj, setAuthorizedSignatoryrRouteObj] =
    useState<InvestorEditRouteLinkObj | null>(null);

  const { esignReferenceId } = authorizedSignatoriesReferenceDetail;

  useEffect(() => {
    const routeLinkObj =
      authorizedSignatoryEditObj && authorizedSignatoryEditObj?.length > 0
        ? getInvestorRouteLinks(
            authorizedSignatoryEditObj,
            history?.location?.pathname?.split('/')?.pop() || ''
          )
        : null;
    setAuthorizedSignatoryrRouteObj(routeLinkObj);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const { groups = [] } = application || {};
        const response = (await dispatch(getDocuments())) as unknown as Document;
        const groupSignatoryData = (
          (response?.non_individual_autherized_signatory as unknown as individuals_Poa_nonIndividuals_Documents[]) ||
          []
        ).map((_doc) => {
          return { ..._doc, documentId: '', isActive: true, errorOnUpload: false };
        });
        setGroupSignatoriesDocData(groupSignatoryData);
        const authorzedSignatoryObj = groups.length
          ? groups.map((group) => {
              return {
                ...group,
                groupsignatories: group.groupsignatories.map((signatory) => {
                  return {
                    ...signatory,
                    countryCode: signatory.countryCode ? signatory.countryCode : '+91',
                    countryNameAndCode: signatory.countryNameAndCode
                      ? signatory.countryNameAndCode
                      : 'India: +91',
                    groupsignatorydocuments: getASDocuments(
                      response,
                      signatory,
                      !!(
                        esignReferenceId &&
                        authorizedSignatoriesReferenceDetail?.id === signatory?.id
                      )
                    ),
                  };
                }),
              };
            })
          : [
              {
                name: '',
                threshold: 1,
                groupsignatories: [],
              },
            ];

        if (esignReferenceId) {
          const getLoggedInAuthorizedSignatoryDetails = getLoggedInAuthorizedSignatory(
            groups,
            authorizedSignatoriesReferenceDetail,
            true
          );
          const getLoggedInAuthorizedSignatoryObj = authorzedSignatoryObj?.find(
            (_group) => _group.id === getLoggedInAuthorizedSignatoryDetails.groupId
          );
          if (
            (
              getLoggedInAuthorizedSignatoryObj?.groupsignatories.find(
                (as_signatory) => as_signatory.id === getLoggedInAuthorizedSignatoryDetails.id
              ) as GroupSignatories
            )?.groupsignatorydocuments?.every((_AS_DOC) => !_AS_DOC.documentId)
          ) {
            setAuthorisedSignatoriesGroup([
              {
                ...getLoggedInAuthorizedSignatoryObj,
                groupsignatories:
                  getLoggedInAuthorizedSignatoryObj?.groupsignatories?.filter(
                    (as_signatory) => as_signatory.id === getLoggedInAuthorizedSignatoryDetails.id
                  ) || [],
              } as Groups,
            ]);
            setAuthorisedSignatories(
              getLoggedInAuthorizedSignatoryObj?.groupsignatories.find(
                (as_signatory) => as_signatory.id === getLoggedInAuthorizedSignatoryDetails.id
              ) as GroupSignatories
            );
            setGroupIndex(0);
            setRowIndex(0);
            setOpen(true);
          }
        } else {
          setAuthorisedSignatoriesGroup(authorzedSignatoryObj);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleClose = () => {
    setAuthorisedSignatories({
      ...GroupSignatoriesObject,
      groupsignatorydocuments: groupSignatoriesDocData,
    }),
      setRowIndex(Number),
      setGroupIndex(Number),
      setOpen(false);
  };

  const onSubmit = (values: GroupSignatories) => {
    try {
      if (values.groupsignatorydocuments.some((_documentCheck) => _documentCheck?.errorOnUpload)) {
        throw docInvalidCheck;
      }
      const editsignatory = authorisedSignatoriesGroup[groupIndex].groupsignatories
        .filter((_item) => _item.isActive)
        .map((signatory, _ind) => {
          if (rowIndex === _ind) {
            return { ...values, countryCode: values.countryNameAndCode.split(':')[1].trim() };
          }
          return signatory;
        });
      const deletedsignatory = authorisedSignatoriesGroup[groupIndex].groupsignatories.filter(
        (_item) => !_item.isActive && _item.id
      );

      const addSignatoriesGroup = authorisedSignatoriesGroup.map((group, ind) => {
        if (ind === groupIndex) {
          return {
            ...group,
            name: getGroupNameMaster[groupIndex],
            threshold: group.threshold,
            groupsignatories: [
              authorisedSignatories.pan,
              authorisedSignatories.mobile,
              authorisedSignatories.email,
            ].includes('')
              ? [
                  ...group.groupsignatories,
                  { ...values, countryCode: values.countryNameAndCode.split(':')[1].trim() },
                ]
              : [...editsignatory, ...deletedsignatory],
          };
        }
        return group;
      });
      setAuthorisedSignatoriesGroup(addSignatoriesGroup);
      handleClose();
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
    }
  };

  const handleSubmit = async (saveTypeText: string) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
        groups = [],
      } = application || {};
      const updatedAuthorisedSignatoriesGroup = esignReferenceId
        ? groups.map((_data) => {
            const dataObj = authorisedSignatoriesGroup.find(
              (_signatory_group) => _signatory_group.id === _data.id
            );
            if (dataObj) {
              return {
                ..._data,
                groupsignatories: _data.groupsignatories.map((_group_signatory) => {
                  const signatoryDataObj = dataObj.groupsignatories.find(
                    (_signatory_obj) => _signatory_obj.id === _group_signatory.id
                  );
                  return signatoryDataObj ? signatoryDataObj : _group_signatory;
                }),
              };
            } else return _data;
          })
        : authorisedSignatoriesGroup;
      const shouldSaveForLater = shouldValidateUponSaveLater(saveTypeText);
      updatedAuthorisedSignatoriesGroup.map((group) => {
        const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
        const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
        if (!getActiveSignatories.length) {
          throw 'Please add Authorized Signatories';
        }
        if (shouldSaveForLater && !getCanEsignCount.length) {
          throw 'Please Select a member for E-Sign';
        }
        if (shouldSaveForLater && getCanEsignCount.length < group.threshold) {
          throw 'Authorized Signatories that can esign can not be less than the Total No. of required Authorized Signatories';
        }
        return group;
      });

      const setGroupSignatoryData = (documentsData: documentDetails[]): documentDetails[] => {
        return documentsData.map((documentData) => {
          const { multipleFiles, required, errorOnUpload, ...rest } = documentData;
          return rest;
        });
      };

      const updatedGroups = updatedAuthorisedSignatoriesGroup.map((group) => ({
        ...group,
        groupsignatories: group.groupsignatories.map((groupsignatory) => {
          const signatoryDocuments =
            application?.groups
              ?.find((_group) => _group.id === group.id)
              ?.groupsignatories?.find(
                (_groupSignatories) => _groupSignatories.id === groupsignatory.id
              )?.groupsignatorydocuments || [];
          const finalDoc = signatoryDocuments?.length && filterSignatoryData(signatoryDocuments);
          const updateGroupSignatoryDocuments = groupsignatory.groupsignatorydocuments.filter(
            (_as_doc) => _as_doc.documentId
          );
          return {
            ...groupsignatory,
            name: removeSingleQuote(groupsignatory.name),
            designation: removeSingleQuote(groupsignatory.designation),
            email: removeSingleQuote(groupsignatory.email),
            groupsignatorydocuments: setGroupSignatoryData(
              finalDoc && finalDoc?.length
                ? [...finalDoc, ...updateGroupSignatoryDocuments]
                : updateGroupSignatoryDocuments
            ),
          };
        }),
        isActive: true,
      }));
      const isSaveLater = saveTypeText !== 'save and proceed';
      const checkApplication = applicationComparison(
        {
          ...application,
          groups: application?.groups
            ?.map((_group) => {
              return {
                ..._group,
                groupsignatories: _group.groupsignatories
                  ?.map((_groupSignatories) => {
                    return {
                      ..._groupSignatories,
                      groupsignatorydocuments: _groupSignatories?.groupsignatorydocuments?.sort(
                        (doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)
                      ),
                    };
                  })
                  ?.sort(
                    (authorizedSignatory1, authorizedSignatory2) =>
                      Number(authorizedSignatory1.id) - Number(authorizedSignatory2.id)
                  ),
              };
            })
            ?.sort(
              (groupAuthorizedSignatory1, groupAuthorizedSignatory2) =>
                Number(groupAuthorizedSignatory1.id) - Number(groupAuthorizedSignatory2.id)
            ),
        },
        {
          ...application,
          groups: updatedGroups
            ?.map((_group) => {
              return {
                ..._group,
                groupsignatories: _group.groupsignatories
                  ?.map((_groupSignatories) => {
                    return {
                      ..._groupSignatories,
                      groupsignatorydocuments: _groupSignatories?.groupsignatorydocuments?.sort(
                        (doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)
                      ),
                    };
                  })
                  ?.sort(
                    (authorizedSignatory1, authorizedSignatory2) =>
                      Number(authorizedSignatory1.id) - Number(authorizedSignatory2.id)
                  ),
              };
            })
            ?.sort(
              (groupAuthorizedSignatory1, groupAuthorizedSignatory2) =>
                Number(groupAuthorizedSignatory1.id) - Number(groupAuthorizedSignatory2.id)
            ),
          currentStep: getStep(
            !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        }
      );

      if (id && !checkApplication) {
        setSaveType(saveTypeText);
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              groups: updatedGroups,
              currentStep: authorizedSignatoryRouteObj
                ? getStep(currentStep as number, isSaveLater)
                : getStep(6, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },

            applicationId: id,
            ...(saveTypeText !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );
        saveTypeText === 'save and proceed'
          ? authorizedSignatoryRouteObj
            ? setOpenPopup(true)
            : history.push('declaration-ubo', { id, applicant1ReferenceId })
          : authorizedSignatoryRouteObj
          ? setOpenPopup(true)
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveTypeText !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveTypeText === 'save and proceed'
          ? authorizedSignatoryRouteObj
            ? setOpenPopup(true)
            : history.push('declaration-ubo', { id, applicant1ReferenceId })
          : authorizedSignatoryRouteObj
          ? setOpenPopup(true)
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const editCell = {
    header: '',
    renderEditDeleteCell: (
      row: GroupSignatories,
      index: number,
      _groupIndex?: number
    ): JSX.Element => {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                const groupSignatoryForView = row.groupsignatorydocuments.map((documentData) => {
                  if (documentData.documentId === documentData?.fileView?.id || '') {
                    return {
                      ...documentData,
                      file: documentData?.fileView,
                    };
                  } else {
                    return {
                      ...documentData,
                    };
                  }
                });
                setAuthorisedSignatories({
                  ...row,
                  groupsignatorydocuments: groupSignatoryForView,
                });
                typeof _groupIndex !== 'undefined' && setGroupIndex(_groupIndex),
                  setRowIndex(index),
                  setOpen(true);
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}>
              <EditIcon sx={{ color: 'primary.main' }} fontSize="small" />
            </IconButton>
          </Tooltip>
          {!esignReferenceId && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setAuthorisedSignatories(row),
                    typeof _groupIndex !== 'undefined' && setGroupIndex(_groupIndex),
                    setRowIndex(index),
                    setDeletePopup(true);
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}>
                <DeleteOutlineIcon color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
  };

  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <Grid
      container
      rowSpacing={1}
      //columnSpacing={5}
      sx={{
        width: '100%',
        ml: 0,
        '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
      }}>
      {authorisedSignatoriesGroup.map((group, index) => (
        <React.Fragment key={index}>
          {!esignReferenceId && (
            <Grid
              sx={{
                display: 'flex',
              }}
              alignItems="center"
              mb={4}
              mt={1}>
              <Typography sx={{ color: 'primary.main', fontSize: '16px' }}>
                Total No. of required authorized signatories
                <Typography component="span" sx={{ color: '#BD2932' }}>
                  *
                </Typography>{' '}
                :
              </Typography>
              <Box
                sx={{
                  width: '100px',
                  ml: 2,
                  '.MuiOutlinedInput-root': {
                    marginTop: '0',
                    backgroundColor: 'rgba(97, 209, 214, 0.07)',
                    '&.MuiInputBase-root': {
                      '& .MuiSelect-select': {
                        padding: '7px 38px',
                        border: 'none',
                      },
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },

                  '&.Mui-focused,&:hover': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderWidth: 'none',
                    },
                  },
                  svg: {
                    color: '#337FC9',
                  },
                }}>
                <Select
                  labelId="user-items"
                  onChange={(e) => {
                    const updatethreshold = authorisedSignatoriesGroup.map((_group, _ind) => {
                      if (index === _ind) {
                        return {
                          ..._group,
                          threshold: Number(e.target.value),
                        };
                      }
                      return _group;
                    });
                    setAuthorisedSignatoriesGroup(updatethreshold);
                  }}
                  value={group.threshold}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                  }}
                  MenuProps={{
                    sx: {
                      '.MuiPaper-root ': {
                        maxHeight: '20%',
                      },
                    },
                  }}>
                  {Array.from(
                    {
                      length:
                        group.groupsignatories.filter((_signatory) => _signatory.isActive).length ||
                        1,
                    },
                    (value, index) => index + 1
                  ).map((thresholdValue, ind) => (
                    <MenuItem
                      sx={{
                        borderBottom: '1px solid #ccc',
                        textAlign: 'center',
                        '&:last-child': { border: 'none' },
                        justifyContent: 'center',
                      }}
                      value={thresholdValue}
                      key={ind}>
                      {thresholdValue}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
          )}
          {group.groupsignatories.filter((_signatory) => _signatory.isActive).length !== 0 && (
            <Grid xs={12} sm={12} lg={12}>
              <DataTable
                groupIndex={index}
                tableData={group.groupsignatories.filter((_signatory) => _signatory.isActive)}
                tableHeader={[...headers, ...viewDownloadCell(groupSignatoriesDocData), editCell]}
                renderAdditionalRow={false}
                tableHeaderCustomStyles={{
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
                rowCustomStyles={{
                  '.MuiTableCell-root': {
                    py: '8px',
                    overflowWrap: 'anywhere',
                  },
                }}
                boxStyles={{ minHeight: '0px', minWidth: 450 }}
              />
            </Grid>
          )}
          {group.groupsignatories.filter((item) => item.isActive).length < 20 && !esignReferenceId && (
            <Grid
              xs={12}
              sm={12}
              lg={12}
              display="flex"
              sx={{
                justifyContent: {
                  xs:
                    group.groupsignatories.filter((_signatory) => _signatory.isActive).length === 0
                      ? 'start'
                      : 'center',
                  sm:
                    group.groupsignatories.filter((_signatory) => _signatory.isActive).length === 0
                      ? 'start'
                      : 'end',
                },
              }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  mr: 2.5,
                  whiteSpace: 'nowrap',
                  mt: '20px',
                }}
                onClick={() => {
                  setAuthorisedSignatories({
                    ...GroupSignatoriesObject,
                    groupsignatorydocuments: groupSignatoriesDocData,
                  }),
                    setGroupIndex(index),
                    setOpen(true);
                }}>
                {/* // */}
                Add Authorized Signatory
              </Button>
            </Grid>
          )}
        </React.Fragment>
      ))}
      {!(authorizedSignatoryRouteObj && authorizedSignatoryRouteObj.backSectionLink) && (
        <FieldValidationNote
          saveType={
            authorizedSignatoryRouteObj
              ? authorizedSignatoryRouteObj.saveAndProceedSectionLink
                ? 'Save and Proceed'
                : 'Save Application'
              : 'Save and Proceed'
          }
        />
      )}

      <ProceedSaveLater
        saveLater={() => {
          handleSubmit('save for later');
        }}
        saveAndProceed={() => {
          handleSubmit('save and proceed');
        }}
        loader={loading}
        clickedButton={saveType}
        saveForLaterButtonText={
          authorizedSignatoryRouteObj
            ? authorizedSignatoryRouteObj.backSectionLink
              ? `Save and Back To ${authorizedSignatoryRouteObj.backSectionName}`
              : 'Save For Later'
            : 'Save For Later'
        }
        saveButtonText={
          authorizedSignatoryRouteObj
            ? authorizedSignatoryRouteObj.saveAndProceedSectionLink
              ? 'Save and Proceed'
              : 'Save Application'
            : 'Save and Proceed'
        }
        showEndIcon={
          authorizedSignatoryRouteObj
            ? authorizedSignatoryRouteObj.saveAndProceedSectionLink
              ? true
              : false
            : true
        }
        showSaveForLater={
          authorizedSignatoryRouteObj
            ? authorizedSignatoryRouteObj.backSectionLink
              ? true
              : false
            : true
        }
      />
      <Dialog
        // onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
            pt: 2,
          },
        }}>
        {!esignReferenceId && (
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'center',
            },
            '.MuiFormControl-root': { my: 1 },
            mt: esignReferenceId ? 1 : 0,
            px: { xs: '0', sm: 5 },
          }}>
          <Typography
            sx={{
              color: '#0D2762',
              position: 'relative',
              bottom: '20px',
              fontSize: '23px',
              fontWeight: 500,
            }}>
            {authorisedSignatories.pan ? 'Edit Member' : 'Add Member'}
          </Typography>

          <Formik
            initialValues={authorisedSignatories}
            onSubmit={onSubmit}
            //validationSchema={nonIndividualAuthorisedSignatories}
            validate={(values: GroupSignatories) => {
              const setValuesForEdit = authorisedSignatoriesGroup.map((group, _ind) => {
                if (_ind === groupIndex) {
                  return {
                    ...group,
                    groupsignatories: group.groupsignatories
                      .filter((_signatory) => _signatory.isActive)
                      .map((_signatory, index) => {
                        if (index === rowIndex) {
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            return _signatory;
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { mobile, email, pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { mobile, email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { pan, email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { mobile, pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { mobile, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          return;
                        }
                        return _signatory;
                      }),
                  };
                }
                return group;
              });

              try {
                validateYupSchema(
                  values,
                  nonIndividualAuthorisedSignatories,
                  true,
                  authorisedSignatories.pan
                    ? setValuesForEdit
                    : authorisedSignatoriesGroup.map((_group) => {
                        return {
                          ..._group,
                          groupsignatories: _group.groupsignatories.filter(
                            (_signatory) => _signatory.isActive
                          ),
                        };
                      })
                );
              } catch (e) {
                return yupToFormErrors(e);
              }
            }}
            enableReinitialize={true}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <MFTextField
                    name={`name`}
                    label="Name *"
                    placeholder="Enter Name"
                    disabled={!!esignReferenceId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`pan`}
                    label="PAN *"
                    placeholder="Enter Pan"
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    onChange={(e) => {
                      setFieldValue('pan', e.target.value.toUpperCase());
                    }}
                    disabled={!!esignReferenceId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`mobile`}
                    label={`Mobile Number *`}
                    placeholder="Enter Mobile Number"
                    onKeyDown={(e) => {
                      preventSpecialCharacters(e);
                    }}
                    startAdornment={
                      <CountryCodesDropDown
                        ElementWidth={mobileElementWidth && mobileElementWidth}
                        name={`countryNameAndCode`}
                        value={values.countryNameAndCode}
                        disabled={!!esignReferenceId}
                      />
                    }
                    regexForFilterValue={nonDigitRegex}
                    disabled={!!esignReferenceId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`email`}
                    label="Email ID (Office ID Only) *"
                    placeholder="Enter Email ID"
                    disabled={!!esignReferenceId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`designation`}
                    label="Designation *"
                    placeholder="Enter Designation"
                    disabled={!!esignReferenceId}
                  />
                </Grid>
                <Grid item xs={12}>
                  {values.groupsignatorydocuments.map((groupSignatoryData, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item>
                          <Typography
                            style={{
                              color: 'rgba(0, 0, 0, 0.7)',
                              fontWeight: 500,
                              textAlign: 'left',
                              fontSize: 14,
                              marginBottom: 10,
                            }}>
                            {groupSignatoryData.documentName}
                            {groupSignatoryData.required === 'true' && ' *'}
                          </Typography>
                        </Grid>

                        <FileUpload
                          onFileChange={(value, documentId, errorOnUpload, removeFile, result) => {
                            const { file, ...rest } = values.groupsignatorydocuments[index];
                            setFieldValue(`groupsignatorydocuments.${index}`, {
                              ...rest,
                              documentId,
                              fileView: result,
                              ...(!removeFile && { file: result?.id ? result : file }),
                              errorOnUpload: errorOnUpload || false,
                            });
                          }}
                          documentData={{
                            ...groupSignatoryData,
                            documentDetails: '',
                            documentId: '',
                            isActive: true,
                          }}
                          name={`groupsignatorydocuments.${index}.documentId`}
                          displayReplaceButton={true}
                          errorTextAlign="flex-start"
                          fileValidateType={{
                            'image/jpeg': '.jpeg',
                            'image/jpg': '.jpg',
                            'image/png': '.png',
                          }}
                          supportTitle="jpeg, png, jpg"
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Grid xs={12} sm={12} lg={12} display="flex" sx={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 15,
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 500,
                      alignSelf: 'center',
                      mt: 2,
                    }}
                    type="submit">
                    {authorisedSignatories.pan ? 'Save' : 'Add'}
                  </Button>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Dialog>
      <ConfirmationDialog
        message={'Are you sure you want to delete Member ?'}
        open={deletePopup}
        setOpen={() => setDeletePopup(false)}
        onSave={() => {
          setDeletePopup(false);
          const deleteSignatory = authorisedSignatoriesGroup.map((group, ind) => {
            if (ind === groupIndex) {
              return {
                ...group,
                name: getGroupNameMaster[groupIndex],
                threshold: group.threshold,
                groupsignatories: group.groupsignatories
                  .filter((_item) => _item.isActive)
                  .map((signatory, _ind) => {
                    if (rowIndex === _ind) {
                      return { ...signatory, isActive: false };
                    }
                    return signatory;
                  }),
              };
            }
            return group;
          });

          // const deletedSignatory = authorisedSignatoriesGroup.map((group, _ind) => {
          //   if (_ind === groupIndex) {
          //     return {
          //       ...group,
          //       name: getGroupNameMaster[groupIndex],
          //       threshold: group.threshold,
          //       groupsignatories: group.groupsignatories.filter(
          //         (_signatory) => !_signatory.isActive && _signatory.id
          //       ),
          //     };
          //   }
          //   return group;
          // });
          const active_DeletedSignatory = deleteSignatory.map((group, _ind) => {
            if (_ind === groupIndex) {
              return {
                ...group,
                name: getGroupNameMaster[groupIndex],
                threshold: group.threshold,
                groupsignatories: [
                  ...authorisedSignatoriesGroup[groupIndex].groupsignatories.filter(
                    (_signatory) => !_signatory.isActive && _signatory.id
                  ),
                  ...group.groupsignatories.filter(
                    (_signatory) => _signatory.isActive || (!_signatory.isActive && _signatory.id)
                  ),
                ],
              };
            }
            return group;
          });
          //const setAuthorisedSignatories=deleteSignatory[groupIndex].groupsignatories:[...active_DeletedSignatory[groupIndex].groupsignatories,...deleteSignatory[groupIndex].groupsignatories]
          setAuthorisedSignatoriesGroup(active_DeletedSignatory);
          setAuthorisedSignatories({
            ...GroupSignatoriesObject,
            groupsignatorydocuments: groupSignatoriesDocData,
          }),
            setRowIndex(Number),
            setGroupIndex(Number);
          // try {
          //   const deleteSignatory = authorisedSignatoriesGroup.map((group, ind) => {
          //     if (ind === groupIndex) {
          //       return {
          //         ...group,
          //         name: getGroupNameMaster[groupIndex],
          //         threshold: group.threshold,
          //         groupsignatories: group.groupsignatories.map((signatory, _ind) => {
          //           if (rowIndex === _ind) {
          //             return { ...signatory, isActive: false };
          //           }
          //           return signatory;
          //         }),
          //       };
          //     }
          //     return group;
          //   });
          //   authorisedSignatoriesGroup.map((group) => {
          //     const getCanEsignCount = group.groupsignatories.filter((item) => item.canEsign);
          //     if (getCanEsignCount.length < group.threshold) {
          //       throw 'Authorised Signatories that can esign cannot be less than the threshold';
          //     }
          //     return group;
          //   });
          //   const { id } = application || {};
          //   if (id && authorisedSignatories.groupId) {
          //     await dispatch(
          //       updateApplication({
          //         body: {
          //           ...application,
          //           groups: deleteSignatory,
          //         },
          //         applicationId: id,
          //       })
          //     );
          //     return;
          //   }
          //   const deletedSignatories = deleteSignatory.map((group, _ind) => {
          //     if (_ind === groupIndex) {
          //       return {
          //         ...group,
          //         name: getGroupNameMaster[groupIndex],
          //         threshold: group.threshold,
          //         groupsignatories: group.groupsignatories.filter(
          //           (_signatory) => _signatory.isActive
          //         ),
          //       };
          //     }
          //     return group;
          //   });
          //   setAuthorisedSignatoriesGroup(deletedSignatories);
          //   setAuthorisedSignatories(GroupSignatoriesObject),
          //     setRowIndex(Number),
          //     setGroupIndex(Number);
          // } catch (e) {
          //   //typeof e === 'string' && dispatch(showError(e));
          //   console.error((e as Error).message);
          // }
        }}
        onCancel={() => {
          setAuthorisedSignatories({
            ...GroupSignatoriesObject,
            groupsignatorydocuments: groupSignatoriesDocData,
          }),
            setRowIndex(Number),
            setGroupIndex(Number),
            setDeletePopup(false);
        }}
      />
      <ConfirmationDialog
        message={`Application Saved successfully and no further edits provided, Are you sure you want to go back to preview?`}
        open={openPopup}
        setOpen={() => {
          setOpenPopup(false);
        }}
        onSave={() => {
          setOpenPopup(false);
          history.push(
            `/authorized-signatories-investment-details/${esignReferenceId}/nonIndividual-application-details`
          );
        }}
        onCancel={() => {
          setOpenPopup(false);
        }}
      />
    </Grid>
  );
}
