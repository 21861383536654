import { LoadingButton } from '@mui/lab';
import { Button, CardMedia, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Webcam, { WebcamProps } from 'react-webcam';
import {
  uploadCapturedImg,
  uploadCapturedImgForAuthorizedSignatory,
} from '../../redux-store/actions/files';
import {
  sendAuthorizedSignatoriesLocationAndPhotoCaptureData,
  sendLocationAndPhotoCaptureData,
} from '../../redux-store/actions/photoLocationCapture';
import { RootStateType } from '../../redux-store/reducers';
import { FileType, getDetailsByRefrenceType } from '../../redux-store/types/api-types';
import { ConsentEmailLayout } from '../ConsentEmailComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDetailByReferenceId } from '../../redux-store/actions/onBoarding';
import { checkPathForAuthorizedSignatory } from '../Investments/investor-details';

export const CapturedDetailsSucsessfully = (): JSX.Element => {
  return (
    <ConsentEmailLayout
      sx={{
        bgcolor: 'grey.50',
        height: { xs: 'unset', sm: '370px' },
        width: 'clamp(300px, 70%, 470px)',
        px: { xs: '25px', sm: '50px' },
        py: '50px',
        mb: 2,
        borderRadius: '10px',
        boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
      }}>
      <>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          {/* <CardMedia
            component="img"
            src="/images/access-approved-icon.svg"
            sx={{ width: 'unset', mx: 'auto' }}
          /> */}
          <CheckCircleIcon color={'success'} sx={{ fontSize: 50 }} />
        </Box>
        <Box
          sx={{
            '.MuiInputLabel-root': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          }}
          mt={5}>
          <Typography
            sx={{ color: 'secondary.main', fontWeight: 600, fontSize: 18, textAlign: 'center' }}>
            Thank you. your photo saved successfully.
          </Typography>
          <Typography sx={{ color: 'secondary.main', fontSize: 15, mt: 2, textAlign: 'center' }}>
            You can close this page.
          </Typography>
        </Box>
      </>
    </ConsentEmailLayout>
  );
};

function WebcamCapture({
  location,
}: {
  location: Location<{
    applicantId: string;
    geolocation: string;
  }>;
}): JSX.Element {
  const { applicantId = '', geolocation = '' } = location.state || {};
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [cameraAccessed, setCameraAccessed] = useState(false);
  const [cameraNotAccess, setCameraNotAccess] = useState(false);
  const [displayGuidelines, setDisplayGuidelines] = useState(true);
  const [camDeviceId, setCamDeviceId] = useState('');
  const [devices, setDevices] = useState([]);
  const [captureLoader, setcaptureLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor } = useSelector((store: RootStateType) => store);
  const { params, path } = useRouteMatch();
  const { referenceId } = params as unknown as { referenceId: string };
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImgSrc(imageSrc);
  };

  useEffect(() => {
    capture();
  }, [webcamRef]);

  const onUserMediaError = (error: string | DOMException) => {
    setCameraNotAccess(true);
  };

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      const getDevices = mediaDevices.filter(({ kind }: { kind: any }) => kind === 'videoinput');
      setDevices(getDevices);
      setCamDeviceId(getDevices[0].deviceId);
    },
    [setDevices]
  );

  const onUserMedia = (stream: MediaStream) => {
    !camDeviceId && navigator.mediaDevices.enumerateDevices().then(handleDevices);
    setCameraAccessed(stream.active);
  };

  useEffect(() => {
    const { token } = investor;
    if (!token || !geolocation) {
      history.push(
        checkPathForAuthorizedSignatory(path)
          ? `/authorized-signatories-investment-details/${referenceId}/strategy-details`
          : `/investment-details/${referenceId}/details`
      );
    }
  }, []);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <>
      {displayGuidelines ? (
        //steps for photo capture
        <ConsentEmailLayout
          sx={{
            bgcolor: 'grey.50',
            height: { xs: 'unset', sm: '470px' },
            width: '90%',
            maxWidth: '500px',
            px: { xs: '25px', sm: '45px' },
            py: '40px',
            mb: 2,
            borderRadius: '10px',
            boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
          }}>
          <>
            <Typography textAlign={'center'} fontSize="20px">
              Face Capture Steps
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ mb: 1, fontSize: '20px', fontWeight: 500 }}>
                Tips to capture a perfect photo
              </Typography>
              <Typography>1. Capture the image in a well-lit environment.</Typography>
              <Typography>2. Ensure only one person is in front of the camera.</Typography>
              <Typography>3. Ensure that your face is centered on the screen.</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ mb: 1, fontSize: '20px', fontWeight: 500 }}>Dont&apos;s</Typography>
              <Typography>1. Avoid Dimly-lit environment.</Typography>
              <Typography>2. Do not wear a mask.</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{ px: 3, mt: 4, pt: '6px' }}
                onClick={() => setDisplayGuidelines(false)}>
                Proceed
              </Button>
            </Box>
          </>
        </ConsentEmailLayout>
      ) : (
        <ConsentEmailLayout
          sx={{
            bgcolor: 'grey.50',
            height: { xs: 'unset', sm: !imgSrc ? '500px' : '350px' },
            width: 'clamp(300px, 70%, 500px)',
            px: { xs: '0px', sm: '60px' },
            py: '40px',
            mb: 2,
            borderRadius: '10px',
            boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
          }}>
          <>
            <Typography textAlign={'center'} fontSize="20px">
              {!imgSrc ? 'Face Capture' : 'Captured Successfully'}
            </Typography>
            {!imgSrc ? (
              <>
                {!cameraNotAccess && (
                  <Select
                    style={{
                      width: '100%',
                      height: '44px',
                      padding: '0 10px',
                      borderRadius: '6px',
                      fontSize: '15px',
                      marginTop: '10px',
                      boxSizing: 'border-box',
                    }}
                    value={camDeviceId}
                    onChange={({ target: { value } }) => {
                      // setFilters(initialFilters);
                      setCamDeviceId(value);
                    }}>
                    {devices
                      .filter((_device: any) => _device.label !== 'camera2 0, facing back')
                      .map((device: any, index: number) => (
                        <MenuItem value={device.deviceId} key={device.deviceId}>
                          <Typography
                            sx={{ whiteSpace: { xs: 'pre-wrap', sm: 'nowrap' } }}
                            component="span">
                            {device.label || `device ${index + 1}`}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}
                <Box
                  sx={{
                    border: '2px solid #ccc',
                    mt: 2,
                    boxSizing: 'border-box',
                    mx: '10px',
                    mb: 2,
                    borderRadius: '10px',
                    bgcolor: 'rgba(51, 127, 201, 0.02)',
                    video: {
                      width: '100%',
                      borderRadius: '10px',
                      display:
                        cameraNotAccess || (!cameraNotAccess && !cameraAccessed) ? 'none' : 'block',
                    },
                  }}>
                  {/* {devices?.map((dev: any, key) => {
                    return (
                      <React.Fragment key={key}> */}

                  {/* taking picture */}
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      facingMode: 'user',
                      deviceId: camDeviceId,
                    }}
                    onUserMedia={onUserMedia}
                    onUserMediaError={onUserMediaError}
                    screenshotQuality={1}
                  />
                  {/* </React.Fragment>
                    );
                  })} */}
                  {/* asking permission for camera access start */}
                  {!cameraNotAccess && !cameraAccessed && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: { xs: 15, sm: 17 } }}>
                      <CardMedia
                        component="img"
                        src="/images/camera.svg"
                        sx={{ width: '60px', mx: 'auto' }}
                      />
                    </Box>
                  )}
                  {/* asking permission for camera access end */}
                  {/* camera access denaide start */}
                  {cameraNotAccess && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        px: 5,
                        py: 5,
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/access_denied.svg"
                        sx={{ width: '60px', mx: 'auto' }}
                      />
                      <Typography
                        sx={{
                          mt: 5,
                        }}>
                        Camera access denied .Please enable access to the camera and then click
                        refresh.
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          window.location.reload();
                        }}
                        sx={{ px: 3, mt: 3, pt: '6px' }}>
                        Refresh
                      </Button>
                    </Box>
                  )}
                  {/* camera access denaide end */}
                </Box>
                {!(cameraNotAccess || (!cameraNotAccess && !cameraAccessed)) && (
                  <LoadingButton
                    loadingPosition="start"
                    // type="submit"
                    //   loading={loadingApprove}
                    variant="contained"
                    sx={{
                      color: 'common.white',
                      lineHeight: 1.5,
                      fontSize: 14,
                      // mx: '10px',
                    }}
                    fullWidth={true}
                    onClick={capture}>
                    Capture
                  </LoadingButton>
                )}
              </>
            ) : (
              //displaying captured img and options for retake or proceed
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <CardMedia component="img" src={imgSrc} sx={{ width: '230px', mx: 'auto' }} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    mt: 3,
                  }}>
                  <LoadingButton
                    loadingPosition="start"
                    variant="outlined"
                    sx={{
                      color: 'primary.main',
                      lineHeight: 1.5,
                      fontSize: 14,
                      px: 5,
                      mb: { xs: 2, sm: 0 },
                    }}
                    onClick={() => {
                      setImgSrc(null);
                    }}
                    disabled={captureLoader}>
                    Retake
                  </LoadingButton>
                  <LoadingButton
                    loadingPosition="start"
                    loading={captureLoader}
                    variant="contained"
                    sx={{
                      color: 'common.white',
                      lineHeight: 1.5,
                      fontSize: 14,
                      px: 5,
                    }}
                    onClick={async () => {
                      try {
                        setcaptureLoader(true);
                        const imgResponse = await dispatch(
                          checkPathForAuthorizedSignatory(path)
                            ? (uploadCapturedImgForAuthorizedSignatory({
                                groupSignatoryId: authorizedSignatoriesReferenceDetail.id,
                                file: (imgSrc as string)?.split('base64,')[1],
                              }) as unknown as FileType)
                            : (uploadCapturedImg({
                                applicantId,
                                file: (imgSrc as string)?.split('base64,')[1],
                              }) as unknown as FileType)
                        );
                        if (imgResponse && Object.keys(imgResponse))
                          await dispatch(
                            checkPathForAuthorizedSignatory(path)
                              ? sendAuthorizedSignatoriesLocationAndPhotoCaptureData({
                                  groupSignatoryId: authorizedSignatoriesReferenceDetail.id,
                                  referenceId: authorizedSignatoriesReferenceDetail.referenceId,
                                  geolocation,
                                  imageFileId: imgResponse.id,
                                })
                              : sendLocationAndPhotoCaptureData({
                                  applicantId: referenceDetail.id,
                                  referenceId: referenceDetail.referenceId,
                                  geolocation,
                                  imageFileId: imgResponse.id,
                                })
                          );
                        setcaptureLoader(false);
                        history.push(
                          checkPathForAuthorizedSignatory(path)
                            ? 'nonIndividual-application-details'
                            : 'application-details'
                        );
                      } catch (e) {
                        setcaptureLoader(false);
                        console.error((e as Error).message);
                      }
                    }}>
                    Proceed
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </>
        </ConsentEmailLayout>
      )}
    </>
  );
}

export default WebcamCapture;
