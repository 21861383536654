import { AdminStrategyDetailsType, strategyFormType } from './api-types';

export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS';
export const GET_STRATEGIES = 'GET_STRATEGIES';

export const ADD_STRATEGY_SUCCESS = 'ADD_STRATEGY_SUCCESS';
export const ADD_STRATEGY = 'ADD_STRATEGY';

export const UPDATE_STRATEGY_SUCCESS = 'UPDATE_STRATEGY_SUCCESS';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';

export const GET_STRATEGY_SUCCESS = 'GET_STRATEGY_SUCCESS';
export const GET_STRATEGY = 'GET_STRATEGY';

export type GetStrategiesResponseBody = {
  createdAt: string;
  updatedAt: string;
  id?: string | null;
  strategyName: string;
  strategyType: string;
  fee: string;
  performanceFee: string;
  investmentAmount: number;
  isActive: boolean;
  benchmark: string;
  exitFee: number;
  maxFee: number;
  minFee: number;
  maxPerformanceFee: number;
  minPerformanceFee: number;
  maxExitFee: number;
  minExitFee: number;
  managementFeeNature: string;
  performanceFeeNature: string;
  exitLoad: string;
  applicationStrategyManagementFee: number | null;
  feeType: string;
  performanceFeeHybrid?: number | string;
  feeTypeHybrid?: string;
  hurdleRate: string;
  strategyFeeDetailId: string;
  applicableForType?: string;
  strategyFeeDetails: strategyFormType[];
  charges: string;
  costs: string;
  taxes: string;
};

export type GetStrategiesListResponseBody = {
  strategies: AdminStrategyDetailsType[];
};
