import {
  AmlDetails,
  CommonLayout,
  CommonLayoutForPOA,
  Footer,
  NavigationThrowErrorPopup,
} from '../commonComponents';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Dialog,
  Tooltip,
  Divider,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  FatcaMdmsData,
  createBankAccountFormNDPSM,
  getApplicationDetails,
  getDocuments,
  getTimeLineDetails,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
  updateApplication,
  validateStrategyDetails,
} from '../../redux-store/actions/application';
import ContactDetails from './contactDetails';
import KycDetails from './kycDetails';
import FatcaDetails from './FatcaDetails';
import NomineeDetails from './nomineeDetails';
import InvestAndPaymentDetails from './InvestmentAndPayment';
import BankDetails from './BankDetails';
import DocumentDetails from './DocumentDetails';
import HistoryIcon from '@mui/icons-material/History';
import {
  Applicant,
  ApplicationProps,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  RiskProfileMaster,
  TimeLineType,
} from '../../redux-store/types/api-types';
import ContributorDetails from './contributor';
import {
  REJECTED_ROLES,
  USER_ROLES,
  APPLICATION_STATUS,
  ApplicationStatusBasedOnRole,
  APPLICATION_TYPE,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  IndividualApplicationStatusBasedOnRole,
  APPLICATION_STATUS_AMCAPPROVER_individual,
  APPLICATION_LISTING_STATUS,
  PHOTOCAPTURE_STATUS,
  APPLICATION_LISTING_STATUS_TIMELINE,
  ROLE_TIMELINE,
  CHECKER_EDIT_ALL_SECTIONS,
  APMIFlow,
} from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { MFTextField } from '../../lib/formik';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatShortDate, formatShortDateAndTime } from '../../utils/date';
import {
  applyRoleBasedStatus,
  showSaveButton,
  checkIfApplicationIsNonIndividual,
  checkNDPMSForIndusindBank,
  checkSendToCheckerNDPMS,
  getInvestorNamesBasedOnPhotoCatureRefId,
  sendApplication_Nri,
  timeLineTime,
  isPendingByAMCAuthorisedSignatory,
  isCustodianICICI,
  getAllStrategiesByDistributorType,
} from '../../utils/utilityFunctions';
import { NonIndividualApplicationLayout } from '../NonIndividualApplicationDetails';
import { showError } from '../../redux-store/actions/auth';
import { ConfirmationDialog } from '../commonComponents';
import DistributorDetails from './rmDistributor';
import RiskProfiles from './RiskProfiles';
import StrategyDetails from './strategyDetails';
import DisclosureOfInterest from './disclosureOfInterest';
import SourceOfInvestment from './sourceOfInvestment';
import RelatedPartyConsent from './relatedPartyConsent';
import DisclosureOfExclusions from './disclosureOfExclusions';
import {
  getNationalityList,
  getStatesList,
  riskProfileMasterData,
} from '../../redux-store/actions';
import { nationaliyType, statesType } from '../../redux-store/types/mdms';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  nonIndividualSendPhotoCaptureLink,
  sendPhotoCaptureLink,
} from '../../redux-store/actions/photoLocationCapture';
import DisplayImg from './displayImg';
import TypeDeclaration, { getApplicant } from './typeDeclaration';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { StrategyErrors } from '../../redux-store/middleware';
import Comments from './Comments';
import {
  checkConsentRejectStatus,
  declarationTypes,
  getDeclaration,
  getLoggedInInvestor,
  refLoginCheck,
} from '../Investments/investor-application';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getConsentDetails, InvestorConsentProps } from '../dashboard/applicationslist';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useCustodianData } from '../../utils/useCustodianData';
import { useValidationOrConfigHook } from '../../utils/validationOrConfigHook';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export function RejectedDetails({
  title,
  description,
}: {
  title: string | null;
  description: string | null;
}): JSX.Element {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontSize: 17, fontWeight: 500, color: 'text.secondary' }}>
          {title}:{' '}
          <Typography
            component="span"
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              letterSpacing: '0.4px',
              fontWeight: 700,
              ml: { sm: 0.5 },
            }}>
            {description}
          </Typography>
        </Typography>
      </Grid>
    </>
  );
}

export function ApplicationDetailsCommonLayout({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element {
  const { role = '', id: loggedinId = '' } = useSelector((store: RootStateType) => store.auth);
  const { applicants = [] } = application || {};
  const getFirstApplicant = getApplicant(application?.applicants as Partial<Applicant>[]);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const amcuserAsInvestor = refLoginCheck(
    loggedinId,
    application as unknown as ApplicationProps,
    referenceDetail
  );
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
            }}
            mb={3}>
            <Box sx={{ p: 2 }}>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}>
                    Application ID: {application.applicationNumber}
                  </Typography>
                  {application.is_custody_account_created && (
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}>
                      DP Account ID: {application.custody_dpAccountId}
                    </Typography>
                  )}
                  {APPLICATION_LISTING_STATUS[application.status] ===
                    APPLICATION_LISTING_STATUS.completed &&
                    !application.is_custody_account_created &&
                    isCustodianICICI(application.custodian) && (
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'text.secondary',
                        }}>
                        DP Account Creation:{' '}
                        <Typography
                          component="span"
                          sx={{
                            fontSize: '16px',
                            letterSpacing: '0.4px',
                            ml: 0.5,
                            color:
                              application.custody_transaction_error === null
                                ? '#B78813'
                                : '#BD2932',
                          }}>
                          {application.custody_transaction_error === null
                            ? 'In Progress'
                            : 'Failed'}
                        </Typography>
                      </Typography>
                    )}
                  <Typography
                    className={
                      application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]
                    }
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                      '&.Draft .MuiTypography-root': {
                        color: 'rgba(51, 127, 201, 0.7)',
                      },
                      '&.Completed .MuiTypography-root': {
                        fontWeight: 600,
                        color: 'rgba(35, 133, 63, 0.7)',
                      },
                      '&.Rejected .MuiTypography-root': {
                        color: '#BD2932',
                        fontWeight: 500,
                      },
                    }}>
                    Status:{' '}
                    <Typography
                      component="span"
                      sx={{
                        color: '#B78813',
                        fontSize: '16px',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                      }}>
                      {USER_ROLES.AMCAUTHORISEDSIGNATORY === role &&
                      isPendingByAMCAuthorisedSignatory(application.status)
                        ? 'Pending'
                        : application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status] +
                          (APMIFlow && application.editedByPrimaryApplicant ? ' PMS Review' : '')
                        : APPLICATION_LISTING_STATUS[application.status]}
                    </Typography>
                  </Typography>
                  {[
                    USER_ROLES.RM,
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.SUBDISTRIBUTOR,
                    USER_ROLES.AMCAPPROVER,
                    USER_ROLES.AMC_ADMIN,
                  ].includes(role) &&
                    application.signedLeegalityFile?.url && (
                      <Tooltip title={'Download'}>
                        <IconButton
                          sx={{
                            p: 0,
                            pr: 0.5,
                            ':hover': {
                              bgcolor: '#F4FCFC',
                            },
                            cursor: 'pointer',
                            pointerEvents: 'all',
                          }}
                          onClick={() =>
                            application.signedLeegalityFile?.url &&
                            window.open(application.signedLeegalityFile?.url)
                          }>
                          <CardMedia
                            component="img"
                            src={'/images/download-icon.png'}
                            alt="Download"
                            sx={{ width: '25px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
                {application.status === 'rejected' && (
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={1}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <RejectedDetails
                      title={'Rejected by'}
                      description={REJECTED_ROLES[application.rejectedByRole]}
                    />
                    <RejectedDetails
                      title={'Rejected on'}
                      description={formatShortDateAndTime(application.rejectedAt)}
                    />
                    <RejectedDetails
                      title={'Reason for rejection'}
                      description={application.rejectedReason}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>

          {USER_ROLES.INVESTOR !== role && !amcuserAsInvestor && (
            <AmlDetails
              applicants={application.applicants}
              applicationType={application.applicationType}
            />
          )}
          {((applicants?.length === 1 && applicants[applicants.length - 1]?.photocaptureStatus) ||
            (applicants?.length > 1 &&
              applicants.filter((applicant) => !applicant.referenceId).length !==
                applicants?.length)) && (
            <DisplayImg
              applicants={application.applicants?.filter(
                (_applicant) => _applicant.photocaptureStatus
              )}
              editable={!application?.signDetails?.url}
            />
          )}
          {role !== USER_ROLES.INVESTOR &&
            !amcuserAsInvestor &&
            application.comments.length > 0 && <Comments application={application} />}
          {<DistributorDetails application={application} />}
          <ContributorDetails application={application} modeOfHolding={application.modeOfHolding} />
          <ContactDetails applicants={application.applicants} custodian={application.custodian} />
          <KycDetails application={application} />
          <FatcaDetails
            applicants={application.applicants}
            custodian={application.custodian || ''}
          />
          <NomineeDetails application={application} />
          {/* <InvestAndPaymentDetails application={application} /> */}
          <BankDetails application={application} />
          <RiskProfiles application={application} />
          <StrategyDetails application={application} />
          <DisclosureOfInterest application={application} />
          <DisclosureOfExclusions application={application} />
          <RelatedPartyConsent application={application} />
          <DocumentDetails application={application} />
          {
            // (role === USER_ROLES.INVESTOR ||
            //   Number(loggedinId) === Number(getFirstApplicant?.userId)) &&
            referenceDetail.applicant_type
              ? ((APMIFlow
                  ? getDeclaration(application, referenceDetail, declarationTypes.typedDeclaration)
                  : getApplicant(application?.applicants as Partial<Applicant>[])
                      ?.typedDeclaration && referenceDetail.applicant_type === '1') ||
                  getDeclaration(application, referenceDetail, declarationTypes.FATCA) ||
                  (application?.riskProfileDeclaration &&
                    referenceDetail.applicant_type === '1')) &&
                (APMIFlow
                  ? !checkConsentRejectStatus(
                      getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                    )?.status
                  : !APMIFlow) && (
                  <TypeDeclaration
                    applicants={application.applicants}
                    declarationForRiskProfile={application?.riskProfileDeclaration}
                  />
                )
              : ((APMIFlow
                  ? application?.applicants
                      ?.map((applicant) => applicant.typedDeclaration)
                      ?.some((_ele) => _ele)
                  : getFirstApplicant?.typedDeclaration) ||
                  application?.riskProfileDeclaration ||
                  application?.applicants
                    ?.map((applicant) => applicant.fatcaDeclaration)
                    ?.some((ele) => ele === true)) && (
                  <TypeDeclaration
                    applicants={application.applicants}
                    declarationForRiskProfile={application?.riskProfileDeclaration}
                  />
                )
          }
        </>
      )}
    </>
  );
}

const RejectApproveButtons = ({
  handleOpen,
  handleDialogOpen,
  approve,
  reject,
  handleSendBack,
  sendBack,
}: {
  handleOpen: () => void;
  handleDialogOpen: () => void;
  approve: boolean;
  reject: boolean;
  handleSendBack: () => void;
  sendBack: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Grid display="flex" justifyContent="center" alignItems="center">
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={reject}
        variant="outlined"
        // sx={{
        //   fontSize: 15,
        //   minWidth: 180,
        //   letterSpacing: 2,
        //   fontWeight: 400,
        // }}
        sx={{
          color: 'primary.main',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          mr: 3,
        }}
        onClick={handleOpen}
        disabled={approve || sendBack}>
        Reject
      </LoadingButton>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={approve}
        variant="contained"
        // sx={{
        //   fontSize: 15,
        //   minWidth: 180,
        //   letterSpacing: 2,
        //   fontWeight: 400,
        // }}
        sx={{
          color: 'common.white',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          ml: 3,
        }}
        onClick={handleDialogOpen}
        disabled={reject || sendBack}>
        Approve
      </LoadingButton>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={sendBack}
        variant="contained"
        sx={{
          color: 'common.white',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          ml: 3,
        }}
        onClick={handleSendBack}
        disabled={reject || approve}>
        Send Back
      </LoadingButton>
      {/* <Button
        variant="contained"
        sx={{
          color: 'common.white',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          ml: 3,
        }}
        onClick={handleDialogOpen}>
        Approve
      </Button> */}
    </Grid>
  );
};

//timeline Component
export const ApplicationTimeline = ({
  id,
  dialogOpen,
  setDialogOpen,
  consentDetails,
  application,
}: {
  id: string;
  dialogOpen: boolean;
  setDialogOpen: any;
  consentDetails: InvestorConsentProps;
  application: ApplicationProps;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timeLineData, setTimeLineData] = useState<TimeLineType[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const timeLineDetail = (await dispatch(
          getTimeLineDetails(id)
        )) as unknown as TimeLineType[];
        setTimeLineData(timeLineDetail);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={dialogOpen}
        sx={{
          '.MuiPaper-root ': {
            p: 0,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid item container direction="row" justifyContent="end" alignItems="center" p={4} pb={2}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: { md: 18, xs: 4 },
              color: 'primary.main',
              fontSize: '28px',
              fontWeight: 500,
            }}>
            Activity Logs
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <Divider />
        <Box
          sx={{
            width: { xs: '100%', sm: '100%' },
            mx: 'auto',
            pl: 0,
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
          }}>
          {!loading ? (
            <>
              <Timeline sx={{ position: 'relative', left: { md: '-80px' } }}>
                {timeLineData.map((data, ind) => {
                  return (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0', mt: ind > 0 ? 6.5 : 1.5 }}
                          align="right"
                          variant="body2"
                          color="text.secondary">
                          {formatShortDate(data.createdAt)}
                          <Typography sx={{ fontSize: '14px !important' }}>
                            {timeLineTime(data.createdAt)}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          {ind > 0 && (
                            <TimelineConnector sx={{ bgcolor: '#22dedf', height: 40, width: 3 }} />
                          )}
                          <TimelineDot
                            variant="outlined"
                            sx={{ borderColor: '#22dedf', borderWidth: 4 }}>
                            <DescriptionOutlinedIcon
                              fontSize="large"
                              sx={{ fontSize: '50px', color: 'primary.main' }}
                            />
                          </TimelineDot>
                          {ind > timeLineData.length && (
                            <TimelineConnector sx={{ bgcolor: '#22dedf', height: 40, width: 3 }} />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography
                            // component="span"
                            sx={{
                              color: 'text.secondary',
                              fontSize: '16px !important',
                              mt: ind > 0 ? 6.5 : 1.5,
                            }}>
                            {APPLICATION_LISTING_STATUS_TIMELINE[data.status]}
                            {[
                              APPLICATION_LISTING_STATUS_TIMELINE.sent_to_applicant1,
                              APPLICATION_LISTING_STATUS_TIMELINE.sent_to_authorised_signatories,
                            ].includes(APPLICATION_LISTING_STATUS_TIMELINE[data.status]) && (
                              <Typography
                                sx={{ color: 'text.secondary', fontSize: '12px !important' }}>
                                Consent Status : {consentDetails.consentStatus}{' '}
                                {consentDetails.consentStatus === 'Pending' &&
                                  `with ${
                                    checkIfApplicationIsNonIndividual(application)
                                      ? 'Authorized Signatories'
                                      : 'applicant(s)'
                                  }`}
                              </Typography>
                            )}
                            <Typography
                              sx={{ color: 'text.secondary', fontSize: '12px !important' }}>
                              By : {data.activityUserName} ({ROLE_TIMELINE[data.activityUser?.role]}
                              )
                            </Typography>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  );
                })}
              </Timeline>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: { xs: '100%', sm: '80%' },
                  mx: 'auto',
                  my: 5,
                  '.MuiTypography-root': {
                    fontSize: 20,
                    fontWeight: 500,
                  },
                }}>
                <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
                  Loading...
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

//Read only view for POA and Non Individual Application for AMC Approver,Individual Application for AMC Approver and POA Approver
const PoaOrNIApplicationDetails = ({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element => {
  const { status, editable, applicationType, amcAuthorisedSignatoryEsignLink } = application || {};
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  // const [sendPhotoCaptureLinkLoader, setSendPhotoCaptureLinkLoader] = useState(false);.
  // const [openDialogForLiveCaptureLink, setDialogForLiveCaptureLink] = useState('');
  const { hasPOA, applicants = [], groups = [] } = application || {};
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [errorOpen, setErrorOpen] = useState({ message: '', open: false });
  const [custodianData] = useCustodianData();
  const validationOrConfigChecks = useValidationOrConfigHook();
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );

  const validateForm = async () => {
    const nationalitiesMdmsMasters = (await dispatch(
      getNationalityList()
    )) as unknown as nationaliyType;
    const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
    const { applicants = [] } = application || {};
    const getInvestorType = applicants[0]?.investorType;
    const documentsResponse = (await dispatch(getDocuments())) as unknown as any;
    const strategiesResponse = (await getAllStrategiesByDistributorType(
      dispatch,
      application
    )) as unknown as GetStrategiesResponseBody[];
    const responseRisk = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
    const responseFatca = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
    if (checkIfApplicationIsNonIndividual(application)) {
      await nonIndividualFormValidForSubmission(
        documentsResponse,
        authorizedSignatoriesReferenceDetail,
        application as ApplicationProps,
        documentsResponse[getInvestorType || ''] as individuals_Poa_nonIndividuals_Documents[],
        nationalitiesMdmsMasters.countries,
        statesMdmsMasters?.states || [],
        true,
        role,
        responseRisk.riskProfile,
        strategiesResponse,
        true,
        responseFatca.fatca_questions
      );
    } else {
      CHECKER_EDIT_ALL_SECTIONS
        ? await isFormValidForSubmission(
            application as ApplicationProps,
            true,
            true,
            nationalitiesMdmsMasters,
            custodianData,
            validationOrConfigChecks,
            documentsResponse,
            role,
            responseRisk.riskProfile,
            strategiesResponse,
            // investorQuestionnaire
            referenceDetail
          )
        : await validateStrategyDetails(application, strategiesResponse, role);
    }
  };

  const sendLinkForPhotoCapture = async (applicants: any) => {
    const response = await Promise.all(
      applicants?.map(async (applicant: any) => {
        if (!applicant.photocaptureStatus && applicant.id) {
          return await dispatch(sendPhotoCaptureLink(applicant.id));
        }
      })
    );
    const lastUpdatedResponce = (response as ApplicationProps[]).sort(
      (response1, response2) =>
        new Date(response1.updatedAt || '').getTime() -
        new Date(response2.updatedAt || '').getTime()
    );
    return (lastUpdatedResponce[lastUpdatedResponce.length - 1] as ApplicationProps) || application;
  };

  const nonIndividualSendLinkForPhotoCapture = async (groups: any) => {
    const response = await Promise.all(
      groups?.map(async (group: any) => {
        return await Promise.all(
          group.groupsignatories.map(async (signatory: any) => {
            if (!signatory.photocaptureStatus && signatory.id) {
              return await dispatch(nonIndividualSendPhotoCaptureLink(signatory.id));
            }
          })
        );
      })
    );

    return (response?.flat()[response?.flat().length - 1] as ApplicationProps) || application;
  };

  const handleOpen = () => setOpen(true);

  const handleDialogOpen = async () => {
    try {
      setApproveLoading(true);
      await validateForm();
      setApproveLoading(false);
      setOpenDialog(true);
    } catch (e) {
      setApproveLoading(false);
      if (e instanceof StrategyErrors) {
        setErrorOpen({
          message: (e as StrategyErrors).message,
          open: true,
        });
      }
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const handleErrorPopupClose = () => {
    setErrorOpen({ message: '', open: false });
  };

  const initialCommentValues: Partial<ApplicationProps> = { comment: '' };
  const Commentvalidation = yup.object().shape({
    comment: yup.string().required('Please provide Comment'),
  });
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };

  const handleClose = () => {
    setOpen(false), setApproveLoading(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false), setRejectLoading(false);
  };

  const initialValues: Partial<ApplicationProps> = { rejectedReason: '' };
  const resonValidation = yup.object().shape({
    rejectedReason: yup.string().required('Please enter the reason for rejection'),
  });
  const onSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleClose();
      setRejectLoading(true);
      if (application) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              rejectedReason: values.rejectedReason,
              status: 'rejected',
            },
            applicationId: application.id,
          })
        );
        history.push('/applications-list');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setRejectLoading(false);
    }
  };
  const onSendBackSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      if (application) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              comment: values.comment,
              status: 'draft',
              applicationSentBack: true,
            },
            applicationId: application.id,
          })
        );
        history.push('/applications-list');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <CommonLayoutForPOA />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: { xs: 7, sm: 10 } }} ref={applicationDetailRef}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            justifyContent: 'space-between',
            // mt: 5,
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications-list"
              disabled={approveLoading || rejectLoading}>
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(
                    role === USER_ROLES.POAAPPROVER
                      ? `/investment-details/edit-application`
                      : !checkIfApplicationIsNonIndividual(application) &&
                        USER_ROLES.AMCAPPROVER === role
                      ? CHECKER_EDIT_ALL_SECTIONS
                        ? `/investment-details/edit-application`
                        : `/investment-details/edit-application/strategy-details`
                      : `/non-individual-application-edit`,
                    {
                      id: application?.id,
                    }
                  )
                }
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        {applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? (
          <NonIndividualApplicationLayout loading={loading} application={application} />
        ) : (
          <ApplicationDetailsCommonLayout loading={loading} application={application} />
        )}
        {application &&
          ((USER_ROLES.AMCAPPROVER === role && status === 'sent_to_amc_approver') ||
          (USER_ROLES.POAAPPROVER === role && status === 'sent_to_poa_approver') ? (
            <RejectApproveButtons
              handleOpen={handleOpen}
              handleDialogOpen={handleDialogOpen}
              approve={approveLoading}
              reject={rejectLoading}
              sendBack={sendBackLoading}
              handleSendBack={handleSendBack}
            />
          ) : USER_ROLES.AMCAUTHORISEDSIGNATORY === role &&
            isPendingByAMCAuthorisedSignatory(status) ? (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
                onClick={() => window.open(amcAuthorisedSignatoryEsignLink, '_blank')}>
                E-Sign
              </Button>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
                onClick={() => {
                  history.push('/applications-list');
                }}>
                Done
              </Button>
            </Box>
          ))}
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ mb: 2 }}>
            <CardMedia
              component="img"
              src="/images/warning-icon-red.svg"
              sx={{ width: 'unset', mx: 'auto' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: '#BD2932' }}>
                Reject
              </Typography>{' '}
              the application
            </Typography>
            <Typography sx={{ color: '#BD2932', mt: 5 }}>
              {' '}
              Give reason for application rejection *
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={resonValidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="rejectedReason"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <Dialog
          onClose={handleDialogClose}
          open={openDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleDialogClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 20,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: 'green' }}>
                Approve
              </Typography>{' '}
              the application
            </Typography>
          </Box>
          <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
            <Button
              onClick={() => {
                handleDialogClose();
              }}
              variant="outlined"
              sx={{
                fontSize: 15,
                color: 'primary.main',
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 500,
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 400,
              }}
              onClick={async () => {
                try {
                  handleDialogClose();
                  setApproveLoading(true);
                  const response = checkIfApplicationIsNonIndividual(application)
                    ? await nonIndividualSendLinkForPhotoCapture(groups)
                    : await sendLinkForPhotoCapture(applicants);
                  await dispatch(
                    updateApplication({
                      body: {
                        ...response,
                        status:
                          USER_ROLES.POAAPPROVER === role &&
                          application?.status === 'sent_to_poa_approver'
                            ? 'sent_to_amc_approver'
                            : USER_ROLES.AMCAPPROVER === role &&
                              application?.status === 'sent_to_amc_approver'
                            ? application?.hasPOA
                              ? 'sent_to_authorised_signatories'
                              : sendApplication_Nri(application) ||
                                (AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                                  !checkIfApplicationIsNonIndividual(application))
                              ? 'sent_to_applicant1'
                              : 'sent_to_authorised_signatories'
                            : application?.status,
                      },
                      applicationId: application.id,
                    })
                  );
                  history.push('/applications-list');
                } catch (e) {
                  console.error((e as Error).message);
                } finally {
                  setApproveLoading(false);
                }
              }}>
              Continue
            </Button>
          </Grid>
        </Dialog>
        <Dialog
          onClose={handleSendBackClose}
          open={openSendBackDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleSendBackClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: '#BD2932' }}>
                Send Back
              </Typography>{' '}
              the application
            </Typography>
            <Typography sx={{ color: '#BD2932', mt: 5 }}>
              {' '}
              Give Comments for application Send Back *
            </Typography>
            <Formik
              initialValues={initialCommentValues}
              onSubmit={onSendBackSubmit}
              validationSchema={Commentvalidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="comment"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={handleSendBackClose}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        {/* <ConfirmationDialog
          message={`${openDialogForLiveCaptureLink}`}
          open={openDialogForLiveCaptureLink !== ''}
          setOpen={handleDialogForLiveCaptureLinkClose}
          onSave={async () => {
            handleDialogForLiveCaptureLinkClose();
            setSendPhotoCaptureLinkLoader(true);
            sendLinkForPhotoCapture();
          }}
          onCancel={handleDialogForLiveCaptureLinkClose}
        /> */}
        <NavigationThrowErrorPopup
          handleClose={handleErrorPopupClose}
          errorOpen={errorOpen}
          onSave={() => {
            // handleErrorPopupClose();
            history.push(`/investment-details/edit-application/strategy-details`, {
              id: application?.id,
            });
          }}
        />
      </Box>
      <Footer />
    </Box>
  );
};

export default function ApplicationDetails(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps | null>(null);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const { path } = useRouteMatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [saveDialogbox, setSaveDialogBox] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const [custodianData] = useCustodianData();
  const validationOrConfigChecks = useValidationOrConfigHook();
  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetails(id)
        )) as unknown as ApplicationProps;
        setApplication(_application);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  const {
    editable,
    hasPOA,
    applicationSentBack = false,
    showBankIcon = false,
    bankAccountFormCreated = false,
    ndpmsSectionEdited = false,
  } = application || {};
  const initialCommentValues: Partial<ApplicationProps> = { comment: '' };
  const Commentvalidation = yup.object().shape({
    comment: yup.string().required('Please provide Comment'),
  });
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };
  const onSendBackSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      const { id, status } = application || {};
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
              comment: values.comment,
              applicationSentBack: false,
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const { id, status } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : // : (status === 'draft' || status === 'sent_to_amc_approver') &&
                sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return [
    USER_ROLES.AMC_ADMIN,
    USER_ROLES.RM,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.AMC_ADMIN,
  ].includes(role) ? (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications">
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {dialogOpen && application && (
            <ApplicationTimeline
              id={id}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              consentDetails={getConsentDetails(application.applicants)}
              application={application}
            />
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {checkNdpmsFlow && showBankIcon && (
              <>
                <Tooltip title={'Add Bank'}>
                  <IconButton>
                    <AccountBalanceIcon
                      fontSize="inherit"
                      sx={{ color: 'primary.main', width: 'inherit', fontSize: '48px' }}
                      onClick={() => history.push(`/application/bank-details-ndpsm`, { id })}
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {editable && (
              <IconButton>
                <CardMedia
                  component="img"
                  src="/images/edit-icon-outlined.svg"
                  alt="Edit Icon"
                  sx={{ width: 'unset' }}
                  onClick={() => history.push(`/application`, { id })}
                />
              </IconButton>
            )}
            <IconButton>
              <HistoryIcon
                fontSize="inherit"
                sx={{ color: 'primary.main', width: 'inherit', fontSize: '48px' }}
                onClick={() => {
                  setDialogOpen(true);
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <ApplicationDetailsCommonLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {/* {!showPhotoCaptureButton && (
          <Box sx={{ textAlign: 'center' }}>
            <LoadingButton
              loadingPosition="start"
              // type="submit"
              loading={sendPhotoCaptureLinkLoader}
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
                pl: sendPhotoCaptureLinkLoader ? 5 : 1.2,
              }}
              onClick={() => sendLinkForPhotoCaptureValidation()}>
              Proceed
            </LoadingButton>
          </Box>
        )} */}

        {/* {path === '/application-preview/:id' && showPhotoCaptureButton && editable && ( */}
        {
          // showPhotoCaptureButton &&
          editable &&
            checkSendToCheckerNDPMS(
              showBankIcon,
              bankAccountFormCreated,
              checkNdpmsFlow,
              applyRoleBasedStatus(role)
            ) &&
            (path === '/application-preview/:id' ||
              APPLICATION_LISTING_STATUS[application?.status || ''] ===
                APPLICATION_LISTING_STATUS.draft) && (
              // APPLICATION_LISTING_STATUS[application?.status || ''] ===
              //   APPLICATION_LISTING_STATUS.draft
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  sx={{
                    color: 'common.white',
                    minWidth: '200px',
                    mt: 4,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    fontSize: '16px',
                  }}
                  onClick={async () => {
                    try {
                      setIsSubmitting(true);
                      const nationalitiesMdmsMasters = (await dispatch(
                        getNationalityList()
                      )) as unknown as nationaliyType;
                      const strategiesResponse = (await getAllStrategiesByDistributorType(
                        dispatch,
                        application as ApplicationProps
                      )) as unknown as GetStrategiesResponseBody[];
                      const responseRiskProfile = (await dispatch(
                        riskProfileMasterData()
                      )) as unknown as RiskProfileMaster;
                      const response = (await dispatch(getDocuments())) as unknown as Document;
                      await isFormValidForSubmission(
                        application,
                        true,
                        true,
                        nationalitiesMdmsMasters,
                        custodianData,
                        validationOrConfigChecks,
                        response,
                        role,
                        responseRiskProfile.riskProfile,
                        strategiesResponse,
                        referenceDetail
                      );
                      setIsSubmitting(false);
                      setDialog(true);
                    } catch (e) {
                      setIsSubmitting(false);
                      typeof e === 'string' && dispatch(showError(e));
                      console.error((e as Error).message);
                    }
                  }}
                  disabled={isSubmitting || sendBackLoading}>
                  {`${
                    application?.hasPOA
                      ? 'Send to POA Approver'
                      : sendApplication_Nri(application) ||
                        (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                      ? 'Send to Checker'
                      : 'Send to Investor'
                  } ${isSubmitting || sendBackLoading ? '...' : ''}`}
                </Button>
              </Box>
            )
        }
        {showSaveButton(
          ndpmsSectionEdited,
          showBankIcon,
          bankAccountFormCreated,
          role,
          checkNdpmsFlow
        ) && (
          <Box sx={{ textAlign: 'center' }}>
            <LoadingButton
              loading={saveButtonLoading}
              loadingPosition="start"
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
              }}
              onClick={async () => {
                try {
                  setSaveButtonLoading(true);
                  const nationalitiesMdmsMasters = (await dispatch(
                    getNationalityList()
                  )) as unknown as nationaliyType;
                  const strategiesResponse = (await getAllStrategiesByDistributorType(
                    dispatch,
                    application as ApplicationProps
                  )) as unknown as GetStrategiesResponseBody[];
                  const responseRiskProfile = (await dispatch(
                    riskProfileMasterData()
                  )) as unknown as RiskProfileMaster;
                  const response = (await dispatch(getDocuments())) as unknown as Document;
                  await isFormValidForSubmission(
                    application,
                    true,
                    true,
                    nationalitiesMdmsMasters,
                    custodianData,
                    validationOrConfigChecks,
                    response,
                    role,
                    responseRiskProfile.riskProfile,
                    strategiesResponse,
                    referenceDetail
                  );
                  setSaveButtonLoading(false);
                  setSaveDialogBox(true);
                } catch (e) {
                  setSaveButtonLoading(false);
                  typeof e === 'string' && dispatch(showError(e));
                  console.error((e as Error).message);
                }
              }}>
              Save
            </LoadingButton>
          </Box>
        )}
        <ConfirmationDialog
          message={`Are you sure you want to save and generate the bank account opening form?`}
          open={saveDialogbox}
          setOpen={() => setSaveDialogBox(false)}
          onSave={async () => {
            try {
              setSaveButtonLoading(true);
              setSaveDialogBox(false);
              await dispatch(createBankAccountFormNDPSM(application?.id || ''));
              history.push('/applications');
              setSaveButtonLoading(false);
            } catch (e) {
              setSaveButtonLoading(false);
              typeof e === 'string' && dispatch(showError(e));
              console.error((e as Error).message);
            }
          }}
          onCancel={() => setSaveDialogBox(false)}
        />
        <Dialog
          onClose={handleSendBackClose}
          open={openSendBackDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleSendBackClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main', mt: 2 }}>
              Give Comments for application Send Back *
            </Typography>
            <Formik
              initialValues={initialCommentValues}
              onSubmit={onSendBackSubmit}
              validationSchema={Commentvalidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="comment"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleSendBackClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}
                      disabled={sendBackLoading}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={sendBackLoading}
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </LoadingButton>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <ConfirmationDialog
          message={`Are you sure you want to send it to ${
            application?.hasPOA
              ? 'POA Approver'
              : sendApplication_Nri(application) ||
                (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
              ? 'Checker'
              : 'Investor'
          }`}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            (sendApplication_Nri(application) ||
              (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))) &&
            applicationSentBack
              ? handleSendBack()
              : handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  ) : (
    <PoaOrNIApplicationDetails loading={loading} application={application as ApplicationProps} />
  );
}
