import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import { getValidationOrConfigChecksMaster } from '../redux-store/actions';
import { ValidationOrConfigMaster } from '../redux-store/types/mdms';

export const useValidationOrConfigHook = (): ValidationOrConfigMaster => {
  const dispatch = useDispatch();
  const { validationOrConfigChecks } = useSelector(
    (state: RootStateType) => state.validationOrConfigReducer
  );
  useEffect(() => {
    if (Object.keys(validationOrConfigChecks).length === 0) {
      dispatch(getValidationOrConfigChecksMaster());
    }
  }, [validationOrConfigChecks]);
  return validationOrConfigChecks;
};
