import { ApplicationProps } from './api-types';

export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';

export const CREATE_NDPSM_BANKFORM_SUCCESS = 'CREATE_NDPSM_BANKFORM_SUCCESS';
export const CREATE_NDPSM_BANKFORM = 'CREATE_NDPSM_BANKFORM';

export const GET_ONBOARDING_SUMMARY = 'GET_ONBOARDING_SUMMARY';
export const GET_ONBOARDING_SUMMARY_SUCCESS = 'GET_ONBOARDING_SUMMARY_SUCCESS';

export const GET_Kyc_DASHBOARD_DETAILS = 'GET_Kyc_DASHBOARD_DETAILS';
export const GET_Kyc_DASHBOARD_DETAILS_SUCCESS = 'GET_Kyc_DASHBOARD_DETAILS_SUCCESS';

export const GET_MONTHWISE_ONBOARDING_SUMMARY = 'GET_MONTHWISE_ONBOARDING_SUMMARY';
export const GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS = 'GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS';

export const GET_DISTRIBUTOR_COMMITMENTAMOUNT = 'GET_DISTRIBUTOR_COMMITMENTAMOUNT';
export const GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS = 'GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS';

export const GET_MONTHWISE_COMMITMENTAMOUNT = 'GET_MONTHWISE_COMMITMENTAMOUNT';
export const GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS = 'GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS';

export const GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS =
  'GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS';
export const GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS =
  'GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS';

export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION = 'GET_APPLICATION';

export const GET_TIMELINE_SUCCESS = 'GET_TIMELINE_SUCCESS';
export const GET_TIMELINE = 'GET_TIMELINE';

export const GET_STAMPPAPER_DETAILS = 'GET_STAMPPAPER_DETAILS';
export const GET_STAMPPAPER_DETAILS_SUCCESS = 'GET_STAMPPAPER_DETAILS_SUCCESS';

export const GET_ALL_APPLICATION = 'GET_ALL_APPLICATION';
export const GET_ALL_APPLICATION_SUCCESS = 'GET_ALL_APPLICATION_SUCCESS';

export const SAVE_DISTRIBUTOR_APPLICATION = 'SAVE_DISTRIBUTOR_APPLICATION';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';

export const ADD_APPLICANT_SUCCESS = 'ADD_APPLICANT_SUCCESS';
export const ADD_APPLICANT = 'ADD_APPLICANT';

export const TYPED_DECLARATION_SUCCESS = 'TYPED_DECLARATION_SUCCESS';
export const TYPED_DECLARATION = 'TYPED_DECLARATION';

export const AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS =
  'AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS';
export const AUTHORIZED_SIGNATORIES_TYPED_DECLARATION = 'AUTHORIZED_SIGNATORIES_TYPED_DECLARATION';

export type CreateApplicationRequestBody = {
  applicantVerificationToken: string;
};

export type AddJointHolderRequestBody = CreateApplicationRequestBody;

export type GetAllApplicantsRequestBody = {
  limit: number;
  page: number;
  sort: string;
  order: string;
  search: string;
  applicationType: string;
  status: string;
  modeOfHolding: string;
  distributorId: string;
  applicationSentBack: boolean | null;
  kycModified: string;
  custodian: string;
};

export type GetAllApplicantionsResponseBody = {
  applications: ApplicationProps[];
  limit: number;
  page: number;
  pages: number;
  total: number;
  applicationType: string;
  status: string;
  modeOfHolding: string;
  distributorId: string;
  kycModified: string;
  custodian: string;
};

export const PENNY_DROP_VERIFICATION_SUCCESS = 'PENNY_DROP_VERIFICATION_SUCCESS';
export const PENNY_DROP_VERIFICATION = 'PENNY_DROP_VERIFICATION';

export const GET_Documents = 'GET_Documents';
export const GET_Documents_SUCCESS = 'GET_Documents_SUCCESS';

export const GET_Ubo = 'GET_Ubo';
export const GET_Ubo_SUCCESS = 'GET_Ubo_SUCCESS';

export const UBO_LISTING = 'UBO_LISTING';
export const UBO_LISTING_SUCCESS = 'UBO_LISTING_SUCCESS';

export const GET_Fatca = 'GET_Fatca';
export const GET_Fatca_SUCCESS = 'GET_Fatca_SUCCESS';
