export const salutationsMasters: string[] = ['Mr.', 'Ms.', 'M/S.', 'Mrs.'];
export const applicantStatusMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  Individual: 'Individual (Resident)',
  NRI: 'Individual (NRI)',
};
export const nationalitiesMasters: string[] = ['Indian', 'Others'];

export const fatherOrSpouseSelectMasters: string[] = ['Father', 'Spouse'];

//SAVE_LATER_VALIDATION_CHECK if false then by-passing mandatory validation on click of save later/ save for later
export const SAVE_LATER_VALIDATION_CHECK = false;
export const maritalStatusMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  Single: 'Single',
  Married: 'Married',
  Others: 'Others',
};

export const educationQualificationMasters: string[] = [
  'Graduate',
  'Post Graduate',
  'Under Graduate',
  'PHD',
];

export const CustodianMaster: { [key: string]: string } = {
  //Inform BE, if anything modified
  // ICICI: 'ICICI',
  // HDFC: 'HDFC',
  // Edelweiss: 'Edelweiss',
  // Orbis: 'Orbis',
  AXIS: 'Axis',
  // Kotak: 'Kotak',
};

export const NDPSMBankMaster: { [key: string]: string } = {
  IndusInd_Bank: 'IndusInd Bank',
  None: 'None',
};

export const modeOfHoldingRadioMaster = [
  { label: 'Jointly', value: 'joint' },
  { label: 'Either or Survivor', value: 'either or survivor' },
  { label: 'Anyone or Survivor', value: 'anyone or survior' },
  { label: 'Former or Survivor', value: 'former or survior' },
];

export const occupationDetailsMasters: string[] = [
  'PVT.SECTOR SERVICE',
  'PUBLIC SECTOR',
  'GOVT.SERVICE',
  'HOUSEWIFE',
  'DEFENCE',
  'PROFESSIONAL',
  'RETIRED',
  'BUSINESS',
  'AGRICULTURE',
  'STUDENT',
  'FOREX DEALER',
  'OTHERS',
];

export const SourceOfFundMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  salary: 'Salary',
  business: 'Business',
  investment: 'Investment',
  gift: 'Gift',
  professional: 'Professional',
  Others: 'Others',
};

export const residenceMasters: { [key: string]: string } = {
  self_owned: 'Self/Family owned',
  rented: 'Rented',
  company_provided: 'Company provided',
};

export const MonthlyIncomeMasters: string[] = [
  'Upto Rs. 25,000',
  'Rs. 25,000 to Rs. 50,000',
  'Rs. 50,000 to Rs. 1 lac',
  'Rs. 1 lac to Rs. 2.99 lac',
  'Rs. 3 lac to Rs. 4.99 lac',
  'Rs. 5 lac to Rs. 9.99 lac',
  'Rs. 10 lac to Rs. 25 lac',
  'Rs. 25 lac and above',
];

export const occupationDetailsMastersForNDPMS: string[] = [
  'Salaried',
  'Self Employed',
  'Self Employed Professional',
  'Retired',
  'Housewife',
  'Student',
  'Farmer',
  'OTHERS',
];

export const grossAnnualMasters = [
  { min: 0, max: 100000, label: 'BELOW 1 LAC' },
  { min: 100000, max: 500000, label: '1-5 LAC' },
  { min: 500000, max: 1000000, label: '5-10 LACS' },
  { min: 1000000, max: 2500000, label: '10-25 LACS' },
  { min: 2500000, max: 10000000, label: '25 LACS - 1 CRORE' },
  { min: 10000000, label: 'GREATER THAN OR EQUAL TO 1 CRORE' },
];

export const PEPsMasters: string[] = ['I am PEP', 'I am Related to PEP', 'Not Applicable'];

export const CompanyMasters: string[] = ['Company', 'Sector', 'Industry'];

export const addressTypesMasters: string[] = [
  'Resident/Business',
  'Resident',
  'Business',
  'Registered Office',
  'Unspecified',
];

export const addressForTaxsMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  communication: 'Communication Address',
  permanent: 'Permanent Address',
  others: 'Please note the address below',
};

export const genderMasters: string[] = ['Male', 'Female', 'Others'];

export const IdentityProofsMasters: string[] = [
  'Photo PAN Card',
  'Passport',
  "Voter's ID",
  'Driving License',
  'Aadhar Card',
];

export const AddressProofsMasters: string[] = [
  'Passport',
  "Voter's ID",
  'Ration Card',
  'Electricity Bill',
  'Landline/Telephone Bill',
  'Gas Bill',
  'Latest Bank Statement/Passbook',
  'Latest Depository Account Statement',
];

export const BankAccountProofsMasters: string[] = [
  'Bank Statement with IFSC printed on it',
  'Cancelled cheque leaf verified',
  'Letter from the bank giving account details',
  'Passbook (showing bank account details)',
];

export const DrawDownMasters: number[] = [1, 2, 3, 4];

export const filterApplicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  // { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  // { key: 'nri_poa', value: 'Individual (NRI-POA)' },
];

export const ModeOfHoldingTypes = [
  { key: 'single', value: 'Single' },
  { key: 'joint', value: 'Joint' },
  { key: 'either or survivor', value: 'Either or Survivor' },
  { key: 'anyone or survior', value: 'Anyone or Survivor' },
  { key: 'former or survior', value: 'Former or Survivor' },
];

export const IMAGE_UPLOAD_FORMATS = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];

export const INVESTOR_IMAGE_UPLOAD_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};

export const SUPPORT_STATUS: { [key: string]: string } = {
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_fundmanager: 'Approved by investment manager',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};

export const POA_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const ESIGN_STATUS: { [key: string]: string } = {
  NOT_GENERATED: 'notgenerated',
  PENDING_SIGNATURE: 'pendingsignature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
};

export enum USER_ROLES {
  SUPER_ADMIN = 'superadmin',
  AMC_ADMIN = 'amcadmin',
  RM = 'rm',
  DISTRIBUTOR = 'distributor',
  INVESTOR = 'investor',
  FUND_MANAGER = 'fundmanager',
  TRUSTEE = 'trustee',
  WITNESS = 'witness',
  APPLICATION = 'application',
  SUBDISTRIBUTOR = 'subdistributor',
  POAAPPROVER = 'poaapprover',
  AMCAPPROVER = 'amcapprover',
  AUTHORISEDSIGNATORY = 'authorisedsignatory',
  AMCAUTHORISEDSIGNATORY = 'amcauthorisedsignatory',
  GROUPSIGNATORY = 'groupsignatory',
}

export const ApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } = {
  [USER_ROLES.POAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_poa_approver: 'Pending',
  },
  [USER_ROLES.AMCAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_amc_approver: 'Pending',
  },
};

export const Relations = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Spouse',
  'Brother',
  'Sister',
  'Others',
];

export const NomineeRelations = [
  'MOTHER',
  'FATHER',
  'DAUGHTER',
  'SON',
  'SPOUSE',
  'BROTHER',
  'SISTER',
  'OTHERS',
];

export const adminSupportMaster: { [key: string]: string } = {
  unlock_user: 'Unlock User',
  check_whitelisted: 'Check Whitelisted',
  whitelist_user: 'Whitelist User',
  unwhitelist_user: 'Unwhitelist User',
  change_expired_status: 'Change Expired Status',
  change_status_to_draft: 'Change Status to draft',
};

export const IS_DOB_APPLICABLE_FOR_AMC = true;

export const REJECTED_ROLES: { [key: string]: string } = {
  poaapprover: 'POA Approver',
  amcapprover: 'Checker',
};

export const investorTypeMasters: { [key: string]: string } = {
  corporate: 'Corporate',
  trust: 'Trust',
  partnership_firm: 'Partnership Firm',
  huf: 'HUF',
  govt_bodies: 'Army/ Government Bodies',
  registered_society: 'Registered Society',
  fii_fpi: 'FII/FPI',
  bank: 'Bank',
  institutional_investor: 'Institutional Investor',
  unincorporated_or_body_of_individuals: 'Unincorporated association or a body of individuals',
  foreign_entity: 'Foreign Entity',
};
export const IS_PENNYDROP_APPLICABLE_FOR_AMC = true;

export const getGroupNameMaster = ['Group-A', 'Group-B', 'Group-C'];

export const NON_INDIVIDUAL_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const CpUboCodesMaster: { [key: string]: string } = {
  C01: '- CP of legal person-ownership',
  C02: '- CP of legal person-other means',
  C03: '- CP of legal person-senior managing official',
  C04: '- CP of legal arrangement-trust-settlor',
  C05: '- CP of legal arrangement-trust-trustee',
  C06: '- CP of legal arrangement-trust-protector',
  C07: '- CP of legal arrangement-trust-beneficiary',
  C08: '- CP of legal arrangement-trust-other',
  C09: '- CP of legal arrangement-trust-other-settlor-equivalent',
  C10: '- CP of legal arrangement-trust-other-trustee-equivalent',
  C11: '- CP of legal arrangement-trust-other-protector-equivalent',
  C12: '- CP of legal arrangement-trust-other-beneficiary-equivalent',
  C13: '- CP of legal arrangement-trust-other-other-equivalent',
  C14: '- Unknown',
};
export const controllingPersonsNationalitiesMasters: string[] = ['Indian', 'Others'];

//Application types
export enum APPLICATION_TYPE {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
}

export const AMC_APPROVER_CHECK_FOR_INDIVIDUAL = true;

export const ENABLE_EMAIL_OTP = true;

export const APPLICATION_STATUS_AMCAPPROVER_individual: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};
export const IndividualApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } =
  {
    [USER_ROLES.AMCAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
    },
  };

export const APPLICATION_LISTING_STATUS_TIMELINE: { [key: string]: string } = {
  application_created: 'Application Created',
  draft: 'Draft',
  'draft(send back)': 'Draft (Send Back)',
  'draft(moved to draft)': 'Draft(Moved to Draft)',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};

export const ROLE_TIMELINE: { [key: string]: string } = {
  superadmin: 'Super admin',
  amcadmin: 'amc admin',
  rm: 'rm',
  distributor: 'distributor',
  investor: 'investor',
  fundmanager: 'Fundmanager',
  trustee: 'Trustee',
  witness: 'Witness',
  application: 'application',
  subdistributor: 'sub distributor',
  poaapprover: 'poa approver',
  amcapprover: 'Checker',
  authorisedsignatory: 'authorized signatory',
  amcauthorisedsignatory: 'amc authorized signatory',
  groupsignatory: 'authorized signatory',
};

export const APPLICATION_LISTING_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};

export const AMC_AUTHORISED_SIGNATORY_STATUS = [
  'approved_by_applicant1',
  'approved_by_applicant2',
  'approved_by_applicant3',
  'approved_by_authorised_signatories',
];

export enum APPLICATION_TYPE_FOR_DOCUMENTS {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
  INDIVIDUAL_POA = 'individual_poa',
  NRI = 'nri',
  NRI_POA = 'nri_poa',
  NOMINEE_DOC = 'nominee',
  NDPSM_DOC = 'individual_ndpms',
  NOMINEE_GUARDIAN = 'nomineeGuardian',
  HDFC = 'Custodian_HDFC',
  AXIS = 'Custodian_AXIS',
  Kotak = 'Custodian_Kotak',
}
export const BankAccountTypeMaster: { [key: string]: string } = {
  Current: 'CURRENT',
  Savings: 'SAVING',
};
export const NRIBankAccountTypeMaster: { [key: string]: string } = {
  NRE: 'NRE',
  NRO: 'NRO',
};
export const DistributorTypesForUserManageMent: { [key: string]: string } = {
  Individual: 'individual',
  Company: 'company',
};

export const ENABLE_KRA_DOCUMENT_FETCH = true;

export const SALT = 'F2lNZWFxUH';
export const SALT2 = 'WTVPJel6dTGVocF';

export const ONBOARDING_OPTIONS: { [key: string]: string } = {
  otp: 'Verify OTP',
  email_consent: 'Email Consent',
  skip_otp: 'Skip Consent',
};

export const ACCOUNT_TYPE_OPTIONS: { [key: string]: string } = {
  discretionary: 'Discretionary',
  non_discretionary: 'Non discretionary',
};
export const STRATEGY_TYPE_OPTIONS: { [key: string]: string } = {
  equity: 'Equity',
  debt: 'Debt',
  hybrid: 'Hybrid',
  multi_asset: 'Multi Asset',
};
export const EmailConsentStatusMaster: { [key: string]: string } = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
  revoked: 'Revoked',
};

export const ENABLE_Email_Consent = true;

export const BloodRelations = ['Father', 'Mother', 'Son', 'Daughter', 'Spouse'];

export const FundInvestStockMarket: { [key: string]: string } = {
  own: 'Own Funds',
  borrowed: 'Borrowed funds',
};

export const ModeOfTransaction: { [key: string]: string } = {
  cheque: 'Cheque',
  rtgs: 'Online Transfer (RTGS)',
};

export const YesNoMaster: { [key: string]: string } = {
  //Inform BE, if anything modified
  yes: 'Yes',
  no: 'No',
};
export const DLCLMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  cdsl: 'CDSL',
  nsdl: 'NSDL',
};

export const AdditionalDLCLMasters: { [key: string]: string } = {
  //Inform BE, if anything modified
  IndividualDirectorsRelative: 'Individual Directors Relative',
  IndividualMarginTradingACMANTRA: 'Individual Margin Trading A/C (MANTRA)',
  IndividualDirector: 'Individual Director',
  Minor: 'Minor',
  HUF: 'HUF',
};
export const PHOTOCAPTURE_STATUS: { [key: string]: string } = {
  pending: 'Pending',
  received: 'Completed',
};

export const strategyFeeTypeMaster: { [key: string]: string } = {
  flatOnAum: 'Flat on AUM',
  flat: 'Flat',
  none: 'none',
};

export const strategyPerformanceFeeTypeMaster: { [key: string]: string } = {
  with_catchup: 'With Catchup on Gains',
  without_catchup: 'Without Catchup on Gains',
  none: 'none',
};

export const CHECKER_EDIT_ALL_SECTIONS = true;

export const SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX = true;

export const COMMENT_ROLES: { [key: string]: string } = {
  rm: 'RM',
  distributor: 'Distributor',
  subdistributor: 'Sub Distributor',
  amcapprover: 'Checker',
};

export const commSentToMaster: { [key: string]: string } = {
  //Inform BE, if anything modified
  firstHolder: 'First Holder',
  allJointAccountHolders: 'All joint account holders',
};

export const custodiansWithMaster = ['ICICI', 'HDFC']; //add custodians here when new custodians with Masters are added

export const APPLICANT_STATUS: { [key: string]: string } = {
  INDIVIDUAL: 'Individual',
  NON_INDIVIDUAL: 'Non-Individual',
  NRI: 'NRI',
};

export const ContactRelations: { [key: string]: string } = {
  //Inform BE, if anything modified
  self: 'Self',
  spouse: 'Spouse',
  dependent_children: 'Dependent Children',
  dependent_parents: 'Dependent Parents',
  authorised_representative: 'Authorised Representative',
};

export const ICICIContactRelations: { [key: string]: string } = {
  //Inform BE, if anything modified
  //same is using for Kotak custodian
  self: 'Self',
  spouse: 'Spouse',
  dependent_children: 'Dependent Children',
  dependent_parents: 'Dependent Parents',
};

export const ContactRelationsForHDFC: { [key: string]: string } = {
  //Inform BE, if anything modified
  //same is using for Axis custodian
  self: 'Self',
  spouse: 'Spouse',
  dependent_children: 'Dependent Children',
  dependent_parents: 'Dependent Parents',
};

export const BooleanYesNoMaster = [
  { key: 'true', value: 'Yes' },
  { key: 'false', value: 'No' },
];

export const APPLICANT_CVL_FORM_SIGNING_STATUS: { [key: string]: string } = {
  invitationexpired: 'Invitation Expired',
  signed: 'Signed',
  completed: 'Completed',
  pending: 'Pending',
};

export const CVL_MODIFICATION_STATUS: { [key: string]: string } = {
  failed: 'Failed',
  underprocess: 'Under Process',
  registered: 'Registered',
  hold: 'Hold',
  rejected: 'Rejected',
  notavailable: 'Not Available',
  deactivated: 'Deactivated',
  notcheckedwithrespectivekra: 'Not Checked Withrespective KRA',
};
export const sourceForFundOptions: { [key: string]: string } = {
  //Inform BE, if anything modified
  salary: 'Salary',
  houseProperty: 'House Property',
  capitalGainsBusiness: 'Capital Gains Business',
  Others: 'Others',
};

export const sourceOfWealthMaster: { [key: string]: string } = {
  //Inform BE, if anything modified
  savings: 'Savings',
  incomeFromEmployement: 'Income From Employement',
  propertySale: 'Property Sale',
  saleOfInvestments: 'Sale Of Investments',
  inheritance: 'Inheritance',
  Others: 'Others',
};

export const lineOfBusinessMaster: { [key: string]: string } = {
  //Inform BE, if anything modified
  broker: 'Broker',
  mfg: 'Mfg',
  realEstate: 'Real Estate',
  trader: 'Trader',
  bullion: 'Bullion',
  stock: 'Stock',
  agri: 'Agri',
  Others: 'Others',
};

export const Invalid_Date = 'Invalid Date';

export const Edit_KYC = true; // true means allowing the investor to updatae kyc information, make it to false for disabling this feature

export const CalendarMaxYearLimit = 1900;

export const distributorMaster: { [key: string]: string } = {
  Direct: 'Direct',
  Distributor: 'Distributor',
};

export const FeeDetailTypeMasters: { [key: string]: string } = {
  Fixed: 'Fixed',
  Variable: 'Variable',
};

export const feeDetailsBrokerageAndTax = 'As Applicable at Actuals';

export const docInvalidCheck =
  'Please upload a file that meets the size and format requirements, and ensure it is not password protected.';

export const APMIFlow = true; // this flow contains consent submit & reject options for joint holders, allowing investor to update Risk profile, disclosure of interest and disclosure of exclusion for their investment options, even though it is filled by maker
