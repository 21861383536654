import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { Location } from 'history';
import { KeyValue } from '../ApplicationDetails/contributor';
import { getStrategyById } from '../../redux-store/actions/strategies';
import { accessType } from '../../redux-store/types/funds';
import { fundAccess } from '../../redux-store/actions/funds';
import { AdminStrategyDetailsType } from '../../redux-store/types/api-types';
import {
  ACCOUNT_TYPE_OPTIONS,
  distributorMaster,
  FeeDetailTypeMasters,
  STRATEGY_TYPE_OPTIONS,
} from '../../utils/constant';
import { checkVariableFee } from './addStrategy';

export default function StrategyDetails({
  location,
}: {
  location: Location<{ StrategyId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { StrategyId } = location.state || {};
  const [strategyDetails, setStrategyDetails] = useState<AdminStrategyDetailsType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (StrategyId) {
          const res = (await dispatch(
            getStrategyById(StrategyId)
          )) as unknown as AdminStrategyDetailsType;
          setStrategyDetails({
            ...res,
          });
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { md: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
              // pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('strategy-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Strategy Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('strategy-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Strategy Details'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() => history.push(`edit-strategy`, { StrategyId: StrategyId })}
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-strategy`, { StrategyId: StrategyId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>
          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {strategyDetails && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Strategy Details'}
                </SubHeading>
              </Box>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <Box sx={{ width: '100%' }}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title="Strategy Name" description={strategyDetails?.strategyName} />
                      <KeyValue
                        title="Strategy Type"
                        description={
                          STRATEGY_TYPE_OPTIONS[strategyDetails?.strategyType || ''] || ''
                        }
                      />
                      <KeyValue title="Benchmark" description={strategyDetails?.benchmark} />
                      <KeyValue
                        title={'Direct'}
                        description={strategyDetails?.isApplicableForDirect ? 'Yes' : 'No'}
                      />
                      <Grid item xs={12}>
                        <Item>{`Applicable Distributor`}</Item>
                        {strategyDetails?.distributorDetails &&
                        strategyDetails?.distributorDetails?.distributors?.length > 0 ? (
                          <Box
                            sx={{
                              border: 0.5,

                              borderColor: 'primary.main',
                              p: 3,
                              mb: 3,
                              mt: 2,
                              borderRadius: 0.5,
                            }}>
                            {strategyDetails?.distributorDetails.distributors?.map(
                              (distributor) => {
                                return (
                                  <React.Fragment key={distributor.id}>
                                    <Chip
                                      label={`${distributor.arnCode || 'N/A'} - ${
                                        distributor.name
                                      } (${distributor.type})`}
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: 'rgba(41, 49, 57, 0.7)',
                                        // p: 2,
                                        m: 0.8,
                                      }}
                                    />
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Box>
                        ) : (
                          <ItemDetails>-</ItemDetails>
                        )}
                      </Grid>
                    </Grid>
                  </Gridstyles>
                </Box>
              </Grid>
            </>
          )}
          {(strategyDetails?.strategyFeeDetails || [])?.map((values, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 && (
                  <Box sx={{ mr: { lg: 18, xs: 3 } }}>
                    <SubHeading
                      sx={{
                        color: 'common.blue',
                        letterSpacing: '0.5px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                        ml: { lg: 8, xs: 2 },
                      }}>
                      {'Fee Details'}
                    </SubHeading>
                  </Box>
                )}

                <Grid
                  container
                  sx={{
                    mt: 1,
                    pl: { xs: 2, sm: 10 },
                    pr: { xs: 2, sm: 10 },
                    pt: 1,
                  }}>
                  <Box
                    sx={{
                      width: '100%',
                      bgcolor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
                      borderRadius: '10px',
                      p: { xs: 2, sm: 0 },
                      pt: { sm: 2 },
                    }}>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <KeyValue
                          title={'Account Type'}
                          description={ACCOUNT_TYPE_OPTIONS[values?.applicableFlowType || '']}
                        />
                        <KeyValue
                          title={'Fee Type'}
                          description={FeeDetailTypeMasters[values?.feeType || '']}
                        />
                        <KeyValue
                          title={'Provide Type'}
                          description={distributorMaster[values?.applicableForType || '']}
                        />
                        <KeyValue title={'Management Fees'} description={values?.fee || ''} />
                        {checkVariableFee(values?.feeType || '') && (
                          <>
                            <KeyValue
                              title={'Hurdle Rate'}
                              description={values?.hurdleRate || ''}
                            />
                            <KeyValue
                              title={'Performance Fees'}
                              description={values?.performanceFee || ''}
                            />
                          </>
                        )}
                        <KeyValue title={'Exit Load'} description={values?.exitLoad || ''} />
                        <KeyValue title={'Other Charges'} description={values?.charges || ''} />
                      </Grid>
                    </Gridstyles>
                  </Box>
                </Grid>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
