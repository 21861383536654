import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import {
  InvestorCustodianTypes,
  getCustodianMaster,
  getCustodianConfig,
  CustodianMapping,
} from '../redux-store/actions';
import { custodiansWithMaster } from './constant';

export const useCustodianData = (): [InvestorCustodianTypes, CustodianMapping] => {
  const dispatch = useDispatch();
  const { custodian, custodianConfig } = useSelector(
    (state: RootStateType) => state.custodianReducer
  );
  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';
  useEffect(() => {
    if (Object.keys(custodianConfig).length === 0) {
      dispatch(getCustodianConfig());
    } else if (
      selectedCustodian &&
      custodiansWithMaster.includes(selectedCustodian) &&
      !custodian[selectedCustodian]
    ) {
      dispatch(getCustodianMaster(custodianConfig[selectedCustodian], selectedCustodian));
    }
  }, [selectedCustodian, custodianConfig]);
  return [custodian[selectedCustodian], custodianConfig];
};
