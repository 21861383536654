// eslint-disable-next-line
import { Element } from 'chart.js';
import { isArray, toPadding, toFont, renderText } from 'chart.js/helpers';

type PaddingTypeObj = {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
};

export default class CenterTitle extends Element {
  chart: any;
  ctx: any;
  _padding: undefined | PaddingTypeObj;
  top: undefined | number;
  bottom: undefined | number;
  left: undefined | number;
  right: undefined | number;
  width: undefined | number;
  height: undefined | number;
  position: undefined;
  weight: undefined;
  fullSize: undefined;
  _options: any;
  constructor(config: { chart: any; options: any; ctx: any }) {
    super();
    this.chart = config.chart;
    this.ctx = config.ctx;
    this._options = config.options;
    this._padding = undefined;
    this.top = undefined;
    this.bottom = undefined;
    this.left = undefined;
    this.right = undefined;
    this.width = undefined;
    this.height = undefined;
    this.position = undefined;
    this.weight = undefined;
    this.fullSize = undefined;
  }
  update(maxWidth: number | undefined, maxHeight: number | undefined) {
    const opts = this._options;
    this.left = 0;
    this.top = 0;
    if (!opts.display) {
      this.width = this.height = this.right = this.bottom = 0;
      return;
    }
    this.width = this.right = maxWidth;
    this.height = this.bottom = maxHeight;
    const lineCount = isArray(opts.text) ? opts.text.length : 1;
    this._padding = toPadding(opts.padding);
    const textSize = lineCount * Number(toFont(opts.font).lineHeight) + this._padding.height;
    this.height = textSize;
  }
  isHorizontal() {
    return true;
  }
  draw() {
    const ctx = this.ctx;
    const opts = this._options;
    if (!opts.display) {
      return;
    }
    const fontOpts = toFont(opts.font);
    const { top, left, bottom, right } = this.chart.chartArea;
    const maxWidth = opts.width ? opts.width : right - left;
    const titleX = (left + right) / 2;
    const lineCount = isArray(opts.text) ? opts.text.length : 1;
    const textSize = lineCount * Number(toFont(opts.font).lineHeight);
    const titleY = (top + (bottom - textSize / 2)) / 2;
    renderText(ctx, opts.text, 0, 0, fontOpts, {
      color: opts.color,
      maxWidth,
      rotation: 0,
      textAlign: 'center',
      textBaseline: 'middle',
      translation: [titleX, titleY],
    });
  }
}
