/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Typography } from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useEffect, useState } from 'react';
import { ApplicationProps, LimitOfConsents } from '../../redux-store/types/api-types';
import { DataTable } from '../DataTable';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../redux-store/actions/auth';
import { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { relatedPartyConsentSchema } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  getDisableStatusForjointHolder,
  getStep,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import { useHistory, useParams } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { useSnackbar } from 'notistack';
import { updateApplication } from '../../redux-store/actions/application';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import { refLoginCheck } from '../Investments/investor-application';
import FieldValidationNote from './FieldValidationNote';

export const relatedDataArr: any = [
  {
    securityType: 'Equity',
    singleAssociateLimit: 15,
    multipleAssociateLimit: 25,
  },
  {
    securityType: 'Debt and hybrid Securities',
    singleAssociateLimit: 15,
    multipleAssociateLimit: 25,
  },
  {
    securityType: 'Equity + Debt + Hybrid Securities',
    singleAssociateLimit: 15,
  },
];

export const relatedDataArrForDissent: any = [
  {
    securityType: 'Equity',
    singleAssociateLimit: 0,
    multipleAssociateLimit: 0,
  },
  {
    securityType: 'Debt and hybrid Securities',
    singleAssociateLimit: 0,
    multipleAssociateLimit: 0,
  },
  {
    securityType: 'Equity + Debt + Hybrid Securities',
    singleAssociateLimit: 0,
  },
];

type Values = {
  relatedPartyLimit?: string;
  relatedpartyconsents: LimitOfConsents[];
  relatedPartyWaiver?: string;
  saveType: string;
};

const initialValues: Values = {
  relatedPartyLimit: 'consent',
  relatedpartyconsents: [],
  relatedPartyWaiver: 'consent',
  saveType: 'save and proceed',
};

const headers = (amcuserAsInvestor: boolean) => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  return [
    {
      header: 'Security',
      label: 'Security',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        const ConsentEmailApplicationStatus = params.securityType;
        return (
          <Typography
            // className={'ConsentEmailApplicationStatus'}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 14,
            }}>
            {ConsentEmailApplicationStatus}
          </Typography>
        );
      },
    },
    {
      header: 'Limit  for  investment in single associate/related party (as % of client’s AUM)',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        return (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <MFTextField
                name={`relatedpartyconsents.${index}.singleAssociateLimit`}
                placeholder=""
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                )}
              />
              <Typography sx={{ ml: 1 }}>%</Typography>
            </Grid>
          </>
        );
      },
    },
    {
      header: `Limit  for  investment  across multiple associates/related parties (as % of 
          client’s AUM)`,
      isVisibleInMobileView: (row: any) => row.securityType !== 'Equity + Debt + Hybrid Securities',
      renderEditDeleteCell: (params: any, index: number): JSX.Element => {
        const securityTypeCombinationCheck = params.securityType;
        return (
          <>
            {securityTypeCombinationCheck !== 'Equity + Debt + Hybrid Securities' && (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <MFTextField
                  name={`relatedpartyconsents.${index}.multipleAssociateLimit`}
                  placeholder=""
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={getDisableStatusForjointHolder(
                    role,
                    referenceDetail.applicant_type,
                    amcuserAsInvestor
                  )}
                />
                <Typography sx={{ ml: 1 }}>%</Typography>
              </Grid>
            )}
          </>
        );
      },
    },
  ];
};

export const headersDissent = [
  {
    header: 'Security',
    label: 'Security',
    renderEditDeleteCell: (params: any, index: number): JSX.Element => {
      const ConsentEmailApplicationStatus = params.securityType;
      return (
        <Typography
          // className={'ConsentEmailApplicationStatus'}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
          }}>
          {ConsentEmailApplicationStatus}
        </Typography>
      );
    },
  },
  {
    header: 'Limit  for  investment in single associate/related party (as % of client’s AUM)',
    renderEditDeleteCell: (params: any, index: number): JSX.Element => {
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <MFTextField
              name={`relatedpartyconsents.${index}.singlesAssociateLimitDissent`}
              placeholder=""
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              disabled={true}
              value={0}
              trimOnBlur={false}
            />
            <Typography sx={{ ml: 1 }}>%</Typography>
          </Grid>
        </>
      );
    },
  },
  {
    header: `Limit  for  investment  across multiple associates/related parties (as % of 
        client’s AUM)`,
    isVisibleInMobileView: (row: any) => row.securityType !== 'Equity + Debt + Hybrid Securities',
    renderEditDeleteCell: (params: any, index: number): JSX.Element => {
      const securityTypeCombinationCheck = params.securityType;
      return (
        <>
          {securityTypeCombinationCheck !== 'Equity + Debt + Hybrid Securities' && (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <MFTextField
                name={`relatedpartyconsents.${index}.multipleAsssociateLimitDissent`}
                placeholder=""
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                value={0}
                disabled={true}
                trimOnBlur={false}
              />
              <Typography sx={{ ml: 1 }}>%</Typography>
            </Grid>
          )}
        </>
      );
    },
  },
];

export default function RelatedPartyConsent(): JSX.Element {
  const [relatedPartyConsentData, setRelatedPartyConsentData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '', id: authId } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { relatedpartyconsents = [], relatedPartyLimit, relatedPartyWaiver } = application || {};

    setRelatedPartyConsentData({
      ...relatedPartyConsentData,
      relatedpartyconsents: relatedpartyconsents.length
        ? relatedpartyconsents?.sort(
            (consent1, consent2) => Number(consent1.id) - Number(consent2.id)
          )
        : relatedDataArr,
      relatedPartyLimit: relatedPartyLimit || '',
      relatedPartyWaiver: relatedPartyWaiver || '',
    });
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
        applicationType,
        relatedpartyconsents: _relatedpartyconsents = [],
      } = application || {};
      const { relatedpartyconsents, relatedPartyLimit, relatedPartyWaiver, saveType } = values;
      const shouldSaveForLater = shouldValidateUponSaveLater(values.saveType);
      if (shouldSaveForLater && (!relatedPartyLimit || !relatedPartyWaiver)) {
        throw 'Please choose consent or dissent';
      }
      const __relatedpartyconsents =
        _relatedpartyconsents.length === 0
          ? relatedpartyconsents
          : _relatedpartyconsents.map((_relatedpartyconsent: LimitOfConsents) => {
              return {
                ..._relatedpartyconsent,
                ...relatedpartyconsents.find(
                  (relatedpartyconsent: LimitOfConsents) =>
                    relatedpartyconsent.securityType === _relatedpartyconsent.securityType
                ),
              };
            });
      const updatedPayload = {
        ...application,
        relatedpartyconsents:
          values.relatedPartyLimit === 'dissent'
            ? __relatedpartyconsents.map((item) => ({
                ...item,
                singleAssociateLimit: null,
                multipleAssociateLimit: null,
              }))
            : __relatedpartyconsents,
        relatedPartyLimit: relatedPartyLimit,
        relatedPartyWaiver: relatedPartyWaiver,
      };
      const isSaveLater = saveType !== 'save and proceed';
      const checkApplication = applicationComparison(
        {
          ...application,
          relatedpartyconsents: application?.relatedpartyconsents?.sort(
            (consent1, consent2) => Number(consent1.id) - Number(consent2.id)
          ),
        },
        {
          ...updatedPayload,
          relatedpartyconsents: updatedPayload?.relatedpartyconsents?.sort(
            (partyConsent1, partyConsent2) => Number(partyConsent1.id) - Number(partyConsent2.id)
          ),
          currentStep: getStep(
            !!currentStep && currentStep > 11 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        }
      );

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: getStep(12, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('fatca', { id, applicant1ReferenceId })
            : history.push('document-details', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('fatca', { id, applicant1ReferenceId })
            : history.push('document-details', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  return (
    <>
      <Formik
        initialValues={relatedPartyConsentData}
        onSubmit={handleSubmit}
        validate={(values: Values) => {
          try {
            validateYupSchema(
              values,
              values.relatedPartyLimit === 'consent'
                ? relatedPartyConsentSchema(shouldValidateUponSaveLater(values.saveType))
                : {},
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        enableReinitialize={true}>
        {({ handleSubmit, values, setValues, errors, setFieldValue }) => (
          <>
            <Grid
              container
              rowSpacing={1}
              sx={{
                width: '100%',
                ml: 0,
                '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
              }}
              component="form"
              noValidate
              onSubmit={handleSubmit}>
              <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{`Limits On investment`}</SubHeading>
              <Grid
                sx={{
                  display: 'flex',
                }}
                alignItems="center"
                mb={4}
                mt={1}>
                <Grid xs={12} sm={12} lg={12}>
                  <UseRadioGroupTransparent
                    disabled={getDisableStatusForjointHolder(
                      role,
                      referenceDetail.applicant_type,
                      amcuserAsInvestor
                    )}
                    name="relatedPartyLimit"
                    relatedPartyLimit={values.relatedPartyLimit}
                    TableRender={
                      <Grid xs={12} sm={12} lg={12}>
                        <Typography sx={{ mb: 2, mt: 0, ml: 2 }}>
                          The maximum allowed limits as per SEBI regulations have been provided
                          below for each security. Investor can modify the limits in accordance with
                          the prescribed regulations.
                        </Typography>
                        <DataTable
                          tableData={values.relatedpartyconsents}
                          tableHeader={headers(amcuserAsInvestor)}
                          renderAdditionalRow={false}
                          tableHeaderCustomStyles={{
                            '.MuiTableCell-head': {
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'primary.main',
                              textAlign: 'left',
                              backgroundColor: '#F3F8FB',
                              // pl: 4,
                              '&:nth-of-type(2)': {
                                pl: 6,
                              },
                              '&:nth-of-type(3)': {
                                pl: 6,
                              },
                            },
                          }}
                          rowCustomStyles={{
                            '.MuiTableCell-root': {
                              overflowWrap: 'anywhere',
                              textAlign: 'left',
                              verticalAlign: 'top',
                              py: 2,
                              '& .MuiFormControl-root': {
                                m: 0,
                              },
                            },
                          }}
                          boxStyles={{
                            minHeight: 'unset',
                            maxHeight: 'unset',
                            minWidth: 450,
                            mb: 4,
                          }}
                        />
                      </Grid>
                    }
                    items={[
                      {
                        label: (
                          <Typography>
                            <Typography
                              component="span"
                              sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                              {' '}
                              Consent:
                            </Typography>{' '}
                            Portfolio Manager{' '}
                            <Typography
                              component="span"
                              sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                              can{' '}
                            </Typography>
                            invest in the securities of its associates/related parties within the
                            limits agreed upon at para 5 above, or as per following limits, subject
                            to regulatory limits{' '}
                          </Typography>
                        ),
                        value: 'consent',
                      },
                      {
                        label: (
                          <Typography>
                            {' '}
                            <Typography
                              component="span"
                              sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                              Dissent:
                            </Typography>{' '}
                            Portfolio Manager{' '}
                            <Typography
                              component="span"
                              sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                              {' '}
                              cannot
                            </Typography>{' '}
                            invest in the securities of its associates/related parties.
                          </Typography>
                        ),
                        value: 'dissent',
                      },
                    ]}
                    onChange={(e) => {
                      if (e.target.value === application?.relatedPartyLimit) {
                        setValues({
                          ...values,
                          relatedPartyLimit: e.target.value,
                          relatedpartyconsents: application?.relatedpartyconsents
                            ? application?.relatedpartyconsents
                            : relatedDataArrForDissent,
                        });
                      } else if (e.target.value === 'consent') {
                        setValues({
                          ...values,
                          relatedPartyLimit: e.target.value,
                          relatedpartyconsents: relatedDataArr,
                        });
                      } else {
                        setValues({
                          ...values,
                          relatedPartyLimit: e.target.value,
                          relatedpartyconsents: relatedDataArrForDissent,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
              {values.relatedPartyLimit === 'dissent' && (
                <Grid xs={12} sm={12} lg={12}>
                  <DataTable
                    tableData={relatedDataArrForDissent}
                    tableHeader={headersDissent}
                    renderAdditionalRow={false}
                    tableHeaderCustomStyles={{
                      '.MuiTableCell-head': {
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'primary.main',
                        textAlign: 'left',
                        backgroundColor: '#F3F8FB',
                        // pl: 4,
                        '&:nth-of-type(2)': {
                          pl: 6,
                        },
                        '&:nth-of-type(3)': {
                          pl: 6,
                        },
                      },
                    }}
                    rowCustomStyles={{
                      '.MuiTableCell-root': {
                        py: 0,
                        overflowWrap: 'anywhere',
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: 'unset', maxHeight: 'unset', minWidth: 450 }}
                  />
                </Grid>
              )}
              <SubHeading
                sx={{
                  mt: 0,
                  letterSpacing: 0.5 + 'px',
                }}>{`Waiver from rebalancing of portfolio on passive breach of investment limits`}</SubHeading>
              <Grid xs={12} sm={12} lg={12} sx={{ mt: 4 }}>
                <UseRadioGroupTransparent
                  disabled={getDisableStatusForjointHolder(
                    role,
                    referenceDetail.applicant_type,
                    amcuserAsInvestor
                  )}
                  name="relatedPartyWaiver"
                  items={[
                    {
                      label: (
                        <Typography>
                          <Typography
                            component="span"
                            sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                            {' '}
                            Consent:
                          </Typography>{' '}
                          Portfolio Manager{' '}
                          <Typography
                            component="span"
                            sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                            need not{' '}
                          </Typography>{' '}
                          rebalance the portfolio on passive breach of investment limits.
                        </Typography>
                      ),
                      value: 'consent',
                    },
                    {
                      label: (
                        <Typography>
                          <Typography
                            component="span"
                            sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                            {' '}
                            Dissent:
                          </Typography>{' '}
                          Portfolio Manager{' '}
                          <Typography
                            component="span"
                            sx={{ fontWeight: 600, fontSize: 16, color: 'Black' }}>
                            should
                          </Typography>{' '}
                          rebalance the portfolio on passive breach of investment limits within
                          prescribed regulatory time lines as amended from time to time.
                        </Typography>
                      ),
                      value: 'dissent',
                    },
                  ]}
                />
              </Grid>
              <FieldValidationNote />
              <ProceedSaveLater
                saveLater={() => setFieldValue('saveType', 'save for later')}
                saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
                loader={loading}
                clickedButton={values.saveType}
              />
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
}
