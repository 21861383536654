import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  CardMedia,
  Dialog,
  Tooltip,
  Theme,
  Stack,
} from '@mui/material';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  CheckRegenrationButtonDisable,
  applicationDownloadStatusCheck,
  auditDownloadStatusCheck,
  checkIfApplicationIsNonIndividual,
  checkNDPMSForIndusindBank,
  getFirstHolderName,
} from '../../utils/utilityFunctions';
import {
  ConfirmationDialog,
  InvestorConsentBeforeEsign,
  KycUpdateStatus,
} from '../commonComponents';
import React, { useEffect, useState } from 'react';
import { getAllApplications, getApplicationDetails } from '../../redux-store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import {
  Applicant,
  ApplicationProps,
  Groups,
  esignResponse,
  investmentDetailsAplicationRef,
} from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import {
  APMIFlow,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  POA_APPLICATION_STATUS,
  REJECTED_ROLES,
  USER_ROLES,
} from '../../utils/constant';
import { formatShortDateAndTime } from '../../utils/date';
import CloseIcon from '@mui/icons-material/Close';
import {
  ApplicationDownload,
  ApplicationRefs,
  AuditTrailDownload,
  BankFormDownload,
  KycAuditTrailDownload,
} from '../../redux-store/actions/onBoarding';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RootStateType } from '../../redux-store/reducers';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';
import HistoryIcon from '@mui/icons-material/History';
import { ApplicationTimeline } from '../ApplicationDetails';
import { reportDownload } from '../Reports/report-utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import { sendEsignAuthorised } from '../../redux-store/actions/onBoarding';
import { sendEsignEmail } from '../../redux-store/actions/onBoarding';
import { showError } from '../../redux-store/actions/auth';
import {
  checkConsentRejectStatus,
  checkTypedDeclaration,
} from '../Investments/investor-application';

export type InvestorConsentDetailsProps = {
  consentStatus: string;
  applicantType?: string;
  applicantName?: string;
};

export type InvestorConsentProps = {
  consentCount: string;
  consentDetails: InvestorConsentDetailsProps[];
  consentStatus: string;
};

export const getConsentDetails = (applicants: Partial<Applicant>[]): InvestorConsentProps => {
  const details = applicants.map((applicant) => {
    return {
      consentStatus: applicant.investorConsent
        ? APMIFlow && checkTypedDeclaration(applicant.typedDeclarationCheck)
          ? 'Rejected'
          : 'Completed'
        : 'Pending',
      applicantType: applicant.applicant_type,
      applicantName: applicant.name,
    };
  });
  const rejectStatus = checkConsentRejectStatus(
    applicants.find((__applicant) => checkTypedDeclaration(__applicant.typedDeclarationCheck))
  );
  return {
    consentCount: `${applicants.filter((applicant) => applicant.investorConsent)?.length}/${
      applicants.length
    }`,
    consentDetails: details,
    consentStatus: (
      APMIFlow
        ? applicants.every((_applicant) => _applicant.investorConsent) || rejectStatus?.status
        : applicants.every((_applicant) => _applicant.investorConsent)
    )
      ? APMIFlow && rejectStatus?.status
        ? rejectStatus?.text?.replace('Consent', '')
        : 'Completed'
      : 'Pending',
  };
};

export const getAuthorizedSignatoriesConsentDetails = (groups: Groups[]): InvestorConsentProps => {
  const getSignatories = groups?.map((group) => group.groupsignatories).flat();
  const details = getSignatories?.map((signatory) => {
    return {
      consentStatus: signatory?.groupSignatoryConsent ? 'Completed' : 'Pending',
      applicantName: signatory?.name,
    };
  });
  return {
    consentCount: `${
      getSignatories?.filter((signatory) => signatory?.groupSignatoryConsent)?.length
    }/${getSignatories?.length}`,
    consentDetails: details,
    consentStatus: getSignatories?.every((_signatory) => _signatory?.groupSignatoryConsent)
      ? 'Completed'
      : 'Pending',
  };
};
export const checkKycUpdateCheck = (_applicantKYC: Partial<Applicant>) => {
  return (
    _applicantKYC?.updateFieldsToCVL ||
    _applicantKYC?.updateCorrespondenceAddressToCVL ||
    _applicantKYC?.updatePermanentAddressToCVL
  );
};
export const getTableHeader = (
  setInvestorConsentStatusDialog: any,
  setKYCStatusDialog: any
): ColumnType[] => {
  return [
    {
      header: 'Application Number',
      label: 'applicationNumber',
      valueGetter: (row: ApplicationProps) => row.applicationNumber || 'N/A',
    },
    {
      header: 'Investor Name',
      valueGetter: ({ applicants = [] }: ApplicationProps) =>
        getFirstHolderName(applicants) || 'N/A',
    },
    {
      header: 'Mode of Holding',
      renderCell: (row: ApplicationProps) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}>
            {(row.modeOfHolding === 'either or survivor' ? 'Joint' : row.modeOfHolding) || 'Single'}
          </Typography>
        );
      },
    },
    {
      header: 'Investor PAN',
      valueGetter: ({ applicants = [] }: ApplicationProps) => applicants[0]?.panNo || 'N/A',
    },
    {
      header: 'Distributor Name',
      label: 'viewDistributorRM',
      valueGetter: (row: ApplicationProps) =>
        row.distributor?.name || row.distributor?.arnCode || 'N/A',
    },
    // {
    //   header: 'Filled By',
    //   label: 'filledBy',
    //   valueGetter: (row: ApplicationProps) => row.filledBy || 'N/A',
    // },
    {
      header: 'Status',
      renderCell: (row: ApplicationProps) => {
        const applicationStatus = APPLICATION_LISTING_STATUS[row.status];
        const getConsentStatus = checkIfApplicationIsNonIndividual(row)
          ? getAuthorizedSignatoriesConsentDetails(row.groups).consentStatus
          : getConsentDetails(row.applicants).consentStatus;
        return (
          <>
            <Typography
              className={applicationStatus}
              sx={{
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: 14,
                color: '#B78813 !important',
                '&.Draft': {
                  color: 'rgba(51, 127, 201, 0.7) !important',
                },
                '&.Completed': {
                  fontWeight: 600,
                  color: 'rgba(35, 133, 63, 0.7) !important',
                },
                '&.Rejected': {
                  color: '#BD2932 !important',
                  fontWeight: 500,
                },
              }}
              component={RouterLink}
              to={
                checkIfApplicationIsNonIndividual(row)
                  ? `/non-individual-application-details/${row.id}`
                  : `/application-details/${row.id}`
              }>
              {`${applicationStatus} ${row.applicationSentBack ? '(Send Back)' : ''}`}
            </Typography>
            {APPLICATION_LISTING_STATUS.draft === applicationStatus &&
              checkNDPMSForIndusindBank(row.accountType, row.bankDetails) && (
                <Typography
                  // className={getConsentStatus}
                  sx={{
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 1,
                    color: 'rgba(51, 127, 201, 0.7)',
                    display: 'block',
                    // cursor: 'pointer',
                  }}>
                  {row.bankAccountFormCreated && row.showBankIcon
                    ? `Bank Details Pending`
                    : row.bankAccountFormCreated && !row.showBankIcon && row.banks.length
                    ? `Bank Detail Updated`
                    : ''}
                </Typography>
              )}

            {(APPLICATION_LISTING_STATUS.sent_to_applicant1 === applicationStatus ||
              (APPLICATION_LISTING_STATUS.sent_to_authorised_signatories === applicationStatus &&
                checkIfApplicationIsNonIndividual(row))) && (
              <Typography
                className={getConsentStatus}
                sx={{
                  textDecoration: 'none',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 1,
                  color: 'rgba(51, 127, 201, 0.7) !important',
                  display: 'block',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setInvestorConsentStatusDialog({ id: row.id });
                }}>
                Consent {getConsentStatus}
              </Typography>
            )}
            {row?.applicants?.some((_applicantKYC) => checkKycUpdateCheck(_applicantKYC)) && (
              <Typography
                sx={{
                  textDecoration: 'none',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 1,
                  color: '#2057A6 !important',
                  display: 'block',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setKYCStatusDialog({ id: row.id });
                }}>
                KYC update
              </Typography>
            )}
          </>
        );
      },
    },
    {
      header: 'Consent Count',
      label: '',
      valueGetter: (row: ApplicationProps) =>
        (checkIfApplicationIsNonIndividual(row)
          ? getAuthorizedSignatoriesConsentDetails(row.groups).consentCount
          : getConsentDetails(row.applicants).consentCount) || 'N/A',
    },
    {
      header: 'Photo Capture Count',
      label: 'photocaptureCount',
      valueGetter: (row: ApplicationProps) =>
        checkIfApplicationIsNonIndividual(row)
          ? row.authorizedSignatoriesPhotocaptureCount
          : row.photocaptureCount || 'N/A',
    },
  ];
};

export default function DashboardAndApplicationsList({
  isLoading,
  applicationsList,
  boxStyles,
  DashboardApplicationsList,
}: {
  isLoading: boolean;
  applicationsList: ApplicationProps[];
  boxStyles?: SxProps<Theme>;
  DashboardApplicationsList?: boolean;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [regenrationLoading, setRegenrationLoading] = useState(false);
  const [downloadBankLoading, setDownloadBankLoading] = useState(false);
  const [rejectedApplication, setRejectedApplication] = useState<ApplicationProps>();
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [moreAction, setMoreSection] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<ApplicationProps | null>(null);
  const [id, setId] = useState('');
  const [checkPoaOrNI, setCheckPoaOrNI] = useState(false);
  const [tooltip, setTooltip] = useState('Click to Copy');
  const [applicationRefsList, setApplicationRefsList] = useState<esignResponse | null>(null);
  const [open, setOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState<{ id: string } | null>(null);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [applicationsListData, setApplicationsListData] = useState<ApplicationProps[]>([]);
  const [timeLineDialog, setTimeLineDialog] = useState(false);
  const [timelineApplicationId, setTimeLineAapplicationId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [investorConsentStatusDialog, setInvestorConsentStatusDialog] = useState<{
    id: string;
  } | null>(null);
  const [kycStatusDialog, setKYCStatusDialog] = useState<{
    id: string;
  } | null>(null);

  const [moreDownloadOptions, setDownloadOptions] = useState(false);
  const closeDownloadOptions = (loading: boolean) => {
    if (!loading) {
      setDownloadOptions(false), setSelectedApplication(null);
    }
  };

  const [loadingPopup, setLoadingPopup] = useState(false);
  const [loadingAuditTrail, setLoadingAuditTrail] = useState(false);
  const [loadingKycAuditTrail, setLoadingKycAuditTrail] = useState(false);

  const handleCloseinvestorConsentStatusDialog = () => {
    setInvestorConsentStatusDialog(null);
  };

  const handleClosekycStatusDialog = () => {
    setKYCStatusDialog(null);
  };

  const handleActionClose = () => {
    setMoreSection(false), setSelectedApplication(null);
  };
  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };
  useEffect(() => {
    setApplicationsListData(applicationsList);
  }, [applicationsList]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            ApplicationRefs(id)
          )) as unknown as esignResponse;
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: ApplicationProps): JSX.Element => {
      return (
        <Box display="flex">
          {APPLICATION_LISTING_STATUS[row.status] === APPLICATION_LISTING_STATUS.rejected && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/comment.svg"
                alt="comment"
                sx={{ width: '20px' }}
                onClick={() => {
                  setRejectedApplication(row);
                  setRejectionDialog(true);
                }}
              />
            </IconButton>
          )}
          <Tooltip
            title={
              !['draft', 'rejected', 'sent_to_poa_approver', 'sent_to_amc_approver'].includes(
                row.status
              )
                ? 'Link'
                : 'Will be enabled after user approves the e-sign'
            }>
            <IconButton
              color={'error'}
              sx={{
                p: 0,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                '&.Mui-disabled': {
                  cursor: ![
                    'draft',
                    'rejected',
                    'sent_to_poa_approver',
                    'sent_to_amc_approver',
                  ].includes(row.status)
                    ? 'pointer'
                    : 'not-allowed !important',
                  pointerEvents: 'all',
                },
                opacity: [
                  'draft',
                  'rejected',
                  'sent_to_poa_approver',
                  'sent_to_amc_approver',
                ].includes(row.status)
                  ? 0.5
                  : 10,
              }}
              disabled={[
                'draft',
                'rejected',
                'sent_to_poa_approver',
                'sent_to_amc_approver',
              ].includes(row.status)}
              onClick={() => {
                setId(row.id as string), setDialogLoading(true), setCheckPoaOrNI(row.hasPOA);
              }}>
              <CardMedia
                component="img"
                src="/images/link-icon.svg"
                alt="Link"
                sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.editable ? 'Edit' : ''}>
            <IconButton
              onClick={() => {
                row.editable &&
                  history.push(
                    checkIfApplicationIsNonIndividual(row)
                      ? `/non-individual-application`
                      : `/application`,
                    { id: row.id }
                  );
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                '&.Mui-disabled': {
                  cursor: row.editable ? 'pointer' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
              disabled={!row.editable}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={'Activity Logs'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  cursor: 'pointer',
                  pointerEvents: 'all',
                }}>
                <HistoryIcon
                  // fontSize="inherit"
                  sx={{ color: 'primary.main' }}
                  onClick={() => {
                    setTimeLineAapplicationId(row.id as string);
                    setTimeLineDialog(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={'Download Options'}>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={() => {
                setSelectedApplication(row);
                setDownloadOptions(true);
              }}>
              <CardMedia component="img" src={'/images/download-icon.png'} alt="Download" />
            </IconButton>
          </Tooltip>
          {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={row.deletable ? 'Delete' : 'Application can`t be Deleted'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => row.deletable && setDeletePopUp({ id: row.id })}
                disabled={!row.deletable}>
                <DeleteOutlineIcon color={row.deletable ? 'error' : 'disabled'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {[
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
            USER_ROLES.AMC_ADMIN,
          ].includes(role) && (
            <Tooltip title={'More Options'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => {
                  setSelectedApplication(row);
                  setMoreSection(true);
                }}>
                <MoreVertIcon color={'error'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        tableHeader={[
          ...getTableHeader(setInvestorConsentStatusDialog, setKYCStatusDialog),
          additionalCell,
        ]}
        tableData={applicationsListData}
        rowCustomStyles={{
          '&:last-child td, &:last-child th': { border: 0 },
          '.MuiTableCell-body ': { color: '#686e72 !important' },
          background: 'transparent',
        }}
        boxStyles={boxStyles}
      />
      {timeLineDialog && (
        <ApplicationTimeline
          id={timelineApplicationId}
          dialogOpen={timeLineDialog}
          setDialogOpen={setTimeLineDialog}
          consentDetails={
            checkIfApplicationIsNonIndividual(
              applicationsListData.find(
                (application) => Number(application.id) === Number(timelineApplicationId)
              ) as ApplicationProps
            )
              ? getAuthorizedSignatoriesConsentDetails(
                  (
                    applicationsListData.find(
                      (application) => Number(application.id) === Number(timelineApplicationId)
                    ) as ApplicationProps
                  ).groups
                )
              : getConsentDetails(
                  (
                    applicationsListData.find(
                      (application) => Number(application.id) === Number(timelineApplicationId)
                    ) as ApplicationProps
                  ).applicants
                )
          }
          application={
            applicationsListData.find(
              (application) => Number(application.id) === Number(timelineApplicationId)
            ) as ApplicationProps
          }
        />
      )}
      <Dialog
        onClose={() => {
          return closeDownloadOptions(loadingPopup || loadingAuditTrail);
        }}
        open={moreDownloadOptions}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          // direction="row"
          justifyContent="space-between"
          alignItems="center"
          // p={4}
          // pb={2}
          sx={{
            p: { xs: 2, sm: 4 },
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: '20px' },
          }}>
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '20px',
              fontWeight: 500,
              alignSelf: { xs: 'center' },
            }}>
            Download Options
          </Typography>
          <IconButton
            onClick={() => {
              return closeDownloadOptions(loadingPopup || loadingAuditTrail);
            }}
            sx={{ alignSelf: { xs: 'flex-end' }, p: { xs: 0 } }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={1}
          sx={{
            width: '100%',
            ':last-of-type': {
              mb: 5,
            },
            gap: { xs: '20px' },
          }}>
          <Stack
            // direction={'row'}
            // justifyContent={'space-between'}
            // alignItems={'center'}
            width={{ xs: '90%' }}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: { xs: 'center', sm: 'center' },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Application
            </Typography>
            <Box>
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={loadingPopup}
                variant="contained"
                sx={{
                  color: 'common.white',
                  lineHeight: 1.5,
                  fontSize: 14,
                  m: 1,
                  ml: { xs: 0 },
                }}
                fullWidth={true}
                disabled={
                  (applicationDownloadStatusCheck(selectedApplication?.status || '') ||
                  selectedApplication?.signedLeegalityFile?.url
                    ? false
                    : true) ||
                  loadingPopup ||
                  false
                }
                onClick={async () => {
                  try {
                    if (applicationDownloadStatusCheck(selectedApplication?.status || '')) {
                      setLoadingPopup(true);
                      const res = (await dispatch(
                        ApplicationDownload(selectedApplication?.id || '')
                      )) as unknown as any;
                      reportDownload(res as unknown as Blob, `${selectedApplication?.id}.pdf`);
                      setLoadingPopup(false);
                      return;
                    }
                    selectedApplication?.signedLeegalityFile?.url &&
                      window.open(selectedApplication?.signedLeegalityFile?.url);
                  } catch (e) {
                    setLoadingPopup(false);
                    console.error((e as Error).message);
                  }
                }}>
                Download
              </LoadingButton>
            </Box>
          </Stack>
          {[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ].includes(role) && (
            <Stack
              // direction={'row'}
              // justifyContent={'space-between'}
              // alignItems={'center'}
              width={{ xs: '90%' }}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: { xs: 'center', sm: 'space-between' },
                alignItems: { xs: 'center', sm: 'center' },
              }}>
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '18px',
                  fontWeight: 500,
                }}>
                Application Audit Trail
              </Typography>
              <Box>
                <LoadingButton
                  loadingPosition="start"
                  type="submit"
                  loading={loadingAuditTrail}
                  variant="contained"
                  sx={{
                    color: 'common.white',
                    lineHeight: 1.5,
                    fontSize: 14,
                    m: 1,
                    ml: { xs: 0 },
                  }}
                  fullWidth={true}
                  disabled={
                    !auditDownloadStatusCheck(selectedApplication?.status || '') ||
                    loadingAuditTrail
                  }
                  onClick={async () => {
                    try {
                      if (auditDownloadStatusCheck(selectedApplication?.status || '')) {
                        setLoadingAuditTrail(true);
                        const res = (await dispatch(
                          AuditTrailDownload(selectedApplication?.id || '')
                        )) as unknown as any;
                        reportDownload(res as unknown as Blob, `${selectedApplication?.id}.pdf`);
                        setLoadingAuditTrail(false);
                        return;
                      }
                      selectedApplication?.signedLeegalityFile?.url &&
                        window.open(selectedApplication?.signedLeegalityFile?.url);
                    } catch (e) {
                      setLoadingAuditTrail(false);
                      console.error((e as Error).message);
                    }
                  }}>
                  Download
                </LoadingButton>
              </Box>
            </Stack>
          )}
          {selectedApplication?.applicants
            ?.filter((item) => item?.cvlSignedLeegalityDocumentId)
            .map((item) => (
              <>
                <Stack
                  // direction={'row'}
                  // justifyContent={'space-between'}
                  // alignItems={'center'}
                  width={{ xs: '90%' }}
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    alignItems: { xs: 'center', sm: 'center' },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                      paddingRight: { xs: 0 },
                    }}>
                    {`KYC Form Download for ${item.name}`}
                  </Typography>
                  <Box>
                    <LoadingButton
                      loadingPosition="start"
                      type="submit"
                      // loading={kycFormloadingPopup}
                      variant="contained"
                      sx={{
                        color: 'common.white',
                        lineHeight: 1.5,
                        fontSize: 14,
                        m: 1,
                        ml: { xs: 0 },
                      }}
                      fullWidth={true}
                      disabled={!item?.cvlSignedLeegalityDocumentId}
                      onClick={async () => {
                        item?.cvlSignedLeegalityFile?.url &&
                          window.open(item?.cvlSignedLeegalityFile?.url, '_parent', 'download');
                      }}>
                      Download
                    </LoadingButton>
                  </Box>
                </Stack>
                <Stack
                  // direction={'row'}
                  // justifyContent={'space-between'}
                  // alignItems={'center'}
                  width={{ xs: '90%' }}
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    alignItems: { xs: 'center', sm: 'center' },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                    }}>
                    KYC Audit Trail
                  </Typography>
                  <Box>
                    <LoadingButton
                      loadingPosition="start"
                      type="submit"
                      loading={loadingKycAuditTrail}
                      variant="contained"
                      sx={{
                        color: 'common.white',
                        lineHeight: 1.5,
                        fontSize: 14,
                        m: 1,
                        ml: { xs: 0 },
                      }}
                      fullWidth={true}
                      disabled={!item?.cvlSignedLeegalityDocumentId}
                      onClick={async () => {
                        try {
                          setLoadingKycAuditTrail(true);
                          const res = (await dispatch(
                            KycAuditTrailDownload(item?.id || '')
                          )) as unknown as any;
                          reportDownload(res as unknown as Blob, `${item.id}.pdf`);
                          setLoadingKycAuditTrail(false);
                          return;
                        } catch (e) {
                          setLoadingKycAuditTrail(false);
                          console.error((e as Error).message);
                        }
                      }}>
                      Download
                    </LoadingButton>
                  </Box>
                </Stack>
              </>
            ))}
          {/* <Stack
            // direction={'row'}
            // justifyContent={'space-between'}
            // alignItems={'center'}
            width={{ xs: '90%', sm: '60%' }}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: { xs: 'center', sm: 'center' },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              KYC Audit Trail
            </Typography>
            <Box>
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={loadingPopup}
                variant="contained"
                sx={{
                  color: 'common.white',
                  lineHeight: 1.5,
                  fontSize: 14,
                  m: 1,
                  ml: { xs: 0 },
                }}
                fullWidth={true}
                // disabled={
                //   (applicationDownloadStatusCheck(selectedApplication?.status || '') ||
                //   selectedApplication?.signedLeegalityFile?.url
                //     ? false
                //     : true) ||
                //   loadingPopup ||
                //   false
                // }
                onClick={async () => {
                  // try {
                  //   if (applicationDownloadStatusCheck(selectedApplication?.status || '')) {
                  //     setLoadingPopup(true);
                  //     const res = (await dispatch(
                  //       ApplicationDownload(selectedApplication?.id || '')
                  //     )) as unknown as any;
                  //     reportDownload(res as unknown as Blob, `${selectedApplication?.id}.pdf`);
                  //     setLoadingPopup(false);
                  //     return;
                  //   }
                  //   selectedApplication?.signedLeegalityFile?.url &&
                  //     window.open(selectedApplication?.signedLeegalityFile?.url);
                  // } catch (e) {
                  //   setLoadingPopup(false);
                  //   console.error((e as Error).message);
                  // }
                }}>
                Download
              </LoadingButton>
            </Box>
          </Stack> */}
        </Grid>
      </Dialog>

      <Dialog
        onClose={handleActionClose}
        open={moreAction}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={2}>
          <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
            More Options
          </Typography>
          <IconButton onClick={handleActionClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <React.Fragment>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={4}
            py={1}
            sx={{
              ':last-of-type': {
                mb: 5,
              },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Generate leegality PDF for Esign
            </Typography>
            <Box>
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={regenrationLoading}
                variant="contained"
                sx={{
                  color: 'common.white',
                  lineHeight: 1.5,
                  fontSize: 14,
                  m: 1,
                }}
                fullWidth={true}
                disabled={CheckRegenrationButtonDisable(selectedApplication as ApplicationProps)}
                onClick={async () => {
                  try {
                    setRegenrationLoading(true);
                    const regenrationResponse =
                      selectedApplication?.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                        ? ((await dispatch(
                            sendEsignAuthorised(selectedApplication.id)
                          )) as unknown as ApplicationProps)
                        : ((await dispatch(
                            sendEsignEmail(selectedApplication?.id as string)
                          )) as unknown as ApplicationProps);
                    setSelectedApplication(regenrationResponse);
                    setRegenrationLoading(false);
                    handleActionClose();
                    dispatch(showError(regenrationResponse.message as string));
                  } catch (e) {
                    setRegenrationLoading(false);
                    handleActionClose();
                    console.error((e as Error).message);
                  }
                }}>
                Generate Leegality
              </LoadingButton>
            </Box>
          </Grid>
          {checkNDPMSForIndusindBank(
            selectedApplication?.accountType,
            selectedApplication?.bankDetails
          ) &&
            selectedApplication?.showBankIcon && (
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={4}
                py={1}
                sx={{
                  ':last-of-type': {
                    mb: 5,
                  },
                }}>
                <Typography
                  sx={{
                    color: 'rgba(41, 49, 57, 0.7)',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}>
                  Download Bank Account Opening Form
                </Typography>
                <Box>
                  <LoadingButton
                    loadingPosition="start"
                    type="submit"
                    loading={downloadBankLoading}
                    variant="contained"
                    sx={{
                      color: 'common.white',
                      lineHeight: 1.5,
                      fontSize: 14,
                      m: 1,
                    }}
                    fullWidth={true}
                    disabled={!selectedApplication?.bankAccountFormCreated}
                    onClick={async () => {
                      try {
                        setDownloadBankLoading(true);
                        const res = (await dispatch(
                          BankFormDownload(selectedApplication?.id || '')
                        )) as unknown as any;
                        reportDownload(
                          res as unknown as Blob,
                          `${selectedApplication?.applicationNumber}.pdf`
                        );
                        setDownloadBankLoading(false);
                        handleActionClose();
                      } catch (e) {
                        setDownloadBankLoading(false);
                        handleActionClose();
                        console.error((e as Error).message);
                      }
                    }}>
                    Download Form
                  </LoadingButton>
                </Box>
              </Grid>
            )}
        </React.Fragment>
      </Dialog>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={2}>
          <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
            Get Link
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        {applicationRefsList?.esignsArray &&
          applicationRefsList?.esignsArray?.length > 0 &&
          applicationRefsList?.esignsArray.map((ref) => {
            return (
              <React.Fragment key={ref.id}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={1}
                  sx={{
                    ':hover': {
                      bgcolor: '#F4FCFC',
                    },
                    ':last-of-type': {
                      mb: 5,
                    },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                    }}>
                    Copy Link For {ref.name}
                  </Typography>
                  <Tooltip title={tooltip}>
                    <IconButton
                      onMouseOver={() => {
                        setTooltip('Copy Link');
                      }}
                      onClick={() => {
                        setTooltip('Copied'),
                          navigator.clipboard.writeText(
                            USER_ROLES.AUTHORISEDSIGNATORY === ref.signatureBy
                              ? `${window.location.origin}/authorized-signatories-investment-details/${ref.referenceId}/strategy-details`
                              : checkPoaOrNI
                              ? ref.invitationUrl
                              : ref?.signatureBy &&
                                ('fundManager' === ref.signatureBy ||
                                  USER_ROLES.AMCAUTHORISEDSIGNATORY === ref.signatureBy)
                              ? ref.invitationUrl
                              : `${window.location.origin}/investment-details/${ref.referenceId}/details`
                          );
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/link-icon.svg"
                        alt="Link"
                        sx={{ width: '35px' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            );
          })}
        {applicationRefsList?.independentEsignsArray &&
          applicationRefsList?.independentEsignsArray?.length > 0 && (
            <>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                pb={2}>
                <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
                  Get Link for Kyc Form Submit
                </Typography>
              </Grid>
              {applicationRefsList?.independentEsignsArray.map((ref) => {
                return (
                  <React.Fragment key={ref.id}>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={4}
                      py={1}
                      sx={{
                        ':hover': {
                          bgcolor: '#F4FCFC',
                        },
                        ':last-of-type': {
                          mb: 5,
                        },
                      }}>
                      <Typography
                        sx={{
                          color: 'rgba(41, 49, 57, 0.7)',
                          fontSize: '18px',
                          fontWeight: 500,
                        }}>
                        Copy Link For {ref.name}
                      </Typography>
                      <Tooltip title={tooltip}>
                        <IconButton
                          onMouseOver={() => {
                            setTooltip('Copy Link');
                          }}
                          onClick={() => {
                            setTooltip('Copied'), navigator.clipboard.writeText(ref?.invitationUrl);
                          }}>
                          <CardMedia
                            component="img"
                            src="/images/link-icon.svg"
                            alt="Link"
                            sx={{ width: '35px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </>
          )}
      </Dialog>
      <Dialog
        onClose={handleDialogClose}
        open={isDialogLoading}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
            Loading...
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        onClose={handleRejectionDialogClose}
        open={rejectionDialog}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <IconButton
          onClick={handleRejectionDialogClose}
          sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        {rejectedApplication && (
          <Box
            sx={{
              width: { xs: '80%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontWeight: 500,
              },
              mt: 1,
              mb: 5,
            }}>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 6.7 },
                mt: 1,
              }}>
              Application Id:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.applicationNumber}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 1.5 },
                mt: 1,
              }}>
              Application Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#BD2932',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {POA_APPLICATION_STATUS[rejectedApplication.status]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9 },
                mt: 1,
              }}>
              Rejected on:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {formatShortDateAndTime(rejectedApplication.rejectedAt)}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9.5 },
                mt: 1,
              }}>
              Rejected by:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                mt: 1,
              }}>
              Reason for rejection:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.rejectedReason}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
              onClick={handleRejectionDialogClose}>
              Done
            </Button>
          </Box>
        )}
      </Dialog>
      <ConfirmationDialog
        message={'Are you sure you want to delete Application ?'}
        open={deletePopUp !== null}
        setOpen={() => setDeletePopUp(null)}
        onSave={async () => {
          try {
            setDeletePopUp(null);
            const _application = (await dispatch(
              getApplicationDetails(deletePopUp?.id || '', 'delete')
            )) as unknown as ApplicationProps;
            if (DashboardApplicationsList) {
              setDialogLoading(true);
              const response = (await dispatch(
                getAllApplications({
                  page: 1,
                  limit: 4,
                })
              )) as unknown as GetAllApplicantionsResponseBody;
              const { applications } = response || {};
              setApplicationsListData(applications);
              setDialogLoading(false);
            } else {
              setApplicationsListData(
                applicationsListData.filter(
                  (application) => application.id !== deletePopUp?.id || ''
                )
              );
            }
            enqueueSnackbar('Application Deleted Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDeletePopUp(null)}
      />
      {investorConsentStatusDialog && (
        <InvestorConsentBeforeEsign
          handleClose={handleCloseinvestorConsentStatusDialog}
          open={investorConsentStatusDialog}
          consentDetails={
            checkIfApplicationIsNonIndividual(
              applicationsListData.find(
                (application) => Number(application.id) === Number(investorConsentStatusDialog.id)
              ) as ApplicationProps
            )
              ? getAuthorizedSignatoriesConsentDetails(
                  (
                    applicationsListData.find(
                      (application) =>
                        Number(application.id) === Number(investorConsentStatusDialog.id)
                    ) as ApplicationProps
                  ).groups
                ).consentDetails
              : getConsentDetails(
                  (
                    applicationsListData.find(
                      (application) =>
                        Number(application.id) === Number(investorConsentStatusDialog.id)
                    ) as ApplicationProps
                  ).applicants
                ).consentDetails
          }
        />
      )}
      {kycStatusDialog && (
        <KycUpdateStatus
          handleClose={handleClosekycStatusDialog}
          open={kycStatusDialog}
          applicantsData={
            (
              applicationsListData.find(
                (application) => Number(application.id) === Number(kycStatusDialog.id)
              ) as ApplicationProps
            ).applicants
          }
        />
      )}
    </>
  );
}
