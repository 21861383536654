import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  CircularProgress,
  Radio,
} from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useEffect, useState } from 'react';
import {
  getAddressFields,
  getAddressData,
  isCustodianICICIOrHDFC,
} from '../../utils/utilityFunctions';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import { ConfirmationDialog } from '../commonComponents';
import LoadingButton from '@mui/lab/LoadingButton';
import { Address } from './contactDetails';
import { RootStateType } from '../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicantAddressType,
  ApplicationProps,
  getDetailsByRefrenceType,
  getDigiLockerResponse,
  kycUpdateResponse,
  updatedDigilockerAddress,
} from '../../redux-store/types/api-types';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
} from '../../redux-store/types/mdms';
import { getNationalityList, getStatesList } from '../../redux-store/actions';
import {
  getAddressFromDigiLocker,
  getDefaultAddress,
  getApplicationDetailsWithRefId,
  getDigiLockerURl,
  kycUpdateonProceed,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { corresspondenceAndPermanentAddressValidation } from '../../utils/schema';

export interface AddressPopupValues {
  selected: string;
}

const UpdateAdressPopupInitialValues: AddressPopupValues = {
  selected: 'both',
};

const AddressObject = {
  applicants: [
    {
      address: {
        nationality: null,
        status: null,
        permanent: getAddressFields('permanent'),
        correspondence: getAddressFields('correspondence'),
      },
    },
  ],
  countryDropdown: [],
  statesDropdown: [],
};

type AddressType = {
  nationality?: string | null;
  status?: string | null;
  permanent?: Partial<ApplicantAddressType>;
  correspondence?: Partial<ApplicantAddressType>;
  overseas?: Partial<ApplicantAddressType>;
  [key: string]: Partial<ApplicantAddressType> | string | null | undefined;
};

type AddressDetailsProps = {
  address: AddressType;
};

export type Values = {
  applicants: AddressDetailsProps[];
  countryDropdown: string[];
  statesDropdown: string[];
};

const initialValues: Values = AddressObject;

export const updateAddressPopupValidation = yup.object().shape({
  selected: yup.string().nullable().required('Atleast One Option is required'),
});

const checkAddressIsFetchedFromKRA = (
  referenceDetail: getDetailsByRefrenceType,
  addressType: string
) => {
  return referenceDetail.addresses.find(
    (_address) => _address.address_type?.toLocaleLowerCase() === addressType?.toLocaleLowerCase()
  )?.fetchedFromKRA;
};

const checkAddressIsFetchedFromDigilocker = (
  referenceDetail: getDetailsByRefrenceType,
  addressType: string
) => {
  return referenceDetail.addresses.find(
    (_address) => _address.address_type?.toLocaleLowerCase() === addressType?.toLocaleLowerCase()
  )?.fetchedFromDigiLocker;
};

export default function KycAddressDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setDialog] = useState(false);
  const [openConfirmDialog, setConfirmDialog] = useState(false);
  const [openDefaultDialog, setDefaultDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { token: investorAuthToken } = useSelector((store: RootStateType) => store.investor);
  const [addressDetails, setAddressDetails] = useState(initialValues);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const [mdmsStatesList, setMdmsStatesList] = useState<mdmsStatesList[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(UpdateAdressPopupInitialValues);
  const [consentDialogLoading, setConsentDialogLoading] = useState(false);
  const [addressSaved, setAddressSaved] = useState(false);
  const [saveType, setSaveType] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(window.location.search);
  const code = query.get('statusCode');

  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';

  useEffect(() => {
    (async function () {
      try {
        if (application) {
          const nationalitiesMdmsMasters = (await dispatch(
            getNationalityList()
          )) as unknown as nationaliyType;
          setMdmsCountriesList(nationalitiesMdmsMasters.countries);
          const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
          setMdmsStatesList(statesMdmsMasters.states);
          const correspondence = getAddressData('correspondence', referenceDetail.addresses);
          const permanent = getAddressData('permanent', referenceDetail.addresses);
          correspondence.country = correspondence.country?.toUpperCase() || 'INDIA';
          permanent.country = permanent.country?.toUpperCase();
          correspondence.state =
            isCustodianICICIOrHDFC(selectedCustodian) &&
            correspondence.country?.toUpperCase() === 'INDIA'
              ? correspondence.state?.toUpperCase()
              : correspondence.state;
          permanent.state =
            isCustodianICICIOrHDFC(selectedCustodian) &&
            permanent.country?.toUpperCase() === 'INDIA'
              ? permanent.state?.toUpperCase()
              : permanent.state;
          // const overseas = getAddressData('overseas', referenceDetail.addresses);
          setAddressDetails({
            ...addressDetails,
            applicants: addressDetails.applicants.map((item) => {
              return {
                ...referenceDetail,
                address: {
                  nationality: referenceDetail.nationality,
                  status: referenceDetail.status,
                  ...(referenceDetail?.addresses?.find(
                    (_address) => _address.address_type === 'correspondence'
                  ) && { correspondence: correspondence }),
                  permanent: permanent,
                },
              };
            }),
            statesDropdown: statesMdmsMasters.states.map((list) => list.name),
            countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
          });
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  // [application, referenceDetail]

  useEffect(() => {
    try {
      if (code === 'access_allowed') {
        enqueueSnackbar('Address Fetched successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setDialog(true);
      }
      if (code === 'access_denied') {
        throw 'Fetching Address from Digilocker failed';
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  }, []);

  async function onUpdateClick() {
    try {
      if (code === 'access_allowed') {
        setDialog(true);
      } else {
        setUpdateLoading(true);
        const response = (await dispatch(
          getDigiLockerURl(referenceDetail.esignReferenceId, investorAuthToken)
        )) as unknown as getDigiLockerResponse;
        const paramsString = response?.uri;
        if (paramsString) {
          const searchParams = new URLSearchParams(paramsString);
          if (searchParams.get('state') === null) {
            history.push('/not-authorized', {
              role: 'investor',
            });
          } else {
            window.open(response.uri, '_self');
          }
        }
      }
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setUpdateLoading(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={addressDetails}
        onSubmit={async (value) => {
          if (!!selectedCheckbox.selected && !openDefaultDialog) {
            setConfirmDialog(true);
          } else {
            if (!openDefaultDialog) {
              // setDefaultDialog(true);
              history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/kyc-update/xmlDocument-details`
              );
            }
          }
        }}
        validate={(values: Values) => {
          try {
            validateYupSchema(
              values,
              corresspondenceAndPermanentAddressValidation(selectedCustodian),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        enableReinitialize={true}>
        {({ handleSubmit, values }) => (
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            onSubmit={handleSubmit}
            noValidate>
            {referenceDetail.referenceId &&
            referenceDetail.addresses &&
            referenceDetail.addresses.length > 0 ? (
              <>
                {(checkAddressIsFetchedFromKRA(referenceDetail, 'correspondence') ||
                  checkAddressIsFetchedFromKRA(referenceDetail, 'permanent') ||
                  checkAddressIsFetchedFromDigilocker(referenceDetail, 'correspondence') ||
                  checkAddressIsFetchedFromDigilocker(referenceDetail, 'permanent')) && (
                  <>
                    <Notes
                      displayContent={
                        'Click  below on Update To get updated Address from Digilocker'
                      }
                    />
                    <Box
                      sx={{
                        textAlign: 'right',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}>
                      <LoadingButton
                        onClick={() => {
                          history.push(
                            `/investment-details/${referenceDetail.esignReferenceId}/application-details`
                          );
                        }}
                        disabled={updateLoading || dialogLoading || consentDialogLoading}
                        variant="contained"
                        sx={{
                          mt: 4,
                          mb: 2,
                          lineHeight: 1.5,
                          fontSize: 14,
                          px: 5,
                          mr: 2,
                          boxSizing: 'border-box',
                        }}>
                        Back To Preview
                      </LoadingButton>
                      <LoadingButton
                        loadingPosition="start"
                        onClick={onUpdateClick}
                        loading={updateLoading}
                        disabled={dialogLoading || consentDialogLoading}
                        variant="contained"
                        sx={{
                          mt: 4,
                          mb: 2,
                          lineHeight: 1.5,
                          fontSize: 14,
                          px: 5,
                          boxSizing: 'border-box',
                        }}>
                        Update
                      </LoadingButton>
                    </Box>
                  </>
                )}
                {(checkAddressIsFetchedFromKRA(referenceDetail, 'correspondence') ||
                  checkAddressIsFetchedFromDigilocker(referenceDetail, 'correspondence')) && (
                  <>
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Correspondence Address
                    </SubHeading>
                    <Address
                      addressType={'correspondence'}
                      index={0}
                      fetchedFromKRACheck={
                        checkAddressIsFetchedFromKRA(referenceDetail, 'correspondence') || false
                      }
                      nationalitiesMdmsResponse={mdmsCountriesList}
                      address={values.applicants[0].address.correspondence}
                      statesMdmsResponse={mdmsStatesList}
                      fetchedFromDigilocker={
                        checkAddressIsFetchedFromDigilocker(referenceDetail, 'correspondence') ||
                        false
                      }
                    />
                  </>
                )}
                {(checkAddressIsFetchedFromKRA(referenceDetail, 'permanent') ||
                  checkAddressIsFetchedFromDigilocker(referenceDetail, 'permanent')) && (
                  <>
                    <SubHeading>Permanent Address</SubHeading>
                    <Address
                      addressType={'permanent'}
                      index={0}
                      nationalitiesMdmsResponse={mdmsCountriesList}
                      fetchedFromKRACheck={
                        checkAddressIsFetchedFromKRA(referenceDetail, 'permanent') || false
                      }
                      address={values.applicants[0].address.permanent}
                      statesMdmsResponse={mdmsStatesList}
                      fetchedFromDigilocker={
                        checkAddressIsFetchedFromDigilocker(referenceDetail, 'permanent') || false
                      }
                    />
                  </>
                )}
                <ProceedSaveLater
                  saveForLaterButtonText="Set To Default"
                  saveButtonText="Proceed"
                  saveAndProceed={handleSubmit}
                  saveLater={() => setDefaultDialog(true)}
                  disabled={
                    (code === 'access_allowed' && !addressSaved) ||
                    !referenceDetail?.aadharXMLFromDigiLockerFileId ||
                    updateLoading ||
                    consentDialogLoading
                  }
                  showSaveForLater={
                    referenceDetail.addresses.some((e) => e.fetchedFromDigiLocker) || addressSaved
                  }
                  showEndIcon={false}
                  loader={dialogLoading}
                  clickedButton={saveType}
                />
              </>
            ) : (
              <Notes displayContent={'No data available please try again'} />
            )}
          </Grid>
        )}
      </Formik>
      <ConfirmationDialog
        message="The KYC details have been changed and a CVL KYC form will be generated for e-signing as part of KYC Modification Request."
        open={openConfirmDialog}
        setOpen={() => setConfirmDialog(false)}
        onSave={async () => {
          try {
            setDialogLoading(true);
            setConfirmDialog(false);
            setSaveType('save and proceed');
            const response = (await dispatch(
              kycUpdateonProceed({
                updateCorrespondenceAddressToCVL: ['both', 'correspondence'].includes(
                  selectedCheckbox.selected
                ),
                updatePermanentAddressToCVL: ['both', 'permanent'].includes(
                  selectedCheckbox.selected
                ),
                applicantId: referenceDetail.id,
              })
            )) as unknown as kycUpdateResponse;
            (await dispatch(
              getApplicationDetailsWithRefId(referenceDetail.esignReferenceId)
            )) as unknown as ApplicationProps;
            setDialogLoading(false);
            history.push(
              `/investment-details/${referenceDetail.esignReferenceId}/kyc-update/xmlDocument-details`
            );
          } catch (error) {
            setDialogLoading(false);
            console.error((error as Error).message);
          }
        }}
        // disabled={dialogLoading}
        onCancel={() => setConfirmDialog(false)}
      />
      <ConfirmationDialog
        message="Are You Sure You Want to Revert to the Default Kyc Details? Any Update Change will be lost."
        open={openDefaultDialog}
        setOpen={() => setDefaultDialog(false)}
        onSave={async () => {
          try {
            setDialogLoading(true);
            setDefaultDialog(false);
            setSaveType('save for later');
            (await dispatch(getDefaultAddress(referenceDetail.id))) as unknown as kycUpdateResponse;
            history.push(
              `/investment-details/${referenceDetail.esignReferenceId}/application-details`
            );
          } catch (error) {
            console.error((error as Error).message);
          } finally {
            setDialogLoading(false);
          }
        }}
        onCancel={() => setDefaultDialog(false)}
      />
      <Dialog open={openDialog} onClose={() => () => setDialog(false)}>
        <Box
          sx={{
            p: { xs: 2, sm: '45px 40px' },
            borderRadius: '10px',
            // width: { xs: '70%', sm: '100%' },
            maxWidth: 410,
            height: { xs: '90%', md: 'unset' },
            overflowY: 'auto',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiButton-root': {
                minWidth: 100,
                fontSize: 16,
              },
            }}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: '#337FC9',
                textAlign: 'center',
                mb: 4,
              }}>
              {`Do you wish to update ${
                referenceDetail?.addresses?.find(
                  (_address) => _address.address_type === 'correspondence'
                )
                  ? ''
                  : 'Permanent Address'
              }?`}
            </Typography>
            <Formik
              initialValues={selectedCheckbox}
              onSubmit={async (value) => {
                try {
                  const corresspondenceAddressCheck = referenceDetail?.addresses?.find(
                    (_address) => _address.address_type === 'correspondence'
                  );
                  setConsentDialogLoading(true);
                  const response = (await dispatch(
                    getAddressFromDigiLocker(
                      referenceDetail.id,
                      corresspondenceAddressCheck ? value.selected : 'permanent'
                    )
                  )) as unknown as updatedDigilockerAddress;
                  const { correspondenceAddress = {}, permanentAddress } = response;
                  if (
                    referenceDetail?.addresses?.find(
                      (_address) => _address.address_type === 'correspondence'
                    )
                  ) {
                    correspondenceAddress.country = correspondenceAddress.country?.toUpperCase();
                    correspondenceAddress.state =
                      isCustodianICICIOrHDFC(selectedCustodian) &&
                      correspondenceAddress.country?.toUpperCase() === 'INDIA'
                        ? correspondenceAddress.state?.toUpperCase()
                        : correspondenceAddress.state;
                  }
                  permanentAddress.country = permanentAddress.country?.toUpperCase();
                  permanentAddress.state =
                    isCustodianICICIOrHDFC(selectedCustodian) &&
                    permanentAddress.country?.toUpperCase() === 'INDIA'
                      ? permanentAddress.state?.toUpperCase()
                      : permanentAddress.state;
                  setAddressDetails({
                    ...addressDetails,
                    applicants: addressDetails.applicants.map((item) => {
                      return {
                        ...item,
                        address: {
                          nationality: item.address.nationality,
                          status: item.address.status,
                          ...(referenceDetail?.addresses?.find(
                            (_address) => _address.address_type === 'correspondence'
                          ) && { correspondence: correspondenceAddress }),
                          // response.correspondenceAddress,
                          permanent: permanentAddress,
                        },
                      };
                    }),
                  });
                  if (!addressSaved) {
                    setAddressSaved(true);
                  }
                  setSelectedCheckbox({
                    selected: corresspondenceAddressCheck ? value.selected : 'permanent',
                  });
                } catch (error) {
                  console.error((error as Error).message);
                } finally {
                  setConsentDialogLoading(false);
                  setDialog(false);
                }
              }}
              validate={(values: AddressPopupValues) => {
                try {
                  referenceDetail?.addresses?.find(
                    (_address) => _address.address_type === 'correspondence'
                  ) && validateYupSchema(values, updateAddressPopupValidation, true, values);
                } catch (e) {
                  return yupToFormErrors(e);
                }
              }}
              enableReinitialize={true}>
              {({ handleSubmit, values, setFieldValue, errors }) => (
                <>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      fontSize: 14,
                      textAlign: 'center',
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                      '.MuiTypography-root': {
                        fontSize: 15,
                        fontWeight: 500,
                      },
                    }}>
                    {referenceDetail?.addresses?.find(
                      (_address) => _address.address_type === 'correspondence'
                    ) && (
                      <RadioGroup aria-label="modeOfHolding" defaultValue="">
                        <FormControlLabel
                          key="correspondence"
                          value="correspondence"
                          onChange={() =>
                            setFieldValue(
                              'selected',
                              values.selected === 'correspondence' ? '' : 'correspondence'
                            )
                          }
                          control={
                            <Radio size="small" checked={values.selected === 'correspondence'} />
                          }
                          label="Correspondence Address"
                          title="Correspondence Address"
                        />
                        <FormControlLabel
                          key="permanent"
                          value="permanent"
                          onChange={() =>
                            setFieldValue(
                              'selected',
                              values.selected === 'permanent' ? '' : 'permanent'
                            )
                          }
                          control={<Radio size="small" checked={values.selected === 'permanent'} />}
                          label="Permanent Address"
                          title="Permanent Address"
                        />
                        <FormControlLabel
                          key="both"
                          value="both"
                          onChange={() =>
                            setFieldValue('selected', values.selected === 'both' ? '' : 'both')
                          }
                          control={<Radio size="small" checked={values.selected === 'both'} />}
                          label="Both"
                          title="Both"
                        />
                      </RadioGroup>
                    )}
                    {Object.keys(errors).includes('selected') ? (
                      <FormHelperText
                        error
                        sx={{ mt: 2, mb: 2, textAlign: 'center', fontSize: '14px' }}>
                        {errors.selected}
                      </FormHelperText>
                    ) : null}
                    <Button
                      variant="contained"
                      disabled={consentDialogLoading}
                      type="submit"
                      sx={{ color: 'common.white', mr: 2, mt: 1 }}>
                      {consentDialogLoading ? (
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        'Yes'
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setDialog(false)}
                      disabled={consentDialogLoading}
                      sx={{ color: 'primary.main', mt: 1 }}>
                      No
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
