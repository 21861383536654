import { SAVE_LATER_VALIDATION_CHECK } from '../../utils/constant';
import { Grid } from '@mui/material';
import { Notes } from './components';

const FieldValidationNote = ({ saveType }: { saveType?: string }): JSX.Element => {
  return !SAVE_LATER_VALIDATION_CHECK ? (
    <Grid sx={{ width: '100%', mt: 4 }}>
      <Notes
        displayContent={`Mandatory field will be verified on clicking ${
          saveType ? saveType : 'save and proceed'
        }`}
      />
    </Grid>
  ) : (
    <></>
  );
};

export default FieldValidationNote;
