import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import React, { Fragment } from 'react';
import { Gridstyles } from './index';
import { ApplicationProps, NomineeType } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SubHeading } from '../investors/components';
import {
  checkAddressField,
  isCustodianAxis,
  isCustodianICICIOrHDFC,
  isCustodianKotak,
  isMinor,
} from '../../utils/utilityFunctions';

export default function NomineeDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { nominees = [] } = application || {};
  const selectedCustodian = application?.custodian || '';

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Nominee Details
            </Typography>
            {nominees.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Nominees
              </Grid>
            ) : (
              nominees.map((nominee, index: number) => {
                const title = [
                  'First Nominee Details',
                  'Second Nominee Details',
                  'Third Nominee Details',
                ][index];
                return (
                  <React.Fragment key={nominee.id}>
                    <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                      {title}
                    </SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <KeyValue
                          title={`Nominee Name ${index + 1}`}
                          description={nominee.nomineeName}
                        />
                        <KeyValue
                          title={'Nominee RelationShip'}
                          description={nominee.nomineeRelationship}
                        />
                        <KeyValue
                          title={'Date Of Birth'}
                          description={formatShortDate(nominee.dateOfBirth)}
                        />
                        {(isCustodianICICIOrHDFC(selectedCustodian) ||
                          isCustodianAxis(selectedCustodian) ||
                          isCustodianKotak(selectedCustodian)) && (
                          <Fragment>
                            <KeyValue title={`Nominee Email`} description={nominee.nomineeEmail} />
                            <KeyValue
                              title={`Nominee Phone Number`}
                              description={
                                nominee.nomineeCountryCode && nominee.nomineePhone
                                  ? nominee.nomineeCountryCode + '-' + nominee.nomineePhone
                                  : nominee.nomineePhone
                              }
                            />
                          </Fragment>
                        )}
                        <KeyValue
                          title={'Nominee Percentage'}
                          description={nominee.nomineePercentage}
                        />
                        <KeyValue
                          title={`Nominee Address`}
                          description={`${checkAddressField(
                            nominee?.nomineeAddress1 || ''
                          )}${checkAddressField(nominee?.nomineeAddress2 || '')}${checkAddressField(
                            nominee?.nomineeAddress3 || ''
                          )}
                              ${checkAddressField(nominee?.nomineeCity || '')}${checkAddressField(
                            nominee?.nomineeState || ''
                          )}${checkAddressField(nominee?.nomineeCountry || '')}${
                            nominee?.nomineePincode
                          }`}
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        />
                        <KeyValue title={'Nominee PAN'} description={nominee.nomineePan} />

                        {nominee.dateOfBirth && isMinor(nominee.dateOfBirth) && (
                          <>
                            <KeyValue title={'Guardian Name'} description={nominee.guardianName} />
                            {(isCustodianICICIOrHDFC(selectedCustodian) ||
                              isCustodianAxis(selectedCustodian) ||
                              isCustodianKotak(selectedCustodian)) && (
                              <Fragment>
                                <KeyValue
                                  title={`Guardian Email`}
                                  description={nominee.guardianEmail}
                                />
                                <KeyValue
                                  title={`Guardian Phone Number`}
                                  description={
                                    nominee.guardianCountryCode && nominee.guardianPhone
                                      ? nominee.guardianCountryCode + '-' + nominee.guardianPhone
                                      : nominee.guardianPhone
                                  }
                                />
                              </Fragment>
                            )}
                            <KeyValue
                              title={'Guardian Pan'}
                              description={nominee.nomineeGuardianPan}
                            />
                            <KeyValue
                              title={'Guardian Address'}
                              description={`${checkAddressField(
                                nominee?.guardianAddress1 || ''
                              )}${checkAddressField(
                                nominee?.guardianAddress2 || ''
                              )}${checkAddressField(nominee?.guardianAddress3 || '')}
                              ${checkAddressField(nominee?.guardianCity || '')}${checkAddressField(
                                nominee?.guardianState || ''
                              )}${checkAddressField(nominee?.guardianCountry || '')}${
                                nominee?.guardianPincode
                              }`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                            <KeyValue
                              title={'Guardian RelationShip'}
                              description={nominee.guardianRelationship}
                            />
                          </>
                        )}
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
