export const GET_PORTFOLIOMANAGERBYID_SUCCESS = 'GET_PORTFOLIOMANAGERBYID_SUCCESS';
export const GET_PORTFOLIOMANAGERBYID = 'GET_PORTFOLIOMANAGERBYID';
export const ADD_PORTFOLIOMANAGER_SUCCESS = 'ADD_PORTFOLIOMANAGER_SUCCESS';
export const ADD_PORTFOLIOMANAGER = 'ADD_PORTFOLIOMANAGER';
export const EDIT_PORTFOLIOMANAGER_SUCCESS = 'EDIT_PORTFOLIOMANAGER_SUCCESS';
export const EDIT_PORTFOLIOMANAGER = 'EDIT_PORTFOLIOMANAGER';
export const GET_PORTFOLIOMANAGER_SUCCESS = 'GET_PORTFOLIOMANAGER_SUCCESS';
export const GET_PORTFOLIOMANAGER = 'GET_PORTFOLIOMANAGER';
export const GET_MITC_ACCESS_SUCCESS = 'GET_MITC_ACCESS_SUCCESS';
export const GET_MITC_ACCESS = 'GET_MITC_ACCESS';
