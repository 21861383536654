import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  allowOnlyNumbers,
  applicationComparison,
  getAddressData,
  getAddressFields,
  preventSpecialCharacters,
  permanentAddressSameAsCorresponding,
  saveForLater,
  removeSingleQuote,
  shouldValidateUponSaveLater,
  getStep,
  isCustodianICICIOrHDFC,
} from '../../utils/utilityFunctions';
import { ApplicantAddressType, ContactPerson } from '../../redux-store/types/api-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Address, getFieldsDisabled } from '../investors/contactDetails';
import { updateApplication } from '../../redux-store/actions/application';
import { nonIndividualContactDetailsSchema } from '../../utils/schema';
import { CountryCodesDropDown } from '../commonComponents';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
} from '../../redux-store/types/mdms';
import { getNationalityList, getStatesList } from '../../redux-store/actions';
import { APPLICATION_TYPE, SAVE_LATER_VALIDATION_CHECK } from '../../utils/constant';
import MFSelectField from '../../lib/formik/SelectField';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { nonDigitRegex } from '../../utils/regex';
import FieldValidationNote from '../investors/FieldValidationNote';

type ContactDetailsProps = {
  contactperson?: ContactPerson;
  address: {
    permanent?: Partial<ApplicantAddressType>;
    correspondence?: Partial<ApplicantAddressType>;
    [key: string]: Partial<ApplicantAddressType> | string | null | undefined | ContactPerson;
  };
};

export type Values = {
  applicants: ContactDetailsProps[];
  saveType: string;
  countryDropdown: string[];
  statesDropdown: string[];
  [key: string]: string | boolean | ContactDetailsProps[] | ContactPerson | string[];
};

export const contactPersonObject: ContactPerson = {
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  country: 'INDIA',
  pincode: '',
  name: '',
  landLineNumber: '',
  email: '',
  mobile: '',
  stdCode: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
};

const ApplicantDetails = ({
  values,
  setValues,
  mdmsCountriesList,
  mdmsStatesList,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  mdmsCountriesList: mdmsCountriesList[];
  mdmsStatesList: mdmsStatesList[];
}): JSX.Element => {
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('applicants.0.contactperson.mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <React.Fragment>
      {values.applicants.map((applicant, index) => (
        <React.Fragment key={index}>
          <SubHeading sx={{ mt: '15px' }}>Correspondence Address</SubHeading>
          <Address
            addressType={'correspondence'}
            index={index}
            nationalitiesMdmsResponse={mdmsCountriesList}
            statesMdmsResponse={mdmsStatesList}
            key={values.applicants.length}
            address={values.applicants[index].address.correspondence}
            fetchedFromKRACheck={applicant.address.correspondence?.fetchedFromKRA || null}
          />
          {!applicant.address.permanent?.fetchedFromKRA &&
            !applicant.address.correspondence?.fetchedFromKRA && (
              <MFCheckbox
                onChange={({ target: { checked } }) =>
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          address: {
                            ..._applicant.address,
                            correspondence: {
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              ..._applicant.address.correspondence!,
                              permanentAddressSameAsCorresponding: checked,
                            },
                            ...(!checked && { permanent: getAddressFields('permanent') }),
                          },
                        };
                      }
                      return _applicant;
                    }),
                  })
                }
                name={`applicants.${index}.address.correspondence.permanentAddressSameAsCorresponding`}
                label="Registered Office Address same as Correspondence address."
                sx={{ ml: '40px' }}
              />
            )}
          {!values.applicants[index]?.address?.correspondence
            ?.permanentAddressSameAsCorresponding && (
            <>
              <SubHeading>Registered Office Address</SubHeading>
              <Address
                addressType={'permanent'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                statesMdmsResponse={mdmsStatesList}
                key={values.applicants.length}
                address={values.applicants[index].address.permanent}
                fetchedFromKRACheck={applicant.address.permanent?.fetchedFromKRA || null}
              />
            </>
          )}
          <SubHeading>Details Of Contact Person</SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.name`}
              label={`Name of Contact Person *`}
              placeholder="Enter Name of Contact Person"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.mobile`}
              label={`Mobile Number *`}
              placeholder="Enter Mobile Number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              startAdornment={
                <CountryCodesDropDown
                  ElementWidth={mobileElementWidth && mobileElementWidth}
                  name={`applicants.${index}.contactperson.countryNameAndCode`}
                  value={values.applicants[index].contactperson?.countryNameAndCode || ''}
                />
              }
              regexForFilterValue={nonDigitRegex}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.stdCode`}
              label={`STD Code`}
              placeholder="STD Code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.landLineNumber`}
              placeholder="Enter Landline Number"
              label={`Landline Number`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.email`}
              label={`Email ID *`}
              placeholder="Enter Email ID"
            />
          </Grid>

          <SubHeading>Address Of Contact Person</SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.address1`}
              label={`Address Line 1`}
              placeholder="Enter Address Line 1"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.address2`}
              label={`Address Line 2`}
              placeholder="Enter Address Line 2"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.address3`}
              label={`Address Line 3`}
              placeholder="Enter Address Line 3"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.pincode`}
              label={`Pincode`}
              placeholder="Enter Pincode"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.city`}
              label={`City`}
              placeholder="Enter City"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.contactperson.state`}
              label={`State`}
              placeholder="Enter State"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <MFTextField
              name={`applicants.${index}.contactperson.country`}
              label={`Country *`}
              placeholder="Enter Country"
            /> */}
            <SearchableSelect
              name={`applicants.${index}.contactperson.country`}
              label="Country"
              items={mdmsCountriesList.map((nationality) => ({
                key: nationality.name,
                value: nationality.name,
              }))}
              searchFieldPlaceholder={'Search Country'}
            />
          </Grid>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default function ContactDetails(): JSX.Element {
  const initialValues: Values = {
    applicants: [
      {
        contactperson: contactPersonObject,
        address: {
          permanent: getAddressFields('permanent'),
          correspondence: getAddressFields('correspondence'),
        },
      },
    ],
    saveType: 'save and proceed',
    countryDropdown: [],
    statesDropdown: [],
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState('');
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contactDetails, setContactDetails] = useState(initialValues);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const [mdmsStatesList, setMdmsStatesList] = useState<mdmsStatesList[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const selectedCustodian = application?.custodian || '';
  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
        setMdmsStatesList(statesMdmsMasters.states);
        setContactDetails({
          ...contactDetails,
          applicants: applicants.length
            ? applicants.map((applicant) => {
                const correspondence = getAddressData('correspondence', applicant.addresses);
                const permanent = getAddressData('permanent', applicant.addresses);
                const correspondenceFieldDisabled = getFieldsDisabled(
                  'correspondence',
                  correspondence?.fetchedFromKRA || false,
                  role,
                  selectedCustodian,
                  correspondence?.fetchedFromDigiLocker || false
                );
                const permanentFieldDisabled = getFieldsDisabled(
                  'permanent',
                  permanent?.fetchedFromKRA || false,
                  role,
                  selectedCustodian,
                  permanent?.fetchedFromDigiLocker || false
                );

                const defaultPayload = {
                  contactperson: applicant.contactperson
                    ? {
                        ...applicant.contactperson,
                        countryNameAndCode: applicant.contactperson.countryNameAndCode
                          ? applicant.contactperson.countryNameAndCode
                          : 'India: +91',
                        country: applicant.contactperson.country
                          ? applicant.contactperson.country.toUpperCase()
                          : 'INDIA',
                      }
                    : contactPersonObject,
                  address: {
                    correspondence,
                  },
                };
                const permanentAddressPayload = correspondence.permanentAddressSameAsCorresponding
                  ? defaultPayload
                  : {
                      ...defaultPayload,
                      address: {
                        ...defaultPayload.address,
                        permanent: {
                          ...permanent,
                          state: permanent.state
                            ? isCustodianICICIOrHDFC(selectedCustodian) &&
                              permanent.country?.toUpperCase() === 'INDIA' &&
                              !permanentFieldDisabled
                              ? permanent.state?.toUpperCase()
                              : permanent.state
                            : '',
                          country: permanent.country
                            ? permanentFieldDisabled
                              ? permanent.country
                              : permanent.country.toUpperCase()
                            : 'INDIA',
                        },
                        correspondence: {
                          ...correspondence,
                          state: correspondence.state
                            ? isCustodianICICIOrHDFC(selectedCustodian) &&
                              correspondence.country?.toUpperCase() === 'INDIA' &&
                              !correspondenceFieldDisabled
                              ? correspondence.state?.toUpperCase()
                              : correspondence.state
                            : '',
                          country: correspondence.country
                            ? correspondenceFieldDisabled
                              ? correspondence.country
                              : correspondence.country.toUpperCase()
                            : 'INDIA',
                        },
                      },
                    };
                return permanentAddressPayload;
              })
            : [
                {
                  contactperson: contactPersonObject,
                  address: {
                    permanent: getAddressFields('permanent'),
                    correspondence: getAddressFields('correspondence'),
                  },
                },
              ],
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
          statesDropdown: statesMdmsMasters.states.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
      } = application || {};
      const { saveType = '' } = values;
      const updatePayload = applicants
        .map((applicant, index: number) => {
          const { address: updatedAddresses = {}, contactperson } = values.applicants[index] || {};
          return {
            ...applicant,
            contactperson: {
              ...contactperson,
              countryCode: contactperson?.countryNameAndCode?.split(':')[1].trim(),
              address1: removeSingleQuote(contactperson?.address1),
              address2: removeSingleQuote(contactperson?.address2),
              address3: removeSingleQuote(contactperson?.address3),
              city: removeSingleQuote(contactperson?.city),
              state: removeSingleQuote(contactperson?.state),
              name: removeSingleQuote(contactperson?.name),
              email: removeSingleQuote(contactperson?.email),
            },
            addresses: Object.keys(updatedAddresses)
              .map((addressType) => {
                if (
                  addressType === 'permanent' &&
                  updatedAddresses['correspondence']?.permanentAddressSameAsCorresponding
                ) {
                  // eslint-disable-next-line
                  return permanentAddressSameAsCorresponding(
                    updatedAddresses['correspondence'],
                    addressType,
                    applicant.addresses
                  );
                }
                if (
                  Object.keys(updatedAddresses).length === 1 &&
                  updatedAddresses['correspondence']?.permanentAddressSameAsCorresponding
                ) {
                  return [
                    {
                      ...permanentAddressSameAsCorresponding(
                        updatedAddresses['correspondence'],
                        'permanent',
                        applicant.addresses
                      ),
                    },
                    {
                      ...getAddressData(addressType, applicant.addresses),
                      ...(updatedAddresses[
                        addressType
                      ] as unknown as Partial<ApplicantAddressType>),
                      address_type: addressType,
                    },
                  ];
                }
                return {
                  ...getAddressData(addressType, applicant.addresses),
                  ...(updatedAddresses[addressType] as unknown as Partial<ApplicantAddressType>),
                  address_type: addressType,
                };
              })
              .flat()
              .filter((address) => Object.keys(address).length),
          };
        })
        .map((_applicant) => {
          return {
            ..._applicant,
            addresses: _applicant.addresses.map((address) => {
              return {
                ...address,
                address1: address.address1,
                address2: address.address2,
                address3: address.address3,
                city: address.city,
                state: address.state,
              };
            }),
          };
        });
      const isSaveLater = saveType !== 'save and proceed';
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                addresses: applicant.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...application!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          applicants: updatePayload
            .map((applicant) => {
              return {
                ...applicant,
                addresses: applicant?.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          currentStep: getStep(
            !!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        }
      );

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatePayload,
              currentStep: getStep(3, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contactDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            nonIndividualContactDetailsSchema(
              selectedCustodian,
              shouldValidateUponSaveLater(values.saveType)
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <ApplicantDetails
            values={values}
            setValues={setValues}
            mdmsCountriesList={mdmsCountriesList}
            mdmsStatesList={mdmsStatesList}
          />
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={
              () => setFieldValue('saveType', 'save for later')
              // setClicked('save for later')
            }
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
