import { useEffect, useState } from 'react';
import { getKycDashboardDetails, onboardingSummary } from '../../redux-store/actions/application';
import { dashboardOnboardingType } from '../../redux-store/types/api-types';
import data from './data-ts';
import { useDispatch, useSelector } from 'react-redux';
import CenterTitlePlugin from './center-title-plugin-ts';
import { Chart as ChartJS, ChartData, ChartOptions, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { RootStateType } from '../../redux-store/reducers';
import { USER_ROLES } from '../../utils/constant';

ChartJS.register(...registerables);

export const dougnutType: dashboardOnboardingType = [
  {
    status: '',
    count: 0,
  },
];

export default function DoughnutGraph({ type = '' }: { type?: string }): JSX.Element {
  const dispatch = useDispatch();
  const checkforKyc = type === 'kyc-update';
  const doughnutData = useSelector((store: RootStateType) =>
    checkforKyc ? store.kycDataDougnut : store.dataDougnut
  );
  const [dataDoughnut, SetDataDoughnut] = useState(dougnutType);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        (await dispatch(
          checkforKyc ? getKycDashboardDetails() : onboardingSummary()
        )) as unknown as dashboardOnboardingType;
        if (!componentIsAlive) {
          return;
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  useEffect(() => {
    SetDataDoughnut(doughnutData);
  }, [doughnutData]);

  data.donutOptions.plugins.title.text =
    USER_ROLES.AMC_ADMIN === role
      ? 'Number of Applications (since inception)'
      : 'Number of Applications (onboarded by RM/SRM)';

  let completedApplication = 0;
  let inProgressApplication = 0;

  doughnutData?.map((data) => {
    if (['completed', 'optedForUpdatedKycToCvl'].includes(data.status)) {
      completedApplication = data.count;
    }
    if (['in_progress', 'notOptedForUpdatedKycToCvl'].includes(data.status)) {
      inProgressApplication = data.count;
    }
  });
  const applicationData = [completedApplication, inProgressApplication];
  const label = [
    [checkforKyc ? `Opted For Kyc Update` : `Completed`, completedApplication],
    [checkforKyc ? `Not Opted For Kyc Update` : `In Progress`, inProgressApplication],
  ];
  const options = checkforKyc ? data.donutOptions2 : data.donutOptions;
  const donutData = checkforKyc ? data.donutData2 : data.donutData;
  (donutData.datasets as any)[0].data = [completedApplication, inProgressApplication];
  options.plugins.centerTitle.display = true;
  options.plugins.centerTitle.text = [
    String(applicationData.reduce<number>((sum, num) => (sum ? sum : 0) + (num ? num : 0), 0)),
    'Total Applications',
  ];
  const donutOptions = options as unknown as ChartOptions;

  (donutData.labels as any) = label;

  return (
    <Grid item xs={12} sm={12} md={12} lg={5}>
      <Card raised={true} sx={{ height: 250, p: 2 }}>
        {JSON.stringify(dougnutType) === JSON.stringify(doughnutData) ||
        JSON.stringify(doughnutData) !== JSON.stringify(dataDoughnut) ? (
          <>
            <Skeleton />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Skeleton
                variant="circular"
                sx={{
                  width: { xs: 150, sm: 170 },
                  height: { xs: 150, sm: 170 },
                  mt: 2,
                  ml: { xs: 0, sm: 15, lg: 2 },
                }}
              />
              <Box>
                <Skeleton sx={{ width: { xs: 150, sm: 200 }, ml: { xs: 4, sm: 0 } }} />
                <Skeleton sx={{ width: { xs: 30, sm: 50 }, ml: { xs: 10, sm: 5 } }} />
                <Skeleton sx={{ width: { xs: 150, sm: 200 }, ml: { xs: 4, sm: 0 } }} />
                <Skeleton sx={{ width: { xs: 30, sm: 50 }, ml: { xs: 10, sm: 5 } }} />
              </Box>
            </Box>
          </>
        ) : (
          <Typography
            component={'div'}
            className="canvas-container"
            sx={{
              '&.canvas-container': {
                position: 'relative',
                width: { xs: '300px', sm: 'unset' },
                margin: 'auto',
              },
            }}>
            <Chart
              height={200}
              type="doughnut"
              plugins={[CenterTitlePlugin]}
              data={donutData as ChartData}
              options={donutOptions}
            />
          </Typography>
        )}
      </Card>
    </Grid>
  );
}
