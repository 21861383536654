import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/auth';
import { InvestorEditErrorObj } from './investorEditSections';

export type AuthorizedSignatoryEditSectionProps = {
  authorizedSignatoryEditObj: InvestorEditErrorObj[] | null;
};

export const initialState: AuthorizedSignatoryEditSectionProps = {
  authorizedSignatoryEditObj: null,
};

function authorizedSignatoryEditSectionsReducer(
  state = initialState,
  action: AnyAction
): Partial<AuthorizedSignatoryEditSectionProps> {
  switch (action.type) {
    case 'GET_AUTHORIZED_SIGNATORY_EDIT_SECTION': {
      return {
        ...state,
        authorizedSignatoryEditObj: action.authorizedSignatoryEditObj,
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export default authorizedSignatoryEditSectionsReducer;
