import { Box, Button, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles, Item, ItemDetails } from './index';
import { SubHeading } from '../investors/components';
import { getBankAddress } from '../../utils/utilityFunctions';

export default function BankDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { banks, applicants } = application;
  const getDocument = applicants
    ?.find((applicant) => Number(applicant.applicant_type) === 1)
    ?.documents?.find((document) => document.documentType === 'bankAccountProof');
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Bank Details
            </Typography>
            {banks.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Bank Details
              </Grid>
            ) : (
              banks.map((bank, index: number) => {
                const title = ['Bank 1 Details', 'Bank 2 Details', 'Bank 3 Details'][index];
                const bankAddress = getBankAddress(bank.address1, bank.address2, bank.address3);
                return (
                  <React.Fragment key={bank.id}>
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        {bank.defaultBankAccount &&
                          bank.documentId === getDocument?.documentId &&
                          getDocument &&
                          Object.keys(getDocument).length > 0 && (
                            <>
                              {' '}
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{
                                  mt: 0,
                                }}>
                                <Item>{'Cancelled Cheque'}</Item>
                                <ItemDetails>{getDocument?.file?.name}</ItemDetails>
                              </Grid>
                              {getDocument.file && (
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      mt: 2,
                                      flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                                    }}>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        color: 'common.white',
                                        // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                        minWidth: '70%',
                                        // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        fontWeight: 600,
                                        lineHeight: 1.5,
                                      }}
                                      onClick={() => window.open(getDocument.file?.viewUrl)}>
                                      View
                                    </Button>
                                    <Tooltip title={'Download'}>
                                      <IconButton
                                        sx={{
                                          // p: 0,
                                          // pl: 1.5,
                                          ':hover': {
                                            bgcolor: '#F4FCFC',
                                          },
                                          cursor: 'pointer',
                                          pointerEvents: 'all',
                                          // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        }}
                                        onClick={() => window.open(getDocument.file?.url)}>
                                        <CardMedia
                                          component="img"
                                          src={'/images/download-icon.png'}
                                          alt="Download"
                                          sx={{ width: '25px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              )}
                            </>
                          )}
                        <KeyValue title={'IFSC Code'} description={bank.ifscCode} />
                        <KeyValue
                          title={'Bank Account Number'}
                          description={bank.bankAccountNumber}
                        />
                        <KeyValue title={'MICR Code'} description={bank.micrCode} />
                        <KeyValue title={'Bank Type'} description={bank.bankAccountType} />
                        <KeyValue title={'Bank Name'} description={bank.bankName} />
                        <KeyValue title={'Branch Name'} description={bank.bankBranch} />
                        <KeyValue
                          title={`Bank Address`}
                          description={`${bankAddress}`}
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        />
                        <KeyValue
                          title={`Default Bank Account`}
                          description={bank.defaultBankAccount ? 'Yes' : '-'}
                        />
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
