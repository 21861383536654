import { ProfilePayload, SET_PROFILE_DETAILS } from '../types/profile';
import { CALL_API } from '../middleware';

export const onGetProfileDetails = () => async (dispatch: any) => {
  try {
    // const profileDetails = await dispatch({
    //   [CALL_API]: {
    //     url: `/profile`,
    //     method: 'GET',
    //   },
    // });
    // dispatch({
    //   type: SET_PROFILE_DETAILS,
    //   profileDetails,
    // });
  } catch (error) {
    alert(`${error}`);
  }
};

export const onUpdateProfileDetails = (newValues: ProfilePayload) => async (dispatch: any) => {
  try {
    // let holiday = await dispatch({
    //   [CALL_API]: {
    //     url: `/profile/update`,
    //     method: 'POST',
    //     body: {...newValues},
    //   },
    // });

    const body = newValues;
    dispatch({
      type: SET_PROFILE_DETAILS,
      body,
    });
  } catch (error) {
    throw new Error('Unable to fetch holiday. ' + error);
  }
};
