import { AnyAction } from 'redux';
import { CustodianMapping, InvestorCustodianTypes } from '../actions';
import { GET_CUSTODIAN_CONFIG_SUCCESS, GET_CUSTODIAN_MASTER_SUCCESS } from '../types/mdms';
import { LOGOUT_SUCCESS } from '../types/auth';
import { CREATE_APPLICATION_SUCCESS, GET_ALL_APPLICATION_SUCCESS } from '../types/application';

export type InvestorCustodianProps = {
  custodian: InvestorCustodianTypes;
  custodianConfig: CustodianMapping;
};

const initialState: InvestorCustodianProps = {
  custodian: {},
  custodianConfig: {},
};

export const custodianReducer = (
  state = initialState,
  action: AnyAction
): InvestorCustodianProps => {
  switch (action.type) {
    case GET_CUSTODIAN_CONFIG_SUCCESS:
      return {
        ...state,
        custodianConfig: action.body,
      };
    case GET_CUSTODIAN_MASTER_SUCCESS:
      return {
        ...state,
        custodian: {
          ...state.custodian,
          [action.body.custodian_name]: action.body.Investor_Type_SubType,
        },
      };
    case LOGOUT_SUCCESS:
    case GET_ALL_APPLICATION_SUCCESS:
    case CREATE_APPLICATION_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
