import { numToAlpMonth, toExactSubstr } from '../../utils/utilityFunctions';
import data from './data-ts';
import { Chart as ChartJS, ChartData, ChartOptions, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {
  monthwiseCommitmentAmountType,
  monthwiseTotalInvestmentAmountAndApplicantsType,
} from '../../redux-store/types/api-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  monthwiseCommitmentAmount,
  monthwiseTotalInvestmentAmountAndApplicants,
} from '../../redux-store/actions/application';
import { Card, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { RootStateType } from '../../redux-store/reducers';
import _ from 'lodash';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { USER_ROLES } from '../../utils/constant';

ChartJS.register(...registerables);

export const monthWiseInvest: monthwiseTotalInvestmentAmountAndApplicantsType = {
  data: [
    {
      month: '',
      totalApplicantsCount: 0,
      totalInvestmentAmount: 0,
    },
  ],
  year: '',
};

export const monthWiseCommit: monthwiseCommitmentAmountType = {
  data: [
    {
      month: '',
      completed: 0,
      contributionAmount: 0,
      commitmentAmount: 0,
    },
  ],
  year: '',
};

export default function CommitmentAndContribution(): JSX.Element {
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [investmentAmtData, SetInvestmentAmtData] = useState(monthWiseInvest);
  const InvestmentAmountAndApplicantsData = useSelector(
    (store: RootStateType) => store.investmentAmountAndApplicantsData
  );

  const filterData = InvestmentAmountAndApplicantsData.data.filter((a) => {
    return a;
  });

  // useEffect(() => {
  //   let componentIsAlive = true;
  //   (async function () {
  //     try {
  //       (await dispatch(
  //         monthwiseTotalInvestmentAmountAndApplicants()
  //       )) as unknown as monthwiseTotalInvestmentAmountAndApplicantsType;
  //       if (!componentIsAlive) {
  //         return;
  //       }
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     componentIsAlive = false;
  //   };
  // }, []);

  useEffect(() => {
    SetInvestmentAmtData(InvestmentAmountAndApplicantsData);
  }, [InvestmentAmountAndApplicantsData]);

  const formatCurrencyGraph = (value?: number | null): string => {
    const formats = ['K', 'L', 'CR'];
    let amount: any = Number(value);
    formats.some((key, i) => {
      const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
      if (size <= amount) {
        amount = toExactSubstr(`${amount / size}`);
        if (amount >= 100 && i < formats.length - 1) {
          i++;
        } else {
          amount =
            formats[i] === 'L'
              ? amount.toString().length === 1
                ? Number('0.0' + amount)
                : Number('0.' + amount)
              : amount;
          amount =
            formats[i] === 'K'
              ? amount.toString().length === 1
                ? Number('0.000' + amount)
                : Number('0.00' + amount)
              : amount;
          return true;
        }
      } else {
        return true;
      }
    });
    return amount;
  };

  // month wise commitment and contribution amount line and bar Graph data Initialization start
  const InvestmentAmountAndApplicantsMonth = [] as any;

  const InvestmentAmountAndApplicantsMonthArr = InvestmentAmountAndApplicantsData.data.map(
    (data) => data.month
  );

  numToAlpMonth(InvestmentAmountAndApplicantsMonthArr, InvestmentAmountAndApplicantsMonth);

  data.lineBarData.labels = InvestmentAmountAndApplicantsMonth;

  const InvestmentAmountBar = InvestmentAmountAndApplicantsData.data.map(
    (amt) => amt.totalInvestmentAmount
  );
  const ApplicantsBar = InvestmentAmountAndApplicantsData.data.map(
    (amt) => amt.totalApplicantsCount
  );

  const InvestmentAmountNum = [] as any;
  const ApplicantsNum = [] as any;

  InvestmentAmountBar.map((a) => {
    InvestmentAmountNum.push(Number(formatCurrencyGraph(a)));
  });

  ApplicantsBar.map((a) => {
    ApplicantsNum.push(Number(formatCurrencyGraph(a)));
  });

  data.multiAxisMixedData.datasets.map((total) => {
    if (total.label === 'Total Invested Amount of all applications (in Cr)') {
      total.data = InvestmentAmountNum;
    }

    if (total.label === 'No of applicants') {
      total.data = ApplicantsNum;
    }
  });

  //month wise commitment and contribution amount line and bar Graph data Initialization End

  return (
    <Grid item xs={12} sm={12} md={12} lg={7}>
      <Card
        raised={true}
        sx={{
          height: {
            lg:
              USER_ROLES.AMC_ADMIN !== role && filterData.length === 0
                ? 440
                : USER_ROLES.AMC_ADMIN !== role && filterData.length !== 0
                ? 610
                : USER_ROLES.AMC_ADMIN === role && filterData.length !== 0
                ? 440
                : USER_ROLES.AMC_ADMIN === role && filterData.length === 0
                ? 440
                : 610,
          },
          pt: USER_ROLES.AMC_ADMIN === role ? 4 : 2,
          px: USER_ROLES.AMC_ADMIN === role ? { xs: 1, sm: 3 } : { xs: 1, sm: 6 },
          pb: USER_ROLES.AMC_ADMIN === role ? 0 : 2,
          width: { lg: USER_ROLES.AMC_ADMIN === role ? 'unset' : 1160 },
        }}>
        {!InvestmentAmountAndApplicantsData.year ||
        JSON.stringify(investmentAmtData) !== JSON.stringify(InvestmentAmountAndApplicantsData) ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  // width: { xs: 500, sm: 600 },
                  // height: { xs: 220, sm: 270 },
                  // mt: { xs: 0, lg: 1 },
                  // mb: { xs: 0, lg: 2 },
                  // ml: { xs: 0, sm: 15, lg: 2 },
                  width: { xs: '300px', sm: USER_ROLES.AMC_ADMIN === role ? 600 : 1040 },
                  height: { xs: 220, sm: USER_ROLES.AMC_ADMIN === role ? 320 : 460 },
                  mt: { xs: 0, lg: 1 },
                  mb: { xs: 0, lg: 2 },
                  m: { xs: 0, sm: 15, lg: 2 },
                }}
              />
            </Box>
            <Skeleton
              sx={{
                width: { xs: '300px', sm: USER_ROLES.AMC_ADMIN === role ? 590 : 1040 },
                ml: { xs: 0, sm: 15, lg: 2 },
              }}
            />
          </>
        ) : (
          <>
            {filterData.length > 0 ? (
              <>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                  }}>
                  <InfoOutlinedIcon color="info" fontSize="small" />
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: 12,
                      fontWeight: 500,
                      ml: 1,
                    }}>
                    Graphical Data is shown only for completed applications .
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'rgb(97, 209, 214)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {`Year ${InvestmentAmountAndApplicantsData.year}`}
                </Typography>
                <Typography
                  component={'div'}
                  className="canvas-container"
                  sx={{
                    '&.canvas-container': {
                      position: 'relative',
                      width: { xs: '320px', sm: 'unset' },
                      margin: 'auto',
                    },
                  }}>
                  <Chart
                    type="bar"
                    data={data.multiAxisMixedData as ChartData}
                    options={data.multiAxisMixedOptions as ChartOptions}
                  />
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                  }}>
                  <InfoOutlinedIcon color="info" fontSize="small" />
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: 12,
                      fontWeight: 500,
                      ml: 1,
                    }}>
                    Graphical Data is shown only for completed applications .
                  </Typography>
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    // backgroundColor: 'red',
                    // pt: 0,
                  }}>
                  <CardMedia
                    component="img"
                    src="/images/Nodata.png"
                    sx={{
                      height: '40%',
                      width: '40%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      color: 'rgb(97, 209, 214)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: USER_ROLES.AMC_ADMIN === role ? '12%' : '7%',
                    }}>
                    No Data Available
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
}
