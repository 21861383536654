import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { Notes, SubHeading } from '../investors/components';
import { Location } from 'history';
import {
  AdminStrategyDetailsType,
  DistributorListType,
  strategyFormType,
} from '../../redux-store/types/api-types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

import { addStrategy, getStrategyById, updateStrategy } from '../../redux-store/actions/strategies';
import { addStrategyValidation } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import {
  ACCOUNT_TYPE_OPTIONS,
  FeeDetailTypeMasters,
  STRATEGY_TYPE_OPTIONS,
  distributorMaster,
  feeDetailsBrokerageAndTax,
} from '../../utils/constant';
import MFCheckbox from '../../lib/formik/Checkbox';
import { showError } from '../../redux-store/actions/auth';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import UseRadioGroup from '../../lib/formik/Radio';
import { ConfirmationDialog } from '../commonComponents';
import { checkDPMSFlow, checkNDPMFlow, getKey } from '../../utils/utilityFunctions';
import { Item, ItemDetails } from '../ApplicationDetails';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { particulars } from '../../utils/feeDescription';

const strategyFeilds = {
  sno: 0,
  fee: '',
  exitLoad: '',
  hurdleRate: '',
  applicableForType: '',
  applicableFlowType: '',
  charges: '',
  feeType: '',
  performanceFee: '',
  isActive: true,
};
export const filterStrategyFeeDetails = (strategy: strategyFormType[]): strategyFormType[] => {
  const filteredStrategyFeeDetails = strategy?.filter(
    (_strategy: strategyFormType) => _strategy.isActive
  );

  return filteredStrategyFeeDetails;
};

const isApplicableTypeDirect = (val: string): boolean => {
  return distributorMaster[val] === distributorMaster.Direct;
};
const isApplicableTypeDistributor = (val: string): boolean => {
  return distributorMaster[val] === distributorMaster.Distributor;
};

export const checkVariableFee = (val: string): boolean => {
  return FeeDetailTypeMasters[val] === FeeDetailTypeMasters.Variable;
};

export const checkStratagyFeeDetails = (
  isApplicableForDirectVal: boolean | null,
  isApplicableForDistributorVal: boolean | null,
  isDPMSFlowVal: boolean | null,
  isNDPMSFlowVal: boolean | null
): boolean => {
  return !!(
    (isApplicableForDirectVal || isApplicableForDistributorVal) &&
    (isDPMSFlowVal || isNDPMSFlowVal)
  );
};

const initialValues: AdminStrategyDetailsType = {
  strategyName: '',
  strategyType: '',
  benchmark: '',
  isActive: true,
  distributorDetails: {
    distributorIds: [],
    distributors: [],
  },
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  strategyFeeDetails: [],
  isNDPMSFlow: false,
  isDPMSFlow: false,
};

export default function AddStrategy({
  location,
}: {
  location: Location<{ StrategyId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [strategyInitial, setStrategyInitial] = useState(initialValues);
  const { StrategyId } = location.state || {};
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);
  const [stratagyFeeToDelete, setStratagyFeeToDelete] = useState<number | null>(null);

  const accountTypeMaster = getKey(ACCOUNT_TYPE_OPTIONS);
  const provideTypeMaster = getKey(distributorMaster);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorListType[];
        setDistributorList(res);
        if (StrategyId) {
          const GetStrategyDetail = (await dispatch(
            getStrategyById(StrategyId)
          )) as unknown as AdminStrategyDetailsType;
          const {
            strategyName,
            strategyType,
            benchmark,
            strategyFeeDetails,
            isApplicableForDirect,
            isApplicableForDistributor,
            distributorDetails,
            isDPMSFlow,
            isNDPMSFlow,
          } = GetStrategyDetail || {};

          setStrategyInitial({
            ...strategyInitial,
            strategyName,
            strategyType,
            benchmark,
            isDPMSFlow,
            isNDPMSFlow,
            isApplicableForDirect,
            isApplicableForDistributor,
            distributorDetails,
            distributorIds:
              distributorDetails && distributorDetails.distributorIds?.length <= 0
                ? null
                : distributorDetails?.distributorIds || null,
            strategyFeeDetails:
              strategyFeeDetails?.length > 0
                ? strategyFeeDetails?.map((strategy, index) => {
                    return {
                      ...strategy,
                      sno: index,
                    };
                  })
                : [],
          });
        } else {
          setStrategyInitial({
            ...strategyInitial,
          });
        }

        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AdminStrategyDetailsType) => {
    try {
      if (!values.isApplicableForDirect && !values.isApplicableForDistributor) {
        throw 'Please select Direct or Distributor';
      }
      if (!values.isDPMSFlow && !values.isNDPMSFlow) {
        throw 'Please select Discretionary or Non discretionary';
      }
      if (filterStrategyFeeDetails(values.strategyFeeDetails)?.length <= 0) {
        throw 'Please add at least one fee detail.';
      }
      if (
        (values.isApplicableForDirect &&
          !filterStrategyFeeDetails(values.strategyFeeDetails)?.some((val) =>
            isApplicableTypeDirect(val.applicableForType)
          )) ||
        (values.isApplicableForDistributor &&
          !filterStrategyFeeDetails(values.strategyFeeDetails)?.some((val) =>
            isApplicableTypeDistributor(val.applicableForType)
          ))
      ) {
        throw 'Please add at least one fee detail with a type distribution and one with a type direct.';
      }
      if (
        (values.isDPMSFlow &&
          !filterStrategyFeeDetails(values.strategyFeeDetails)?.some((val) =>
            checkDPMSFlow(val.applicableFlowType || '')
          )) ||
        (values.isNDPMSFlow &&
          !filterStrategyFeeDetails(values.strategyFeeDetails)?.some((val) =>
            checkNDPMFlow(val.applicableFlowType || '')
          ))
      ) {
        throw 'Please add at least one fee detail with a type Discretionary and one with a type Non discretionary.';
      }

      const updatedStrategyFeeDetails = values.strategyFeeDetails
        ?.filter((_feeDetail) => _feeDetail?.id || _feeDetail.isActive)
        ?.map((val) => {
          // eslint-disable-next-line
          const { sno, ...rest } = val;
          const updateFeeDetails = {
            ...rest,
            hurdleRate: checkVariableFee(rest.feeType) ? rest.hurdleRate : '',
            performanceFee: checkVariableFee(rest.feeType) ? rest.performanceFee : '',
          };
          return updateFeeDetails;
        });
      // eslint-disable-next-line
      const { distributorDetails, ...rest } = values;
      const payloadObj = {
        ...rest,
        strategyFeeDetails: updatedStrategyFeeDetails,
        distributorIds: rest.isApplicableForDistributor ? rest.distributorIds : [],
      };
      if (!StrategyId) {
        await dispatch(addStrategy(payloadObj));
      } else {
        await dispatch(updateStrategy(StrategyId, payloadObj));
      }
      history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
        StrategyId: StrategyId,
      });
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={strategyInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validate={(values: AdminStrategyDetailsType) => {
          try {
            validateYupSchema(
              values,
              addStrategyValidation(distributorList?.map((distributor) => Number(distributor.id))),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
                      StrategyId: StrategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Strategy Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
                      // planId: StrategyId,
                      StrategyId: StrategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {StrategyId ? 'Edit Strategy' : 'Add Strategy'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {'Strategy Details'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="strategyName"
                      label="Strategy Name *"
                      placeholder="Enter Strategy Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name={`strategyType`}
                      label="Strategy type *"
                      items={Object.keys(STRATEGY_TYPE_OPTIONS)?.map((item) => ({
                        key: STRATEGY_TYPE_OPTIONS[item],
                        value: item,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name={`benchmark`}
                      label="Benchmark"
                      placeholder="Enter Benchmark"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 5 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <MFCheckbox
                        name={`isDPMSFlow`}
                        label="Discretionary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          let updateValues = [];

                          updateValues = values?.strategyFeeDetails?.map(
                            (value: strategyFormType) => {
                              return {
                                ...value,
                                isActive:
                                  checkDPMSFlow(value.applicableFlowType || '') &&
                                  !event.target.checked
                                    ? event.target.checked
                                    : value.isActive,
                                applicableFlowType:
                                  !value.applicableFlowType && !event.target.checked
                                    ? accountTypeMaster.non_discretionary
                                    : value.applicableFlowType,
                              };
                            }
                          );

                          setValues({
                            ...values,
                            isDPMSFlow: event.target.checked,
                            strategyFeeDetails: updateValues,
                          });
                        }}
                      />
                      <MFCheckbox
                        name={`isNDPMSFlow`}
                        label="Non discretionary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          let updateValues = [];

                          updateValues = values?.strategyFeeDetails?.map(
                            (value: strategyFormType) => {
                              return {
                                ...value,
                                isActive:
                                  checkNDPMFlow(value.applicableFlowType || '') &&
                                  !event.target.checked
                                    ? event.target.checked
                                    : value.isActive,
                                applicableFlowType:
                                  !value.applicableFlowType && !event.target.checked
                                    ? accountTypeMaster.discretionary
                                    : value.applicableFlowType,
                              };
                            }
                          );

                          setValues({
                            ...values,
                            isNDPMSFlow: event.target.checked,
                            strategyFeeDetails: updateValues,
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 5 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <MFCheckbox
                        name={`isApplicableForDirect`}
                        label="Direct"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          let updateValues = [];

                          updateValues = values?.strategyFeeDetails?.map(
                            (value: strategyFormType) => {
                              return {
                                ...value,
                                isActive:
                                  isApplicableTypeDirect(value.applicableForType) &&
                                  !event.target.checked
                                    ? event.target.checked
                                    : value.isActive,
                                applicableForType:
                                  !value.applicableForType && !event.target.checked
                                    ? provideTypeMaster.Distributor
                                    : value.applicableForType,
                              };
                            }
                          );

                          setValues({
                            ...values,
                            isApplicableForDirect: event.target.checked,
                            strategyFeeDetails: updateValues,
                          });
                        }}
                      />
                      <MFCheckbox
                        name={`isApplicableForDistributor`}
                        label="Distributor"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          let updateValues = [];

                          updateValues = values?.strategyFeeDetails?.map(
                            (value: strategyFormType) => {
                              return {
                                ...value,
                                isActive:
                                  isApplicableTypeDistributor(value.applicableForType) &&
                                  !event.target.checked
                                    ? event.target.checked
                                    : value.isActive,
                                applicableForType:
                                  !value.applicableForType && !event.target.checked
                                    ? provideTypeMaster.Direct
                                    : value.applicableForType,
                              };
                            }
                          );

                          setValues({
                            ...values,
                            isApplicableForDistributor: event.target.checked,
                            strategyFeeDetails: updateValues,
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  {values.isApplicableForDistributor && (
                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name="distributorIds"
                        label="Applicable Distributor(s) *"
                        items={distributorList?.map((item) => ({
                          key: item.id,
                          value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                        }))}
                        renderText="Selected Distributor(s)"
                      />
                    </Grid>
                  )}
                </Grid>

                {checkStratagyFeeDetails(
                  values?.isApplicableForDirect,
                  values?.isApplicableForDistributor,
                  values?.isDPMSFlow,
                  values?.isNDPMSFlow
                ) && (
                  <>
                    {filterStrategyFeeDetails(values?.strategyFeeDetails)?.length > 0 && (
                      <>
                        <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                          <SubHeading
                            sx={{
                              color: 'common.blue',
                              letterSpacing: 0.5 + 'px',
                              padding: { xs: '10px 10px', sm: '10px 30px' },
                              ml: { xs: 3.5 },
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Typography>{`Fee Details`}</Typography>
                          </SubHeading>
                        </Grid>
                        {values?.strategyFeeDetails &&
                          values?.strategyFeeDetails?.length > 0 &&
                          filterStrategyFeeDetails(values?.strategyFeeDetails || [])?.map(
                            (strategy, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Box
                                    sx={{
                                      bgcolor: 'white',
                                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
                                      borderRadius: '10px',
                                      py: { xs: 2, sm: 5 },
                                      pl: { xs: 0, sm: 5 },
                                      mt: { xs: 2, sm: 5 },
                                    }}>
                                    <Grid container rowSpacing={1} columnSpacing={4} px={4}>
                                      <Grid
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'flex-end'}
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}>
                                        <IconButton
                                          disabled={
                                            filterStrategyFeeDetails(values?.strategyFeeDetails)
                                              ?.length === 1
                                          }
                                          onClick={() => {
                                            setStratagyFeeToDelete(strategy.sno as number);
                                          }}>
                                          <DeleteOutlineIcon
                                            titleAccess="Delete Form"
                                            sx={{ cursor: 'pointer' }}
                                            color={
                                              filterStrategyFeeDetails(values?.strategyFeeDetails)
                                                ?.length === 1
                                                ? 'disabled'
                                                : 'error'
                                            }
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <MFSelectField
                                          name={`strategyFeeDetails.${strategy.sno}.applicableFlowType`}
                                          label="Account Type *"
                                          items={Object.keys(ACCOUNT_TYPE_OPTIONS)?.map((item) => ({
                                            key: ACCOUNT_TYPE_OPTIONS[item],
                                            value: item,
                                          }))}
                                          disabled={!(values.isDPMSFlow === values.isNDPMSFlow)}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <MFSelectField
                                          name={`strategyFeeDetails.${strategy.sno}.feeType`}
                                          label="Fee Type *"
                                          items={Object.keys(FeeDetailTypeMasters)?.map((item) => ({
                                            key: FeeDetailTypeMasters[item],
                                            value: item,
                                          }))}
                                          disabled={!!strategy.id && !!strategy.feeType}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{ pt: '25px !important' }}>
                                        <UseRadioGroup
                                          disabled={
                                            !(
                                              values.isApplicableForDirect ===
                                              values.isApplicableForDistributor
                                            )
                                          }
                                          formLabel="Provide Type *"
                                          name={`strategyFeeDetails.${strategy.sno}.applicableForType`}
                                          items={Object.keys(distributorMaster)?.map(
                                            (applicableForType) => ({
                                              label: distributorMaster[applicableForType],
                                              value: applicableForType,
                                            })
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <MFTextField
                                          name={`strategyFeeDetails.${strategy.sno}.fee`}
                                          label="Management Fees *"
                                          placeholder="Enter Management Fees"
                                          disabled={!strategy.feeType}
                                        />
                                      </Grid>
                                      {checkVariableFee(strategy.feeType) && (
                                        <>
                                          {' '}
                                          <Grid item xs={12} sm={6} md={4}>
                                            <MFTextField
                                              name={`strategyFeeDetails.${strategy.sno}.hurdleRate`}
                                              label="Hurdle Rate *"
                                              placeholder="Enter Hurdle Rate"
                                              disabled={!strategy.feeType}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6} md={4}>
                                            <MFTextField
                                              name={`strategyFeeDetails.${strategy.sno}.performanceFee`}
                                              label="Performance Fees *"
                                              placeholder="Enter Performance Fees"
                                              disabled={!strategy.feeType}
                                            />
                                          </Grid>
                                        </>
                                      )}

                                      <Grid item xs={12} sm={6} md={4}>
                                        <MFTextField
                                          name={`strategyFeeDetails.${strategy.sno}.exitLoad`}
                                          label="Exit Load *"
                                          placeholder="Enter Exit Load"
                                          disabled={!strategy.feeType}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <MFTextField
                                          name={`strategyFeeDetails.${strategy.sno}.charges`}
                                          label="Other Charges *"
                                          placeholder="Enter Other Charges"
                                          disabled={!strategy.feeType}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </React.Fragment>
                              );
                            }
                          )}
                      </>
                    )}

                    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                      <Grid
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                        item
                        xs={12}
                        sm={12}
                        md={12}>
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          sx={{
                            color: 'primary.main',
                            fontWeight: 500,
                            fontSize: 14,
                            mr: { xs: 0, sm: 5 },
                            mt: { xs: 0.5, sm: 1 },
                          }}
                          onClick={() => {
                            const newStrategyForm = {
                              ...strategyFeilds,
                              sno: values.strategyFeeDetails?.length,
                              applicableForType:
                                values.isApplicableForDirect === values.isApplicableForDistributor
                                  ? ''
                                  : values.isApplicableForDirect
                                  ? provideTypeMaster.Direct
                                  : values.isApplicableForDistributor
                                  ? provideTypeMaster.Distributor
                                  : '',
                              applicableFlowType:
                                values.isDPMSFlow === values.isNDPMSFlow
                                  ? ''
                                  : values.isDPMSFlow
                                  ? accountTypeMaster.discretionary
                                  : values.isNDPMSFlow
                                  ? accountTypeMaster.non_discretionary
                                  : '',
                            };

                            setValues((val) => {
                              return {
                                ...val,
                                strategyFeeDetails: [...val.strategyFeeDetails, newStrategyForm],
                              };
                            });
                          }}>
                          Add Fee Details
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
            <ConfirmationDialog
              message={`Are you sure you want to delete the fee details ?`}
              open={stratagyFeeToDelete !== null}
              setOpen={() => setStratagyFeeToDelete(null)}
              onSave={() => {
                setValues({
                  ...values,
                  strategyFeeDetails: values.strategyFeeDetails?.map((formData) => {
                    return formData.sno === stratagyFeeToDelete
                      ? { ...formData, isActive: false }
                      : formData;
                  }),
                });
                setStratagyFeeToDelete(null);
              }}
              onCancel={() => setStratagyFeeToDelete(null)}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
