/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { validateYupSchema } from 'formik';
import { bankObject } from '../../components/investors/bankDetails';
//import { getDocumentDetails } from '../../components/investors/documentDetails';
import { contactPersonObject } from '../../components/NonIndividualInvestor/ContactDetails';
import { document_object } from '../../components/NonIndividualInvestor/DocumentDetails';
import { percentageError } from '../../components/NonIndividualInvestor/ubo';
import {
  APMIFlow,
  APPLICANT_STATUS,
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  CHECKER_EDIT_ALL_SECTIONS,
  DLCLMasters,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  occupationDetailsMasters,
  salutationsMasters,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import { emailRegex, individualPanRegex } from '../../utils/regex';
import {
  bankDetailsSchema,
  contributorDetailsSchema,
  disclosureOfExcludedSchema,
  disclosureOfInterestSchema,
  documentDetailsSchema,
  documentDetailsSchemaForInvestorLogin,
  FATCAValidationSchema,
  KYCDetailsSchema,
  nomineeDetailsSchema,
  nonIndividualContactDetailsSchema,
  NonIndividualContributorValidationSchema,
  nonIndividualDocumentDetailsSchema,
  nonIndividualFatcaSchema,
  relatedPartyConsentSchema,
  strategyDeclarationScheme,
} from '../../utils/schema';
import {
  addressInitialFields,
  checkIfApplicationIsNonIndividual,
  checkNDPMSForIndusindBank,
  displayInputFieldWithLabel,
  documentNameValidationCheckForMultipleDocumentsMandatory,
  fieldValidateForInvestor,
  getAddressData,
  getNomineeRelation,
  getOccupationType,
  getRelation,
  isApplicantNRI,
  isApplicantPEPOrRPEP,
  isCustodianAxis,
  isCustodianHDFC,
  isCustodianICICI,
  isCustodianICICIOrHDFC,
  isCustodianKotak,
  isCustodianOrbis,
  isMinor,
  IsRiskFieldTextfield,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import {
  AuthorisedErrors,
  BankError,
  CALL_API,
  UboErrors,
  RiskProfileErrors,
  StrategyErrors,
  RelatedPartyConsentErrors,
  FatcaErrors,
  fatcaErrors,
  KycDetailsErrors,
  DisclosureOfInterestErrors,
  DisclosureOfExclusionsErrors,
} from '../middleware';
import { useDispatch, useSelector } from 'react-redux';

import {
  Applicant,
  ApplicationProps,
  Bank,
  NomineeType,
  Document,
  uboTypes,
  FetchUBORequestBody,
  StampPapersType,
  dashboardOnboardingType,
  monthwiseOnboardingSummaryType,
  distributorWisecommitmentType,
  monthwiseCommitmentAmountType,
  ubo,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  Groups,
  GroupSignatories,
  RiskProfileMasterType,
  TimeLineType,
  getDetailsByRefrenceType,
  nonIndividualMdmsQuestionsFatca,
  getAuthorizedSignatoriesDetailsByRefrenceType,
  monthwiseTotalInvestmentAmountAndApplicantsType,
} from '../types/api-types';
import {
  CreateApplicationRequestBody,
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_SUCCESS,
  GET_STAMPPAPER_DETAILS_SUCCESS,
  GET_STAMPPAPER_DETAILS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT,
  GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS,
  GET_MONTHWISE_COMMITMENTAMOUNT,
  GET_ONBOARDING_SUMMARY_SUCCESS,
  GET_ONBOARDING_SUMMARY,
  GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS,
  GET_MONTHWISE_ONBOARDING_SUMMARY,
  GET_ALL_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION,
  AddJointHolderRequestBody,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT,
  GetAllApplicantsRequestBody,
  GetAllApplicantionsResponseBody,
  PENNY_DROP_VERIFICATION_SUCCESS,
  PENNY_DROP_VERIFICATION,
  GET_Documents_SUCCESS,
  GET_Documents,
  GET_Ubo_SUCCESS,
  GET_Ubo,
  UBO_LISTING_SUCCESS,
  UBO_LISTING,
  GET_Fatca_SUCCESS,
  GET_Fatca,
  TYPED_DECLARATION_SUCCESS,
  TYPED_DECLARATION,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS,
  AUTHORIZED_SIGNATORIES_TYPED_DECLARATION,
  GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS,
  GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS,
  CREATE_NDPSM_BANKFORM,
  CREATE_NDPSM_BANKFORM_SUCCESS,
  GET_Kyc_DASHBOARD_DETAILS_SUCCESS,
  GET_Kyc_DASHBOARD_DETAILS,
} from '../types/application';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
  ValidationOrConfigMaster,
} from '../types/mdms';
import { GetStrategiesResponseBody } from '../types/strategies';
import {
  feeTypeStratagiesFilter,
  newStrategy,
  updatedStrategyDetails,
} from '../../components/investors/strategyDetails';
import { handleIntialSelect, RiskProfileObj } from '../../components/investors/riskProfileDetails';
import { relatedDataArr } from '../../components/investors/relatedPartyDetails';
import { newNominee } from '../../components/investors/nomineeDetails';
import {
  investorEditDisclosureOfExclusionObj,
  investorEditDisclosureOfInterestObj,
  investorEditDocumentDetailsObj,
  investorEditRiskProfileObj,
  investorEditAuthorizedSignatoryObj,
} from '../../components/investors/components';
import { InvestorEditErrorObj, InvestorEditSectionProps } from '../reducers/investorEditSections';
import {
  AssignAnsAndVisibilityForInitialFatca,
  validationPopUp,
} from '../../components/NonIndividualInvestor/fatca';
import { documentDetails } from '../../components/investors/documentDetails';
import { InvestorCustodianTypes } from './mdms';
import { getASDocuments } from '../../components/NonIndividualInvestor/Authorised';
import { getFieldsDisabled } from '../../components/investors/contactDetails';
import { sectionFilledByInvestor } from '../../utils/declaration';

export const createApplication =
  (body: CreateApplicationRequestBody) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'POST',
        types: [CREATE_APPLICATION_SUCCESS, CREATE_APPLICATION],
        body,
      },
    });
  };

export const getApplicationDetails =
  (applicationId: string, method = '') =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}`,
        method: method ? 'DELETE' : 'GET',
        types: [GET_APPLICATION_SUCCESS, GET_APPLICATION],
      },
    });
  };

export const getTimeLineDetails =
  (applicationId: string) =>
  async (dispatch: any): Promise<TimeLineType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/activity-logs`,
        method: 'GET',
        types: [GET_TIMELINE_SUCCESS, GET_TIMELINE],
      },
    });
  };

export const getParams = (paramsObj: Partial<GetAllApplicantsRequestBody>) => ({
  type: 'GET_PARAMS_DATA',
  paramsObj,
});
export const getAllApplications =
  (params: Partial<GetAllApplicantsRequestBody>) =>
  async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
    await dispatch(getParams(params));
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'GET',
        types: [GET_ALL_APPLICATION_SUCCESS, GET_ALL_APPLICATION],
        params: {
          limit: 10,
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const onboardingSummary =
  () =>
  async (dispatch: any): Promise<dashboardOnboardingType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/onboardingSummary`,
        method: 'GET',
        types: [GET_ONBOARDING_SUMMARY_SUCCESS, GET_ONBOARDING_SUMMARY],
      },
    });
  };

export const getKycDashboardDetails =
  () =>
  async (dispatch: any): Promise<dashboardOnboardingType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/onboardingKycUpdationSummary`,
        method: 'GET',
        types: [GET_Kyc_DASHBOARD_DETAILS_SUCCESS, GET_Kyc_DASHBOARD_DETAILS],
      },
    });
  };

export const monthwiseOnboardingSummary =
  () =>
  async (dispatch: any): Promise<monthwiseOnboardingSummaryType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseOnboardingSummary?months=4`,
        method: 'GET',
        types: [GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS, GET_MONTHWISE_ONBOARDING_SUMMARY],
      },
    });
  };

export const distributorWisecommitmentAmount =
  () =>
  async (dispatch: any): Promise<distributorWisecommitmentType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/distributorWisecommitmentAmount`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS, GET_DISTRIBUTOR_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseCommitmentAmount =
  () =>
  async (dispatch: any): Promise<monthwiseCommitmentAmountType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseCommitmentAmount`,
        method: 'GET',
        types: [GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS, GET_MONTHWISE_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseTotalInvestmentAmountAndApplicants =
  () =>
  async (dispatch: any): Promise<monthwiseTotalInvestmentAmountAndApplicantsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseTotalInvestmentAmountAndApplicants`,
        method: 'GET',
        types: [
          GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS,
          GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS,
        ],
      },
    });
  };

// export const getStampPaperCount =
//   (params: Partial<GetAllApplicantsRequestBody>) =>
//   async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
//     return await dispatch({
//       [CALL_API]: {
//         url: `/leegality/stampPaperDetails`,
//         method: 'GET',
//         types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],

//       },
//     });
//   };

export const getStampPaperCount =
  () =>
  async (dispatch: any): Promise<StampPapersType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/leegality/stampPaperDetails`,
        method: 'GET',
        types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],
      },
    });
  };

export const createBankAccountFormNDPSM =
  (applicationId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/send-aof-form/${applicationId}`,
        method: 'GET',
        types: [CREATE_NDPSM_BANKFORM_SUCCESS, CREATE_NDPSM_BANKFORM],
      },
    });
  };

export const updateApplication =
  ({
    body,
    applicationId,
    toastMessage,
  }: {
    body: Partial<ApplicationProps>;
    applicationId: string;
    toastMessage?: string;
  }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    const showToast = typeof toastMessage !== 'undefined';
    if (showToast) {
      toastMessage =
        `Application ${body.applicationNumber || ''} - ` + (toastMessage || ' Saved successfully');
    }
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/applications/${applicationId}/update`,
          method: 'POST',
          types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
          body,
          showToast,
          toastMessage,
        },
      });
    } catch (error: any) {
      console.log('catch error on update application', error);
      throw error;
    }
  };

export const addJointHolder =
  (body: AddJointHolderRequestBody, applicationId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/addApplicant`,
        method: 'POST',
        types: [ADD_APPLICANT_SUCCESS, ADD_APPLICANT],
        body,
      },
    });
  };

export const sendTypedDeclaration =
  (
    body: {
      typedDeclaration: string;
    },
    applicantId: string
  ) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/typed-declaration/${applicantId}`,
        method: 'POST',
        types: [TYPED_DECLARATION_SUCCESS, TYPED_DECLARATION],
        body,
      },
    });
  };

export const authorizedSignatoriesTypedDeclaration =
  (
    body: {
      groupSignatoryConsent?: boolean;
      groupsignatorydocuments: documentDetails[];
    },
    refId: string
  ) =>
  async (dispatch: any): Promise<getAuthorizedSignatoriesDetailsByRefrenceType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${refId}/group-signatory/uploadDocumentsAndConsent`,
        method: 'POST',
        types: [
          AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS,
          AUTHORIZED_SIGNATORIES_TYPED_DECLARATION,
        ],
        body,
      },
    });
  };

export const validateAdditionalDetails = async (
  application: ApplicationProps | null,
  role: any,
  custodian: string,
  custodianData: InvestorCustodianTypes,
  maritalStatusChange: string
) => {
  const { applicants = [], createdAt = '' } = application || {};
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      ...applicant,
      nationality: applicant.nationality
        ? applicant.nationality
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Indian',
      namePrefix:
        applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
          ? applicant.namePrefix
          : 'Mr.',
      jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
        ? applicant.relationShipWithFirstApplicant
        : 'Others',
      relationShipWithFirstApplicant:
        applicant.relationShipWithFirstApplicant === 'Others' ||
        getRelation(applicant.relationShipWithFirstApplicant)
          ? ''
          : applicant.relationShipWithFirstApplicant,
      occupationType: getOccupationType(applicant.occupationDetails)
        ? applicant.occupationDetails
        : 'OTHERS',
      occupationDetails:
        applicant.occupationDetails === 'OTHERS'
          ? ''
          : getOccupationType(applicant.occupationDetails)
          ? ''
          : applicant.occupationDetails,
      occupationDescription: applicant.occupationDescription || '',
      maritalStatus: applicant.maritalStatus || '',
      maritalStatusOthers: applicant.maritalStatusOthers || '',
      aadhaarNumber: applicant.aadhaarNumber || '',
      aadhaarCheck: (isCustodianHDFC(custodian) ? 'Yes' : applicant.aadhaarCheck) || '',
      isExisitngCustomer: isCustodianAxis(custodian) ? applicant.isExisitngCustomer : '',
      customerId: applicant.customerId || '',
      educationalQualification: applicant.educationalQualification || '',
      taxAssesseeCheck: applicant.taxAssesseeCheck || '',
      gender: applicant.gender || '',
      dateOfBecomeNRI: applicant.dateOfBecomeNRI || null,
      investorSubType: applicant.investorSubType || '',
    })),
    custodianData: custodianData,
  };

  try {
    await validateYupSchema(
      schemaData,
      contributorDetailsSchema(custodian, createdAt, maritalStatusChange),
      true,
      schemaData
    );
  } catch (error) {
    throw `Applicant(s) details`;
  }
};

export const validateKYCDetails = async (
  application: ApplicationProps | null,
  role: any,
  custodian: string,
  commSendTo: string,
  dlclId?: string,
  dividendDeclaration?: boolean,
  cdslAddtionalType?: string,
  pledgeDeclaration?: boolean
) => {
  const { applicants = [] } = application || {};
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      grossAnnualIncome: applicant.grossAnnualIncome || '',
      income: applicant.income || 0,
      politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
        ? applicant.politicallyExposedPersonStatus
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Not Applicable',
      otherPepInfo:
        isCustodianAxis(custodian) &&
        isApplicantPEPOrRPEP(applicant.politicallyExposedPersonStatus || '')
          ? applicant.otherPepInfo
          : '',
      ckycNo: applicant.ckycNo || '',
      grossAnnualIncomeDate: applicant.grossAnnualIncomeDate || null,
      netWorthDate: applicant.netWorthDate || null,
      netWorth: applicant.netWorth || '',
      sourceOfFund: checkNdpmsFlow || isCustodianOrbis(custodian) ? applicant.sourceOfFund : '',
      residence: checkNdpmsFlow ? applicant.residence : '',
      monthlyIncome: checkNdpmsFlow ? applicant.monthlyIncome : '',
      sourceOfFundOthers:
        checkNdpmsFlow || isCustodianOrbis(custodian) ? applicant.sourceOfFundOthers : '',
      sourceOfFundAmount: isCustodianOrbis(custodian) ? applicant.sourceOfFundAmount : null,
      projectedCashTransaction: checkNdpmsFlow ? applicant.projectedCashTransaction : '',
      sourceOfWealth: isCustodianOrbis(custodian) ? applicant.sourceOfWealth : '',
      sourceOfWealthOthers: isCustodianOrbis(custodian) ? applicant.sourceOfWealthOthers : '',
      sourceOfWealthAmount: isCustodianOrbis(custodian) ? applicant.sourceOfWealthAmount : null,
      lineOfBusiness: isCustodianOrbis(custodian) ? applicant.lineOfBusiness : '',
      lineOfBusinessOthers: isCustodianOrbis(custodian) ? applicant.lineOfBusinessOthers : '',
    })),
    commSendTo: commSendTo,
    dlclId: isCustodianAxis(custodian) ? dlclId : '',
    cdslAddtionalType: isCustodianAxis(custodian) ? cdslAddtionalType : '',
  };
  if (
    isCustodianAxis(custodian) &&
    dlclId &&
    DLCLMasters[dlclId || ''] === DLCLMasters.cdsl &&
    !pledgeDeclaration &&
    !dividendDeclaration
  ) {
    throw new KycDetailsErrors('In KYC details, CDSL Declaration(s) are required');
  }
  try {
    await validateYupSchema(
      schemaData,
      KYCDetailsSchema(checkNdpmsFlow, custodian),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof KycDetailsErrors) {
      throw error;
    }
    throw `KYC details`;
  }
};

export const validateFATCA = async (
  custodian: string,
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    applicants: applicants?.map((applicant) => ({
      typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
      addresses: applicant.addresses || [],
      nationality: applicant.nationality || '',
      taxResidentOfAnyCountryOtherThanIndia:
        applicant.taxResidentOfAnyCountryOtherThanIndia || false,
      placeOfBirth: applicant.placeOfBirth || '',
      countryOfBirth: applicant.countryOfBirth
        ? applicant.countryOfBirth.toUpperCase()
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      countryOfNationality: applicant.countryOfNationality
        ? applicant.countryOfNationality.toUpperCase()
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      taxCountryName: applicant.taxCountryName || '',
      taxID: applicant.taxID || '',
      idType: applicant.idType || '',
      nameOfEntity: applicant.nameOfEntity || '',
      dateOfIncorporation: applicant.dateOfIncorporation || '',
      cityOfIncorporation: applicant.cityOfIncorporation || '',
      countryOfIncorporation: applicant.countryOfIncorporation || '',
      entityExcemptionCode: applicant.entityExcemptionCode || '',
      poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || 'no',
      addressTelephoneOutsideIndia: applicant.addressTelephoneOutsideIndia || 'no',
      fatcaDeclaration: applicant.fatcaDeclaration || false,
      fatcaAddress:
        applicant?.fatcaAddress && Object.keys(applicant?.fatcaAddress)?.length
          ? {
              ...applicant?.fatcaAddress,
              address_type: isCustodianKotak(custodian)
                ? 'others'
                : applicant?.fatcaAddress?.address_type || '',
              address_tax_purpose: isCustodianKotak(custodian)
                ? 'others'
                : applicant?.fatcaAddress?.address_tax_purpose || '',
            }
          : isCustodianAxis(custodian) || isCustodianKotak(custodian)
          ? {
              ...addressInitialFields(),
              address_type: isCustodianKotak(custodian) ? 'others' : '',
              address_tax_purpose: isCustodianKotak(custodian) ? 'others' : '',
            }
          : applicant?.fatcaAddress,
    })),
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(schemaData, FATCAValidationSchema(custodian), true, schemaData);
    // applicants.map((applicant, ind) => {
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 0) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By first applicant');
    //   }
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 1) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By second applicant');
    //   }
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 2) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By third applicant');
    //   }
    // });
  } catch (e) {
    if (e instanceof FatcaErrors) {
      throw e;
    }
    throw 'FATCA';
  }
};

export const validateNomineeDetails = async (
  Nominees: Partial<NomineeType>[],
  doNotWishToNominate: boolean,
  applicants: Partial<Applicant>[],
  modeOfHolding: string,
  nationalitiesMdmsMasters: nationaliyType,
  custodian: string
) => {
  const schemaData = {
    doNotWishToNominate: Nominees.length
      ? false
      : doNotWishToNominate === null
      ? true
      : doNotWishToNominate,
    nominees: Nominees.length
      ? Nominees.map((nominee, index) => ({
          ...nominee,
          Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
            ? nominee.nomineeRelationship
              ? nominee.nomineeRelationship.toUpperCase()
              : nominee.nomineeRelationship
            : 'OTHERS',
          nomineeRelationship:
            nominee.nomineeRelationship?.toUpperCase() === 'OTHERS'
              ? ''
              : getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? ''
              : nominee.nomineeRelationship,
          sno: index,
          addressSameAsApplicant: nominee.addressSameAsApplicant || false,
          nomineeAddress1: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress1,
          nomineeAddress2: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress2,
          nomineeAddress3: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress3,
          nomineeCity: nominee.addressSameAsApplicant ? '' : nominee.nomineeCity,
          nomineeState: nominee.addressSameAsApplicant ? '' : nominee.nomineeState,
          nomineeCountry: nominee.addressSameAsApplicant ? '' : nominee.nomineeCountry,
          nomineePincode: nominee.addressSameAsApplicant ? '' : nominee.nomineePincode,
          guardianAddressSameAsApplicant: nominee.guardianAddressSameAsApplicant || false,
          guardianAddress1: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianAddress1
            : null,
          guardianAddress2: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianAddress2
            : null,
          guardianAddress3: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianAddress3
            : null,
          guardianCity: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianCity
            : null,
          guardianState: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianState
            : null,
          guardianCountry: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianCountry?.toUpperCase()
            : null,
          guardianPincode: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
            ? nominee.guardianAddressSameAsApplicant
              ? ''
              : nominee.guardianPincode
            : null,
          nomineePercentage: nominee.nomineePercentage
            ? nominee.nomineePercentage
            : Nominees.length === 1
            ? 100
            : 0,
          nomineeCountryNameAndCode: nominee.nomineeCountryNameAndCode
            ? nominee.nomineeCountryNameAndCode
            : 'India: +91',
          guardianCountryNameAndCode: nominee.guardianCountryNameAndCode
            ? nominee.guardianCountryNameAndCode
            : 'India: +91',
          guardianRelationship: getNomineeRelation(nominee.guardianRelationship?.toUpperCase())
            ? nominee.guardianRelationship
              ? nominee.guardianRelationship.toUpperCase()
              : nominee.guardianRelationship
            : 'OTHERS',
          otherGuardianRelationship:
            nominee.guardianRelationship?.toUpperCase() === 'OTHERS'
              ? ''
              : getNomineeRelation(nominee.guardianRelationship?.toUpperCase())
              ? ''
              : nominee.guardianRelationship,
        }))
      : [],
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(
      schemaData,
      nomineeDetailsSchema(custodian, applicants),
      true,
      schemaData
    );
  } catch (error) {
    throw `Nominee details`;
  }
};

export const validateBankDetails = async (
  bankDetails: Partial<Bank>[],
  verifyPennydrop: boolean,
  applicationType: string,
  application: ApplicationProps | null,
  bankValidationFlage: boolean
) => {
  const { applicants = [] } = application || {};
  const checkNDPSM = checkNDPMSForIndusindBank(application?.accountType, application?.bankDetails);
  const schemaData = {
    applicationType: applicationType,
    status: applicants.length ? applicants[0].status : '',
    banks: bankDetails.length
      ? bankDetails.map((bank) => ({ ...bank, defaultBankAccount: !!bank.defaultBankAccount }))
      : [
          {
            ...bankObject,
            defaultBankAccount: checkNDPSM ? true : false,
            bankAccountType: checkNDPSM ? application?.bankAccountTypeNdpms || '' : '',
          },
        ],
  };
  try {
    if (
      IS_PENNYDROP_APPLICABLE_FOR_AMC &&
      verifyPennydrop &&
      !isApplicantNRI(schemaData?.status || '')
    ) {
      const isAllBanksPennyChecked = bankDetails.every((bank) => bank.pennydropCheck);
      if (!isAllBanksPennyChecked && !bankValidationFlage) {
        throw new BankError('Please make sure that all the banks are verified');
      }
    }
    await validateYupSchema(
      schemaData,
      bankDetailsSchema(applicants, bankValidationFlage),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof BankError) {
      throw error;
    }
    throw `Bank details`;
  }
};

export const validateRiskProfile = async (
  application: ApplicationProps | null,
  riskProfileDataMaster: RiskProfileMasterType[],
  role: string,
  applicantType?: string,
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) => {
  const {
    riskprofiles = [],
    isRiskProfileToBeFilledByInvestor = true,
    riskProfileDeclaration = false,
  } = application || {};
  try {
    const schemaData = {
      isRiskProfileToBeFilledByInvestor: isRiskProfileToBeFilledByInvestor || false,
      riskprofiles: riskProfileDataMaster
        ?.map((mdmsProfile) => {
          const existingRiskProfiles = riskprofiles?.filter(
            (risk_profiles) => risk_profiles.question === mdmsProfile.key
          );
          return existingRiskProfiles.length
            ? existingRiskProfiles?.map((existingProfiles) => {
                if (
                  existingProfiles.question === mdmsProfile.key &&
                  mdmsProfile.isMultiselect === 'true'
                ) {
                  const getInvestment = handleIntialSelect(riskprofiles, mdmsProfile.key);
                  const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                  let getOtherValue = '';
                  const updateOptions = getInvestment.map((ans) => {
                    if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                      getOtherValue = ans.split('_')[0];
                      return `others_${ans.split('_')[1]}`;
                    }
                    return ans;
                  });
                  return {
                    ...existingProfiles,
                    ...mdmsProfile,
                    values: mdmsProfile.values?.map((value) => {
                      const scoreUpdate = updateOptions
                        .find((investment) => investment?.split('_')[0] === value.key)
                        ?.split('_')[1];

                      return {
                        ...value,
                        score: scoreUpdate ? Number(scoreUpdate) : value.score,
                      };
                    }),
                    InvestmentGoal: updateOptions || [],
                    scoreCal: Number(existingProfiles.scoreText) || 0,
                    otherValue: getOtherValue,
                    answer: getOtherValue
                      ? updateOptions?.toString()?.replace(/,/g, '*')
                      : existingProfiles.answer,
                  };
                } else {
                  const checkAnswer =
                    existingProfiles.question === mdmsProfile.key &&
                    mdmsProfile.isMultiselect === 'false' &&
                    mdmsProfile.values?.map((value) => value.key).includes(existingProfiles.answer);

                  return {
                    ...existingProfiles,
                    ...mdmsProfile,
                    values: mdmsProfile.values?.map((value) => {
                      if (existingProfiles.answer === value.key) {
                        return {
                          ...value,
                          score: Number(existingProfiles.scoreText),
                        };
                      }
                      return value;
                    }),
                    otherValue:
                      checkAnswer || IsRiskFieldTextfield(mdmsProfile?.questionType || '')
                        ? ''
                        : existingProfiles.answer,
                    answer:
                      checkAnswer || IsRiskFieldTextfield(mdmsProfile?.questionType || '')
                        ? existingProfiles.answer
                        : 'others',
                  };
                }
              })
            : [{ ...RiskProfileObj, question: mdmsProfile.key, ...mdmsProfile }];
        })
        .flat(),
    };

    const checkAllQuestionsAnswered = schemaData.riskprofiles
      ?.map((profile) => profile.answer !== '' && profile.required === 'true')
      ?.every((_profile) => _profile);
    if (APMIFlow && !isRiskProfileToBeFilledByInvestor && !applicantType) {
      throw new RiskProfileErrors(
        `In Risk Profile, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (
      (!isRiskProfileToBeFilledByInvestor ||
        (isRiskProfileToBeFilledByInvestor &&
          ((applicantType && fieldValidateForInvestor(applicantType, 1, false)) ||
            validateFormIncludingAllJointHoldersInInvestorLogin))) &&
      !checkAllQuestionsAnswered
    ) {
      throw new RiskProfileErrors('In Risk Profile Please fill all the required(*) fields');
    }
    schemaData.riskprofiles?.map((risk) => {
      if (
        (!isRiskProfileToBeFilledByInvestor ||
          (isRiskProfileToBeFilledByInvestor &&
            ((applicantType && fieldValidateForInvestor(applicantType, 1, false)) ||
              validateFormIncludingAllJointHoldersInInvestorLogin))) &&
        (risk.answer === 'others' ||
          (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
        !risk.otherValue &&
        risk.required === 'true'
      ) {
        const questionForDisplay = riskProfileDataMaster
          ?.map((riskProfile) => {
            if (riskProfile.key === risk.question) {
              return riskProfile.displayText;
            }
            return;
          })
          ?.filter((ele) => ele)
          ?.toString();
        throw new RiskProfileErrors(
          'In Risk Profile Please specify others in ' + questionForDisplay
        );
      }
    });
    if (!riskProfileDeclaration && application && checkIfApplicationIsNonIndividual(application)) {
      throw new RiskProfileErrors('In Risk Profile Declaration is required');
    }
    // await validateYupSchema(schemaData, strategyDeclarationScheme, true, schemaData);
  } catch (e) {
    if (e instanceof RiskProfileErrors) {
      throw e;
    }
    if (typeof e !== 'string') {
      throw e;
    }
  }
};

export const validateStrategyDetails = async (
  application: ApplicationProps | null,
  strategyRes: GetStrategiesResponseBody[],
  role: string
) => {
  const { applicationstrategydetails = [] } = application || {};
  try {
    const transformedDataArray: updatedStrategyDetails[] | GetStrategiesResponseBody[] =
      strategyRes?.filter((responseData) => {
        return !applicationstrategydetails?.some(
          (appData) => appData.strategyId === responseData.id
        );
      });
    const result = [
      ...applicationstrategydetails?.map((applicationData) => {
        const { id, strategyFeeDetailId, strategyType, benchmark, ...rest } = applicationData;

        const savedStrategyDetails = strategyRes
          ?.filter((responseItem) => responseItem.id === applicationData.strategyId)
          ?.map((responseItem) => responseItem.strategyFeeDetails)
          ?.flat()
          ?.filter((item) => item);

        return {
          ...applicationData,
          strategyFeeDetails: savedStrategyDetails
            ?.filter(
              (savedStrategyDetail) =>
                Number(savedStrategyDetail?.id) !== Number(applicationData?.strategyFeeDetailId)
            )
            .concat([
              {
                id: strategyFeeDetailId || null,
                applicableForType: (rest.applicableForType as string) || '',
                isActive: rest.isActive,
                feeType: rest.feeType || '',
                fee: rest.fee || '',
                hurdleRate: rest.hurdleRate || '',
                exitLoad: rest.exitLoad || '',
                charges: rest.charges || '',
                performanceFee: rest.performanceFee || '',
              },
            ]),
          maxFee: applicationData?.maxFee || null,
          minFee: applicationData?.minFee || null,
          strategyType: (strategyType || '') as string,
          benchmark: (benchmark || '') as string,
        };
      }),
      ...transformedDataArray?.map((transformData) => {
        const { id, strategyFeeDetails, benchmark, strategyType, ...rest } = transformData;
        return {
          ...rest,
          ...newStrategy,
          strategyId: id as string,
          strategyType: (strategyType || '') as string,
          benchmark: (benchmark || '') as string,
          strategyFeeDetails: strategyFeeDetails || [],
        };
      }),
    ] as GetStrategiesResponseBody[] | updatedStrategyDetails[];
    const schemaData = {
      totalStrategyInvestmentAmount: application?.totalStrategyInvestmentAmount || null,
      ownBorrowedFunds: application?.ownBorrowedFunds || '',
      cheque: application?.modeOfPayment ? application.modeOfPayment.includes('cheque') : false,
      rtgs: application?.modeOfPayment ? application.modeOfPayment.includes('rtgs') : false,
      applicationstrategydetails: application?.strategiesFeeType
        ? feeTypeStratagiesFilter(result as updatedStrategyDetails[], application.strategiesFeeType)
        : (result as updatedStrategyDetails[]),
      strategiesFeeType: application?.strategiesFeeType || '',
    };
    if (!applicationstrategydetails.length) {
      throw new StrategyErrors('Please Select atleast One Strategy In Strategy Details');
    }
    const isBenchmarkAdded = applicationstrategydetails
      .filter((item) => item?.isActive)
      .every((startegyItem) => startegyItem?.benchmark);

    if (!isBenchmarkAdded) {
      throw new StrategyErrors('Benchmark is required In Strategy Details');
    }
    await validateYupSchema(schemaData, strategyDeclarationScheme(), true, schemaData);
  } catch (error) {
    if (error instanceof StrategyErrors) {
      throw error;
    }
    throw role === USER_ROLES.AMCAPPROVER && !CHECKER_EDIT_ALL_SECTIONS
      ? new StrategyErrors(`In Strategy details, the required fields are not filled.`)
      : `Strategy details`;
  }
};

export const validateDisclosureOfInterest = async (
  application: ApplicationProps | null,
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean
) => {
  try {
    const schemaData = {
      isDisclosureOfInterestToBeFilledByInvestor:
        application?.isDisclosureOfInterestToBeFilledByInvestor || false,
      interestedCompaniesCheck: application?.interestedCompaniesCheck || '',
      interestedcompanies: application?.interestedcompanies.length
        ? application.interestedcompanies?.map((interestedCompanies, index) => {
            return { ...interestedCompanies, sno: index };
          })
        : [],
    };
    if (APMIFlow && !application?.isDisclosureOfInterestToBeFilledByInvestor && !applicantType) {
      throw new DisclosureOfInterestErrors(
        `In Disclosure Of Interest, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (YesNoMaster[schemaData.interestedCompaniesCheck] === YesNoMaster.no) {
      return;
    }
    await validateYupSchema(
      schemaData,
      disclosureOfInterestSchema(
        applicantType,
        true,
        validateFormIncludingAllJointHoldersInInvestorLogin
      ),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof DisclosureOfInterestErrors) {
      throw error;
    }
    throw `Disclosure Of Interest`;
  }
};

export const validateDisclusionOfExclusion = async (
  application: ApplicationProps | null,
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean
) => {
  try {
    const schemaData = {
      isDisclosureOfExclusionsToBeFilledByInvestor:
        application?.isDisclosureOfExclusionsToBeFilledByInvestor || false,
      excludedCompaniesCheck: application?.excludedCompaniesCheck || '',
      excludedcompanies: application?.excludedcompanies.length
        ? application.excludedcompanies?.map((excludedCompanies, index) => {
            return { ...excludedCompanies, sno: index };
          })
        : [],
    };
    if (APMIFlow && !application?.isDisclosureOfExclusionsToBeFilledByInvestor && !applicantType) {
      throw new DisclosureOfExclusionsErrors(
        `In Disclosure Of Exclusions, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (YesNoMaster[schemaData.excludedCompaniesCheck] === YesNoMaster.no) {
      return;
    }
    await validateYupSchema(
      schemaData,
      disclosureOfExcludedSchema(
        applicantType,
        true,
        validateFormIncludingAllJointHoldersInInvestorLogin
      ),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof DisclosureOfExclusionsErrors) {
      throw error;
    }
    throw `Disclosure Of Exclusions`;
  }
};

export const validateRelatedParty = async (application: ApplicationProps | null) => {
  try {
    const {
      relatedpartyconsents = [],
      relatedPartyLimit = '',
      relatedPartyWaiver = '',
    } = application || {};

    if (!relatedPartyLimit || !relatedPartyWaiver) {
      throw new RelatedPartyConsentErrors(
        'Please choose consent or dissent in Related Party Consent'
      );
    }
    const schemaData = {
      relatedpartyconsents: relatedpartyconsents.length ? relatedpartyconsents : relatedDataArr,
    };
    relatedPartyLimit === 'consent' &&
      (await validateYupSchema(schemaData, relatedPartyConsentSchema(), true, schemaData));
  } catch (error) {
    if (error instanceof RelatedPartyConsentErrors) {
      throw error;
    }
    throw `Related Party Consent`;
  }
};

export const validateDocuments = async (
  application: ApplicationProps | null,
  hasPOA: boolean,
  documentsData: Document,
  Nominees: Partial<NomineeType>[],
  custodian: string,
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean,
  banks: Partial<Bank>[],
  validateInvestorSignatureOnly: boolean,
  checkNdpmsFlow = false,
  routeForNDPSM = false
) => {
  const {
    applicants = [],
    bankAccountFormCreated = false,
    showBankIcon = false,
  } = application || {};
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      let docData = '';
      let custodianDoc = '';
      if (isCustodianHDFC(custodian)) {
        custodianDoc = APPLICATION_TYPE_FOR_DOCUMENTS.HDFC;
      }
      if (isCustodianAxis(custodian)) {
        custodianDoc = APPLICATION_TYPE_FOR_DOCUMENTS.AXIS;
      }
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
      }
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
      }
      if (checkNdpmsFlow) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NDPSM_DOC;
      }
      return {
        documents:
          typeof documentsData !== 'undefined'
            ? [
                ...((documentsData as Document)[docData] || []),
                ...((documentsData as Document)[custodianDoc] || []),
              ]
                .filter((doc) => {
                  if (
                    hasPOA &&
                    applicant.applicant_type !== '1' &&
                    doc.documentType === 'poaNotarized'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .filter((document) => {
                  if (applicant.amlCheck && document.documentType === 'compliance_document') {
                    return;
                  }
                  return document;
                })
                .filter((ele) => ele)
                .filter((doc) => {
                  if (
                    !applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return;
                  }
                  if (applicant_index > 0 && doc.documentType === 'newBankAccountProof') {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((doc) => {
                  if (
                    applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  if (isCustodianAxis(custodian) && doc.documentType === 'addressProof') {
                    return { ...doc, required: 'true' };
                  }
                  if (
                    checkNdpmsFlow &&
                    bankAccountFormCreated &&
                    showBankIcon &&
                    routeForNDPSM &&
                    doc.documentType === 'newBankAccountProof'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  if (
                    checkNdpmsFlow &&
                    bankAccountFormCreated &&
                    !showBankIcon &&
                    !routeForNDPSM &&
                    doc.documentType === 'newBankAccountProof'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                .map((updateRequired) => {
                  if (
                    updateRequired.documentType === 'investorSignature' &&
                    !fieldValidateForInvestor(applicantType, applicant_index + 1, false) &&
                    !validateFormIncludingAllJointHoldersInInvestorLogin
                  ) {
                    return {
                      ...updateRequired,
                      required: 'false',
                    };
                  }
                  return updateRequired;
                })
                .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const { documentType, documentName, multipleFiles, required, options } = doc;
                  const { documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  const defaultDocObj = {
                    documentType,
                    documentName,
                    required,
                    options,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      '0') as string,
                    ...document_object,
                  };
                  const existingDocObj = docsOfCurrentDocType?.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                    } = doc;
                    return {
                      id: doc.id,
                      applicantId: doc.applicantId,
                      documentType,
                      documentName,
                      documentId,
                      isActive,
                      file,
                      options,
                      required:
                        ind === 1 &&
                        !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                          ? 'false'
                          : required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  });
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? multipleFiles === 'true' && existingDocObj?.length === 1
                        ? [
                            ...existingDocObj,
                            {
                              ...defaultDocObj,
                              documentName: existingDocObj[existingDocObj.length - 1].documentName,
                              required: documentNameValidationCheckForMultipleDocumentsMandatory(
                                existingDocObj[existingDocObj.length - 1].documentName
                              )
                                ? required
                                : 'false',
                              uniqueKey: (applicant_index.toString() +
                                '-' +
                                index.toString() +
                                '-' +
                                '1') as string,
                            },
                          ]
                        : existingDocObj
                      : multipleFiles === 'true'
                      ? [
                          defaultDocObj,
                          {
                            ...defaultDocObj,
                            required: documentNameValidationCheckForMultipleDocumentsMandatory(
                              defaultDocObj.documentName
                            )
                              ? required
                              : 'false',
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : [defaultDocObj],
                    required,
                    multipleFiles,
                    options,
                  };
                })
            : [],
      };
    }),
    nominees: Nominees.map((nominee, nominee_index) => {
      return {
        nomineedocuments:
          typeof documentsData !== 'undefined'
            ? (isMinor(nominee.dateOfBirth || '')
                ? [
                    ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                      []),
                    ...((documentsData as Document)[
                      APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                    ] || []),
                  ].map((doc) => {
                    if (doc.documentType === 'nomineeIdProof') {
                      return { ...doc, required: 'false' };
                    }
                    if (doc.documentType === 'guardianIdProof') {
                      return { ...doc, required: 'true' };
                    }
                    return doc;
                  })
                : (
                    (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
                  ).map((_nomineeDoc) => {
                    if (
                      isCustodianAxis(custodian) &&
                      _nomineeDoc.documentType === 'nomineeIdProof'
                    ) {
                      return { ..._nomineeDoc, required: 'true' };
                    }
                    return _nomineeDoc;
                  })
              ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                const defaultDocObj = {
                  documentType,
                  documentName,
                  required,
                  options,
                  uniqueKey: (nominee_index.toString() +
                    '-' +
                    index.toString() +
                    '-' +
                    '0') as string,
                  ...document_object,
                };
                const existingDocObj = docsOfCurrentDocType.map((doc, ind) => {
                  const {
                    documentName = '',
                    documentType = '',
                    documentId = '',
                    isActive = true,
                    file = undefined,
                  } = doc;
                  return {
                    id: doc.id,
                    nomineeId: doc.nomineeId,
                    documentType,
                    documentName,
                    documentId,
                    isActive,
                    file,
                    options,
                    required:
                      ind === 1 &&
                      !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                        ? 'false'
                        : required,
                    uniqueKey: (nominee_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      ind.toString()) as string,
                  };
                });
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? multipleFiles === 'true' && existingDocObj?.length === 1
                      ? [
                          ...existingDocObj,
                          {
                            ...defaultDocObj,
                            documentName: existingDocObj[existingDocObj.length - 1].documentName,
                            required: documentNameValidationCheckForMultipleDocumentsMandatory(
                              existingDocObj[existingDocObj.length - 1].documentName
                            )
                              ? required
                              : 'false',
                            uniqueKey: (nominee_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : existingDocObj
                    : multipleFiles === 'true'
                    ? [
                        defaultDocObj,
                        {
                          ...defaultDocObj,
                          required: documentNameValidationCheckForMultipleDocumentsMandatory(
                            defaultDocObj.documentName
                          )
                            ? required
                            : 'false',
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '1') as string,
                        },
                      ]
                    : [defaultDocObj],
                  required,
                  multipleFiles,
                  options,
                };
              })
            : [],
      };
    }),
    banks: banks,
  };
  try {
    await validateYupSchema(
      schemaData,
      validateInvestorSignatureOnly
        ? documentDetailsSchemaForInvestorLogin()
        : documentDetailsSchema(!checkNdpmsFlow),
      true,
      schemaData
    );
  } catch (error) {
    throw `Document details`;
  }
};

export const isFormValidForSubmission = async (
  application: ApplicationProps | null,
  validateDoc = true,
  verifyPennydrop = true,
  nationalitiesMdmsMasters = {},
  custodianData: InvestorCustodianTypes,
  validationOrConfigChecks: ValidationOrConfigMaster,
  documentsResponse = {},
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  strategyRes: GetStrategiesResponseBody[],
  referenceDetail: getDetailsByRefrenceType,
  bankValidation = false,
  routeForNDPSM = false,
  validateFormIncludingAllJointHoldersInInvestorLogin = false
) => {
  const {
    applicants = [],
    nominees = [],
    doNotWishToNominate = false,
    banks = [],
    hasPOA = true,
    applicationType = '',
    modeOfHolding = '',
    custodian = '',
    commSendTo = '',
    dlclId = '',
    dividendDeclaration = false,
    cdslAddtionalType = '',
    pledgeDeclaration = false,
  } = application || {};
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const { maritalStatusChange = '' } = validationOrConfigChecks || {};
  try {
    await validateAdditionalDetails(
      application,
      role,
      custodian,
      custodianData,
      maritalStatusChange
    );
    await validateKYCDetails(
      application,
      role,
      custodian,
      commSendTo,
      dlclId,
      dividendDeclaration,
      cdslAddtionalType,
      pledgeDeclaration
    );
    await validateFATCA(custodian, applicants, role, nationalitiesMdmsMasters as nationaliyType);
    await validateNomineeDetails(
      nominees,
      doNotWishToNominate,
      applicants,
      modeOfHolding,
      nationalitiesMdmsMasters as nationaliyType,
      custodian
    );
    await validateBankDetails(banks, verifyPennydrop, applicationType, application, bankValidation);

    await validateRiskProfile(
      application,
      riskProfileDataMaster,
      role,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin
    );
    await validateStrategyDetails(application, strategyRes, role);
    await validateDisclosureOfInterest(
      application,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin
    );
    await validateDisclusionOfExclusion(
      application,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin
    );
    await validateRelatedParty(application);
    validateDoc &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(
        application,
        hasPOA,
        documentsResponse as Document,
        nominees,
        custodian,
        referenceDetail.applicant_type,
        validateFormIncludingAllJointHoldersInInvestorLogin,
        banks,
        false,
        checkNdpmsFlow,
        routeForNDPSM
      ));
  } catch (error) {
    if (error instanceof RiskProfileErrors) {
      throw (error as RiskProfileErrors).message;
    }
    if (error instanceof KycDetailsErrors) {
      throw (error as KycDetailsErrors).message;
    }
    if (error instanceof FatcaErrors) {
      throw (error as FatcaErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    if (error instanceof StrategyErrors) {
      throw (error as StrategyErrors).message;
    }
    if (error instanceof DisclosureOfInterestErrors) {
      throw (error as DisclosureOfInterestErrors).message;
    }
    if (error instanceof DisclosureOfExclusionsErrors) {
      throw (error as DisclosureOfExclusionsErrors).message;
    }
    if (error instanceof RelatedPartyConsentErrors) {
      throw (error as RelatedPartyConsentErrors).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};
export const pennyDropVerification =
  (body: Bank) =>
  async (dispatch: any): Promise<Bank> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/pennydropverification`,
        method: 'POST',
        types: [PENNY_DROP_VERIFICATION_SUCCESS, PENNY_DROP_VERIFICATION],
        body,
      },
    });
  };

export const getDocuments =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/required_document_master.json',
        method: 'GET',
        types: [GET_Documents_SUCCESS, GET_Documents],
      },
    });
  };

export const getUboTypes =
  () =>
  async (dispatch: any): Promise<uboTypes> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/uboTypeMaster.json',
        method: 'GET',
        types: [GET_Ubo_SUCCESS, GET_Ubo],
      },
    });
  };

export const FetchData =
  (body: FetchUBORequestBody) =>
  async (dispatch: any): Promise<ubo> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kradetails`,
        method: 'POST',
        types: [UBO_LISTING_SUCCESS, UBO_LISTING],
        body,
      },
    });
  };

export const FatcaMdmsData =
  () =>
  async (dispatch: any): Promise<FatcaMdms> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/fatca_data.json',
        method: 'GET',
        types: [GET_Fatca_SUCCESS, GET_Fatca],
      },
    });
  };

export const validateNonIndividualContributorDetails = async (applicants: Partial<Applicant>[]) => {
  const schemaData = {
    applicants: applicants,
  };
  try {
    await validateYupSchema(
      schemaData,
      NonIndividualContributorValidationSchema(),
      true,
      schemaData
    );
  } catch (error) {
    throw `Applicant(s) details`;
  }
};
export const validateNonIndividualContactDetails = async (
  applicants: Partial<Applicant>[],
  countryDropdown: string[],
  statesDropdown: string[],
  custodian: string,
  role: string
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const correspondence = getAddressData('correspondence', applicant.addresses);
      const permanent = getAddressData('permanent', applicant.addresses);
      const correspondenceFieldDisabled = getFieldsDisabled(
        'correspondence',
        correspondence?.fetchedFromKRA || false,
        role,
        custodian,
        correspondence?.fetchedFromDigiLocker || false
      );
      const permanentFieldDisabled = getFieldsDisabled(
        'permanent',
        permanent?.fetchedFromKRA || false,
        role,
        custodian,
        permanent?.fetchedFromDigiLocker || false
      );
      const defaultPayload = {
        contactperson: applicant.contactperson
          ? {
              ...applicant.contactperson,
              countryNameAndCode: applicant.contactperson.countryNameAndCode
                ? applicant.contactperson.countryNameAndCode
                : 'India: +91',
              country: applicant.contactperson.country
                ? applicant.contactperson.country.toUpperCase()
                : 'INDIA',
            }
          : contactPersonObject,
        address: {
          correspondence,
        },
      };
      const permanentAddressPayload = correspondence.permanentAddressSameAsCorresponding
        ? defaultPayload
        : {
            ...defaultPayload,
            address: {
              ...defaultPayload.address,
              permanent: {
                ...permanent,
                state: permanent.state
                  ? isCustodianICICIOrHDFC(custodian) &&
                    permanent.country?.toUpperCase() === 'INDIA' &&
                    !permanentFieldDisabled
                    ? permanent.state?.toUpperCase()
                    : permanent.state
                  : '',
                country: permanent.country
                  ? permanentFieldDisabled
                    ? permanent.country
                    : permanent.country.toUpperCase()
                  : 'INDIA',
              },
              correspondence: {
                ...correspondence,
                state: correspondence.state
                  ? isCustodianICICIOrHDFC(custodian) &&
                    correspondence.country?.toUpperCase() === 'INDIA' &&
                    !correspondenceFieldDisabled
                    ? correspondence.state?.toUpperCase()
                    : correspondence.state
                  : '',
                country: correspondence.country
                  ? correspondenceFieldDisabled
                    ? correspondence.country
                    : correspondence.country.toUpperCase()
                  : 'INDIA',
              },
            },
          };

      return permanentAddressPayload;
    }),
    countryDropdown: countryDropdown,
    statesDropdown,
  };
  try {
    await validateYupSchema(
      schemaData,
      nonIndividualContactDetailsSchema(custodian),
      true,
      schemaData
    );
  } catch (error) {
    throw `Contact details`;
  }
};

export const validateNonIndividualDocumentDetails = async (
  applicants: Partial<Applicant>[],
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  banks: Partial<Bank>[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      return {
        documents: documentsData
          .filter((document) => {
            if (applicant.amlCheck && document.documentType === 'compliance_document') {
              return;
            }
            return document;
          })
          .filter((ele) => ele)
          //.map((doc) => ({ ...doc, required: 'true' }))
          // .filter((doc) => {
          //   if (
          //     !applicant.correspondenceAddressEdited &&
          //     doc.documentType === 'correspondenceAddress'
          //   ) {
          //     return;
          //   }
          //   return doc;
          // })
          // .filter((ele) => ele)
          // .map((doc) => {
          //   if (
          //     applicant.correspondenceAddressEdited &&
          //     doc.documentType === 'correspondenceAddress'
          //   ) {
          //     return { ...doc, required: 'true' };
          //   }
          //   return doc;
          // })
          .map((doc, index) => {
            const { documentType, documentName, multipleFiles, required, options } = doc;
            const { documents: existingDocuments = [] } = applicant || {};
            const docsOfCurrentDocType = existingDocuments
              .filter((doc) => doc.documentType === documentType)
              .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
            return {
              documentType: documentType,
              documentName: documentName,
              documentsList: docsOfCurrentDocType.length
                ? docsOfCurrentDocType.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                    } = doc;
                    return {
                      documentType,
                      documentName,
                      documentId,
                      isActive,
                      file,
                      options,
                      required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  })
                : [
                    {
                      documentType,
                      documentName,
                      required,
                      options,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        '0') as string,
                      ...document_object,
                    },
                  ],
              required,
              multipleFiles,
              options,
            };
          }),
      };
    }),
    banks: banks,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualDocumentDetailsSchema(), true, schemaData);
  } catch (error) {
    throw `Document details`;
  }
};
const fieldsTocheckForAuthorisedSignatories = [
  {
    key: 'name',
    label: 'Name',
    validateMulitple: false,
  },
  {
    key: 'pan',
    label: 'PAN',
    validateMulitple: true,
    regex: individualPanRegex,
    regexMsg: 'Only Individual PANs are allowed',
  },
  { key: 'mobile', label: 'Mobile Number', validateMulitple: true },
  {
    key: 'email',
    label: 'Email ID',
    validateMulitple: true,
    regex: emailRegex,
  },
  {
    key: 'designation',
    label: 'Designation',
    validateMulitple: false,
  },
];

export const validateNonIndividualAuthorisedSignatories = async (
  groups: Groups[],
  documentResponseForAllFlows: Document | null,
  authorizedSignatoriesReferenceDetail: getAuthorizedSignatoriesDetailsByRefrenceType,
  validateForAllSignatories: boolean
) => {
  try {
    if (!groups || !groups.length) {
      throw new AuthorisedErrors('Please add Authorized Signatories');
    }
    const ASDetails = groups.map((group) => {
      return {
        ...group,
        groupsignatories: group.groupsignatories.map((signatory) => {
          return {
            ...signatory,
            groupsignatorydocuments: getASDocuments(
              documentResponseForAllFlows,
              signatory,
              validateForAllSignatories ||
                !!(
                  authorizedSignatoriesReferenceDetail?.esignReferenceId &&
                  authorizedSignatoriesReferenceDetail?.id === signatory?.id
                )
            ),
          };
        }),
      };
    });
    ASDetails.map((group) => {
      const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
      const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
      if (!getActiveSignatories.length) {
        throw new AuthorisedErrors('Please add Authorized Signatories');
      }
      if (!getCanEsignCount.length) {
        throw new AuthorisedErrors('Please Select a member for E-Sign in Authorized Signatories');
      }
      if (getCanEsignCount.length < group.threshold) {
        throw new AuthorisedErrors(
          'Authorized Signatories that can esign can not be less than the Total No. of required Authorized Signatories'
        );
      }
      getActiveSignatories.forEach((signatory, index) => {
        fieldsTocheckForAuthorisedSignatories.forEach((field) => {
          if (!signatory[field.key as keyof GroupSignatories] as unknown as string) {
            throw new AuthorisedErrors(`${field.label} is required for signatory ${index + 1}`);
          }
          if (
            field.regex &&
            !field.regex.test(signatory[field.key as keyof GroupSignatories] as unknown as string)
          ) {
            throw new AuthorisedErrors(
              field.regexMsg
                ? `${field.regexMsg} for signatory ${index + 1}`
                : `Please enter valid ${field.label} for signatory ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = getActiveSignatories.filter(
              (vsignatory) =>
                (vsignatory[field.key as keyof GroupSignatories] as unknown as string) ===
                (signatory[field.key as keyof GroupSignatories] as unknown as string)
            );
            if (values.length > 1) {
              throw new AuthorisedErrors(
                `There is already same ${field.label} for an Authorized Signatory associated with this application`
              );
            }
          }
        });

        signatory.groupsignatorydocuments.forEach((_AS_Doc) => {
          if (_AS_Doc.required === 'true' && !_AS_Doc.documentId) {
            throw new AuthorisedErrors(
              `Please upload ${_AS_Doc.documentName || 'mandatory document'} for signatory ${
                index + 1
              }`
            );
          }
        });
      });
      return group;
    });
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      throw error;
    }
  }
};

const fieldsTocheckForUbos = [
  {
    key: 'panNumber',
    label: 'Taxpayer ID Number/PAN/Equivalent ID Number',
    validateMulitple: true,
    required: true,
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    validateMulitple: false,
    required: true,
  },
  { key: 'name', label: 'Name of UBO', validateMulitple: false, required: true },
  {
    key: 'identificationType',
    label: 'Identification Type',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'percentageOfBeneficialInterest',
    label: 'Percentage of beneficial interest',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'countryOfTaxResidency',
    label: 'Country of Tax Residency',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'cpUboCode',
    label: 'CP/UBO Code',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'placeAndCountryOfBirth',
    label: 'Place & Country of Birth',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'occupation',
    label: 'Occupation',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'gender',
    label: 'Gender',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'nationality',
    label: 'Nationality',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'fatherName',
    label: 'Father Name',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'ckycNumber',
    label: 'CKYC Number',
    validateMulitple: true,
    required: false,
  },
  {
    key: 'address2',
    label: 'Address 1',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'address3',
    label: 'Address 2',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'pincode',
    label: 'Pincode',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'city',
    label: 'City',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'state',
    label: 'State',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'country',
    label: 'Country',
    validateMulitple: false,
    required: true,
  },
];
export const validateNonIndividualUbo = async (
  applicants: Partial<Applicant>[],
  ubo_declaration_type: string,
  ubo_declaration_value: string,
  ubos: ubo[],
  nationalityDropdown: string[],
  countryDropdown: string[]
) => {
  try {
    const applicantsCkycNumbers = applicants?.map((applicant) => applicant.ckycNo);
    if (!ubo_declaration_type) {
      throw new UboErrors('Please Select Declaration Type in Declaration of Ubo');
    }
    if (!ubo_declaration_value) {
      throw new UboErrors('Please Select Declaration value in Declaration of Ubo');
    }
    if (ubo_declaration_type === 'none' && !ubos.filter((_item) => _item.isActive).length) {
      throw new UboErrors('Please Add Ultimate Benificiary Owners(UBO)');
    }
    if (ubos.filter((_item) => _item.isActive).length) {
      const activeUbos = ubos.filter((_item) => _item.isActive);
      activeUbos.map((ubo) => {
        if (ubo.ckycNumber && applicantsCkycNumbers.includes(ubo.ckycNumber)) {
          throw new UboErrors(
            `CKYC Number of ${ubo.name} should not be same as CKYC Number exist in Applicant(s) Details`
          );
        }
        if (!nationalityDropdown.includes(ubo.nationality || '')) {
          throw new UboErrors(`Invalid Nationality for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.country || '')) {
          throw new UboErrors(`Invalid Country for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.countryOfTaxResidency || '')) {
          throw new UboErrors(
            `Invalid Country of Tax Residency for ${ubo.name} in Declaration of Ubo`
          );
        }
        if (!occupationDetailsMasters.includes(ubo.occupation || '')) {
          throw new UboErrors(`Invalid Occupation for ${ubo.name} in Declaration of Ubo`);
        }
      });
      percentageError(ubos, true);
    }
    ubos
      .filter((_item) => _item.isActive)
      .forEach((ubo, index) => {
        fieldsTocheckForUbos.forEach((field) => {
          if ((!ubo[field.key as keyof ubo] as unknown as string) && field.required) {
            throw new UboErrors(`${field.label} is required for ubo ${index + 1}`);
          }
          if (
            field.key === 'dob' &&
            (ubo[field.key as keyof ubo] as unknown as string) &&
            isMinor(field.key)
          ) {
            throw new UboErrors(`Age should be greater than 18 for ubo ${index + 1}`);
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) <= 0
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest should be greater 0 for ubo ${index + 1}`
            );
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) > 100
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest must not exceed 100% for ubo ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = ubos.filter(
              (_ubo) =>
                (_ubo[field.key as keyof ubo] as unknown as string) ===
                  (ubo[field.key as keyof ubo] as unknown as string) &&
                (ubo[field.key as keyof ubo] as unknown as string) &&
                (_ubo[field.key as keyof ubo] as unknown as string)
            );
            if (values.length > 1) {
              throw new UboErrors(
                `There is already same ${field.label} for an Ubos associated with this application`
              );
            }
          }
        });
      });
  } catch (e) {
    if (e instanceof UboErrors) {
      throw e;
    }
  }
};

export const validateNonIndividualFatca = async (
  applicants: Partial<Applicant>[],
  fatcaMasterData: nonIndividualMdmsQuestionsFatca,
  mdmsCountriesList: mdmsCountriesList[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const { fatcadetail: existingFatcaDetails = null } = applicant || {};
      return {
        fatcadetail: existingFatcaDetails
          ? AssignAnsAndVisibilityForInitialFatca(fatcaMasterData, existingFatcaDetails)
          : fatcaMasterData,
      };
    }),
  };
  schemaData?.applicants?.forEach((applicant: any) => {
    applicant?.fatcadetail?.forEach((fatca: any) => {
      validationPopUp(fatca, applicants, mdmsCountriesList);
    });
  });
  try {
    await validateYupSchema(schemaData, nonIndividualFatcaSchema(), true, schemaData);
  } catch (error) {
    if (error instanceof fatcaErrors) {
      throw error;
    }
    throw `FATCA details`;
  }
};

export const nonIndividualFormValidForSubmission = async (
  documentResponseForAllFlows: Document | null,
  authorizedSignatoriesReferenceDetail: getAuthorizedSignatoriesDetailsByRefrenceType,
  application: ApplicationProps | null,
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  mdmsCountriesList: mdmsCountriesList[],
  mdmsStatesDropdown: mdmsStatesList[],
  verifyPennydrop = true,
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  strategyRes: GetStrategiesResponseBody[],
  validateFatca = true,
  fatcaMasterData?: nonIndividualMdmsQuestionsFatca,
  validateAllASDocuments = false
) => {
  const {
    applicants = [],
    banks = [],
    groups = [],
    ubo_declaration_type = '',
    ubo_declaration_value = '',
    ubos = [],
    applicationType = '',
    custodian = '',
  } = application || {};
  const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
  const countryDropdown = mdmsCountriesList.map((list) => list.name);
  const statesDropdown = mdmsStatesDropdown.map((list) => list.name);
  try {
    await validateNonIndividualContributorDetails(applicants);
    await validateNonIndividualContactDetails(
      applicants,
      countryDropdown,
      statesDropdown,
      custodian,
      role
    );
    await validateBankDetails(banks, verifyPennydrop, applicationType, application, true);
    await validateNonIndividualDocumentDetails(applicants, documentsData, banks);
    await validateNonIndividualAuthorisedSignatories(
      groups,
      documentResponseForAllFlows,
      authorizedSignatoriesReferenceDetail,
      validateAllASDocuments
    );
    await validateNonIndividualUbo(
      applicants,
      ubo_declaration_type,
      ubo_declaration_value,
      ubos,
      nationalityDropdown,
      countryDropdown
    );
    await validateRiskProfile(application, riskProfileDataMaster, role, '', true);
    await validateStrategyDetails(application, strategyRes, role);
    await validateDisclosureOfInterest(application, '', true);
    await validateDisclusionOfExclusion(application, '', true);
    await validateRelatedParty(application);
    validateFatca &&
      fatcaMasterData &&
      (await validateNonIndividualFatca(applicants, fatcaMasterData, mdmsCountriesList));
  } catch (error) {
    if (error instanceof UboErrors) {
      throw (error as UboErrors).message;
    }
    if (error instanceof AuthorisedErrors) {
      throw (error as AuthorisedErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    if (error instanceof RiskProfileErrors) {
      throw (error as RiskProfileErrors).message;
    }
    if (error instanceof StrategyErrors) {
      throw (error as StrategyErrors).message;
    }
    if (error instanceof RelatedPartyConsentErrors) {
      throw (error as RelatedPartyConsentErrors).message;
    }
    if (error instanceof fatcaErrors) {
      throw `${(error as fatcaErrors).message} in FATCA details`;
    }
    throw authorizedSignatoriesReferenceDetail?.referenceId
      ? `Required fields are not filled by Authorized Signatories`
      : `In ${error}, the required fields are not filled.`;
  }
};

export const getInvestorEditSections = (investorEditObj: Partial<InvestorEditErrorObj[]>) => ({
  type: 'GET_INVESTOR_EDIT_SECTION',
  investorEditObj,
});
let errorsArray: any = [];
export const FormValidationForDisplayCompleteApplicationButtonForInvestor = async (
  application: ApplicationProps | null,
  documentsResponse = {},
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  referenceDetail: getDetailsByRefrenceType,
  isValidateRiskProfile: boolean,
  isValidateDisclosureOfInterest: boolean,
  isValidateDisclosureOfExclusions: boolean,
  isValidateDocuments: boolean
) => {
  const {
    applicants = [],
    nominees = [],
    hasPOA = true,
    banks = [],
    custodian = '',
  } = application || {};
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  errorsArray =
    isValidateRiskProfile &&
    isValidateDisclosureOfInterest &&
    isValidateDisclosureOfExclusions &&
    isValidateDocuments
      ? []
      : errorsArray;
  try {
    isValidateRiskProfile &&
      (await validateRiskProfile(
        application,
        riskProfileDataMaster,
        role,
        referenceDetail.applicant_type,
        false
      ));
    isValidateDisclosureOfInterest &&
      (await validateDisclosureOfInterest(application, referenceDetail.applicant_type, false));
    isValidateDisclosureOfExclusions &&
      (await validateDisclusionOfExclusion(application, referenceDetail.applicant_type, false));
    isValidateDocuments &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(
        application,
        hasPOA,
        documentsResponse as Document,
        nominees,
        custodian,
        referenceDetail.applicant_type,
        false,
        banks,
        true
      ));
    throw errorsArray;
  } catch (error) {
    if (error instanceof RiskProfileErrors) {
      errorsArray.push({
        ...investorEditRiskProfileObj,
        errorMessage: (error as RiskProfileErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        true,
        true,
        true
      );
    } else if (
      error === 'Disclosure Of Interest' ||
      (APMIFlow && error instanceof DisclosureOfInterestErrors)
    ) {
      errorsArray.push({
        ...investorEditDisclosureOfInterestObj,
        errorMessage:
          error === 'Disclosure Of Interest'
            ? `In ${error}, the required fields are not filled.`
            : (error as DisclosureOfInterestErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        false,
        true,
        true
      );
    } else if (
      error === 'Disclosure Of Exclusions' ||
      (APMIFlow && error instanceof DisclosureOfExclusionsErrors)
    ) {
      errorsArray.push({
        ...investorEditDisclosureOfExclusionObj,
        errorMessage:
          error === 'Disclosure Of Exclusions'
            ? `In ${error}, the required fields are not filled.`
            : (error as DisclosureOfExclusionsErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        false,
        false,
        true
      );
    } else if (error === 'Document details') {
      errorsArray.push({
        ...investorEditDocumentDetailsObj,
        errorMessage: `In ${error}, the required fields are not filled.`,
      });
      throw errorsArray;
    } else {
      throw errorsArray;
    }
  }
};
export const getASLoginEditSections = (
  authorizedSignatoryEditObj: Partial<InvestorEditErrorObj[]>
) => ({
  type: 'GET_AUTHORIZED_SIGNATORY_EDIT_SECTION',
  authorizedSignatoryEditObj,
});
let errorsArrayForASLogin: any = [];
export const FormValidationForAuthorizedSignatoryLogin = async (
  application: ApplicationProps | null,
  documentResponseForAllFlows = {},
  authorizedSignatoriesReferenceDetail: getAuthorizedSignatoriesDetailsByRefrenceType,
  isValidateAuthorizedSignatories: boolean
) => {
  const { groups = [] } = application || {};
  errorsArrayForASLogin = isValidateAuthorizedSignatories ? [] : errorsArrayForASLogin;
  try {
    isValidateAuthorizedSignatories &&
      Object.keys(documentResponseForAllFlows).length !== 0 &&
      (await validateNonIndividualAuthorisedSignatories(
        groups,
        documentResponseForAllFlows as Document,
        authorizedSignatoriesReferenceDetail,
        false
      ));
    throw errorsArrayForASLogin;
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      errorsArrayForASLogin.push({
        ...investorEditAuthorizedSignatoryObj,
        errorMessage: (error as AuthorisedErrors).message,
      });
      throw errorsArrayForASLogin;
    } else {
      throw errorsArrayForASLogin;
    }
  }
};
