import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux-store/actions/auth';
import { useHistory } from 'react-router';
import SimpleDialog from '../commonComponents';
import { LoadingButton } from '@mui/lab';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';

export const NotAuthorized = ({
  location,
}: {
  location: Location<{
    role?: string;
  }>;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { role = '' } = location.state || {};
  const query = new URLSearchParams(window.location.search);
  const digilockerCheck = query.get('digilockerCheck') || false;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const onClick = async () => {
    try {
      setLoading(true);
      await dispatch(logout());
      if (role === 'investor') {
        history.push(`/investment-details/${referenceDetail.esignReferenceId}/details`);
      } else {
        history.replace('/login');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    // <SimpleDialog
    //   title="Not Authorized"
    //   content="You are not authorized to view this page"
    //   actions={[{ label: 'OK', onClick }]}
    //   open
    // />
    <Dialog
      open={true}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& .MuiButton-root': {
            width: '160px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: '18px',
            py: 1,
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      <DialogContent>
        <DialogContentText>You are not authorized to view this page</DialogContentText>
      </DialogContent>
      {!digilockerCheck && (
        <DialogActions>
          <LoadingButton
            loadingPosition="start"
            type="submit"
            loading={loading}
            variant="contained"
            fullWidth={true}
            onClick={onClick}>
            OK
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
