import * as yup from 'yup';
import { emailRegex, numberRegex, phoneRegExp } from '../../utils/regex';
import { Box, CardMedia, Grid, InputAdornment } from '@mui/material';
import { SubHeading } from './components';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import MFSelectField from '../../lib/formik/SelectField';
import {
  fatherOrSpouseSelectMasters,
  maritalStatusMasters,
  occupationDetailsMasters,
  salutationsMasters,
  grossAnnualMasters,
} from '../../utils/constant';
import {
  minDateForContributor,
  allowOnlyNumbers,
  applicationComparison,
  checkForMaritalStatusOthers,
} from '../../utils/utilityFunctions';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useState, useEffect } from 'react';
import { getDetailsByRefrenceType, kycUpdateResponse } from '../../redux-store/types/api-types';
import { kycUpdateonProceed } from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { ConfirmationDialog } from '../commonComponents';

const PersonalObject = {
  name: '',
  namePrefix: '',
  motherName: '',
  fatherOrSpouse: '',
  fatherOrSpouseName: '',
  dateOfBirth: minDateForContributor().toString(),
  maritalStatus: '',
  occupationDetails: '',
  kraEmail: '',
  kraMobileNumber: '',
  phoneNumberResidence: '',
  fax: '',
  grossAnnualIncome: '',
  kraMaritalStatus: '',
  maritalStatusOthers: '',
};

type PersonalDetailsProps = {
  name: string;
  namePrefix: string;
  fatherOrSpouse: string | null;
  motherName: string;
  fatherOrSpouseName: string;
  dateOfBirth: string | null;
  kraEmail: string;
  relationShipWithFirstApplicant: string | null;
  maritalStatus: string;
  kraMaritalStatus: string;
  maritalStatusOthers: string;
  kraMobileNumber: string;
  fax: string;
  grossAnnualIncome?: string;
  occupationDetails: string;
  phoneNumberResidence: string | null | number;
};

export type Values = Partial<PersonalDetailsProps>;

const initialValues: Values = PersonalObject;

export const PersonalDetailsValidation = yup.object().shape({
  fatherOrSpouseName: yup.string().nullable().required('Father/Spouse Name is required'),
  kraEmail: yup
    .string()
    .nullable()
    .matches(emailRegex, 'invalid Email ID')
    .required('Email is required'),
  kraMobileNumber: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { createError, path, parent } = context;
      if (!value) {
        return createError({
          message: 'Mobile is required',
          path,
        });
      }
      if (!numberRegex.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      return true;
    }),
});

const PersonalDetails = ({ data }: { data: Values }): JSX.Element => {
  const index = 0;
  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="name"
          label="Full Name *"
          placeholder="Enter Full Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name="namePrefix"
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={true}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="motherName"
          label="Mother Name *"
          placeholder="Enter Mother Name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Father or Spouse *"
          name="fatherOrSpouse"
          items={fatherOrSpouseSelectMasters.map((fatherOrSpouse) => ({
            key: fatherOrSpouse,
            value: fatherOrSpouse,
          }))}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="fatherOrSpouseName"
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          name="dateOfBirth"
          maxDate={minDateForContributor()}
          disabled={true}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Marital Status *"
          name="maritalStatus"
          items={Object.keys(maritalStatusMasters).map((status) => ({
            key: maritalStatusMasters[status],
            value: status,
          }))}
          disabled={true}
        />
      </Grid> */}
      {/* {data.kraMaritalStatus && ( */}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kraMaritalStatus`}
          label={`KRA Marital Status`}
          placeholder="Enter KRA Marital Status"
          disabled={true}
        />
      </Grid>
      {/* )} */}
      {/* {checkForMaritalStatusOthers(selectedCustodian, data.maritalStatus || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`maritalStatusOthers`}
            label={`Please Specify Marital Status`}
            placeholder={`Enter Marital Status`}
            disabled={true}
          />
        </Grid>
      )} */}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          label="Occupation Type *"
          name="occupationDetails"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name="grossAnnualIncome"
          label="Gross Annual Income (in INR) *"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome.label,
            value: grossIncome.label,
          }))}
          disabled={true}
        />
      </Grid>
    </>
  );
};

const ContactDetails = (): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="kraMobileNumber"
          label={`KRA Mobile Number *`}
          placeholder="Enter KRA Mobile Number"
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="kraEmail"
          label="KRA Email ID *"
          placeholder="Enter E-Mail ID"
          startAdornment={
            <InputAdornment position="start">
              <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
            </InputAdornment>
          }
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name="phoneNumberResidence"
          label="Phone (Residential)"
          placeholder="Enter Phone (Residential)"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          startAdornment={
            <InputAdornment position="start">
              <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
            </InputAdornment>
          }
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField name="fax" label="Fax" placeholder="Enter Fax here" disabled={true} />
      </Grid>
    </>
  );
};

export default function KycPersonalDetails(): JSX.Element {
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [personalDetails, setPersonalDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openConfirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    setPersonalDetails({
      ...personalDetails,
      name: referenceDetail.name,
      namePrefix: referenceDetail.namePrefix,
      fatherOrSpouse: referenceDetail.fatherOrSpouse,
      fatherOrSpouseName: referenceDetail.fatherOrSpouseName,
      dateOfBirth: referenceDetail.dateOfBirth,
      kraEmail: referenceDetail.kraEmail,
      maritalStatus: referenceDetail.maritalStatus,
      kraMaritalStatus: referenceDetail.kraMaritalStatus,
      maritalStatusOthers: referenceDetail.maritalStatusOthers,
      kraMobileNumber: referenceDetail.kraMobileNumber,
      fax: '',
      grossAnnualIncome: referenceDetail.grossAnnualIncome,
      occupationDetails: referenceDetail.occupationDetails,
      motherName: referenceDetail.motherName,
      phoneNumberResidence: referenceDetail.phoneNumberResidence,
    });
  }, [referenceDetail]);
  return (
    <Formik
      initialValues={personalDetails}
      onSubmit={async (value) => {
        try {
          const { fax, ...rest } = value;
          const checkIfUpdated = applicationComparison(referenceDetail, {
            ...referenceDetail,
            ...rest,
          } as Partial<getDetailsByRefrenceType>);
          if (!checkIfUpdated) {
            setLoading(true);
            const response = (await dispatch(
              kycUpdateonProceed({
                fieldsToUpdate: {
                  fatherOrSpouseName: value.fatherOrSpouseName,
                  kraMobileNumber: value.kraMobileNumber,
                  kraEmail: value.kraEmail,
                },
                applicantId: referenceDetail.id,
              })
            )) as unknown as kycUpdateResponse;
          }
          setConfirmDialog(true);
        } catch (error) {
          console.error((error as Error).message);
        } finally {
          setLoading(false);
        }
      }}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, PersonalDetailsValidation, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          onSubmit={handleSubmit}
          noValidate>
          <SubHeading
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            Personal Details
          </SubHeading>
          <PersonalDetails data={values} />
          <SubHeading>Contact details</SubHeading>
          <ContactDetails />
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                mt: 10,
                mb: 2,
                lineHeight: 1.5,
                fontSize: 14,
                px: 15,
                boxSizing: 'border-box',
              }}>
              Proceed
            </LoadingButton>
          </Box>
          <ConfirmationDialog
            message="Are you sure you want to proceed further to update Address ?"
            open={openConfirmDialog}
            setOpen={() => setConfirmDialog(false)}
            onSave={() => {
              setConfirmDialog(false);
              history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/kyc-update/address-details`
              );
            }}
            onCancel={() => {
              setConfirmDialog(false);
              history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              );
            }}
            yesButtonText={'Proceed'}
            noButtonText={'Back To Preview'}
          />
        </Grid>
      )}
    </Formik>
  );
}
