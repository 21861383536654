/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { Fragment, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { ApplicationProps, NomineeType } from '../../redux-store/types/api-types';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkNDPMSForIndusindBank,
  getApplicantName,
  getDisableStatusForjointHolder,
  getNomineeRelation,
  getStep,
  isCustodianAxis,
  isCustodianHDFC,
  isCustodianICICIOrHDFC,
  isCustodianKotak,
  isMinor,
  preventSpecialCharacters,
  removeSingleQuote,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  shouldValidateUponSaveLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useParams } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { ConfirmationDialog, CountryCodesDropDown } from '../commonComponents';
import { nomineeDetailsSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  NomineeRelations,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import { OnEditDialogBox } from './additionalKYCDetails';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { getNationalityList } from '../../redux-store/actions';
import { nonDigitRegex } from '../../utils/regex';
import { refLoginCheck } from '../Investments/investor-application';
import FieldValidationNote from './FieldValidationNote';

type updateNominee = NomineeType & {
  Relationship: string | null;
  sno?: number;
  otherGuardianRelationship: string | null;
};

type Values = {
  doNotWishToNominate: boolean;
  nominees: updateNominee[];
  saveType: string;
  countryDropdown: string[];
};

export const newNominee: updateNominee = {
  nomineeName: '',
  nomineeRelationship: '',
  nomineeEmail: '',
  nomineeEmailSameAsGuardian: false,
  nomineePhone: '',
  nomineePhoneSameAsGuardian: false,
  dateOfBirth: null,
  nomineePercentage: 0,
  addressSameAsApplicant: false,
  guardianAddressSameAsApplicant: false,
  guardianName: '',
  nomineePan: '',
  nomineeGuardianPan: '',
  guardianEmail: '',
  guardianPhone: '',
  guardianCountryCode: '+91',
  guardianCountryNameAndCode: 'India: +91',
  nomineeAddress1: '',
  nomineeAddress2: '',
  nomineeAddress3: '',
  nomineeCity: '',
  nomineeState: '',
  nomineeCountry: '',
  nomineePincode: '',
  guardianAddress1: '',
  guardianAddress2: '',
  guardianAddress3: '',
  guardianCity: '',
  guardianState: '',
  guardianCountry: '',
  guardianPincode: '',
  isActive: true,
  Relationship: '',
  sno: 0,
  nomineeCountryCode: '+91',
  nomineeCountryNameAndCode: 'India: +91',
  guardianRelationship: '',
  otherGuardianRelationship: '',
};

const initialValues: Values = {
  doNotWishToNominate: true,
  nominees: [newNominee],
  saveType: 'save and proceed',
  countryDropdown: [],
};

export const NomineeAddress = ({
  index,
  nationalitiesMdmsResponse,
  disable,
}: {
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  disable: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeAddress1`}
          label={`Nominee Address Line 1 *`}
          placeholder="Enter Nominee Address Line 1"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeAddress2`}
          label={`Nominee Address Line 2 *`}
          placeholder="Enter Nominee Address Line 2"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeAddress3`}
          label={`Nominee Address Line 3 *`}
          placeholder="Enter Nominee Address Line 3"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePincode`}
          label={`Nominee Pincode *`}
          placeholder="Enter Nominee Pincode"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeCity`}
          label={`Nominee City *`}
          placeholder="Enter Nominee City"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeState`}
          label={`Nominee State *`}
          placeholder="Enter Nominee State"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`nominees.${index}.nomineeCountry`}
          label="Nominee Country *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          disabled={disable}
          searchFieldPlaceholder={'Search Country'}
        />
      </Grid>
    </>
  );
};

export const GuardianAddress = ({
  index,
  nationalitiesMdmsResponse,
  disable,
}: {
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  disable: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianAddress1`}
          label={`Guardian Address Line 1 *`}
          placeholder="Enter Guardian Address Line 1"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianAddress2`}
          label={`Guardian Address Line 2 *`}
          placeholder="Enter Guardian Address Line 2"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianAddress3`}
          label={`Guardian Address Line 3 *`}
          placeholder="Enter Guardian Address Line 3"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianPincode`}
          label={`Guardian Pincode *`}
          placeholder="Enter Guardian Pincode"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianCity`}
          label={`Guardian City *`}
          placeholder="Enter Guardian City"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.guardianState`}
          label={`Guardian State *`}
          placeholder="Enter Guardian State"
          disabled={disable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`nominees.${index}.guardianCountry`}
          label="Guardian Country *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          disabled={disable}
          searchFieldPlaceholder={'Search Country'}
        />
      </Grid>
    </>
  );
};

const Nominee = ({
  nomineeName,
  index,
  values,
  nationalitiesMdmsResponse,
  selectedCustodian,
  amcuserAsInvestor,
}: {
  nomineeName: number;
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  selectedCustodian: string;
  amcuserAsInvestor: boolean;
}): JSX.Element => {
  const { dateOfBirth = '' } = values.nominees[index];
  const { setFieldValue, setValues } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  const [mobileElementWidthGuardian, setMobileElementWidthGuardian] = useState(null);

  useEffect(() => {
    const mobileElement = document.getElementsByName('nominees.0.nomineePhone');
    const mobileElementGuardian = document.getElementsByName('nominees.0.guardianPhone');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
    setMobileElementWidthGuardian(mobileElementGuardian[0]?.parentElement?.clientWidth as any);
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeName`}
          label={`Nominee Name ${nomineeName} *`}
          placeholder={`Enter Nominee Name ${nomineeName}`}
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
        />
      </Grid>
      {(isCustodianICICIOrHDFC(selectedCustodian) ||
        isCustodianAxis(selectedCustodian) ||
        isCustodianKotak(selectedCustodian)) && (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineeEmail`}
              label={`Nominee Email ${
                (!isMinor(dateOfBirth ? dateOfBirth : '') && isCustodianKotak(selectedCustodian)) ||
                isCustodianHDFC(selectedCustodian) ||
                isCustodianAxis(selectedCustodian)
                  ? '*'
                  : ''
              }`}
              placeholder={`Enter Nominee Email`}
              disabled={
                getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                ) ||
                (isMinor(dateOfBirth ? dateOfBirth : '') &&
                  values.nominees[index].nomineeEmailSameAsGuardian)
              }
            />
            {isMinor(dateOfBirth ? dateOfBirth : '') && (
              <MFCheckbox
                name={`nominees.${index}.nomineeEmailSameAsGuardian`}
                label="Nominee Email Same As Guardian"
                sx={{ letterSpacing: '1px' }}
                checked={values.nominees[index].nomineeEmailSameAsGuardian}
                disabled={getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                )}
                onChange={({ target: { checked } }) => {
                  setValues({
                    ...values,
                    nominees: values.nominees.map((nominee, i) => {
                      return i === index
                        ? {
                            ...nominee,
                            nomineeEmail: checked
                              ? nominee.guardianEmail || newNominee.guardianEmail
                              : newNominee.nomineeEmail,
                            nomineeEmailSameAsGuardian: checked,
                          }
                        : nominee;
                    }),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineePhone`}
              label={`Nominee Phone Number ${
                (!isMinor(dateOfBirth ? dateOfBirth : '') && isCustodianKotak(selectedCustodian)) ||
                isCustodianHDFC(selectedCustodian) ||
                isCustodianAxis(selectedCustodian)
                  ? '*'
                  : ''
              }`}
              placeholder={`Enter Nominee Phone Number`}
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              startAdornment={
                <CountryCodesDropDown
                  ElementWidth={mobileElementWidth && mobileElementWidth}
                  name={`nominees.${index}.nomineeCountryNameAndCode`}
                  value={values.nominees[index].nomineeCountryNameAndCode}
                  disabled={
                    getDisableStatusForjointHolder(
                      role,
                      referenceDetail.applicant_type,
                      amcuserAsInvestor
                    ) ||
                    (isMinor(dateOfBirth ? dateOfBirth : '') &&
                      values.nominees[index].nomineePhoneSameAsGuardian)
                  }
                />
              }
              disabled={
                getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                ) ||
                (isMinor(dateOfBirth ? dateOfBirth : '') &&
                  values.nominees[index].nomineePhoneSameAsGuardian)
              }
              regexForFilterValue={nonDigitRegex}
            />
            {isMinor(dateOfBirth ? dateOfBirth : '') && (
              <MFCheckbox
                name={`nominees.${index}.nomineePhoneSameAsGuardian`}
                label="Nominee Phone Number Same As Guardian"
                sx={{ letterSpacing: '1px' }}
                checked={values.nominees[index].nomineePhoneSameAsGuardian}
                disabled={getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                )}
                onChange={({ target: { checked } }) => {
                  setValues({
                    ...values,
                    nominees: values.nominees.map((nominee, i) => {
                      return i === index
                        ? {
                            ...nominee,
                            nomineePhone: checked
                              ? nominee.guardianPhone || newNominee.guardianPhone
                              : newNominee.nomineePhone,
                            nomineePhoneSameAsGuardian: checked,
                            nomineeCountryNameAndCode: checked
                              ? nominee.guardianCountryNameAndCode ||
                                newNominee.guardianCountryNameAndCode
                              : newNominee.nomineeCountryNameAndCode,
                          }
                        : nominee;
                    }),
                  });
                }}
              />
            )}
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`nominees.${index}.Relationship`}
          label="Nominee Relationship *"
          items={NomineeRelations.filter((relation) => {
            const { nominees } = values;
            const selectedRelations = nominees
              .filter(
                (nominee, ind) =>
                  ind !== index &&
                  nominee.Relationship &&
                  nominee.isActive &&
                  ['MOTHER', 'FATHER', 'SPOUSE'].includes(nominee.Relationship)
              )
              .map((nominee) => nominee.Relationship);
            return !selectedRelations.includes(relation);
          }).map((relation) => ({
            key: relation,
            value: relation,
          }))}
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
        />
      </Grid>
      {values.nominees.map((nominee, idx: number) => {
        if (nominee.Relationship === 'OTHERS' && idx === index) {
          return (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.nomineeRelationship`}
                label="Relationship *"
                placeholder={`Enter Nominee Relationship`}
                disabled={getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                )}
              />
            </Grid>
          );
        }
      })}
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
          name={`nominees.${index}.dateOfBirth`}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePercentage`}
          label="Nominee % *"
          placeholder="Enter Nominee %"
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <MFCheckbox
          name={`nominees.${index}.addressSameAsApplicant`}
          label="Nominee Address Same As Applicant"
          sx={{ letterSpacing: '1px' }}
          checked={values.nominees[index].addressSameAsApplicant}
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
        />
      </Grid>
      {!values.nominees[index].addressSameAsApplicant && (
        <NomineeAddress
          index={index}
          nationalitiesMdmsResponse={nationalitiesMdmsResponse}
          disable={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
        />
      )}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePan`}
          label={`Nominee PAN ${
            isCustodianICICIOrHDFC(selectedCustodian) ||
            ((isCustodianAxis(selectedCustodian) || isCustodianKotak(selectedCustodian)) &&
              !isMinor(dateOfBirth || ''))
              ? '*'
              : ''
          }`}
          placeholder="Enter Nominee PAN"
          inputProps={{ style: { textTransform: 'uppercase' } }}
          disabled={getDisableStatusForjointHolder(
            role,
            referenceDetail.applicant_type,
            amcuserAsInvestor
          )}
          onChange={(e) => {
            setFieldValue(`nominees.${index}.nomineePan`, e.target.value.toUpperCase());
          }}
        />
      </Grid>
      {isMinor(dateOfBirth ? dateOfBirth : '') && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianName`}
              label="Guardian Name *"
              placeholder="Enter Guardian Name"
              disabled={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {(isCustodianICICIOrHDFC(selectedCustodian) ||
            isCustodianAxis(selectedCustodian) ||
            isCustodianKotak(selectedCustodian)) && (
            <Fragment>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`nominees.${index}.guardianEmail`}
                  label={`Guardian Email ${
                    isCustodianHDFC(selectedCustodian) ||
                    isCustodianAxis(selectedCustodian) ||
                    isCustodianKotak(selectedCustodian)
                      ? '*'
                      : ''
                  }`}
                  placeholder={`Enter Guardian Email`}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      nominees: values.nominees.map((nominee, _nominee_Ind) => {
                        return _nominee_Ind === index
                          ? {
                              ...nominee,
                              nomineeEmail: nominee.nomineeEmailSameAsGuardian
                                ? e.target.value
                                : nominee.nomineeEmail,
                              guardianEmail: e.target.value,
                            }
                          : nominee;
                      }),
                    });
                  }}
                  disabled={getDisableStatusForjointHolder(
                    role,
                    referenceDetail.applicant_type,
                    amcuserAsInvestor
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`nominees.${index}.guardianPhone`}
                  label={`Guardian Phone Number ${
                    isCustodianHDFC(selectedCustodian) ||
                    isCustodianAxis(selectedCustodian) ||
                    isCustodianKotak(selectedCustodian)
                      ? '*'
                      : ''
                  }`}
                  placeholder={`Enter Guardian Phone Number`}
                  onKeyDown={(e) => {
                    preventSpecialCharacters(e);
                  }}
                  startAdornment={
                    <CountryCodesDropDown
                      ElementWidth={mobileElementWidthGuardian && mobileElementWidthGuardian}
                      name={`nominees.${index}.guardianCountryNameAndCode`}
                      value={values.nominees[index].guardianCountryNameAndCode as string}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type,
                        amcuserAsInvestor
                      )}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          nominees: values.nominees.map((nominee, _ind) => {
                            return _ind === index
                              ? {
                                  ...nominee,
                                  nomineeCountryNameAndCode: nominee.nomineePhoneSameAsGuardian
                                    ? e.target.value
                                    : nominee.nomineeCountryNameAndCode,
                                  guardianCountryNameAndCode: e.target.value,
                                }
                              : nominee;
                          }),
                        });
                      }}
                    />
                  }
                  onChange={(e) => {
                    setValues({
                      ...values,
                      nominees: values.nominees.map((nominee, _nomineeInd) => {
                        return _nomineeInd === index
                          ? {
                              ...nominee,
                              nomineePhone: nominee.nomineePhoneSameAsGuardian
                                ? e.target.value
                                : nominee.nomineePhone,
                              guardianPhone: e.target.value,
                            }
                          : nominee;
                      }),
                    });
                  }}
                  disabled={getDisableStatusForjointHolder(
                    role,
                    referenceDetail.applicant_type,
                    amcuserAsInvestor
                  )}
                  regexForFilterValue={nonDigitRegex}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <MFCheckbox
              name={`nominees.${index}.guardianAddressSameAsApplicant`}
              label="Guardian Address Same As Applicant"
              sx={{ letterSpacing: '1px' }}
              checked={values.nominees[index].guardianAddressSameAsApplicant}
              disabled={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {!values.nominees[index].guardianAddressSameAsApplicant && (
            <GuardianAddress
              index={index}
              nationalitiesMdmsResponse={nationalitiesMdmsResponse}
              disable={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
            />
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineeGuardianPan`}
              label="Guardian PAN *"
              placeholder="Enter Guardian Pan"
              inputProps={{ style: { textTransform: 'uppercase' } }}
              disabled={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
              onChange={(e) => {
                setFieldValue(`nominees.${index}.nomineeGuardianPan`, e.target.value.toUpperCase());
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`nominees.${index}.guardianRelationship`}
              label="Guardian Relationship *"
              items={NomineeRelations.map((relation) => ({
                key: relation,
                value: relation,
              }))}
              disabled={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {values.nominees.map((nominee, idx: number) => {
            if (nominee.guardianRelationship === 'OTHERS' && idx === index) {
              return (
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`nominees.${index}.otherGuardianRelationship`}
                    label="Guardian Relationship *"
                    placeholder={`Enter Guardian Relationship`}
                    disabled={getDisableStatusForjointHolder(
                      role,
                      referenceDetail.applicant_type,
                      amcuserAsInvestor
                    )}
                  />
                </Grid>
              );
            }
          })}
        </>
      )}
    </>
  );
};

export default function NomineeDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [nomineesData, setNomineesData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [nomineeToDelete, setNomineeToDelete] = useState<{
    nominee: Partial<NomineeType>;
    index: number;
  } | null>(null);
  const [bulkNomineeDelete, setBulkNomineeDelete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const selectedCustodian = application?.custodian || '';

  const updatePayloadAndCheckApplication = (values: Values, verifyCurrentStep: boolean) => {
    try {
      const { nominees: existingNominees = [], currentStep, applicants = [] } = application || {};
      const { nominees, doNotWishToNominate, saveType } = values;
      const shouldSaveForLater = shouldValidateUponSaveLater(saveType);
      const paramanantAddress = applicants
        .find((applicant) => applicant.applicant_type === '1')
        ?.addresses?.find((address) => address.address_type?.toLowerCase() === 'permanent');
      const updatedPayload = {
        ...application,
        doNotWishToNominate,
        nominees: nominees
          .filter((nominee) => nominee?.id || nominee.isActive)
          .map((nominee, index) => {
            const { id = null } = existingNominees[index] || {};
            if (nominee.id === id) {
              const { Relationship, otherGuardianRelationship, sno, ...rest } = nominee;
              return {
                ...(existingNominees[index] || {}),
                ...rest,
                nomineeRelationship: nominee.nomineeRelationship
                  ? removeSingleQuote(nominee.nomineeRelationship)
                  : getNomineeRelation(Relationship, shouldSaveForLater)
                  ? Relationship
                  : removeSingleQuote(nominee.nomineeRelationship),

                guardianRelationship: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? otherGuardianRelationship
                    ? removeSingleQuote(otherGuardianRelationship)
                    : getNomineeRelation(nominee.guardianRelationship, shouldSaveForLater)
                    ? nominee.guardianRelationship
                    : removeSingleQuote(otherGuardianRelationship)
                  : null,
                dateOfBirth: !nominee.dateOfBirth
                  ? null
                  : new Date(nominee.dateOfBirth).toISOString(),
                isActive: nominee.isActive && !doNotWishToNominate,
                nomineeName: removeSingleQuote(rest.nomineeName),
                nomineeAddress1: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address1)
                  : removeSingleQuote(rest.nomineeAddress1),
                nomineeAddress2: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address2)
                  : removeSingleQuote(rest.nomineeAddress2),
                nomineeAddress3: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address3)
                  : removeSingleQuote(rest.nomineeAddress3),
                nomineeCity: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.city)
                  : removeSingleQuote(rest.nomineeCity),
                nomineeState: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.state)
                  : removeSingleQuote(rest.nomineeState),
                nomineePincode: nominee.addressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.pincode)
                  : removeSingleQuote(rest.nomineePincode),
                nomineeCountry: nominee.addressSameAsApplicant
                  ? paramanantAddress?.country
                  : rest.nomineeCountry,
                guardianAddressSameAsApplicant: isMinor(
                  nominee.dateOfBirth ? nominee.dateOfBirth : ''
                )
                  ? nominee.guardianAddressSameAsApplicant
                  : false,
                guardianAddress1: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.address1)
                    : removeSingleQuote(rest.guardianAddress1)
                  : null,
                guardianAddress2: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.address2)
                    : removeSingleQuote(rest.guardianAddress2)
                  : null,
                guardianAddress3: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.address3)
                    : removeSingleQuote(rest.guardianAddress3)
                  : null,
                guardianCity: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.city)
                    : removeSingleQuote(rest.guardianCity)
                  : null,
                guardianState: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.state)
                    : removeSingleQuote(rest.guardianState)
                  : null,
                guardianPincode: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? removeSingleQuote(paramanantAddress?.pincode)
                    : removeSingleQuote(rest.guardianPincode)
                  : null,
                guardianCountry: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? paramanantAddress?.country
                    : rest.guardianCountry
                  : null,
                guardianName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianName)
                  : null,
                nomineeGuardianPan: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.nomineeGuardianPan)
                  : null,
                nomineePercentage:
                  nominee.nomineePercentage === 0 || nominee.nomineePercentage
                    ? Number(nominee.nomineePercentage)
                    : null,
                nomineeCountryCode: rest.nomineeCountryNameAndCode?.split(':')[1].trim(),
                guardianEmail: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.guardianEmail
                  : null,
                guardianPhone: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.guardianPhone
                  : null,
                guardianCountryCode: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.guardianCountryNameAndCode?.split(':')[1].trim()
                  : null,
                guardianCountryNameAndCode: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.guardianCountryNameAndCode
                  : null,
                nomineePhoneSameAsGuardian: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.nomineePhoneSameAsGuardian
                  : newNominee.nomineePhoneSameAsGuardian,
                nomineeEmailSameAsGuardian: isMinor(rest.dateOfBirth ? rest.dateOfBirth : '')
                  ? rest.nomineeEmailSameAsGuardian
                  : newNominee.nomineeEmailSameAsGuardian,
              };
            }
            const { Relationship, sno, otherGuardianRelationship, ...rest1 } =
              nominees[nominee.sno as number];
            return {
              ...rest1,
              nomineeRelationship: removeSingleQuote(rest1.nomineeRelationship),
              guardianRelationship: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? otherGuardianRelationship
                  ? removeSingleQuote(otherGuardianRelationship)
                  : getNomineeRelation(rest1.guardianRelationship, shouldSaveForLater)
                  ? rest1.guardianRelationship
                  : removeSingleQuote(otherGuardianRelationship)
                : null,
              nomineeName: removeSingleQuote(rest1.nomineeName),
              nomineeAddress1: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.address1)
                : removeSingleQuote(rest1.nomineeAddress1),
              nomineeAddress2: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.address2)
                : removeSingleQuote(rest1.nomineeAddress2),
              nomineeAddress3: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.address3)
                : removeSingleQuote(rest1.nomineeAddress3),
              nomineeCity: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.city)
                : removeSingleQuote(rest1.nomineeCity),
              nomineeState: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.state)
                : removeSingleQuote(rest1.nomineeState),
              nomineePincode: nominee.addressSameAsApplicant
                ? removeSingleQuote(paramanantAddress?.pincode)
                : removeSingleQuote(rest1.nomineePincode),
              nomineeCountry: nominee.addressSameAsApplicant
                ? paramanantAddress?.country
                : rest1.nomineeCountry,
              guardianAddressSameAsApplicant: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianAddressSameAsApplicant
                : false,
              guardianAddress1: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address1)
                  : removeSingleQuote(rest1.guardianAddress1)
                : null,
              guardianAddress2: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address2)
                  : removeSingleQuote(rest1.guardianAddress2)
                : null,
              guardianAddress3: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.address3)
                  : removeSingleQuote(rest1.guardianAddress3)
                : null,
              guardianCity: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.city)
                  : removeSingleQuote(rest1.guardianCity)
                : null,
              guardianState: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.state)
                  : removeSingleQuote(rest1.guardianState)
                : null,
              guardianPincode: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? removeSingleQuote(paramanantAddress?.pincode)
                  : removeSingleQuote(rest1.guardianPincode)
                : null,
              guardianCountry: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? nominee.guardianAddressSameAsApplicant
                  ? paramanantAddress?.country
                  : rest1.guardianCountry
                : null,
              nomineeGuardianPan: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.nomineeGuardianPan)
                : null,
              nomineeCountryCode: rest1.nomineeCountryNameAndCode?.split(':')[1].trim(),
              guardianName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianName)
                : null,
              guardianEmail: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianEmail
                : null,
              guardianPhone: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianPhone
                : null,
              guardianCountryCode: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianCountryNameAndCode?.split(':')[1].trim()
                : null,
              guardianCountryNameAndCode: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianCountryNameAndCode
                : null,
              nomineePhoneSameAsGuardian: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.nomineePhoneSameAsGuardian
                : newNominee.nomineePhoneSameAsGuardian,
              nomineeEmailSameAsGuardian: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.nomineeEmailSameAsGuardian
                : newNominee.nomineeEmailSameAsGuardian,
              nomineePercentage:
                rest1.nomineePercentage === 0 || rest1.nomineePercentage
                  ? Number(rest1.nomineePercentage)
                  : null,
            };
          }),
      };
      const isSaveLater = values.saveType !== 'save and proceed';
      let currentStepIsIncluded = {};
      if (verifyCurrentStep) {
        currentStepIsIncluded = {
          currentStep: getStep(
            !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        };
      }
      const checkApplication = applicationComparison(
        {
          ...application,
          nominees: application?.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        },
        {
          ...updatedPayload,
          ...currentStepIsIncluded,
          nominees: updatedPayload.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        }
      );
      return { updatedPayload, checkApplication };
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const onSubmit = async (values: Values) => {
    try {
      const { id, status, hasPOA, applicationNumber } = application || {};
      const { saveType } = values;
      const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, true);
      const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
      const updatedPayload = updatePayloadAndCheckApplicationValue?.updatedPayload;

      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              currentStep: getStep(6, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const { bankAccountFormCreated = false, showBankIcon = false } = application || {};

    const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, false);
    const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
    if (
      !checkApplication &&
      checkNdpmsFlow &&
      bankAccountFormCreated &&
      showBankIcon &&
      role === USER_ROLES.RM
    ) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      onSubmit(values);
    }
  };

  // const [ModeOfHolding, SetModeOfHolding] = useState<boolean>(true);
  useEffect(() => {
    (async function () {
      try {
        const {
          nominees = [],
          doNotWishToNominate = true,
          modeOfHolding = '',
          applicants = [],
        } = application || {};
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const shouldSaveForLater = shouldValidateUponSaveLater(nomineesData?.saveType);

        setNomineesData({
          ...nomineesData,
          doNotWishToNominate: nominees.length
            ? false
            : doNotWishToNominate === null
            ? true
            : doNotWishToNominate,

          nominees: nominees.length
            ? nominees.map((nominee, index) => ({
                ...nominee,
                Relationship: getNomineeRelation(
                  nominee.nomineeRelationship?.toUpperCase(),
                  shouldSaveForLater
                )
                  ? nominee.nomineeRelationship
                    ? nominee.nomineeRelationship.toUpperCase()
                    : nominee.nomineeRelationship
                  : 'OTHERS',
                nomineeRelationship:
                  nominee.nomineeRelationship?.toUpperCase() === 'OTHERS'
                    ? ''
                    : getNomineeRelation(
                        nominee.nomineeRelationship?.toUpperCase(),
                        shouldSaveForLater
                      )
                    ? ''
                    : nominee.nomineeRelationship,
                addressSameAsApplicant: nominee.addressSameAsApplicant || false,
                nomineeAddress1: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress1,
                nomineeAddress2: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress2,
                nomineeAddress3: nominee.addressSameAsApplicant ? '' : nominee.nomineeAddress3,
                nomineeCity: nominee.addressSameAsApplicant ? '' : nominee.nomineeCity,
                nomineeState: nominee.addressSameAsApplicant ? '' : nominee.nomineeState,
                nomineeCountry: nominee.addressSameAsApplicant ? '' : nominee.nomineeCountry,
                nomineePincode: nominee.addressSameAsApplicant ? '' : nominee.nomineePincode,
                guardianAddressSameAsApplicant: nominee.guardianAddressSameAsApplicant || false,
                guardianAddress1: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianAddress1
                  : null,
                guardianAddress2: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianAddress2
                  : null,
                guardianAddress3: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianAddress3
                  : null,
                guardianCity: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianCity
                  : null,
                guardianState: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianState
                  : null,
                guardianCountry: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianCountry?.toUpperCase()
                  : null,
                guardianPincode: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddressSameAsApplicant
                    ? ''
                    : nominee.guardianPincode
                  : null,
                sno: index,
                nomineePercentage: nominee.nomineePercentage
                  ? nominee.nomineePercentage
                  : nominees.length === 1
                  ? 100
                  : 0,
                nomineeCountryNameAndCode: nominee.nomineeCountryNameAndCode
                  ? nominee.nomineeCountryNameAndCode
                  : 'India: +91',
                guardianCountryNameAndCode: nominee.guardianCountryNameAndCode
                  ? nominee.guardianCountryNameAndCode
                  : 'India: +91',
                guardianRelationship: getNomineeRelation(
                  nominee.guardianRelationship?.toUpperCase(),
                  shouldSaveForLater
                )
                  ? nominee.guardianRelationship
                    ? nominee.guardianRelationship.toUpperCase()
                    : nominee.guardianRelationship
                  : 'OTHERS',
                otherGuardianRelationship:
                  nominee.guardianRelationship?.toUpperCase() === 'OTHERS'
                    ? ''
                    : getNomineeRelation(
                        nominee.guardianRelationship?.toUpperCase(),
                        shouldSaveForLater
                      )
                    ? ''
                    : nominee.guardianRelationship,
              }))
            : [],
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  return (
    <Formik
      initialValues={nomineesData}
      onSubmit={manageSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            nomineeDetailsSchema(
              selectedCustodian,
              application?.applicants,
              shouldValidateUponSaveLater(values.saveType)
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes displayContent={'Investor(s) have the option to choose upto three nominees'} />
          <Grid item xs={12}>
            <MFCheckbox
              // disabled={ModeOfHolding}
              name="doNotWishToNominate"
              label="I do not wish to nominate."
              sx={{ letterSpacing: '1px' }}
              onChange={({ target: { checked } }) => {
                const updatedNominees = !checked
                  ? [
                      ...values.nominees,
                      { ...newNominee, nomineePercentage: 100, sno: values.nominees.length },
                    ]
                  : values.nominees;
                setValues({
                  ...nomineesData,
                  doNotWishToNominate:
                    values.nominees?.filter((item) => item.isActive).length === 0
                      ? checked
                      : !checked,
                  nominees: updatedNominees.map((nominee) => ({
                    ...nominee,
                  })),
                });
                checked && setBulkNomineeDelete(true);
              }}
              checked={values.doNotWishToNominate}
              disabled={getDisableStatusForjointHolder(
                role,
                referenceDetail.applicant_type,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {values.nominees
            .filter((_nominee) => _nominee.isActive)
            .map((nominee, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}>
                  {getApplicantName(index + 1, true)} Nominee Details
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => setNomineeToDelete({ nominee, index: nominee.sno as number })}>
                    <Typography
                      sx={{
                        color: 'rgba(196, 42, 51, 0.8)',
                        fontSize: 12,
                        ml: 'auto',
                        letterSpacing: '-0.2px',
                        cursor: 'pointer',
                      }}>
                      Remove Nominee{' '}
                    </Typography>
                    <IconButton sx={{ p: '2px' }}>
                      <CardMedia
                        component="img"
                        src="/images/delete-red.svg"
                        alt="Delete Icon"
                        sx={{ width: 'unset' }}
                      />
                    </IconButton>
                  </Box>
                </SubHeading>
                <Nominee
                  nomineeName={index + 1}
                  index={nominee.sno as number}
                  key={nominee.sno as number}
                  values={values}
                  nationalitiesMdmsResponse={mdmsCountriesList}
                  selectedCustodian={selectedCustodian}
                  amcuserAsInvestor={amcuserAsInvestor}
                />
              </React.Fragment>
            ))}
          {values.nominees.filter((item) => item.isActive).length < 3 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                disabled={getDisableStatusForjointHolder(
                  role,
                  referenceDetail.applicant_type,
                  amcuserAsInvestor
                )}
                onClick={() =>
                  setValues({
                    ...values,
                    nominees: [
                      ...values.nominees,
                      {
                        ...newNominee,
                        nomineePercentage: values.nominees.filter((item) => item.isActive).length
                          ? 0
                          : 100,
                        sno: values.nominees.length,
                      },
                    ],
                    doNotWishToNominate: false,
                  })
                }>
                Add Nominee
              </Button>
            </Grid>
          )}
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
          />
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee.Relationship,
                })),
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee?.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee?.Relationship,
                })),
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete nominees ?'}
            open={nomineeToDelete !== null || bulkNomineeDelete !== false}
            setOpen={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedApplicants = values.nominees.map((nominee, i) => {
                  if (nominee.sno === nomineeToDelete?.index || bulkNomineeDelete) {
                    return {
                      ...nominee,
                      isActive: false,
                    };
                  }
                  return nominee;
                });
                setValues({
                  ...values,
                  nominees: remainedApplicants,
                  doNotWishToNominate:
                    remainedApplicants.filter((item) => item.isActive).length === 0
                      ? true
                      : values.doNotWishToNominate,
                });
                setBulkNomineeDelete(false);
                setNomineeToDelete(null);
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
