import { CardMedia, Grid, InputAdornment, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { MFTextField } from '../../lib/formik';
import { Formik, FormikHandlers, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  getAddressData,
  getAddressFields,
  getApplicantName,
  preventSpecialCharacters,
  saveForLater,
  _updatedAddresses,
  checkForCorrespondenceAddress,
  removeSingleQuote,
  saveForLaterInvestorPhotoCapture,
  checkEditiable,
  checkNDPMSForIndusindBank,
  isCustodianICICIOrHDFC,
  isCustodianNuvuma,
  isCustodianHDFC,
  isCustodianOrbis,
  shouldValidateUponSaveLater,
  getStep,
  isCustodianICICI,
  isCustodianAxis,
  addressInitialFields,
  isCustodianKotak,
} from '../../utils/utilityFunctions';
import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
} from '../../redux-store/types/api-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { contactDetailsSchema } from '../../utils/schema';
import {
  USER_ROLES,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  ENABLE_EMAIL_OTP,
  APPLICANT_STATUS,
  ContactRelations,
  ContactRelationsForHDFC,
  SAVE_LATER_VALIDATION_CHECK,
  ICICIContactRelations,
  Edit_KYC,
  addressForTaxsMasters,
} from '../../utils/constant';
import { CountryCodesDropDown, NavigationThrowErrorPopup } from '../commonComponents';
import { getNationalityList, getStatesList } from '../../redux-store/actions';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
} from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OnEditDialogBox } from './additionalKYCDetails';
import MFSelectField from '../../lib/formik/SelectField';
import { refLoginCheck } from '../Investments/investor-application';
import FieldValidationNote from './FieldValidationNote';

type AddressType = {
  nationality?: string | null;
  status?: string | null;
  permanent?: Partial<ApplicantAddressType>;
  correspondence?: Partial<ApplicantAddressType>;
  overseas?: Partial<ApplicantAddressType>;
  [key: string]: Partial<ApplicantAddressType> | string | null | undefined;
};

type ContactDetailsProps = {
  phoneNumberoffice: string;
  phoneNumberResidence: string;
  email: string;
  mobile: string;
  countryNameAndCode: string;
  countryCode: string;
  address: AddressType;
  kraMobileNumber: string;
  kraEmail: string;
  mobileEmailDeclaration?: string | null;
  mobileDeclaration?: string | null;
  emailDeclaration?: string | null;
};

export type Values = {
  hasPOA: boolean;
  applicants: ContactDetailsProps[];
  saveType: string;
  countryDropdown: string[];
  statesDropdown: string[];
  [key: string]: string | boolean | ContactDetailsProps[] | string[];
};

export const getFieldsDisabled = (
  addressType: string,
  fetchedFromKRACheck: boolean | null,
  role: any,
  selectedCustodian: string,
  fetchedFromDigilocker: boolean | null
): boolean => {
  return (
    (['overseas', 'permanent'].includes(addressType) && [USER_ROLES.POAAPPROVER].includes(role)) ||
    (Edit_KYC
      ? fetchedFromKRACheck || fetchedFromDigilocker
      : addressType === 'correspondence'
      ? (fetchedFromKRACheck || fetchedFromDigilocker) && isCustodianICICI(selectedCustodian)
      : fetchedFromKRACheck || fetchedFromDigilocker) ||
    false
  );
};

export const hidingDropDownFieldBasedOnDisabled = (
  isFieldDisabled: boolean,
  applicantType: string,
  role: any,
  index: number,
  amcuserAsInvestor: boolean
): boolean => {
  return isFieldDisabled || checkEditiable(applicantType, role, index, amcuserAsInvestor);
};

export const Address = ({
  addressType = '',
  index,
  nationalitiesMdmsResponse,
  fetchedFromKRACheck = false,
  statesMdmsResponse = [],
  address = {},
  fetchedFromDigilocker = false,
  fieldName = '',
  FatcaDisabled = false,
  address1Mandatory = false,
  address2Mandatory = false,
  address3Mandatory = false,
  pincodeMandatory = false,
  cityMandatory = false,
  districtMandatory = false,
  stateMandatory = false,
  countryMandatory = false,
  isFatca = false,
}: {
  addressType: string;
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  statesMdmsResponse?: mdmsStatesList[];
  fetchedFromKRACheck?: boolean | null;
  address?: Partial<ApplicantAddressType>;
  fetchedFromDigilocker?: boolean | null;
  fieldName?: string;
  FatcaDisabled?: boolean;
  address1Mandatory?: boolean;
  address2Mandatory?: boolean;
  address3Mandatory?: boolean;
  pincodeMandatory?: boolean;
  cityMandatory?: boolean;
  districtMandatory?: boolean;
  stateMandatory?: boolean;
  countryMandatory?: boolean;
  isFatca?: boolean;
}): JSX.Element => {
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';
  const isFieldDisabled = getFieldsDisabled(
    addressType,
    fetchedFromKRACheck,
    role,
    selectedCustodian,
    fetchedFromDigilocker
  );
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );
  const dropDownHidingCheck = hidingDropDownFieldBasedOnDisabled(
    isFieldDisabled,
    referenceDetail.applicant_type,
    role,
    index + 1,
    amcuserAsInvestor
  );
  const key = fieldName ? fieldName : `applicants.${index}.address.${addressType}`;
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${key}.address1`}
          label={`Address Line 1 ${isFatca ? (address1Mandatory ? '*' : '') : '*'}`}
          placeholder="Enter Address Line 1"
          disabled={
            isFatca
              ? FatcaDisabled
              : isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${key}.address2`}
          label={`Address Line 2 ${
            isFatca
              ? address2Mandatory
                ? '*'
                : ''
              : !(fetchedFromKRACheck || fetchedFromDigilocker) ||
                ((fetchedFromKRACheck || fetchedFromDigilocker) &&
                  (Edit_KYC
                    ? !['permanent', 'correspondence'].includes(addressType)
                    : addressType === 'correspondence'
                    ? !isCustodianICICI(selectedCustodian)
                    : addressType !== 'permanent'))
              ? '*'
              : ''
          }`}
          placeholder="Enter Address Line 2"
          disabled={
            isFatca
              ? FatcaDisabled
              : isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${key}.address3`}
          label={`Address Line 3 ${isFatca ? (address3Mandatory ? '*' : '') : ''}`}
          placeholder="Enter Address Line 3"
          disabled={
            isFatca
              ? FatcaDisabled
              : isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${key}.pincode`}
          label={`Pincode ${isFatca ? (pincodeMandatory ? '*' : '') : '*'}`}
          placeholder="Enter Pincode"
          disabled={
            isFatca
              ? FatcaDisabled
              : isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${key}.city`}
          label={`City ${isFatca ? (cityMandatory ? '*' : '') : '*'}`}
          placeholder="Enter City"
          disabled={
            isFatca
              ? FatcaDisabled
              : isFieldDisabled ||
                checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
          }
        />
      </Grid>
      {(isCustodianICICIOrHDFC(selectedCustodian) ||
        isCustodianOrbis(selectedCustodian) ||
        isCustodianKotak(selectedCustodian) ||
        isCustodianAxis(selectedCustodian)) && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`${key}.district`}
            label={`District ${
              isFatca ? (districtMandatory ? '*' : '') : addressType === 'overseas' ? '' : '*'
            }`}
            placeholder="Enter District"
            disabled={
              isFatca
                ? FatcaDisabled
                : (Edit_KYC
                    ? (fetchedFromDigilocker || referenceDetail.referenceId) &&
                      !!(address?.district || '')
                    : false) ||
                  checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {!FatcaDisabled &&
        isCustodianICICIOrHDFC(selectedCustodian) &&
        addressType !== 'overseas' &&
        address?.country?.toUpperCase() === 'INDIA' &&
        !dropDownHidingCheck ? (
          <SearchableSelect
            name={`${key}.state`}
            label={`State ${isFatca ? (stateMandatory ? '*' : '') : '*'}`}
            items={statesMdmsResponse.map((state) => ({
              key: state.name,
              value: state.name,
            }))}
            disabled={isFatca ? FatcaDisabled : dropDownHidingCheck}
            searchFieldPlaceholder={'Search State'}
          />
        ) : (
          <MFTextField
            name={`${key}.state`}
            label={
              addressType === 'overseas'
                ? `State`
                : `State ${isFatca ? (stateMandatory ? '*' : '') : '*'}`
            }
            placeholder="Enter State"
            disabled={
              isFatca
                ? FatcaDisabled
                : isFieldDisabled ||
                  checkEditiable(referenceDetail.applicant_type, role, index + 1, amcuserAsInvestor)
            }
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* {!nationalitiesMdmsResponse && (
          <MFTextField
            name={`applicants.${index}.address.${addressType}.country`}
            label={`Country *`}
            placeholder="Enter Country"
            disabled={isFieldDisabled}
          />
        )} */}
        {/* {nationalitiesMdmsResponse && ( */}
        {FatcaDisabled || dropDownHidingCheck ? (
          <MFTextField
            name={`${key}.country`}
            label={`Country ${isFatca ? (countryMandatory ? '*' : '') : '*'}`}
            placeholder="Enter Country"
            disabled={isFatca ? FatcaDisabled : true}
          />
        ) : (
          <SearchableSelect
            name={`${key}.country`}
            label={`Country ${isFatca ? (countryMandatory ? '*' : '') : '*'}`}
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={isFatca ? FatcaDisabled : dropDownHidingCheck}
            searchFieldPlaceholder={'Search Country'}
          />
        )}
        {/* )} */}
      </Grid>
    </>
  );
};

const ApplicantDetails = ({
  values,
  handleChange,
  setValues,
  mdmsCountriesList,
  mdmsStatesList,
  application,
}: {
  values: Values;
  handleChange: FormikHandlers['handleChange'];
  setValues: FormikHelpers<Values>['setValues'];
  mdmsCountriesList: mdmsCountriesList[];
  mdmsStatesList: mdmsStatesList[];
  application: ApplicationProps | null;
}): JSX.Element => {
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('applicants.0.mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);

  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );

  return (
    <React.Fragment>
      {values.applicants.map((applicant, index) => (
        <React.Fragment key={index}>
          <SubHeading>Contact details of {getApplicantName(index + 1)} Applicant</SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.email`}
              label="E-Mail ID *"
              placeholder="Enter E-Mail ID"
              // readOnly={values.hasPOA ? false : true && ENABLE_EMAIL_OTP}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
                </InputAdornment>
              }
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
            {/* <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlinedIcon color="info" fontSize="small" />
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '12px',
                  fontWeight: 450,
                  ml: 1,
                }}>
                {`This email address cannot be modified`}
              </Typography>
            </Typography> */}
          </Grid>
          {(isCustodianHDFC(application?.custodian || '') ||
            isCustodianAxis(application?.custodian || '')) && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.emailDeclaration`}
                label="This Email Id Belongs To? *"
                items={Object.keys(ContactRelationsForHDFC).map((relatedTo) => ({
                  key: ContactRelationsForHDFC[relatedTo],
                  value: relatedTo,
                }))}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.mobile`}
              label="Mobile *"
              placeholder="Enter Mobile Number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              // readOnly={values.hasPOA ? false : true}
              startAdornment={
                <CountryCodesDropDown
                  ElementWidth={mobileElementWidth && mobileElementWidth}
                  name={`applicants.${index}.countryNameAndCode`}
                  value={values.applicants[index].countryNameAndCode}
                  // readOnly={values.hasPOA ? false : true}
                  // disabled={[USER_ROLES.INVESTOR].includes(role)}
                  disabled={checkEditiable(
                    referenceDetail.applicant_type,
                    role,
                    index + 1,
                    amcuserAsInvestor
                  )}
                />
              }
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
              // disabled={[USER_ROLES.INVESTOR].includes(role)}
            />
            {/* <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlinedIcon color="info" fontSize="small" />
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '12px',
                  fontWeight: 450,
                  ml: 1,
                }}>
                {`This mobile number cannot be modified`}
              </Typography>
            </Typography> */}
          </Grid>
          {(isCustodianNuvuma(application?.custodian || '') ||
            isCustodianICICI(application?.custodian || '') ||
            isCustodianKotak(application?.custodian || '')) && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.mobileEmailDeclaration`}
                label={
                  isCustodianICICI(application?.custodian || '') ||
                  isCustodianKotak(application?.custodian || '')
                    ? 'KRA Email Id & Mobile Number Belongs To? *'
                    : 'This Email Id & Mobile Number Belongs To? *'
                }
                items={Object.entries(
                  isCustodianICICI(application?.custodian || '') ||
                    isCustodianKotak(application?.custodian || '')
                    ? ICICIContactRelations
                    : ContactRelations
                ).map(([value, key]) => ({ key, value }))}
              />
            </Grid>
          )}
          {(isCustodianHDFC(application?.custodian || '') ||
            isCustodianAxis(application?.custodian || '')) && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.mobileDeclaration`}
                label="This Mobile Number Belongs To? *"
                items={Object.keys(ContactRelationsForHDFC).map((relatedTo) => ({
                  key: ContactRelationsForHDFC[relatedTo],
                  value: relatedTo,
                }))}
              />
            </Grid>
          )}
          {applicant.kraMobileNumber && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraMobileNumber`}
                label={`KRA Mobile Number`}
                placeholder="Enter KRA Mobile Number"
                disabled={true}
              />
            </Grid>
          )}
          {applicant.kraEmail && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraEmail`}
                label={`KRA E-Mail ID`}
                placeholder="Enter KRA E-Mail ID"
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberoffice`}
              label="Phone (Office)"
              placeholder="Enter Phone (Office)"
              onChange={handleChange}
              type="number"
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberResidence`}
              label="Phone (Residential)"
              placeholder="Enter Phone (Residential)"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
              disabled={checkEditiable(
                referenceDetail.applicant_type,
                role,
                index + 1,
                amcuserAsInvestor
              )}
            />
          </Grid>
          {/* {applicant.address.nationality?.toLowerCase() !== 'indian' && (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
              />
            </React.Fragment>
          )} */}
          {checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          ) ? (
            <>
              <SubHeading>
                Correspondence Address of {getApplicantName(index + 1)} Applicant
              </SubHeading>
              <Address
                addressType={'correspondence'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                key={values.applicants.length}
                fetchedFromKRACheck={applicant.address.correspondence?.fetchedFromKRA || null}
                address={values.applicants[index].address.correspondence}
                statesMdmsResponse={mdmsStatesList}
                fetchedFromDigilocker={
                  applicant.address.correspondence?.fetchedFromDigiLocker || false
                }
              />
            </>
          ) : (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                statesMdmsResponse={mdmsStatesList}
                address={values.applicants[index].address.overseas}
                fetchedFromKRACheck={applicant.address.overseas?.fetchedFromKRA || null}
                fetchedFromDigilocker={applicant.address.overseas?.fetchedFromDigiLocker || false}
              />
            </React.Fragment>
          )}
          {applicant.address.nationality &&
            !(
              applicant.address.permanent?.fetchedFromKRA ||
              applicant.address.permanent?.fetchedFromDigiLocker
            ) &&
            !(
              applicant.address.correspondence?.fetchedFromKRA ||
              applicant.address.correspondence?.fetchedFromDigiLocker
            ) &&
            !(
              applicant.address.overseas?.fetchedFromKRA ||
              applicant.address.overseas?.fetchedFromDigiLocker
            ) && (
              <MFCheckbox
                onChange={({ target: { checked } }) =>
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          address: checkForCorrespondenceAddress(
                            _applicant.address.nationality,
                            _applicant.address.status || ''
                          )
                            ? {
                                ..._applicant.address,
                                correspondence: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.correspondence!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              }
                            : {
                                ..._applicant.address,
                                overseas: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.overseas!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              },
                        };
                      }
                      return _applicant;
                    }),
                  })
                }
                name={`applicants.${index}.address.${
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'correspondence'
                    : 'overseas'
                }.permanentAddressSameAsCorresponding`}
                label={
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'Permanent address same as correspondence address.'
                    : 'Permanent address same as overseas address.'
                }
                sx={{ ml: '40px' }}
                disabled={
                  [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || amcuserAsInvestor
                }
              />
            )}
          {(checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          )
            ? !values.applicants[index]?.address?.correspondence
                ?.permanentAddressSameAsCorresponding
            : !values.applicants[index]?.address?.overseas
                ?.permanentAddressSameAsCorresponding) && (
            <>
              <SubHeading>Permanent Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'permanent'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                fetchedFromKRACheck={applicant.address.permanent?.fetchedFromKRA || null}
                address={values.applicants[index].address.permanent}
                statesMdmsResponse={mdmsStatesList}
                fetchedFromDigilocker={applicant.address.permanent?.fetchedFromDigiLocker || false}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default function ContactDetails(): JSX.Element {
  const initialValues: Values = {
    hasPOA: false,
    applicants: [
      {
        phoneNumberoffice: '',
        phoneNumberResidence: '',
        email: '',
        mobile: '',
        countryNameAndCode: 'India: +91',
        countryCode: '+91',
        address: {
          nationality: null,
          status: null,
          permanent: getAddressFields('permanent'),
          correspondence: getAddressFields('correspondence'),
          overseas: getAddressFields('overseas'),
        },
        kraMobileNumber: '',
        kraEmail: '',
        mobileEmailDeclaration: '',
        mobileDeclaration: '',
        emailDeclaration: '',
      },
    ],
    saveType: 'save and proceed',
    countryDropdown: [],
    statesDropdown: [],
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '', id: authId = '' } = useSelector((store: RootStateType) => store.auth);
  const [contactDetails, setContactDetails] = useState(initialValues);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const [mdmsStatesList, setMdmsStatesList] = useState<mdmsStatesList[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const selectedCustodian = application?.custodian || '';
  const [errorOpen, setErrorOpen] = useState({ saveLaterCheck: false, message: '', open: false });
  const handleErrorPopupClose = () => {
    setErrorOpen({ saveLaterCheck: false, message: '', open: false });
  };

  const updatePayloadAndCheckApplication = (values: Values, verifyCurrentStep: boolean) => {
    try {
      const { applicants = [], currentStep, custodian = '' } = application || {};
      const updatePayload = applicants
        .map((applicant, index: number) => {
          const { address: updatedAddresses = {}, ...rest } = values.applicants[index] || {};
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { nationality, status, ...addressProps } = updatedAddresses;
          return {
            ...applicant,
            ...rest,
            email: removeSingleQuote(rest.email),
            countryCode: rest.countryNameAndCode?.split(':')[1].trim(),
            addresses:
              !checkForCorrespondenceAddress(applicant.nationality, status) &&
              applicant.addresses?.map((address) => address.address_type).includes('correspondence')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('correspondence', applicant.addresses),
                      isActive: false,
                      address_type: 'correspondence',
                    },
                  ]
                : checkForCorrespondenceAddress(applicant.nationality, status) &&
                  applicant.addresses?.map((address) => address.address_type).includes('overseas')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('overseas', applicant.addresses),
                      isActive: false,
                      address_type: 'overseas',
                    },
                  ]
                : _updatedAddresses(updatedAddresses, applicant),
          };
        })
        .map((_applicant) => {
          const updateAddress = _applicant.addresses.map((address) => {
            return {
              ...address,
              address1: address.address1,
              address2: address.address2,
              address3: address.address3,
              city: address.city,
              state: address.state,
            };
          });
          const ApplicantAddress = _applicant.addresses.map((address) => {
            return addressInitialFields(address.address_type, address);
          });
          const communicationAddress = checkForCorrespondenceAddress(
            _applicant.nationality,
            _applicant.status
          )
            ? ApplicantAddress?.find((val) => val.address_type === 'correspondence')
            : ApplicantAddress?.find((val) => val.address_type === 'overseas');
          const permenentAddress = ApplicantAddress?.find(
            (val) => val.address_type === 'permanent'
          );
          return {
            ..._applicant,
            addresses: updateAddress,
            fatcaAddress:
              isCustodianAxis(custodian) &&
              _applicant?.fatcaAddress &&
              Object.keys(_applicant?.fatcaAddress)?.length &&
              addressForTaxsMasters[_applicant?.fatcaAddress?.address_tax_purpose || ''] !==
                addressForTaxsMasters.others
                ? addressForTaxsMasters[_applicant?.fatcaAddress?.address_tax_purpose || ''] ===
                  addressForTaxsMasters.permanent
                  ? {
                      ..._applicant?.fatcaAddress,
                      ...permenentAddress,
                    }
                  : addressForTaxsMasters[_applicant?.fatcaAddress?.address_tax_purpose || ''] ===
                    addressForTaxsMasters.communication
                  ? {
                      ..._applicant?.fatcaAddress,
                      ...communicationAddress,
                    }
                  : _applicant?.fatcaAddress
                : _applicant?.fatcaAddress,
          };
        });
      const isSaveLater = values.saveType !== 'save and proceed';
      let currentStepIsIncluded = {};
      if (verifyCurrentStep) {
        currentStepIsIncluded = {
          currentStep: getStep(
            !!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
            isSaveLater
          ),
        };
      }
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                addresses: applicant.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...application!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          applicants: updatePayload
            .map((applicant) => {
              return {
                ...applicant,
                addresses: applicant?.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          ...currentStepIsIncluded,
        }
      );
      return { updatePayload, checkApplication };
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const navigation = (isSaveLater: boolean): void => {
    const { id } = application || {};
    //save for later route for investor at liveliness
    const investorEditSaveelaterRoute =
      referenceIdForInvestorPhotoCapture &&
      saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
    const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
      ? referenceIdForInvestorPhotoCapture
      : referenceDetail.esignReferenceId;
    !isSaveLater
      ? history.push('additional-KYC-details', {
          id,
          applicant1ReferenceId: referenceIdForSaveProceedRoute,
        })
      : referenceIdForInvestorPhotoCapture
      ? history.push(investorEditSaveelaterRoute.routePath, investorEditSaveelaterRoute.routeState)
      : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
  };

  const onSubmit = async (values: Values) => {
    try {
      const { id, status, hasPOA, applicationNumber, applicants = [] } = application || {};
      const { saveType = '' } = values;
      const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, true);
      const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
      const updatePayload = updatePayloadAndCheckApplicationValue?.updatePayload;
      const isSaveLater = saveType !== 'save and proceed';

      const getFatcaAddressUpdateApplicants: any[] = [];
      applicants.map((_checkApplicants, index: number) => {
        if (updatePayload) {
          const checkFatcaAddressUpdated = applicationComparison(
            _checkApplicants?.fatcaAddress,
            updatePayload[index]?.fatcaAddress
          );
          if (!checkFatcaAddressUpdated) {
            getFatcaAddressUpdateApplicants.push(`${getApplicantName(index + 1)}`);
          }
        }
      });
      const AddressUpdateMsg = {
        msg:
          getFatcaAddressUpdateApplicants?.length > 0
            ? `FATCA address has been updated due to change in ${getFatcaAddressUpdateApplicants?.toString()} applicant's address`
            : '',
        isUpdated: getFatcaAddressUpdateApplicants?.length > 0,
      };
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              applicants: updatePayload!,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              currentStep: getStep(3, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        setLoading(false);
        AddressUpdateMsg.isUpdated
          ? setErrorOpen({
              saveLaterCheck: isSaveLater,
              message: AddressUpdateMsg.msg,
              open: AddressUpdateMsg.isUpdated,
            })
          : navigation(isSaveLater);
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }

        AddressUpdateMsg.isUpdated
          ? setErrorOpen({
              saveLaterCheck: isSaveLater,
              message: AddressUpdateMsg.msg,
              open: AddressUpdateMsg.isUpdated,
            })
          : navigation(isSaveLater);
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const { bankAccountFormCreated = false, showBankIcon = false } = application || {};
    const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, false);
    const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
    if (!checkApplication && checkNdpmsFlow && showBankIcon && bankAccountFormCreated) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      onSubmit(values);
    }
  };

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  // }, []);
  const amcuserAsInvestor = refLoginCheck(
    authId,
    application as unknown as ApplicationProps,
    referenceDetail
  );
  useEffect(() => {
    const { applicants: exisitingApplicants = [], hasPOA = false } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
        setMdmsStatesList(statesMdmsMasters.states);
        setContactDetails({
          ...contactDetails,
          hasPOA,
          applicants:
            exisitingApplicants?.length === 0
              ? exisitingApplicants.map((applicant) => {
                  const {
                    nationality = '',
                    status = '',
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    countryNameAndCode = 'India: +91',
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmail = '',
                    mobileEmailDeclaration = '',
                    mobileDeclaration = '',
                    emailDeclaration = '',
                  } = applicant;
                  const defaultPayload = {
                    nationality,
                    status,
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: null,
                      status: null,
                      permanent: getAddressFields('permanent'),
                    },
                    kraMobileNumber,
                    kraEmail,
                    mobileEmailDeclaration,
                    mobileDeclaration,
                    emailDeclaration,
                  };
                  if (
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicant.status === APPLICANT_STATUS.INDIVIDUAL ||
                      applicantStatusMasters[applicant.status || ''] ===
                        applicantStatusMasters.Individual)
                  ) {
                    return {
                      ...defaultPayload,
                      address: {
                        ...defaultPayload.address,
                        correspondence: getAddressFields('correspondence'),
                      },
                    };
                  }
                  return {
                    ...defaultPayload,
                    address: {
                      ...defaultPayload.address,
                      overseasAddress: getAddressFields('overseas'),
                    },
                  };
                })
              : exisitingApplicants?.map((applicant, index) => {
                  const {
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    countryNameAndCode = 'India: +91',
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmail = '',
                    mobileEmailDeclaration = '',
                    mobileDeclaration = '',
                    emailDeclaration = '',
                  } = applicant;
                  const correspondence = getAddressData('correspondence', applicant.addresses);
                  const permanent = getAddressData('permanent', applicant.addresses);
                  const overseas = getAddressData('overseas', applicant.addresses);
                  const correspondenceFieldDisabled = getFieldsDisabled(
                    'correspondence',
                    correspondence?.fetchedFromKRA || false,
                    role,
                    selectedCustodian,
                    correspondence?.fetchedFromDigiLocker || false
                  );
                  const permanentFieldDisabled = getFieldsDisabled(
                    'permanent',
                    permanent?.fetchedFromKRA || false,
                    role,
                    selectedCustodian,
                    permanent?.fetchedFromDigiLocker || false
                  );
                  const overseasFieldDisabled = getFieldsDisabled(
                    'overseas',
                    overseas?.fetchedFromKRA || false,
                    role,
                    selectedCustodian,
                    overseas?.fetchedFromDigiLocker || false
                  );
                  const dropDownDisableForCorrespondenceAddress =
                    hidingDropDownFieldBasedOnDisabled(
                      correspondenceFieldDisabled,
                      referenceDetail.applicant_type,
                      role,
                      index + 1,
                      amcuserAsInvestor
                    );
                  const dropDownDisableForPermanentAddress = hidingDropDownFieldBasedOnDisabled(
                    permanentFieldDisabled,
                    referenceDetail.applicant_type,
                    role,
                    index + 1,
                    amcuserAsInvestor
                  );
                  const dropDownDisableForOverseasAddress = hidingDropDownFieldBasedOnDisabled(
                    overseasFieldDisabled,
                    referenceDetail.applicant_type,
                    role,
                    index + 1,
                    amcuserAsInvestor
                  );
                  const defaultPayload = {
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: applicant.nationality,
                      status: applicant.status,
                    },
                    kraMobileNumber,
                    kraEmail,
                    mobileEmailDeclaration,
                    mobileDeclaration,
                    emailDeclaration,
                  };
                  const permanentAddressPayload =
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual ||
                      applicant.status === APPLICANT_STATUS.INDIVIDUAL)
                      ? correspondence.permanentAddressSameAsCorresponding
                        ? defaultPayload
                        : {
                            ...defaultPayload,
                            address: {
                              ...defaultPayload.address,
                              permanent: {
                                ...permanent,
                                state: permanent.state
                                  ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                    permanent.country?.toUpperCase() === 'INDIA' &&
                                    !dropDownDisableForPermanentAddress
                                    ? permanent.state?.toUpperCase()
                                    : permanent.state
                                  : '',
                                country: permanent.country
                                  ? dropDownDisableForPermanentAddress
                                    ? permanent.country
                                    : permanent.country?.toUpperCase()
                                  : 'INDIA',
                              },
                            },
                          }
                      : overseas.permanentAddressSameAsCorresponding
                      ? defaultPayload
                      : {
                          ...defaultPayload,
                          address: {
                            ...defaultPayload.address,
                            permanent: {
                              ...permanent,
                              state: permanent.state
                                ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                  permanent.country?.toUpperCase() === 'INDIA' &&
                                  !dropDownDisableForPermanentAddress
                                  ? permanent.state?.toUpperCase()
                                  : permanent.state
                                : '',
                              country: permanent.country
                                ? dropDownDisableForPermanentAddress
                                  ? permanent.country
                                  : permanent.country?.toUpperCase()
                                : 'INDIA',
                            },
                          },
                        };
                  return ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual ||
                      applicant.status === APPLICANT_STATUS.INDIVIDUAL)
                    ? {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          correspondence: {
                            ...correspondence,
                            state: correspondence.state
                              ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                correspondence.country?.toUpperCase() === 'INDIA' &&
                                !dropDownDisableForCorrespondenceAddress
                                ? correspondence.state?.toUpperCase()
                                : correspondence.state
                              : '',
                            country: correspondence.country
                              ? dropDownDisableForCorrespondenceAddress
                                ? correspondence.country
                                : correspondence.country?.toUpperCase()
                              : 'INDIA',
                          },
                        },
                      }
                    : {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          overseas: {
                            ...overseas,
                            country: overseas.country
                              ? dropDownDisableForOverseasAddress
                                ? overseas.country
                                : overseas.country?.toUpperCase()
                              : '',
                          },
                        },
                      };
                }),
          statesDropdown: statesMdmsMasters.states.map((list) => list.name),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  return (
    <Formik
      initialValues={contactDetails}
      onSubmit={manageSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            contactDetailsSchema(selectedCustodian, shouldValidateUponSaveLater(values.saveType)),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, handleChange, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <ApplicantDetails
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            mdmsCountriesList={mdmsCountriesList}
            mdmsStatesList={mdmsStatesList}
            application={application}
          />
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
          />
          {!!application?.applicants.length && (
            <>
              <FieldValidationNote />
              <ProceedSaveLater
                saveLater={() => setFieldValue('saveType', 'save for later')}
                saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
                loader={loading}
                clickedButton={values.saveType}
              />
            </>
          )}
          <NavigationThrowErrorPopup
            handleClose={handleErrorPopupClose}
            errorOpen={errorOpen}
            onSave={() => {
              navigation(errorOpen.saveLaterCheck);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
