import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { mitcAccessType, PortfolioManagerDetailsType } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Location } from 'history';
import { portfolioManagerSchema } from '../../utils/schema';
import {
  addPorfolioManager,
  editPorfolioManager,
  getPorfolioManagerById,
} from '../../redux-store/actions/porfolioManager';

import { nonDigitRegex } from '../../utils/regex';

const initialValues: PortfolioManagerDetailsType = {
  portfolioManagerName: '',
  portfolioManagerMobileNumber: '',
  sebiRegistrationNumber: '',
  investorRelationOfficerName: '',
  investorRelationOfficerMobileNumber: '',
  tenureOfPMS: '',
  pmFee: '',
  otherTnC: '',
};

export default function AddPortFolioManager({
  location,
}: {
  location: Location<{ portFolioManagerId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { portFolioManagerId } = location.state || { portFolioManagerId: '' };
  const [portFolioManagerDetails, setPortFolioManagerDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (portFolioManagerId) {
          const getPortFolioManagerDetails = (await dispatch(
            getPorfolioManagerById(portFolioManagerId)
          )) as unknown as PortfolioManagerDetailsType;
          setPortFolioManagerDetails(getPortFolioManagerDetails);
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: PortfolioManagerDetailsType) => {
    try {
      if (!portFolioManagerId) {
        await dispatch(addPorfolioManager({ ...values }));
      } else {
        await dispatch(editPorfolioManager(portFolioManagerId, { ...values }));
      }
      history.push(`details`);
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={portFolioManagerDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={portfolioManagerSchema}>
        {({ handleSubmit }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              {portFolioManagerId && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    sx={{ height: 'fit-content', p: 0 }}
                    onClick={() => history.push(`details`)}>
                    <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                  </IconButton>

                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: '#1C2D47',
                    }}>
                    Back
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  MITC
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {portFolioManagerId && (
                <Hidden only="xs">
                  <IconButton
                    sx={{ height: 'fit-content', p: 0 }}
                    onClick={() => history.push(`details`)}>
                    <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                  </IconButton>
                </Hidden>
              )}
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 2, sm: 0 },
                }}>
                {portFolioManagerId ? 'MITC Details' : 'Onboarding MITC'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="portfolioManagerName"
                      label="Portfolio Manager Name *"
                      placeholder="Enter Portfolio Manager Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="portfolioManagerMobileNumber"
                      label="Portfolio Manager Contact Details *"
                      placeholder="Enter Portfolio Manager Contact Details"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="sebiRegistrationNumber"
                      label="SEBI Registration Number *"
                      placeholder="Enter SEBI Registration Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="investorRelationOfficerName"
                      label="Investor Relations Officer Name *"
                      placeholder="Enter Investor Relations Officer Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="investorRelationOfficerMobileNumber"
                      label="Investor Relations Officer Contact Details *"
                      placeholder="Enter Investor Relations Officer Contact Details"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="tenureOfPMS"
                      label="Tenure of PMS *"
                      placeholder="Enter Tenure of PMS"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="pmFee"
                      label="Fees & Charges including exit load *"
                      placeholder="Enter Fees & Charges including exit load"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="otherTnC"
                      label="Other important terms & conditions"
                      placeholder="Enter Other important terms & conditions"
                    />
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
