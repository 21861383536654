import { AnyAction } from 'redux';
import { dougnutType } from '../../components/dashboard/doughnutGraph';
import { dashboardOnboardingType } from '../types/api-types';
import {
  GET_Kyc_DASHBOARD_DETAILS_SUCCESS,
  GET_ONBOARDING_SUMMARY_SUCCESS,
} from '../types/application';
import { LOGOUT_SUCCESS } from '../types/auth';

function dashboardDoughnutReducer(
  state = dougnutType,
  action: AnyAction
): Partial<dashboardOnboardingType> {
  switch (action.type) {
    case GET_ONBOARDING_SUMMARY_SUCCESS: {
      return action.body;
    }
    case LOGOUT_SUCCESS: {
      return dougnutType;
    }

    default:
      return state;
  }
}

export default dashboardDoughnutReducer;
