import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import { CustodianMaster } from '../../utils/constant';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <SubHeading
                sx={{
                  color: 'common.black',
                  letterSpacing: 0.5 + 'px',
                  padding: { xs: '10px 10px', sm: '10px 30px' },
                }}>
                RM Details
              </SubHeading>
              <Gridstyles>
                <Grid
                  item
                  container
                  pt={1}
                  pb={5}
                  rowSpacing={6}
                  columnSpacing={13}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <KeyValue title={'RM Name'} description={application?.rm?.name} />
                  <KeyValue
                    title={"RM's Email Address"}
                    description={application?.rm?.user?.email}
                  />
                  <KeyValue
                    title={"RM's Mobile Number"}
                    description={application?.rm?.user?.phone}
                  />
                  <KeyValue
                    title={'Custodian Name'}
                    description={CustodianMaster[application?.custodian || '']}
                  />
                </Grid>
              </Gridstyles>
            </Box>
            {application?.distributor && (
              <Box sx={{ width: '100%' }}>
                <SubHeading
                  sx={{
                    color: 'common.black',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                  }}>
                  Distributor Details
                </SubHeading>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pt={1}
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Distributor Code'}
                      description={application?.distributor?.arnCode}
                    />
                    <KeyValue
                      title={'Distributor/IFA Name'}
                      description={application?.distributor?.name}
                    />
                    <KeyValue
                      title={"Distributor's RM Code"}
                      description={application?.subDistributor?.distributors_RM_Code}
                    />
                    <KeyValue
                      title={"Distributor's RM Name"}
                      description={application?.subDistributor?.user?.firstName}
                    />
                    <KeyValue
                      title={'Distributor RM/IFA Email'}
                      description={
                        application?.subDistributor?.user?.email ||
                        application?.distributor?.user?.email
                      }
                    />
                    <KeyValue title={'AMCs RM/Advisor Name - Location'} description={null} />
                    <KeyValue title={'Filled By'} description={application?.filledBy} />
                    <KeyValue
                      title={'Custodian Name'}
                      description={CustodianMaster[application?.custodian || '']}
                    />
                  </Grid>
                </Gridstyles>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
