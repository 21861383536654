import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Hidden,
  Stack,
  Switch,
} from '@mui/material';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { getDistributorById, updateDistributorRM } from '../../redux-store/actions/userManagement';
import {
  AdddistributorRM,
  DistributorListType,
  DistributorNewList,
  SubDistributorListType,
} from '../../redux-store/types/api-types';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';

function DistributorRm({
  location,
}: {
  location: Location<{ distributorId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { distributorId } = location.state || {};
  const [distributorData, setDistributorData] = useState<DistributorNewList>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    distributorRmRowData: AdddistributorRM;
  } | null>(null);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        if (distributorId) {
          const res = (await dispatch(getDistributorById(distributorId))) as unknown as any;
          setDistributorData(res);
        } else {
          history.replace('/user-management');
        }
        if (!isComponentActive) return;
      } catch (e) {
        console.log((e as Error).message);
      } finally {
        if (isComponentActive) {
          setIsLoading(false);
        }
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [distributorId]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const headers = [
    {
      header: `Distributor's RM name`,
      renderCell: (row: SubDistributorListType) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{ pathname: 'distributors-rm-details', state: { subDistributorId: row.id } }}>
            {row.user?.firstName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: `Distributor's RM code`,
      valueGetter: (row: SubDistributorListType) => row.distributors_RM_Code || 'N/A',
    },
    {
      header: 'Email Id',
      valueGetter: (row: SubDistributorListType) => row.user?.email || 'N/A',
    },
    {
      header: 'Mobile Number',
      valueGetter: (row: SubDistributorListType) => row.user?.phone || 'N/A',
    },
    {
      header: `AMC's RM`,
      valueGetter: (row: any) => row.rm?.name || 'N/A',
    },
    {
      header: AccessHeader,
      renderCell: (row: AdddistributorRM): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ distributorRmRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: SubDistributorListType): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{
    //                 pathname: 'edit-distributors-rm',
    //                 state: { subDistributorId: row.id },
    //               }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-distributors-rm', state: { subDistributorId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Grid container>
        <Grid item sx={{ bgcolor: 'common.white', px: 4, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pt: { xs: 2, sm: 7 },
              pb: { xs: 2, sm: 4 },
            }}>
            <Typography
              sx={{
                fontSize: { xs: 13, sm: 25 },
                fontWeight: 500,
                // whiteSpace: 'nowrap',
              }}>
              {`List of Distributor's RM(s)`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 14,
                    mr: 2.5,
                    py: 0.5,
                    px: 3,
                  }}
                  onClick={() => history.push('add-distributors-rm', { distributorId })}>
                  {`Add Distributor's RM`}
                </Button>
              </Hidden>
              <Hidden smUp={true}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 13,
                    // mr: { sm: 1, lg: 2.5 },
                    py: 0.5,
                    // px: { sm: 0.5, lg: 1 },
                    // whiteSpace: 'nowrap',
                  }}
                  onClick={() => history.push('add-distributors-rm', { distributorId })}>
                  {`Add Distributor's RM`}
                </Button>
              </Hidden>
            </Box>
          </Box>
          <Grid item xs={12} sm={12} lg={12}>
            {useMemo(
              () => (
                <DataTable
                  tableData={distributorData?.subdistributors || []}
                  tableHeader={headers}
                  isLoading={isLoading}
                  currentPage={currentPage}
                  noOfPages={1}
                  paginationAction={handlePageChange}
                  renderAdditionalRow={true}
                  tableBodyCustomStyles={{
                    '.MuiTableCell-root': {
                      borderBottom: 'none',
                    },
                  }}
                  tableHeaderCustomStyles={{
                    '&:last-child th': { border: 0 },
                    '.MuiTableCell-head': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'primary.main',
                    },
                  }}
                />
              ),
              [isLoading, distributorData]
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.distributorRmRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                updateDistributorRM(openDialog.distributorRmRowData.id as number, {
                  ...openDialog.distributorRmRowData,
                  isActive: !openDialog?.distributorRmRowData.isActive,
                })
              )) as unknown as SubDistributorListType;
              setDistributorData({
                ...distributorData,
                subdistributors: distributorData?.subdistributors?.map((subdistributor) => {
                  if (Number(subdistributor.id) === Number(updatedResponse.id)) {
                    return {
                      ...subdistributor,
                      ...updatedResponse,
                    };
                  }
                  return subdistributor;
                }) as SubDistributorListType[],
              } as DistributorNewList);
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default DistributorRm;
