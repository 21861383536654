import { LoadingButton } from '@mui/lab';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  LinearProgress,
  IconButton,
  CardMedia,
  Hidden,
  Badge,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Distributor,
  getDistributorsList,
  getDistributorsListForFilters,
  getRMsList,
} from '../../redux-store/actions';
import { fundAccess, getFundsList } from '../../redux-store/actions/funds';
import {
  distributorOnboardingCompletedDownload,
  distributorWiseCommitmentAmountDownload,
  distributorWiseOnBoardingSummaryDownload,
  getCompletedApplications,
  getDistributorWiseCommitmentAmount,
  getDistributorWiseOnBoardingSummary,
  getOnBoardingDetails,
  getOnBoardingSummary,
  onBoardingDetailsDownload,
  onBoardingDetailsDownloadAllFields,
  onBoardingSummaryDownload,
} from '../../redux-store/actions/reports';
import {
  DistributorCommitmentAmountReportTypeSummary,
  GenericReportTypeSummary,
  GetDistributorCommitmentAmount,
  GetOnboardingCompletedDetailsReportResponse,
  GetOnboardingDetailsReportResponse,
  GetOnBoardingSummaryReportResponse,
  OnBoardingCompletedReportDetailsType,
  OnBoardingReportDetailsType,
  ReportFilterType,
  RMType,
} from '../../redux-store/types/api-types';
import { accessType, FundProps, GetFundsListResponseBody } from '../../redux-store/types/funds';
import { CommonLayout } from '../commonComponents';
import { ColumnType, DataTable } from '../DataTable';
import { FilterDatePicker, FilterDropdown, initialFilters } from './filters';
import {
  applicationTypes,
  onboardingDetailsDownload,
  reportData,
  reportDownload,
} from './report-utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const tableMainBoxStyles = {
  maxHeight: 'calc(100vh - 240px)',
  minHeight: '150px',
};

const CommonComponentForSummaryReports = ({
  isLoading,
  tableHeaders,
  summaryReport,
}: {
  isLoading: boolean;
  tableHeaders: ColumnType[];
  summaryReport: GetOnBoardingSummaryReportResponse[];
}): JSX.Element => {
  const getApplicationStatusCount = (
    applications: GenericReportTypeSummary[],
    status: string
  ): string => {
    return applications.find((application) => application.status === status)?.count || '0';
  };

  return (
    <Box>
      {isLoading ? (
        <LinearProgress />
      ) : (
        summaryReport.map((report) => {
          const tableData = Object.keys(report)
            .filter((key) => key !== 'name')
            .map((key) => {
              const applicationType = applicationTypes
                .map((type) => {
                  if (type.key === key) {
                    return type.value;
                  }
                  return;
                })
                .filter((ele) => ele)
                .toString();
              const applications = report[key] as GenericReportTypeSummary[];
              if (applications.length) {
                return {
                  investorType: applicationType,
                  total_onboarded: getApplicationStatusCount(applications, 'total_onboarded'),
                  in_progress: getApplicationStatusCount(applications, 'in_progress'),
                  draft: getApplicationStatusCount(applications, 'draft'),
                  pending_with_investor: getApplicationStatusCount(
                    applications,
                    'pending_with_investor'
                  ),
                  pending_with_im: getApplicationStatusCount(
                    applications,
                    'pending_with_fundmanager'
                  ),
                  pending_with_trustee: getApplicationStatusCount(
                    applications,
                    'pending_with_trustee'
                  ),
                  invitationexpired: getApplicationStatusCount(applications, 'invitationexpired'),
                  rejected: getApplicationStatusCount(applications, 'rejected'),
                  completed: getApplicationStatusCount(applications, 'completed'),
                };
              }
              return;
            })
            .filter((ele) => ele);
          return (
            <>
              <Typography key={report.name} mt={2}>
                {report.name}
              </Typography>
              <Box sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
                <DataTable
                  isLoading={false}
                  tableHeader={tableHeaders}
                  tableData={tableData}
                  boxStyles={tableMainBoxStyles}
                />
              </Box>
            </>
          );
        })
      )}
    </Box>
  );
};

const OnBoardingSummaryReport = ({
  tableHeaders,
  filters,
}: {
  tableHeaders: ColumnType[];
  filters: ReportFilterType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryReport, setSummaryReport] = useState<GetOnBoardingSummaryReportResponse[]>([]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          getOnBoardingSummary(filters)
        )) as unknown as GetOnBoardingSummaryReportResponse[];
        if (!isComponentActive) return;
        setSummaryReport(response);
      } catch (e) {
        console.error(e);
      } finally {
        if (isComponentActive) setIsLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [filters]);

  return (
    <CommonComponentForSummaryReports
      isLoading={isLoading}
      tableHeaders={tableHeaders}
      summaryReport={summaryReport}
    />
  );
};

const OnBoardingDetailsReport = ({
  tableHeaders,
  filters,
}: {
  tableHeaders: ColumnType[];
  filters: ReportFilterType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<OnBoardingReportDetailsType[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(1);

  const apiCall = async (page: number) => {
    setIsLoading(true);
    const { data = [], pages = 0 } = (await dispatch(
      getOnBoardingDetails({ ...filters, page, limit: 10 })
    )) as unknown as GetOnboardingDetailsReportResponse;
    return { data, pages };
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        const { data, pages } = await apiCall(1);
        if (!isComponentActive) return;
        setTableData(data);
        setNoPages(pages);
      } catch (e) {
        console.error(e);
      } finally {
        if (isComponentActive) setIsLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [filters]);

  const handlePageChange = async (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const { data, pages } = await apiCall(value);
    setTableData(data);
    setNoPages(pages);
    setIsLoading(false);
  };

  return (
    <DataTable
      isLoading={isLoading}
      tableHeader={tableHeaders}
      tableData={tableData}
      boxStyles={tableMainBoxStyles}
      noOfPages={noOfPages}
      currentPage={currentPage}
      paginationAction={handlePageChange}
    />
  );
};

const DistributorOnBoardingSummary = ({
  tableHeaders,
  filters,
}: {
  tableHeaders: ColumnType[];
  filters: ReportFilterType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryReport, setSummaryReport] = useState<GetOnBoardingSummaryReportResponse[]>([]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          getDistributorWiseOnBoardingSummary(filters)
        )) as unknown as GetOnBoardingSummaryReportResponse[];
        if (!isComponentActive) return;
        setSummaryReport(response);
      } catch (e) {
        console.error(e);
      } finally {
        if (isComponentActive) setIsLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [filters]);

  return (
    <CommonComponentForSummaryReports
      isLoading={isLoading}
      tableHeaders={tableHeaders}
      summaryReport={summaryReport}
    />
  );
};

const DistributorCommitmentAmount = ({
  tableHeaders,
  filters,
}: {
  tableHeaders: ColumnType[];
  filters: ReportFilterType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryReport, setSummaryReport] = useState<GetDistributorCommitmentAmount[]>([]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          getDistributorWiseCommitmentAmount(filters)
        )) as unknown as GetDistributorCommitmentAmount[];
        if (!isComponentActive) return;
        setSummaryReport(response);
      } catch (e) {
        console.error(e);
      } finally {
        if (isComponentActive) setIsLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [filters]);

  return (
    <>
      {' '}
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}>
        <InfoOutlinedIcon color="info" fontSize="small" />
        <Typography
          sx={{
            fontSize: 15,
            ml: 1,
          }}>
          Data is shown only for completed applications .
        </Typography>
      </Typography>
      {isLoading ? (
        <LinearProgress />
      ) : (
        summaryReport.map((report) => {
          const tableData = Object.keys(report)
            .filter((key) => key !== 'name')
            .map((key) => {
              const applicationType = applicationTypes
                .map((type) => {
                  if (type.key === key) {
                    return type.value;
                  }
                  return;
                })
                .filter((ele) => ele)
                .toString();
              const applications = report[key] as DistributorCommitmentAmountReportTypeSummary;
              if (Object.keys(report[key]).length === 0) {
                return;
              }
              return {
                investorType: applicationType,
                completed: applications.completed,
                commitmentAmount: applications.commitmentAmount,
                contributionAmount: applications.contributionAmount,
              };
            })
            .filter((ele) => ele);
          return (
            <>
              <Typography key={report.name} mt={2}>
                {report.name}
              </Typography>
              <Box sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
                <DataTable
                  isLoading={false}
                  tableHeader={tableHeaders}
                  tableData={tableData}
                  boxStyles={tableMainBoxStyles}
                />
              </Box>
            </>
          );
        })
      )}
    </>
  );
};

const OnBoardingCompletedDetailsReport = ({
  tableHeaders,
  filters,
}: {
  tableHeaders: ColumnType[];
  filters: ReportFilterType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<OnBoardingCompletedReportDetailsType[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(1);

  const apiCall = async (page: number) => {
    setIsLoading(true);
    const { data = [], pages = 0 } = (await dispatch(
      getCompletedApplications({ ...filters, page })
    )) as unknown as GetOnboardingCompletedDetailsReportResponse;
    return { data, pages };
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        const { data, pages } = await apiCall(1);
        if (!isComponentActive) return;
        setTableData(data);
        setNoPages(pages);
      } catch (e) {
        console.error(e);
      } finally {
        if (isComponentActive) setIsLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [filters]);

  const handlePageChange = async (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const { data, pages } = await apiCall(value);
    setTableData(data);
    setNoPages(pages);
    setIsLoading(false);
  };

  return (
    <>
      {' '}
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}>
        <InfoOutlinedIcon color="info" fontSize="small" />
        <Typography
          sx={{
            fontSize: 15,
            ml: 1,
            mb: 2,
          }}>
          Data is shown only for completed applications .
        </Typography>
      </Typography>
      <DataTable
        isLoading={isLoading}
        tableHeader={tableHeaders}
        tableData={tableData}
        boxStyles={tableMainBoxStyles}
        noOfPages={noOfPages}
        currentPage={currentPage}
        paginationAction={handlePageChange}
      />
    </>
  );
};

export default function Reports(): JSX.Element {
  const dispatch = useDispatch();
  const [reportType, setReportType] = useState(reportData[0].value);
  const [filters, setFilters] = useState<ReportFilterType>(initialFilters);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const open = Boolean(filtersAnchorEl);
  const [funds, setFunds] = useState<FundProps[]>([]);
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const [toDateErrorText, setToDateErrorText] = useState('');
  const [fromDateErrorText, setFromDateErrorText] = useState('');
  const [rmsList, setRmList] = useState<RMType[]>([]);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        //super Admin Access same for creating funds and viewing completed applications report
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        // const { funds } = (await dispatch(getFundsList())) as unknown as GetFundsListResponseBody;
        // const response = (await dispatch(
        //   getDistributorsListForFilters()
        // )) as unknown as Distributor[];
        // const rms = (await dispatch(getRMsList())) as unknown as RMType[];
        if (!isComponentActive) return;
        // setFunds(funds);
        // setDistributors(response);
        // setRmList(rms);
      } catch (e) {
        console.error(e);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  // useEffect(() => {
  //   setFilters(initialFilters);
  // }, [reportType]);

  const reportsTable = useMemo(() => {
    const tableHeaders = reportData.find(({ value }) => value === reportType)?.tableHeaders || [];
    const updatedFilter = { ...filters, status: filters?.status?.toString() };
    switch (reportType) {
      case 'onboardingSummary':
        return <OnBoardingSummaryReport tableHeaders={tableHeaders} filters={updatedFilter} />;
      case 'onboardingDetails':
        return <OnBoardingDetailsReport tableHeaders={tableHeaders} filters={updatedFilter} />;
      case 'distributorOnBoardingSummary':
        return <DistributorOnBoardingSummary tableHeaders={tableHeaders} filters={updatedFilter} />;
      case 'distributorCommitmentAmount':
        return <DistributorCommitmentAmount tableHeaders={tableHeaders} filters={updatedFilter} />;
      case 'onboardingCompletedDetails':
        return (
          <OnBoardingCompletedDetailsReport tableHeaders={tableHeaders} filters={updatedFilter} />
        );
    }
  }, [reportType, filters]);

  const handleFiltersAnchorEl = () => {
    setFiltersAnchorEl(true);
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['distributorId', 'schemeId', 'planId', 'rmId', 'investorType'].filter(
      (filterKey) => filters[filterKey]
    ).length;
    const updatedBadgeCountWithStatusFilter =
      filters.status?.length > 0 ? badgeCount + 1 : badgeCount;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={updatedBadgeCountWithStatusFilter} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => setFilters(value)}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            funds={funds}
            distributors={distributors}
            rmsList={rmsList}
          />
        )}
      </>
    );
  };

  return (
    <>
      <CommonLayout>
        <>
          <Box>
            <Box>
              <Typography
                sx={{
                  display: 'block',
                  padding: '0 0 5px',
                  fontWeight: 'bold',
                  alignSelf: { xs: 'center' },
                }}>
                Report Type
              </Typography>
              <Grid container>
                <Grid item xs={10} sm={10} md={3}>
                  <Select
                    style={{
                      width: '100%',
                      height: '44px',
                      padding: '0 10px',
                      borderRadius: '6px',
                      fontSize: '15px',
                    }}
                    value={reportType}
                    onChange={({ target: { value } }) => {
                      // setFilters(initialFilters);
                      setReportType(value);
                    }}>
                    {reportData
                      .filter((reports) => {
                        if (!access && reports.value === 'onboardingCompletedDetails') {
                          return;
                        }
                        return reports;
                      })
                      .filter((ele) => ele)
                      .map((report) => (
                        <MenuItem value={report.value} key={report.key}>
                          {report.key}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Hidden mdUp={true}>
                  <Grid item xs={2}>
                    {filterOptions()}
                  </Grid>
                </Hidden>
                <Grid item xs={6} sm={6} md={4}>
                  <Box
                    sx={{
                      display: { sm: 'block', md: 'flex' },
                      color: 'blue',
                      alignItems: 'center',
                      padding: '0 10px',
                      paddingLeft: { xs: '0px', sm: '0px', md: '10px' },
                      marginTop: { xs: '10px', sm: '10px', md: '0px' },
                    }}>
                    <Typography style={{ fontSize: '13px', color: 'blue' }}>From Date</Typography>
                    <FilterDatePicker
                      placeholder={'From Date'}
                      onChange={(date) => {
                        const timeDiff =
                          new Date(date as Date).getTime() - new Date(filters.to).getTime();
                        if (timeDiff > 0) {
                          setFromDateErrorText('From Date should be less than To Date');
                          return;
                        }
                        setFilters({ ...filters, from: new Date(date as Date).toISOString() });
                        setFromDateErrorText('');
                      }}
                      value={new Date(filters.from)}
                      errorText={fromDateErrorText}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <Box
                    sx={{
                      display: { sm: 'block', md: 'flex' },
                      color: 'blue',
                      alignItems: 'center',
                      padding: '0 10px',
                      marginTop: { xs: '10px', sm: '10px', md: '0px' },
                    }}>
                    <Typography style={{ fontSize: '13px', color: 'blue' }}>To Date</Typography>
                    <FilterDatePicker
                      placeholder={'To Date'}
                      value={new Date(filters.to)}
                      onChange={(date) => {
                        const timeDiff =
                          new Date(filters.from).getTime() - new Date(date as Date).getTime();
                        if (timeDiff > 0) {
                          setToDateErrorText('To Date should be greater than From Date');
                          return;
                        }
                        setFilters({ ...filters, to: new Date(date as Date).toISOString() });
                        setToDateErrorText('');
                      }}
                      errorText={toDateErrorText}
                    />
                  </Box>
                </Grid>
                <Hidden only={['xs', 'sm']}>
                  <Grid item md={1}>
                    {filterOptions()}
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Tooltip title="Click here to download Report">
                {reportType !== 'onboardingDetails' ? (
                  <LoadingButton
                    loadingPosition="start"
                    type="submit"
                    loading={isSendLoading}
                    variant="contained"
                    sx={{
                      fontSize: 12,
                      minWidth: isSendLoading ? 180 : '',
                      letterSpacing: 1,
                      fontWeight: 400,
                      px: isSendLoading ? 0 : 3,
                      py: 1,
                      mt: 2,
                      mr: 4,
                    }}
                    onClick={async () => {
                      try {
                        setIsSendLoading(true);
                        const download = { ...filters, downloadFile: true };
                        switch (reportType) {
                          case 'onboardingSummary': {
                            const res = await dispatch(onBoardingSummaryDownload(download));
                            reportDownload(res as unknown as Blob, 'pms-onboarding-summary.xlsx');
                            return;
                          }
                          case 'distributorOnBoardingSummary': {
                            const res = await dispatch(
                              distributorWiseOnBoardingSummaryDownload(download)
                            );
                            reportDownload(res as unknown as Blob, 'pms-onboarding-summary.xlsx');
                            return;
                          }
                          case 'distributorCommitmentAmount': {
                            const res = await dispatch(
                              distributorWiseCommitmentAmountDownload(download)
                            );
                            reportDownload(
                              res as unknown as Blob,
                              'pms-distributor-commitment-amount.xlsx'
                            );
                            return;
                          }
                          case 'onboardingCompletedDetails': {
                            const res = await dispatch(
                              distributorOnboardingCompletedDownload(download)
                            );
                            reportDownload(res as unknown as Blob, 'pms-onboarding-completed.xlsx');
                            return;
                          }
                        }
                      } catch (e) {
                        setIsSendLoading(false);
                        console.error((e as Error).message);
                      } finally {
                        setIsSendLoading(false);
                      }
                    }}>
                    Download Report
                  </LoadingButton>
                ) : (
                  <Grid item xs={10} sm={10} md={3} sx={{ mt: 2, mr: 4, display: 'flex' }}>
                    <Select
                      style={{
                        width: '100%',
                        height: '44px',
                        padding: '0 10px',
                        borderRadius: '6px',
                        fontSize: '15px',
                      }}
                      defaultValue={'Export'}
                      onChange={async ({ target: { value } }) => {
                        try {
                          const download = { ...filters, downloadFile: true };
                          if (value === 'Download Current Fields') {
                            setIsSendLoading(true);
                            const res = await dispatch(onBoardingDetailsDownload(download));
                            reportDownload(
                              res as unknown as Blob,
                              'pms-onboarding-details-current-fields.xlsx'
                            );
                          } else if (value === 'Download All Fields') {
                            setIsSendLoading(true);
                            const res = await dispatch(
                              onBoardingDetailsDownloadAllFields(download)
                            );
                            reportDownload(
                              res as unknown as Blob,
                              'pms-onboarding-details-all-fields.xlsx'
                            );
                          }
                        } catch (e) {
                          console.error((e as Error).message);
                        } finally {
                          setIsSendLoading(false);
                        }
                      }}>
                      {onboardingDetailsDownload.map((report) => (
                        <MenuItem value={report} key={report}>
                          {report}
                        </MenuItem>
                      ))}
                    </Select>
                    {isSendLoading && (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress size={30} />
                      </Box>
                    )}
                  </Grid>
                )}
              </Tooltip>
            </Box>
            <Box
              sx={{
                mt: { sm: 8, xs: 4 },
              }}>
              {reportsTable}
            </Box>
          </Box>
        </>
      </CommonLayout>
    </>
  );
}
