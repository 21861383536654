import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles, Item, ItemDetails } from './index';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import {
  SourceOfFundMasters,
  residenceMasters,
  commSentToMaster,
  sourceOfWealthMaster,
  lineOfBusinessMaster,
  sourceForFundOptions,
  DLCLMasters,
  AdditionalDLCLMasters,
} from '../../utils/constant';
import {
  checkNDPMSForIndusindBank,
  isApplicantPEPOrRPEP,
  isCustodianAxis,
  isCustodianKotak,
  isCustodianNuvuma,
  isCustodianOrbis,
} from '../../utils/utilityFunctions';
import { currencyConversion, isCustodianICICIOrHDFC } from '../../utils/utilityFunctions';

export default function KycDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicants = [] } = application || {};
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const selectedCustodian = application?.custodian || '';
  const sourceOfFundMergedMaster = Object.assign({}, SourceOfFundMasters, sourceForFundOptions);
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      {(isCustodianICICIOrHDFC(selectedCustodian) ||
                        isCustodianKotak(selectedCustodian)) && (
                        <KeyValue
                          title={'Income'}
                          description={applicant.income}
                          amount={applicant.income && currencyConversion(applicant.income)}
                        />
                      )}
                      {/* <KeyValue
                        title={'Gross Annual Income as on Date'}
                        description={formatShortDate(applicant.grossAnnualIncomeDate)}
                      />  */}
                      <KeyValue
                        title={'NetWorth (in INR)'}
                        description={applicant.netWorth}
                        amount={applicant.netWorth && currencyConversion(applicant.netWorth)}
                      />
                      <KeyValue
                        title={'Networth as on Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      />

                      {/* <KeyValue
                        title={'Net-Worth (Mandatory For Non-Individuals)'}
                        description={applicant.netWorth}
                      />
                      <KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      {/* checkNdpmsFlow && isCustodianOrbis(selectedCustodian) */}
                      {(isCustodianOrbis(selectedCustodian) || checkNdpmsFlow) && (
                        <KeyValue
                          title={'Source Of Fund'}
                          description={
                            sourceOfFundMergedMaster[applicant.sourceOfFund || ''] ===
                            sourceOfFundMergedMaster.Others
                              ? applicant.sourceOfFundOthers
                              : sourceOfFundMergedMaster[applicant.sourceOfFund || '']
                          }
                        />
                      )}
                      {isCustodianOrbis(selectedCustodian) && (
                        <>
                          <KeyValue
                            title={'Fund Amount'}
                            description={applicant.sourceOfFundAmount}
                            amount={
                              applicant.sourceOfFundAmount &&
                              currencyConversion(applicant.sourceOfFundAmount)
                            }
                          />
                          <KeyValue
                            title={'Source Of Wealth'}
                            description={
                              sourceOfWealthMaster[applicant.sourceOfWealth || ''] ===
                              sourceOfWealthMaster.Others
                                ? applicant.sourceOfWealthOthers
                                : sourceOfWealthMaster[applicant.sourceOfWealth || '']
                            }
                          />
                          <KeyValue
                            title={'Wealth Amount'}
                            description={applicant.sourceOfWealthAmount}
                            amount={
                              applicant.sourceOfWealthAmount &&
                              currencyConversion(applicant.sourceOfWealthAmount)
                            }
                          />
                          <KeyValue
                            title={'Line Of Business / Industry '}
                            description={
                              lineOfBusinessMaster[applicant.lineOfBusiness || ''] ===
                              lineOfBusinessMaster.Others
                                ? applicant.lineOfBusinessOthers
                                : lineOfBusinessMaster[applicant.lineOfBusiness || '']
                            }
                          />
                        </>
                      )}
                      {checkNdpmsFlow && (
                        <>
                          <KeyValue
                            title={'Residence'}
                            description={residenceMasters[applicant.residence || '']}
                          />
                          <KeyValue
                            title={'Monthly Income'}
                            description={applicant.monthlyIncome}
                          />
                          <KeyValue
                            title={'Projected Cash Transaction (Rs. per month)'}
                            description={applicant.projectedCashTransaction}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      {isCustodianAxis(selectedCustodian) &&
                        isApplicantPEPOrRPEP(applicant.politicallyExposedPersonStatus || '') && (
                          <KeyValue
                            title={'Any Other Information'}
                            description={applicant.otherPepInfo}
                          />
                        )}

                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />
                      {(isCustodianICICIOrHDFC(selectedCustodian) ||
                        isCustodianNuvuma(selectedCustodian) ||
                        isCustodianAxis(selectedCustodian) ||
                        isCustodianKotak(selectedCustodian)) && (
                        <KeyValue
                          title={'Communications to be sent to'}
                          description={commSentToMaster[application.commSendTo] || ''}
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
            {isCustodianAxis(selectedCustodian) && (
              <>
                {' '}
                <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>Demat Account Details</SubHeading>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={1}
                    columnSpacing={15}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Demat Account Details (for allotment of units)'}
                      description={DLCLMasters[application.dlclId || '']}
                    />
                    {DLCLMasters[application.dlclId || ''] === DLCLMasters.cdsl && (
                      <KeyValue
                        title={'Additional Type For CDSL'}
                        description={AdditionalDLCLMasters[application.cdslAddtionalType || '']}
                      />
                    )}
                    {/* start to add empty grid for fixing alignment */}
                    <Grid item xs={12} sm={6}>
                      {' '}
                      <Item></Item>
                      <ItemDetails></ItemDetails>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {' '}
                      <Item></Item>
                      <ItemDetails></ItemDetails>
                    </Grid>
                    {/* end to add empty grid for fixing alignment */}
                  </Grid>
                </Gridstyles>
                {isCustodianAxis(selectedCustodian) &&
                  DLCLMasters[application.dlclId || ''] === DLCLMasters.cdsl && (
                    <>
                      {application?.pledgeDeclaration && (
                        <Gridstyles>
                          <Box sx={{ mb: 4 }}>
                            <ItemDetails>
                              I/We would like to instruct the DP to accept all the pledge
                              instructions in my/our account without any other further instruction
                              from my/our end.
                            </ItemDetails>
                          </Box>
                        </Gridstyles>
                      )}
                      {application?.dividendDeclaration && (
                        <Gridstyles>
                          <Box sx={{ mb: 4 }}>
                            <ItemDetails>
                              I/We wish to receive dividend / interest directly into my bank account
                              as given below through ECS (If not marked, the default option would be
                              &quot;Yes&quot;)
                            </ItemDetails>
                          </Box>
                        </Gridstyles>
                      )}
                    </>
                  )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
